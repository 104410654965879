export const generalEnquiries = [
    {
      label: "Do you wish to terminate your current contract (if applicable)?",
      key: "cancel_current_contract",
    },
    {
      label:
        "Does the main driver have any physical peculiarity or illness that may reduce fitness to drive?",
      key: "primary_driver_lessened_driving_ability",
      helper:
        "In this needs analysis, no additional medical information is requested if this question is clicked",
    },
    {
      label:
        "Has the main driver been convicted or prosecuted for drunkenness, alcohol intoxication, refusal of a blood test or hiding offence?",
      key: "primary_driver_convicted",
    },
    {
      label: "Has the main driver already been banned from driving?",
      key: "primary_driver_driving_ban",
    },
    {
      label: "Has the main driver already had his insurance cancelled?",
      key: "primary_driver_refused",
    },
    // {
    //   label:
    //     "I agree that Callant will provide me with formal, business information in a digital format",
    //   key: "agree_formal_data",
    // },
    {
      label:
        "I give permission to Callant Affinity to use my personal data for future marketing campaigns",
      key: "agree_marketing_data",
    },
  ];

export const teacherEnquiries = [
    {
      label: "Did the policyholder sign up for a legal aid policy in the past?",
      key: "previous_legal_aid_policy",
    },
    {
      label:
        "Has the policyholder already subscribed to a family civil liability policy in the past?",
      key: "previous_civil_liability",
    },
    {
      label: "Has the policyholder had any claims in the last 5 years?",
      key: "had_claims_last_five_years",
    },
    // {
    //   label:
    //     "I agree that Callant will provide me with formal, business information in a digital format",
    //   key: "agree_formal_data",
    // },
    {
      label:
        "I give permission to Callant Affinity to use my personal data for future marketing campaigns",
      key: "agree_marketing_data",
    },
  ];

export const legalEnquiries = [
    {
      label: "Did the policyholder sign up for a legal aid policy in the past?",
      key: "previous_legal_aid_policy",
    },
    {
      label: "Has the policyholder had any claims in the last 5 years?",
      key: "had_claims_last_five_years",
    },
    {
      label:
        "Has the application for legal expenses cover in the past been refused by another insurance company?",
      key: "previously_rejected",
    },
    // {
    //   label:
    //     "I agree that Callant will provide me with formal, business information in a digital format",
    //   key: "agree_formal_data",
    // },
    {
      label:
        "I give permission to Callant Affinity to use my personal data for future marketing campaigns",
      key: "agree_marketing_data",
    },
  ];

export const homeEnquiries = [
    {
      label: "Do you currently have house insurance?",
      key: "current_insurance",
    },
    {
      label: "Do you wish to cancel your current insurance through us?",
      key: "cancel_current_insurance",
    },
    {
      label:
        "I give Callant permission to use my personal information for future marketing campaigns",
      key: "agree_marketing_data",
    },
    // {
    //   label:
    //     "I agree that Callant will digitally provide me with formal business information",
    //   key: "agree_formal_data",
    // },
  ];

export function getEnquiries(insuranceType) {
    if (insuranceType === "legal") {
        return legalEnquiries;
    } else if (insuranceType === "teacher") {
        return teacherEnquiries;
    } else if (insuranceType === "home") {
        return homeEnquiries;
    } else {
        return generalEnquiries;
    }
}