/* eslint-disable no-case-declarations */
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useParams } from "react-router";

import AsyncSelect from "react-select/async";

import { func, bool, string } from "prop-types";
import i18n from "../i18n";

import { useDebouncedCallback } from "use-debounce";
import { retrieveAddress } from "../helpers/apiRouterService";
import { cleanAddressData } from "../helpers/addressService";
// import { retrieveStorageById } from '../helpers/storeService';

const FlowAddressInput = ({
  // setDisabled,
  addressValues,
  disabled,
  //   errors,
  fullAddressError,
  title,
}) => {
  const [selected, setSelected] = useState();
  const [isDisabled, setIsDisabled] = useState(disabled);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(fullAddressError);
  const { affinity } = useParams();

  const handleChange = (_selected) => {
    if (_selected) {
      setSelected(_selected);
      setIsDisabled(true);
      setError("");
      setSelected({});
      addressValues({
        postalCode: _selected.value.postalCode,
        municipalityName: _selected.value.municipalityName,
        streetName: _selected.value.streetName,
        streetNumber: _selected.value.streetNumber,
        boxNumber: _selected.value.boxNumber,
      });
    }
  };

  const fetchResults = async (value, callback) => {
    if (hasNumber(value)) {
      setLoading(true);
      const [res] = await retrieveAddress(value);
      setLoading(false);
      callback(cleanAddressData(res));
    }
  };

  const [debounceFetchResults] = useDebouncedCallback(fetchResults, 500);

  const hasNumber = (myString) => {
    return /\d/.test(myString);
  };

  useEffect(() => {
    setError(fullAddressError);
  }, [fullAddressError]);

  const handleFullAddressValidation = () => {
    if (!selected) {
      setError(i18n.t(`required`));
    }
  };

  return (
    <>
      {!isDisabled ? (
        title ? (
          <Label>{title} *</Label>
        ) : (
          <Label>{i18n.t("Fill in your address")} *</Label>
        )
      ) : null}
      <Input
        affinity={affinity}
        isDisabled={isDisabled}
        onChange={(e) => handleChange(e)}
        loadOptions={debounceFetchResults}
        onBlurResetsInput={false}
        onBlur={() => handleFullAddressValidation()}
        error={error}
        isClearable
        menuPlacement="top"
        loadingMessage={() =>
          loading
            ? i18n.t("Searching addresses") + "..."
            : i18n.t("Please enter street and house number")
        }
        noOptionsMessage={() => i18n.t("No results")}
        classNamePrefix="Select"
        value={selected}
        placeholder={i18n.t("Street, number and municipality")}
      />
      <Error>{error}</Error>
    </>
  );
};

const Label = styled.p`
  line-height: 1.8rem;
  font-size: 1.4rem;
  color: ${({ theme }) => theme.typo.subTitle};
`;

const Error = styled.p`
  color: red;
  font-size: 1.1rem;
  width: 100%;
  margin-top: 0.5rem;
  text-align: right;
`;

const Input = styled(AsyncSelect)`
  width: 100%;
  height: 4rem;
  margin-top: 1rem;
  justify-self: center;

  span {
    border-color: ${({ theme, hasValue }) =>
      hasValue ? theme.brand.primary : `#ccc`};
  }

  .Select {
    &__control {
      height: 100%;
      border-radius: 0.3rem;
      border-color: ${({ theme, hasValue, error }) =>
        error ? "red" : hasValue ? theme.brand.primary : `#ccc`};
      z-index: 999;

      :hover {
        border-color: ${({ theme, hasValue }) =>
          hasValue ? theme.brand.primary : `#ccc`};
      }

      &--is-focused {
        border-color: ${({ theme, hasValue }) =>
          hasValue ? theme.brand.primary : `#ccc`};
        box-shadow: none;

        :hover {
          border-color: ${({ theme, hasValue }) =>
            hasValue ? theme.brand.primary : `#ccc`};
        }
      }
    }

    &__value-container {
      padding: 0 1em;
      font-family: 2rem;
      font-size: 1.8rem;
      /* text-transform: uppercase; */
      z-index: 999;

      @media screen and (max-width: 500px) {
        font-size: 1.5rem;
      }
    }

    &__single-value {
      text-transform: capitalize;
      color: ${({ theme }) => theme.brand.primary};
    }

    &__menu {
      font-size: 1.8rem;
      margin-top: 5rem;
      z-index: 999;
      position: relative;
    }

    &__menu-notice--no-options {
      text-align: left;
    }

    &__option {
      z-index: 999;
      line-height: 2.1rem;

      :hover {
        cursor: pointer;
        background-color: ${({ theme }) => theme.brand.lighter};
      }

      &--is-focused {
        background-color: ${({ theme }) => theme.brand.lighter};
        color: ${({ affinity }) =>
          affinity === "agoria" ? "#fff" : "#292929"};
      }

      &--is-selected {
        background: white;
        color: #292929;
      }
    }
  }
`;

FlowAddressInput.propTypes = {
  setDisabled: func,
  addressValues: func,
  disabled: bool,
  errors: func,
  fullAddressError: string,
  clickedSubmit: bool,
  title: string,
};

export default FlowAddressInput;
