import React, { useReducer, useEffect, useState } from "react";
import FlowPriceCalculatorOption from "./FlowPriceCalculatorOption";
import styled from "styled-components";
import pricingOptions from "../constants/PricingOptions";
import { getNestedObject } from "../helpers/objectService";
import { useParams } from "react-router";
import i18n from "../i18n";
import {
  retrieveStorageById /*, patchStorage */,
} from "../helpers/storeService.js";

const FlowPriceCalculatorOptions = ({
  subPrices,
  onChange,
  selectedType,
  carAge,
}) => {
  const { id, affinity, insuranceType } = useParams();
  const { data } = retrieveStorageById(id, affinity, insuranceType);
  const [appliedDiscounts, setAppliedDiscounts] = useState({});

  function handleOptionChange(name, status) {
    if (status) {
      dispatch({ type: "add", value: name });
    } else {
      dispatch({ type: "remove", value: name });
    }
  }

  const [selectedOptions, dispatch] = useReducer((arr, { type, value }) => {
    switch (type) {
      case "add":
        return [...arr, value];
      case "remove":
        return arr.filter((item) => item !== value);
      default:
        return arr;
    }
  }, []);

  useEffect(() => {
    processOptionsqueryString(
      selectedOptions.filter(
        (item) =>
          item !== "civil_liability" &&
          item !== "omnium" &&
          item !== "mini_omnium"
      )
    );
  }, [selectedOptions, selectedType]);

  useEffect(() => {
    // Get information about the taken promotion, if any was taken
    let appliedPromotion = {};
    for (let k = 0; k < subPrices?.promotions?.length; k++) {
      if (subPrices?.promotions[k].applied) {
        appliedPromotion = subPrices?.promotions[k];
      }
    }
    let discounts =
      appliedPromotion?.criteria?.modifications?.multipliers || {};
    setAppliedDiscounts(discounts);
    console.log("Applied discounts:", discounts);
  }, [subPrices]);

  function handleSubPrices(name) {
    let assistanceRoadPremium = 0;
    let driverPremium = 0;
    let accessoriesPremium = 0;

    const quoteOptions = subPrices?.quote?.options || [];
    quoteOptions.forEach((option) => {
      if (option.name === "accessories") {
        accessoriesPremium = option.total_premium;
      } else if (option.name === "driver") {
        driverPremium = option.total_premium;
      } else if (option.name === "assistance_road") {
        assistanceRoadPremium = option.total_premium;
      }
    });

    if (name === "civil_liability") {
      const value = getNestedObject(subPrices, [
        "quote",
        "base",
        "total_premium",
      ]);
      return (
        value +
        assistanceRoadPremium +
        (["honda", "one_automotive"].includes(affinity) && insuranceType === "car" ? driverPremium : 0)
      );
    }

    const options = getNestedObject(subPrices, ["quote", "options"]) || [];
    const currentOption = options.filter((item) => item.name === name)[0] || {};

    if (["mini_omnium", "omnium"].includes(name)) {
      return currentOption.total_premium + accessoriesPremium;
    }

    return currentOption.total_premium || 0;
  }

  function processOptionsqueryString(_selectedOptions) {
    if (_selectedOptions === undefined) return;
    if (_selectedOptions.length === 0) return onChange("");
    const queryString = "option=" + _selectedOptions.join("&option=");

    onChange(queryString);
  }

  const docsData = {
    callant: {
      terms: {
        nl:
          "https://docs.callant.eu/Affinity/AtWork/AV_Callant%20Mobility_20210101_NL.pdf",
        fr:
          "https://docs.callant.eu/Affinity/AtWork/CG_Callant%20Mobility_20210101_FR.pdf",
      },
      ipid: {
        nl:
          "https://docs.callant.eu/Affinity/MotorInsurance/IPID_Callant%20Mobility_20220719_NL.pdf",
        fr:
          "https://docs.callant.eu/Affinity/MotorInsurance/IPID_Callant%20Mobility_20220719_FR.pdf",
      },
    },
    honda: {
      terms: {
        nl:
          "https://docs.callant.eu/Affinity/AtWork/AV_Callant%20Mobility_20210101_NL.pdf",
        fr:
          "https://docs.callant.eu/Affinity/AtWork/CG_Callant%20Mobility_20210101_FR.pdf",
      },
      ipid: {
        nl:
          "https://docs.callant.eu/Affinity/Honda/IPID_Callant%20Mobility_Honda_20210101_NL.pdf",
        fr:
          "https://docs.callant.eu/Affinity/Honda/IPID_Callant%20Mobility_Honda_20210101_FR.pdf",
      },
    },
    one_automotive: {
      terms: {
        nl:
          "https://docs.callant.eu/Affinity/AtWork/AV_Callant%20Mobility_20210101_NL.pdf",
        fr:
          "https://docs.callant.eu/Affinity/AtWork/CG_Callant%20Mobility_20210101_FR.pdf",
      },
      ipid: {
        nl:
          "https://docs.callant.eu/Affinity/Honda/IPID_Callant%20Mobility_Honda_20210101_NL.pdf",
        fr:
          "https://docs.callant.eu/Affinity/Honda/IPID_Callant%20Mobility_Honda_20210101_FR.pdf",
      },
    },
    valckenier: {
      terms: {
        nl:
          "https://docs.callant.eu/Affinity/AtWork/AV_Callant%20Mobility_20210101_NL.pdf",
        fr:
          "https://docs.callant.eu/Affinity/AtWork/CG_Callant%20Mobility_20210101_FR.pdf",
      },
      ipid: {
        nl:
          "https://docs.callant.eu/Affinity/Valckenier/IPID_Callant%20Mobility_Valckenier_20210101_NL.pdf",
        fr:
          "https://docs.callant.eu/Affinity/Valckenier/IPID_Callant%20Mobility_Valckenier_20210101_FR.pdf",
      },
    },
    arag: {
      terms: {
        nl:
          "https://docs.callant.eu/affinity/atwork/AV_Rechtsbijstand_ARAG_NL_.pdf",
        fr:
          "https://docs.callant.eu/affinity/atwork/CG_Protection Juridique_ARAG_FR.pdf",
      },
      ipid: {
        nl:
          "https://docs.callant.eu/affinity/atwork/IPID_Rechtsbijstand_Particulier Basics_ARAG_NL.pdf",
        fr:
          "https://docs.callant.eu/affinity/atwork/IPID_Protection Juridique_Particulier Basics_ARAG_FR.pdf",
      },
    },
  };
  let ipidLink = docsData?.[affinity]?.ipid?.[i18n.language];
  let termsLink = docsData?.[affinity]?.terms?.[i18n.language];
  if (!ipidLink || !termsLink) {
    ipidLink = docsData?.callant?.ipid?.[i18n.language];
    termsLink = docsData?.callant?.terms?.[i18n.language];
  }
  let ipidLinkLegal = docsData?.arag?.ipid?.[i18n.language];
  let termsLinkLegal = docsData?.arag?.terms?.[i18n.language];

  return (
    <>
      {pricingOptions.map(({ id, title, mandatory, helper }) => {
        if (selectedType === "omnium" && id === "mini_omnium") return null;
        if (selectedType === "mini_omnium" && id === "omnium") return null;
        if (selectedType === "civil_liability" && id === "omnium") return null;
        if (selectedType === "civil_liability" && id === "mini_omnium")
          return null;
        if (["assistance_vehicle"].includes(id)) return null;
        if (
          ["driver"].includes(id) &&
          (!["honda", "one_automotive"].includes(affinity) || insuranceType !== "car")
        )
          return null;
        let helperAdditional = null;
        if (id === "omnium" && insuranceType === "car") {
          // Define the (?) info for the franchise
          if (affinity === "honda") {
            helperAdditional = i18n.t(`omnium_description_additional_honda`);
          } else if (affinity === "one_automotive") {
            helperAdditional = i18n.t(`omnium_description_additional_one_automotive`);
          } else if (affinity === "valckenier") {
            helperAdditional = i18n.t(
              `omnium_description_additional_valckenier`,
              {
                franchise: parseFloat(
                  data?.car?.details?.catalogueValue * 0.025
                ).toLocaleString("nl-BE", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }),
              }
            );
          } else {
            helperAdditional = i18n.t(`omnium_description_additional_general`, {
              franchise: parseFloat(
                data?.car?.details?.catalogueValue * 0.025
              ).toLocaleString("nl-BE", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }),
            });
          }
        }
        return (
          <FlowPriceCalculatorOption
            id={id}
            key={id}
            title={i18n.t(title)}
            ipidLink={id === "legal" ? ipidLinkLegal : ipidLink}
            termsLink={id === "legal" ? termsLinkLegal : termsLink}
            disabled={id === "assistance_vehicle" && carAge >= 10}
            onChange={handleOptionChange}
            mandatory={
              ["honda", "one_automotive"].includes(affinity) && id === "driver" && insuranceType === "car"
                ? true
                : mandatory
            }
            price={
              ["honda", "one_automotive"].includes(affinity) && id === "driver" && insuranceType === "car"
                ? i18n.t(`Included`)
                : handleSubPrices(id)
            }
            helperText={`${i18n.t(helper)}${
              helperAdditional ? "<br>" + helperAdditional : ""
            }`}
            defaultValue={id === "legal"}
            discount={
              appliedDiscounts?.[id] !== undefined
                ? 1 - appliedDiscounts?.[id]
                : null
            }
          />
        );
      })}
      <Title>{i18n.t("Recommended options")}</Title>
      {pricingOptions.map(({ id, title, mandatory, helper }) => {
        if (!["assistance_vehicle", "driver"].includes(id)) return null;
        if (
          ["driver"].includes(id) &&
          ["honda", "one_automotive"].includes(affinity) &&
          insuranceType === "car"
        )
          return null;
        return (
          <FlowPriceCalculatorOption
            id={id}
            key={id}
            title={i18n.t(title)}
            ipidLink={id === "legal" ? ipidLinkLegal : ipidLink}
            termsLink={id === "legal" ? termsLinkLegal : termsLink}
            disabled={id === "assistance_vehicle" && carAge >= 10}
            onChange={handleOptionChange}
            mandatory={
              ["honda", "one_automotive"].includes(affinity) && id === "driver" && insuranceType === "car"
                ? true
                : mandatory
            }
            price={handleSubPrices(id)}
            helperText={i18n.t(helper)}
            defaultValue={id === "legal"}
            discount={
              appliedDiscounts?.[id] !== undefined
                ? 1 - appliedDiscounts?.[id]
                : null
            }
          />
        );
      })}
    </>
  );
};

const Title = styled.h2`
  font-size: 1.4rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
`;

export default FlowPriceCalculatorOptions;
