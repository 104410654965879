import React, { useState, useEffect } from "react";
import FlowPolicyHolderInfoFormPrivate from "./FlowPolicyHolderInfoFormPrivate.jsx";
import FlowPolicyHolderInfoFormLegal from "./FlowPolicyHolderInfoFormLegal.jsx";
import FlowPolicyHolderPersonRadio from "./FlowPolicyHolderPersonRadio.jsx";
import { patchStorage } from "../helpers/storeService";
import { useParams, useLocation } from "react-router";
import qs from "query-string";

const FlowPolicyHolderInfoForm = ({ retrieveSubmit, defaultValues }) => {
  const [personType, setPersonType] = useState();
  const { affinity, insuranceType } = useParams();
  const [allowedSwitchPersonType, setAllowedSwitchPersonType] = useState(true);
  const { search } = useLocation();

  const formSubmitHandler = (val) => {
    const tempVal = JSON.parse(JSON.stringify(val));
    // if (tempVal.national_register_nr && !tempVal.national_register_nr.match(/^[0-9][0-9].[0-9][0-9].[0-9][0-9]-[0-9][0-9][0-9].[0-9][0-9]$/)) {
    //   tempVal.national_register_nr = undefined;
    // }
    if (tempVal.telephonenr && tempVal.telephonenr.length < 4) {
      tempVal.telephonenr = undefined;
    }
    tempVal.personType = personType;
    retrieveSubmit(tempVal);
  };

  useEffect(() => {
    if (insuranceType === "teacher" || insuranceType === "legal") {
      setPersonType("natural");
      setAllowedSwitchPersonType(false);
    } else {
      setAllowedSwitchPersonType(true);
      console.log(defaultValues);
      if (defaultValues?.personType) {
        console.log("test");
        setPersonType(defaultValues?.personType);
      }
    }
  }, [defaultValues]);

  useEffect(() => {
    const { sessionId: id } = qs.parse(search);
    if (personType && id) {
      patchStorage({ payload: { policyHolder: { ...defaultValues, personType } }, id, affinity, insuranceType });
    }
  }, [personType]);

  return (
    <>
      {allowedSwitchPersonType ? (
        <FlowPolicyHolderPersonRadio
          personType={(val) => setPersonType(val)}
          checked={personType}
        />
      ) : null}
      {personType === "natural" ? (
        <FlowPolicyHolderInfoFormPrivate
          insuranceType={insuranceType}
          defaultValues={defaultValues}
          sendSubmit={formSubmitHandler}
        />
      ) : null}
      {personType === "legal" ? (
        <FlowPolicyHolderInfoFormLegal
          defaultValues={defaultValues}
          sendSubmit={formSubmitHandler}
        />
      ) : null}
    </>
  );
};

FlowPolicyHolderInfoForm.defaultProps = {
  setId: () => {},
};

export default FlowPolicyHolderInfoForm;
