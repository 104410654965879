import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { bool, func } from "prop-types";
import { useParams } from "react-router";

const ToggleButton = ({ defaultCheck, onChange, className, disabled }) => {
  const [isChecked, setIsChecked] = useState(defaultCheck);
  const { affinity } = useParams();

  function handleToggle() {
    if (disabled) return;
    setIsChecked(!isChecked);
  }

  useEffect(() => {
    onChange(isChecked);
  }, [isChecked]);

  return (
    <div className={className}>
      <label>
        <Input checked={isChecked} onChange={handleToggle} type="checkbox" />
        <Background
          affinity={affinity}
          disabled={disabled}
          isChecked={isChecked}
        >
          <Toggler isChecked={isChecked} />
        </Background>
      </label>
    </div>
  );
};

const Background = styled.div`
  vertical-align: middle;
  border-radius: 3.5rem;
  background-color: ${({ isChecked, theme, affinity }) =>
    isChecked && affinity === "callant"
      ? "#94C11A"
      : isChecked
      ? theme.brand.primary
      : theme.ui.interactive};
  transition-duration: 0.4s;
  transition-property: background-color, box-shadow;
  cursor: ${({ disabled }) => (disabled ? "inherit" : "pointer")};
  opacity: ${({ disabled }) => (disabled ? 0.75 : 1)};
  width: 5rem;
  height: 2.5rem;
  padding-top: 0.15rem;
  padding-left: 0.1rem;
`;

const Toggler = styled.div`
  width: 2.1rem;
  height: 2.1rem;
  border-radius: 50%;
  background: white;
  transition-timing-function: cubic-bezier(1, 0, 0, 1);
  transition-duration: 0.4s;
  transition-property: transform, background-color;
  pointer-events: none;
  margin-top: 1px;
  margin-left: 1px;
  transform: ${({ isChecked }) =>
    isChecked ? `translate3d(2.5rem, 0, 0)` : null};
`;

const Input = styled.input`
  position: absolute;
  opacity: 0;
`;

ToggleButton.defaultProps = {
  defaultCheck: false,
  onChange: () => {},
};

ToggleButton.propTypes = {
  defaultCheck: bool,
  onChange: func,
};

export default ToggleButton;
