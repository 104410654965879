import React from "react";
import styled from "styled-components";
import { useHistory, useParams } from "react-router-dom";
import i18n from "../i18n";

import {
  IconPropertyFilled,
  IconPropertyExtraFilled,
  IconStatusCheck,
  IconIdFilled,
} from "wg-fe-ui";

import FlowProgressBarStepContainer from "./FlowProgressBarStepContainer";
import GoBack from "../assets/icons/GoBack";

const icons = [
  <IconIdFilled key={0} color="white" />,
  <IconPropertyFilled key={1} color="white" />,
  <IconPropertyExtraFilled key={2} color="white" />,
  <IconStatusCheck key={3} color="white" />,
];

const FLowHomeProgressBar = ({ errorState }) => {
  const history = useHistory();
  let historyParams = history.location.pathname.split("/").includes("thankyou");
  const { affinity, insuranceType } = useParams();

  function handleNewSession() {
    history.push(`/${affinity}/${insuranceType}/verify-user`);
  }

  return (
    <StyledBar>
      {!errorState && historyParams ? (
        <StartNewSessionButton
          affinity={affinity}
          onClick={() => handleNewSession()}
        >
          {i18n.t("Calculate another rate")}
        </StartNewSessionButton>
      ) : (
        <GoBack
          data-test-it="underwriting_goback_nav"
          onClick={() => history.goBack()}
        />
      )}
      <ProgressBar>
        {icons.map((icon, key) => {
          return (
            <FlowProgressBarStepContainer icon={icon} key={key} id={key + 1} />
          );
        })}
      </ProgressBar>
    </StyledBar>
  );
};

const StartNewSessionButton = styled.button`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #df2815;
  border-radius: 15px;
  border: none;
  margin-right: 2rem;
  height: 5.6rem;
  color: white;
  font-weight: bold;
  font-size: 1.5rem;

  @media screen and (max-width: 800px) {
    margin-right: 3.5rem;
  }
`;

const ProgressBar = styled.div`
  display: flex;
  justify-content: space-around;
  width: 80%;
`;

const StyledBar = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1440px;
  width: 85%;
  margin-bottom: 3rem;

  @media screen and (max-width: 425px) {
    display: none;
  }
`;

export default FLowHomeProgressBar;
