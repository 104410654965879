import React, { forwardRef, useState, useEffect } from "react";
import {
  bool,
  node,
  number,
  func,
  string,
  object,
  oneOfType,
} from "prop-types";
import styled from "styled-components";
import Error, { getBorderColor } from "./Error";
import NumberFormat from "react-number-format";

const CurrencyInput = forwardRef(
  (
    {
      className,
      children,
      name,
      disabled,
      onChange,
      setFieldTouched,
      error,
      touched,
      value,
      ...otherProps
    },
    ref
  ) => {
    const [inputValue, setInputValue] = useState(value);

    useEffect(() => {
      if (inputValue !== value) {
        setInputValue(value);
      }
    }, [value]);

    const handleChange = (e) => {
      setInputValue(e.value);
      onChange({ name, value: e.value });
    };
    const handleBlur = () => {
      setFieldTouched(name, true);
    };

    return (
      <Container className={className}>
        <StyledLabel disabled={disabled}>
          {children}
          <StyledNumberFormat
            inputMode="numeric"
            isNumericString={true}
            thousandSeparator={"."}
            decimalSeparator={","}
            // prefix={"€ "}
            decimalScale={2}
            ref={ref}
            name={name}
            disabled={disabled}
            touched={touched}
            value={inputValue}
            onValueChange={handleChange}
            onBlur={handleBlur}
            {...otherProps}
          />
        </StyledLabel>
        <Error error={error} />
      </Container>
    );
  }
);

const Container = styled.div`
  width: 100%;
  font-family: ${({ theme }) => theme.font};
  position: relative;
  height: 9rem;
`;

const StyledLabel = styled.label`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  font-size: 1.4rem;
  color: ${(props) => (props.disabled ? "#AEAEAE" : "#5B5550")};
  line-height: 1rem;
`;

const StyledNumberFormat = styled(NumberFormat)`
  background-color: ${(props) => (props.disabled ? "#F0F1F3" : "white")};
  width: 100%;
  border: 0.1rem solid;
  border-color: ${({ error, touched, theme, warning }) =>
    getBorderColor(error, touched, theme, warning)};
  border-radius: 0.3rem;
  height: 4rem;
  font-size: 1.6rem;
  margin-top: 1.4rem;
  box-sizing: border-box;
  padding-right: ${({ symbolSide, symbol }) =>
    symbol !== "" && symbolSide === "right" ? "4.7rem" : "0.7rem"};
  padding-left: ${({ symbolSide, symbol }) =>
    symbol !== "" && symbolSide === "left" ? "4.7rem" : "0.7rem"};

  @media screen and (max-width: 425px) {
    font-size: 1.4rem;
  }

  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.brand.primary};
  }

  &::placeholder {
    color: ${({ theme }) => theme.ui.interactive};
  }
`;

CurrencyInput.defaultProps = {
  disabled: false,
  placeholder: "",
  error: "",
  warning: "",
  symbol: "",
  symbolSide: "right",
  symbolText: false,
  touched: false,
  type: "text",
  setFieldTouched: () => {},
  onChange: () => {},
  otherProps: {},
  value: "",
};

CurrencyInput.propTypes = {
  /** Beeing able to use it in Styled Components */
  className: string,
  /** label above the input */
  children: node.isRequired,
  /** name of input and label */
  name: string.isRequired,
  /** type of input: email, text, ... */
  type: string,
  /** if true input is disabled */
  disabled: bool,
  /** example value in the input */
  placeholder: string,
  /** string with errormessage */
  error: string,
  /** string with warningmessage */
  warning: string,
  /** Pass an icon name to show said icon inside the input  */
  symbol: string,
  /** Choose a side at which the icon will be shown */
  symbolSide: string,
  /** Picks whether to show the symbol as an icon or as text */
  symbolText: bool,
  /** boolean to check if inputfield is touched */
  touched: bool,
  /** returns name and touched boolean */
  setFieldTouched: func,
  /** returns onChange event */
  onChange: func,
  /** Adds extra props to the element */
  otherProps: object,
  /** sets initial value */
  value: oneOfType([string, number]),
};

CurrencyInput.displayName = "CurrencyInput";

export default CurrencyInput;
