import React from "react";
import styled from "styled-components";
import i18n from "../i18n";

const ListItem = ({ selectedPack, value, type }) => {
  const checked = (selectedPack === "BRONZE" && type === "BRONZE") || (selectedPack === "SILVER" && (type === "BRONZE" || type === "SILVER")) || (selectedPack === "GOLD")
  return (
    <LI>
      <Checkbox checked={checked}>{checked ? "✓" : "✗"}</Checkbox>
      {i18n.t(value)}
    </LI>
  );
};

const Checkbox = styled.p`
  margin-right: 1rem;
  color: ${({ checked }) => checked ? "green" : "red"}
`;

const LI = styled.li`
  display: flex;
`;

export default ListItem;
