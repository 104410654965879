import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { LoadingSpinner } from "wg-fe-ui/dist";

import FlowSearchForBikeForm from "../components/FlowSearchForBikeForm.jsx";
import useRouting from "../hooks/useRouting.js";
import { useParams } from "react-router";
import { patchStorage, retrieveStorageById } from "../helpers/storeService.js";
import louise_logo from "../assets/images/louise_logo.svg";

import i18n from "../i18n";

const FlowSearchForBike = () => {
  const [carBrandImage, setCarBrandImage] = useState(louise_logo);

  const [defaultValues, setDefaultValues] = useState({});
  const { renderNextRoute } = useRouting();
  const { id, affinity, insuranceType } = useParams();
  const [loading] = useState(false);

  useEffect(() => {
    const { data } = retrieveStorageById(id, affinity, insuranceType);
    setDefaultValues(data);
  }, []);

  function handleBrandLogo(img) {
    if (!img) {
      return;
    } else {
      setCarBrandImage(img);
    }
  }

  function handleManual() {
    const { data } = retrieveStorageById(id, affinity, insuranceType);
    const { isMainVehicle, vehicleType } = data?.twoWheeler?.details || {};
    patchStorage({
      payload: { twoWheeler: {...data?.twoWheeler, details: { isMainVehicle, vehicleType } } },
      id,
      affinity,
      insuranceType,
    });
    renderNextRoute();
  }

  function handleSubmit(bikeInfo, licensePlate, vinNumber) {
    if (!bikeInfo) return;
    if (licensePlate) {
      bikeInfo.licensePlate = licensePlate.value;
    }
    if (vinNumber) {
      bikeInfo.vinNumber = vinNumber.value;
    }
    bikeInfo.model_year = null;
    bikeInfo["catalogueValue"] = bikeInfo.price;
    bikeInfo["motorType"] = bikeInfo.motor_type;
    bikeInfo["bikeImg"] = carBrandImage;
    delete bikeInfo.price;
    delete bikeInfo.motor_type;
    console.log(bikeInfo);
    bikeInfo.images = [];
    if (bikeInfo.images === [] || bikeInfo.images === undefined) {
      bikeInfo.images = [carBrandImage];
    }

    const { data } = retrieveStorageById(id, affinity, insuranceType);
    patchStorage({
      payload: { twoWheeler: {...data?.twoWheeler, details: { ...data?.twoWheeler?.details, ...bikeInfo } } },
      id,
      affinity,
      insuranceType,
    });
    renderNextRoute();
  }

  if (loading) {
    return (
      <LoadingContainer>
        <LoadingSpinner />
      </LoadingContainer>
    );
  }

  return (
    <SplitContainer>
      <LeftSplit>
        <RoundImageContainer>
          <RoundImage img={carBrandImage} alt="car brand logo" />
        </RoundImageContainer>
        <TextBalloon>
          <p
            dangerouslySetInnerHTML={{
              __html: i18n.t(
                "Next I need some information about your motorcycle You can fill it out here on the right"
              ),
            }}
          ></p>
        </TextBalloon>
      </LeftSplit>
      <RightSplit>
        <FlowSearchForBikeForm
          defaultValues={defaultValues}
          handleBrandLogo={handleBrandLogo}
          handleSubmit={handleSubmit}
          handleManual={handleManual}
        />
      </RightSplit>
    </SplitContainer>
  );
};

const TextBalloon = styled.div`
  display: flex;
  font-size: 1.6rem;
  line-height: 1.5;
  width: 100%;
  border-radius: 0.5rem;
  color: #393939;
  background: #f3f3f3;
  padding: 3rem;
  margin-top: auto;
  > span {
    font-weight: 700;
  }
  .text {
    width: 90%;
  }
`;

const RightSplit = styled.div`
  width: 50%;
  overflow-y: auto;

  @media (max-width: 768px) {
    width: 100%;
  }
  /* 
  @media screen and (max-width: 425px) {
    padding: 4rem 2.5rem;
  } */
`;

const SplitContainer = styled.div`
  display: flex;
  height: 100%;
`;

const LeftSplit = styled.div`
  width: 50%;
  padding: 5rem 4rem;
  display: grid;
  grid-template-rows: 75% auto;
  grid-gap: 15px 0;
  justify-items: center;

  :after {
    content: "";
    width: 1px;
    height: 70%;
    background: rgba(0, 0, 0, 0.3);
    position: absolute;
    right: 50%;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const RoundImage = styled.div`
  background-image: url(${(props) => props.img});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
  width: 100%;
  height: auto;
  padding-top: 100%;
`;

const RoundImageContainer = styled.div`
  max-width: 35rem;
  width: 100%;
  margin-top: auto;
`;

const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default FlowSearchForBike;
