import React, { useEffect, useState } from "react";
import styled from "styled-components";
import useForm from "../hooks/useForm.js";
import { TextInput, SearchSelectInput, ActionButton } from "wg-fe-ui";
import * as Yup from "yup";
import {
  string,
  number,
  cylinder_capacity,
  cylinder_capacity_light,
  year,
  catalogueValueBike,
} from "../constants/validationSchemas.js";
import { parseIntSafely } from "../helpers/intService";
import {
  fuelType,
  motoCategoryOptions,
} from "../constants/FlowSearchSelectData.js";
import OptionsPriceInput from "./VATInputSwitcherV2";
import i18n from "../i18n";
import { patchStorage, retrieveStorageById } from "../helpers/storeService.js";
import { useParams } from "react-router";
import InfoPopup from "./InfoPopup";

const FlowManualBikeForm = ({ defaultValues, resetImage, renderNextRoute }) => {
  const { id, affinity, insuranceType } = useParams();
  const { data } = retrieveStorageById(id, affinity, insuranceType);
  const [fuelTypeOptions, setFuelTypeOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState();
  const [errorOptions, setErrorOptions] = useState("");
  const SelectPlaceholder = i18n.t("Choose your option");
  console.log(defaultValues);

  const validationSchema = Yup.object().shape({
    brand: string.required,
    series: string.required,
    model: string.notRequired,
    category: string.notRequired,
    motorType: string.required,
    registrationYear: year.required.test(
      "valid-registration-year",
      i18n.t("Date cant be in the future"),
      handleRegistrationYear
    ),
    cc:
      data?.twoWheeler?.details?.vehicleType === "LIGHT_SCOOTER"
        ? cylinder_capacity_light
        : cylinder_capacity,
    kw: number.notRequired,
    catalogueValue:
      data?.twoWheeler?.details?.vehicleType === "LIGHT_SCOOTER"
        ? number.notRequired
        : catalogueValueBike,
  });

  const { errors, values, handleSubmit, handleChange } = useForm({
    validationSchema,
  });

  useEffect(() => {
    Object.keys(defaultValues || {}).forEach((name) => {
      handleChange({ name, value: defaultValues[name] });
    });
    console.log(defaultValues);
    resetImage(defaultValues.bikeImg);
  }, [defaultValues]);

  // useEffect(() => {
  //   handleChange({ name: "brand", value: defaultValues?.bike_info.brand });
  //   handleChange({ name: "model", value: defaultValues?.bike_info.model });
  //   handleChange({
  //     name: "cc",
  //     value: defaultValues?.bike_info.cc
  //   });
  // }, [defaultValues]);

  function handleRegistrationYear() {
    const { registrationYear } = this.parent;
    const firstRegistrationDate = new Date(registrationYear, 0, 1);
    return firstRegistrationDate <= new Date();
  }

  const formSubmitHandler = (e) => {
    e.preventDefault();
    handleSubmit(handleFormValues);
  };

  const handleFormValues = () => {
    const { data } = retrieveStorageById(id, affinity, insuranceType);
    patchStorage({
      payload: {
        twoWheeler: {
          ...data?.twoWheeler,
          details: { ...data?.twoWheeler?.details, ...values },
        },
      },
      id,
      affinity,
      insuranceType,
    });
    if (insuranceType === "two-wheeler") {
      // 1. BikePriceCalculation
      // 2. LightScooterPriceCalculation
      // 3. NotAcceptedPage
      if (data?.twoWheeler?.details?.vehicleType === "LIGHT_SCOOTER") {
        isPersonAccepted() ? renderNextRoute(2) : renderNextRoute(3);
      } else {
        isPersonAccepted() ? renderNextRoute(1) : renderNextRoute(3);
      }
    } else if (insuranceType === "6-wheeler") {
      // 1. BikePriceCalculation
      // 2. LightScooterPriceCalculation
      // 3. NotAcceptedPage
      // 4. CarPriceCalculation
      if (data?.selectedFormula === "MOTOR_ALREADY_INSURED") {
        isPersonAccepted() ? renderNextRoute(4) : renderNextRoute(3);
      } else if (data?.selectedFormula === "MOTOR_COMPANY_CAR") {
        // 1. BikePriceCalculation
        // 2. LightScooterPriceCalculation
        // 3. NotAcceptedPage
        // 4. CarPriceCalculation
        if (data?.twoWheeler?.details?.vehicleType === "LIGHT_SCOOTER") {
          isPersonAccepted() ? renderNextRoute(2) : renderNextRoute(3);
        } else {
          isPersonAccepted() ? renderNextRoute(1) : renderNextRoute(3);
        }
      } else if (data?.selectedFormula === "6WHEELS") {
        isPersonAccepted() ? renderNextRoute(4) : renderNextRoute(3);
      } else if (data?.selectedFormula === "CAR_ALREADY_INSURED") {
        if (data?.twoWheeler?.details?.vehicleType === "LIGHT_SCOOTER") {
          isPersonAccepted() ? renderNextRoute(2) : renderNextRoute(3);
        } else {
          isPersonAccepted() ? renderNextRoute(1) : renderNextRoute(3);
        }
      }
    } else {
      // 1. CarPriceCalculation
      // 2. NotAcceptedPage
      isPersonAccepted() ? renderNextRoute(1) : renderNextRoute(2);
    }
  };

  function getAge(dateString) {
    var today = new Date();
    const newDateString = dateString.split("/");
    let newVal =
      newDateString[1] + "/" + newDateString[0] + "/" + newDateString[2];
    var birthDate = new Date(newVal);
    console.log(newVal);

    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  function isPersonAccepted() {
    const { data } = retrieveStorageById(id, affinity, insuranceType);
    console.log(data);
    if (
      insuranceType === "car" ||
      (insuranceType === "6-wheeler" &&
        ["MOTOR_ALREADY_INSURED", "6WHEELS"].includes(data?.selectedFormula))
    ) {
      const birthDateString = data?.car?.mainDriver?.birth;
      let age = getAge(birthDateString);
      let driverLicenseAge = getAge(data?.car?.mainDriver?.issueLicenseDate);
      const bm = parseInt(data?.car?.mainDriver?.bonusMalus);
      const claimsInFault = parseIntSafely(
        data?.car?.mainDriver?.claims_in_fault
      );
      if (age < 18) {
        patchStorage({
          payload: { rejectionDescription: i18n.t(`Age < 18`) },
          id,
          affinity,
          insuranceType,
        });
        return false;
      } else if (claimsInFault > 2) {
        patchStorage({
          payload: {
            rejectionDescription: i18n.t(`No. of claims last 5 years > 2`),
          },
          id,
          affinity,
          insuranceType,
        });
        return false;
      } else if (data?.car?.details?.kw > 200) {
        patchStorage({
          payload: { rejectionDescription: i18n.t(`Car KW > 200`) },
          id,
          affinity,
          insuranceType,
        });
        return false;
      } else if (data?.car?.details?.catalogueValue > 75000) {
        patchStorage({
          payload: { rejectionDescription: i18n.t(`Car value > 75000`) },
          id,
          affinity,
          insuranceType,
        });
        return false;
      } else if (data?.car?.mainDriver?.driverLicenseType === "PROVISIONAL") {
        patchStorage({
          payload: {
            rejectionDescription: i18n.t(`Main driver has provisional license`),
          },
          id,
          affinity,
          insuranceType,
        });
        return false;
      } else if (
        age < 25 &&
        affinity === "honda" &&
        data?.car?.details?.kw > 90
      ) {
        patchStorage({
          payload: { rejectionDescription: i18n.t(`Age < 25 & Car KW > 90`) },
          id,
          affinity,
          insuranceType,
        });
        return false;
      } else if (
        age < 25 &&
        affinity !== "honda" &&
        data?.car?.details?.kw > 85
      ) {
        patchStorage({
          payload: { rejectionDescription: i18n.t(`Age < 25 & Car KW > 85`) },
          id,
          affinity,
          insuranceType,
        });
        return false;
      } else if (age < 25 && claimsInFault > 0) {
        patchStorage({
          payload: {
            rejectionDescription: i18n.t(
              `Age < 25 & No. of claims last 5 years > 0`
            ),
          },
          id,
          affinity,
          insuranceType,
        });
        return false;
      } else if (
        age < 30 &&
        driverLicenseAge < 1 &&
        affinity !== "honda" &&
        data?.car?.details?.kw > 85
      ) {
        patchStorage({
          payload: {
            rejectionDescription: i18n.t(
              `Age < 30 & Less than one year driver license & Car KW > 85`
            ),
          },
          id,
          affinity,
          insuranceType,
        });
        return false;
      } else if (
        age < 30 &&
        driverLicenseAge < 1 &&
        affinity === "honda" &&
        data?.car?.details?.kw > 85
      ) {
        patchStorage({
          payload: {
            rejectionDescription: i18n.t(
              `Age < 30 & Less than one year driver license & Car KW > 90`
            ),
          },
          id,
          affinity,
          insuranceType,
        });
        return false;
      } else if (age < 30 && driverLicenseAge < 1 && claimsInFault > 0) {
        patchStorage({
          payload: {
            rejectionDescription: i18n.t(
              `Age < 30 & Less than one year driver license & No. of claims last year > 0`
            ),
          },
          id,
          affinity,
          insuranceType,
        });
        return false;
      } else if (bm > 11) {
        patchStorage({
          payload: { rejectionDescription: i18n.t(`Bonus malus > 11`) },
          id,
          affinity,
          insuranceType,
        });
        return false;
      } else if (age >= 65 && bm > 3) {
        patchStorage({
          payload: {
            rejectionDescription: i18n.t(`Age > 65 & Bonus malus > 3`),
          },
          id,
          affinity,
          insuranceType,
        });
        return false;
      } else {
        return true;
      }
    }

    if (
      insuranceType === "two-wheeler" ||
      (insuranceType === "6-wheeler" &&
        ["MOTOR_COMPANY_CAR", "CAR_ALREADY_INSURED", "6WHEELS"].includes(
          data?.selectedFormula
        ))
    ) {
      const birthDateString = data?.twoWheeler?.mainDriver?.birth;
      let age = getAge(birthDateString);
      let driverLicenseAge = data?.twoWheeler?.mainDriver?.issueLicenseDate
        ? getAge(data?.twoWheeler?.mainDriver?.issueLicenseDate)
        : null;
      const claimsInFault = parseIntSafely(
        data?.twoWheeler?.mainDriver?.claims_in_fault
      );
      if (driverLicenseAge !== null && age < 18) {
        patchStorage({
          payload: { rejectionDescription: i18n.t(`Age < 18`) },
          id,
          affinity,
          insuranceType,
        });
        return false;
      } else if (claimsInFault > 2) {
        patchStorage({
          payload: {
            rejectionDescription: i18n.t(`No. of claims last 5 years > 2`),
          },
          id,
          affinity,
          insuranceType,
        });
        return false;
      } else if (data?.twoWheeler?.details?.cc > 2000) {
        patchStorage({
          payload: { rejectionDescription: i18n.t(`Moto CC > 2000`) },
          id,
          affinity,
          insuranceType,
        });
        return false;
      } else if (
        data?.twoWheeler?.mainDriver?.driverLicenseType === "PROVISIONAL"
      ) {
        patchStorage({
          payload: {
            rejectionDescription: i18n.t(`Main driver has provisional license`),
          },
          id,
          affinity,
          insuranceType,
        });
        return false;
      } else if (
        driverLicenseAge != null &&
        age < 25 &&
        data?.twoWheeler?.details?.cc > 2000
      ) {
        patchStorage({
          payload: {
            rejectionDescription: i18n.t(`Age < 25 & Moto CC > 2000`),
          },
          id,
          affinity,
          insuranceType,
        });
        return false;
      } else if (driverLicenseAge != null && age < 25 && claimsInFault > 0) {
        patchStorage({
          payload: {
            rejectionDescription: i18n.t(
              `Age < 25 & No. of claims last 5 years > 0`
            ),
          },
          id,
          affinity,
          insuranceType,
        });
        return false;
      } else if (
        driverLicenseAge !== null &&
        age < 30 &&
        driverLicenseAge < 1 &&
        data?.twoWheeler?.details?.cc > 2000
      ) {
        patchStorage({
          payload: {
            rejectionDescription: i18n.t(
              `Age < 30 & Less than one year driver license & Moto CC > 2000`
            ),
          },
          id,
          affinity,
          insuranceType,
        });
        return false;
      } else if (
        driverLicenseAge != null &&
        age < 30 &&
        driverLicenseAge < 1 &&
        claimsInFault > 0
      ) {
        patchStorage({
          payload: {
            rejectionDescription: i18n.t(
              `Age < 30 & Less than one year driver license & No. of claims last year > 0`
            ),
          },
          id,
          affinity,
          insuranceType,
        });
        return false;
      } else {
        return true;
      }
    }

    return true;
  }

  useEffect(() => {
    loadSelectOptions();
  }, []);

  function loadSelectOptions() {
    setFuelTypeOptions(
      fuelType.map(({ value, label }) => {
        return { value, label: i18n.t(label) };
      })
    );
    setCategoryOptions(
      motoCategoryOptions.map(({ value, label }) => {
        return { value, label: i18n.t(label) };
      })
    );
  }

  function handleSelectValue(object, inputName) {
    if (!values[inputName]) return "";
    const key = values[inputName];
    return object.filter(({ value }) => value === key)[0];
  }

  function handleSelectChange(val, name) {
    handleChange({ name, value: val ? val.value : "" });
  }
  console.log(insuranceType);

  return (
    <Form onSubmit={formSubmitHandler}>
      <FlexWrapper>
        <StyledTextInput
          name="brand"
          error={errors.brand}
          onChange={(val) => {
            handleChange(val);
          }}
          value={defaultValues?.brand}
        >
          {i18n.t("Brand")} *
        </StyledTextInput>
        <StyledTextInput
          name="series"
          error={errors.series}
          onChange={(val) => {
            handleChange(val);
          }}
          value={defaultValues?.series}
        >
          {i18n.t("Series")} *
        </StyledTextInput>
      </FlexWrapper>
      <FlexWrapper>
        <StyledTextInput
          name="model"
          error={errors.model}
          onChange={(val) => {
            handleChange(val);
          }}
          value={defaultValues?.model}
        >
          {i18n.t("Model")}
        </StyledTextInput>
        <StyledSearchSelectInput
          onChange={(val) => {
            handleSelectChange(val, "motorType");
          }}
          name="motorType"
          error={errors.motorType}
          options={fuelTypeOptions}
          value={handleSelectValue(fuelTypeOptions, "motorType")}
          placeholder={
            defaultValues?.motorType
              ? defaultValues?.motorType
              : SelectPlaceholder
          }
          checked={defaultValues?.motorType != null}
        >
          {i18n.t("Fuel type")}
        </StyledSearchSelectInput>
      </FlexWrapper>
      <FlexWrapper>
        <StyledHelperInput
          name="registrationYear"
          error={errors.registrationYear}
          onChange={(val) => {
            handleChange(val);
          }}
          value={values.registrationYear}
        >
          <StyledLabel>
            {i18n.t("Registration year")} *
            <StyledInfoPopup
              margin-top="-.55rem"
              title={i18n.t("Registration year")}
              info={i18n.t("Registration year info")}
            />
          </StyledLabel>
        </StyledHelperInput>
        <StyledSearchSelectInput
          onChange={(val) => {
            handleSelectChange(val, "category");
          }}
          name="category"
          error={errors.category}
          disabled={defaultValues?.vehicleType !== "MOTORCYCLE" ? true : false}
          options={categoryOptions}
          value={handleSelectValue(categoryOptions, "category")}
          defaultValue={defaultValues?.category ? defaultValues?.category : ""}
          placeholder={
            defaultValues?.category
              ? defaultValues?.category
              : SelectPlaceholder
          }
          checked={defaultValues?.category != null}
        >
          {i18n.t("Category")} *
        </StyledSearchSelectInput>
      </FlexWrapper>
      <FlexWrapper>
        <StyledHelperInput
          name="cc"
          error={errors.cc}
          onChange={(val) => {
            handleChange(val);
          }}
          value={defaultValues?.cc}
        >
          <StyledLabel>{i18n.t("Cylinder capacity")} *</StyledLabel>
          <p className="valueType">CC</p>
        </StyledHelperInput>
        <StyledHelperInput
          name="horsepower"
          error={errors.kw}
          onChange={(val) => {
            handleChange(val);
          }}
          value={defaultValues?.horsepower}
        >
          <StyledLabel>{i18n.t("kw")} </StyledLabel>
          <p className="valueType">{i18n.t("HP")}</p>
        </StyledHelperInput>
      </FlexWrapper>
      {data?.twoWheeler?.details?.vehicleType === "LIGHT_SCOOTER" ? (
        ""
      ) : (
        <>
          <OptionsPriceInput
            name="catalogueValue"
            id="catalogueValue"
            error={errors.catalogueValue}
            onChange={({ value, isVATInclusive }) =>
              handleChange({
                name: "catalogueValue",
                value: isVATInclusive ? (value / 1.21).toFixed(2) : value,
              })
            }
            value={values.catalogueValue}
            defaultValue={
              defaultValues?.catalogueValue ? defaultValues?.catalogueValue : 0
            }
            popUpTitle={i18n.t("Invoice value")}
            popUpInfo={i18n.t(
              "The invoice value of the motorcycle is the new price This price does not include discounts, trade-in conditions and show-room conditions The entry of the value of your car can be done excluding or including VAT, by clicking on the `Excl/Incl VAT` button"
            )}
          >
            {i18n.t("Invoice value")} *
          </OptionsPriceInput>
          <p className="catalogueValue">
            {errors.catalogueValue
              ? ""
              : i18n.t(
                  "Carefully check the details on your invoice / order form"
                )}
          </p>
          <OptionsPriceInput
            name="options_value"
            id="options_value"
            error={errors.options}
            onChange={({ value, isVATInclusive }) =>
              handleChange({
                name: "options_value",
                value: isVATInclusive ? (value / 1.21).toFixed(2) : value,
              })
            }
            value={values.options_value}
            popUpTitle={i18n.t("Value of additional options")}
            popUpInfo={i18n.t("optionsValuePopupText")}
          >
            <Options>{i18n.t("Value of additional options")}</Options>
          </OptionsPriceInput>
          <OptionsPriceInput
            name="clothing_value"
            id="clothing_value"
            error={errorOptions}
            onChange={({ value, isVATInclusive }) => {
              if (value > 2500) {
                setErrorOptions("Max €2500 allowed");
              } else {
                setErrorOptions("");
              }
              handleChange({
                name: "clothing_value",
                value: isVATInclusive ? (value / 1.21).toFixed(2) : value,
              });
            }}
            popUpTitle={i18n.t("Clothing")}
            popUpInfo={i18n.t(
              "Damage to clothing will only be compensated if it goes hand in hand with reimbursable damage to the motorcycle (see warranties (mini-)omnium) and is limited to EUR 2500"
            )}
            value={values.clothing_value}
          >
            <Options>{i18n.t("Clothing")}</Options>
          </OptionsPriceInput>
        </>
      )}
      <ButtonContainer>
        <Required>* {i18n.t("Required fields")}</Required>
        <StyledActionButton
          type="submit"
          affinity={affinity}
          data-test-id="manual_vehicle_flow_submit"
        >
          {i18n.t("Next")}
        </StyledActionButton>
      </ButtonContainer>
    </Form>
  );
};

const StyledSearchSelectInput = styled(SearchSelectInput)`
  .Select__placeholder {
    color: ${({ checked }) => (checked ? "black" : "hsl(0,0%,50%)")};
  }
`;
const Required = styled.p`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.typo.label};
  margin-top: 2rem;
  @media (max-width: 768px) {
    margin: 0 2rem 2rem 2rem;
  }
`;

const StyledActionButton = styled(ActionButton)`
  color: ${({ affinity }) => (affinity === "de-lijn" ? "black" : "white")};
  background-color: ${({ affinity }) =>
    affinity === "callant" ? "#94C11A" : "default"};
  font-weight: ${({ affinity }) => (affinity === "de-lijn" ? "700" : "normal")};
`;

const StyledLabel = styled.div`
  height: 1rem;
  a {
    margin-top: -0.55rem;
  }
`;

const Options = styled.span`
  flex-direction: column;
  position: relative;
  width: 100%;
  font-size: 1.4rem;
  color: #5b5550;
  line-height: 1.1rem;
  display: flex;
  height: 1rem;
  margin: 0;
`;

const Form = styled.form`
  p.catalogueValue {
    color: grey;
    font-size: 1.3rem;
    margin: -2rem 0 2rem 0.5rem;
  }
`;

const StyledTextInput = styled(TextInput)`
  height: 9rem;
  svg {
    display: none;
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  & > div {
    width: 48%;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;

  & button {
    width: 20rem;
    margin-left: auto;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    & button {
      width: 100%;
    }
  }
`;

const StyledHelperInput = styled(StyledTextInput)`
  right: 0;
  p.valueType {
    padding: 1.4rem;
    border-left: 1px solid #cccccc;
    position: absolute;
    margin: 0;
    right: 0rem;
    top: 2.5rem;
  }
  svg {
    display: none;
  }
`;

const StyledInfoPopup = styled(InfoPopup)`
  float: right;
  font-size: 1.2rem;
  height: 1.6rem;
  line-height: 1.3;
  width: 1.6rem;
  right: 0;
  position: absolute;
`;

export default FlowManualBikeForm;
