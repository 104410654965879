import React, { useEffect, useState } from "react";
import styled from "styled-components";
import i18n from "../i18n";

import { TextInput, Label, SearchSelectInput } from "wg-fe-ui";
import DateInput from "../components/DateInput";
import "../assets/css/cogoToast.css";
import { object, func } from "prop-types";
import {
  string,
  email,
  firstName,
  lastName,
  date,
  telephonenr,
  captcha,
} from "../constants/validationSchemas";
import InfoPopup from "./InfoPopup";
import {
  parseObjectToNewDate,
  parseIncDateToObject,
} from "../helpers/dateService.js";
import { differenceInYears } from "date-fns";

import { primaryOptions as primaryOptionsConstant } from "../constants/FlowSearchSelectData";
import useForm from "../hooks/useForm";
import * as Yup from "yup";
import FlowPolicyHolderInfoFormDefaultInputs from "./FlowPolicyHolderInfoFormDefaultInputs";
import { useParams } from "react-router";

const phoneUtil = require("google-libphonenumber").PhoneNumberUtil.getInstance();

const FlowPolicyHolderInfoFormPrivate = ({ sendSubmit, defaultValues }) => {
  const { insuranceType } = useParams();
  const [primaryOptions, setPrimaryOptions] = useState([]);
  const [, setBillingAddressOptions] = useState([]);
  const [, setBirthValue] = useState(false);
  const validationSchema = Yup.object().shape({
    email: email,
    firstName: firstName,
    captcha: captcha,
    lastName: lastName,
    birth: date.test(
      "valid-birth",
      i18n.t("Insuree is too young"),
      handleBirthValidation
    ),
    primary: insuranceType === "car" ? string.required : string.notRequired,
    // national_register_nr: nationalRegisterNr,
    telephonenr: telephonenr.notRequired.test(
      "Phone number",
      i18n.t(`Please enter a valid telephone number`),
      (value) => {
        if (value) {
          const input = value.replace(/ /g, "");
          if (input.length >= 8) {
            try {
              const number = phoneUtil.parseAndKeepRawInput(input);
              if (phoneUtil.isValidNumber(number)) {
                return true;
              }
            } catch (e) {
              return false;
            }
            return false;
          } else if (value.length < 4) {
            return true;
          }
          return false;
        }
        return true;
      }
    ),
  });
  function handleBirthValidation() {
    const { birth } = this.parent;

    const dateObject = parseIncDateToObject(birth);
    const birthDate = parseObjectToNewDate(dateObject);
    return (
      17 <= differenceInYears(new Date(), birthDate) &&
      differenceInYears(new Date(), birthDate) <= 100
    );
  }
  function handleSelectValue(object, inputName) {
    if (!values[inputName]) return "";
    const key = values[inputName];
    return object.filter(({ value }) => value === key)[0];
  }

  function handleSelectChange(val, name) {
    handleChange({ name, value: val ? val.value : "" });
  }

  const SelectPlaceholder = i18n.t("Choose your option");

  const { errors, values, handleSubmit, handleChange } = useForm({
    validationSchema,
  });

  useEffect(() => {
    if (defaultValues) {
      Object.keys(defaultValues).forEach((name) => {
        handleChange({ name, value: defaultValues[name] });
      });
    }
  }, [defaultValues]);

  useEffect(() => {
    loadSelectOptions();
  }, []);

  const handleForm = (e) => {
    e.preventDefault();
    handleSubmit(sendSubmit);
  };

  const capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1).toLocaleLowerCase();
  };

  function handleNameChange(e) {
    const words = e.value.split(" ");
    const res = words.map((x) => capitalize(x));
    const name = res.join(" ");
    handleChange({ name: e.name, value: name });
  }

  function loadSelectOptions() {
    setPrimaryOptions(
      primaryOptionsConstant.map(({ value, label }) => {
        return { value, label: i18n.t(label) };
      })
    );
    setBillingAddressOptions(
      primaryOptionsConstant.map(({ value, label }) => {
        return { value, label: i18n.t(label) };
      })
    );
  }

  // useEffect(() => {
  //   if (values.birth != null && values.birth.length === 10) {
  //     let natNr = values.birth.split("/").reverse();
  //     // natNr.reverse();
  //     let year = natNr[0][2] + natNr[0][3];

  //     values.national_register_nr = year + natNr[1] + natNr[2];
  //     let name = "national_register_nr";
  //     handleChange({ name, value: `${year}.${natNr[1]}.${natNr[2]}-   .  `});
  //   }
  // }, [values.birth]);

  return (
    <form onSubmit={handleForm}>
      <FlexWrapper>
        <TextInput
          name="firstName"
          error={errors.firstName}
          onChange={(val) => handleNameChange(val)}
          value={values.firstName}
        >
          <NameInput>{i18n.t("First name")} *</NameInput>
        </TextInput>
        <TextInput
          name="lastName"
          error={errors.lastName}
          onChange={(val) => handleNameChange(val)}
          value={values.lastName}
        >
          <NameInput>{i18n.t("Last name")} *</NameInput>
        </TextInput>
        <DateInput
          error={errors.birth}
          name="birth"
          type="birth"
          onChange={(val) => {
            if (val.value != null && val.value.length === 10) {
              setBirthValue(true);
            }
            handleChange(val);
          }}
          value={values.birth}
        >
          {i18n.t("Date of birth")} *
        </DateInput>
      </FlexWrapper>
      {/* <MaskedInput
        error={errors.national_register_nr}
        mask="99.99.99-999.99"
        name="national_register_nr"
        onChange={(e) => {
          if (e.value === "  .  .  -   .  " || e.value === "") {
            e.value = undefined;
          }
          handleChange(e);
        }}
        type="text"
        value={
          defaultValues?.national_register_nr != null
            ? defaultValues?.national_register_nr
            : values.national_register_nr
        }
        placeholder="  .  .  -   .  "
      >
        {i18n.t("National registration number")}
      </MaskedInput> */}
      {insuranceType === "car" ||
      insuranceType === "two-wheeler" ||
      insuranceType === "6-wheeler" ? (
        <div>
          <StyledLabel className="padding">
            {i18n.t("Is the main driver also the policyholder")}
            <StyledInfoPopup
              title={i18n.t("Is the main driver also the policyholder")}
              buttonText={i18n.t("?")}
              info={i18n.t("mainDriverPopupText")}
            />
          </StyledLabel>
          <SelectInput
            error={errors.primary}
            name="primary"
            onChange={(val) => {
              handleSelectChange(val, "primary");
            }}
            options={primaryOptions}
            placeholder={SelectPlaceholder}
            isClearable
            value={handleSelectValue(primaryOptions, "primary")}
          />
        </div>
      ) : (
        ""
      )}
      <FlowPolicyHolderInfoFormDefaultInputs
        errors={errors}
        values={values}
        defaultValues={defaultValues}
        handleChange={handleChange}
      />
    </form>
  );
};

const SelectInput = styled(SearchSelectInput)`
  height: 8rem;

  & .Select__option {
    font-size: 1.6rem;
    color: ${({ theme }) => theme.typo.text};
  }

  & .Select__option--is-focused {
    color: ${({ theme }) => theme.typo.text};
    font-weight: 500;
    background-color: ${({ theme }) => theme.brand.lighter};
    :hover {
      color: white;
      background-color: ${({ theme }) => theme.brand.secondary};
    }
  }
  & div {
    margin-bottom: 0;
  }
`;

const StyledLabel = styled(Label)`
  color: ${({ theme }) => theme.typo.subTitle};
  font-size: 1.5rem;
`;

const NameInput = styled.p`
  height: 1rem;
`;

const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  height: 9rem;

  & > div + div {
    /* width: 48%; */
    margin-left: 1rem;
  }
  @media (max-width: 490px) {
    flex-direction: column;
    height: unset;
    margin-bottom: 2rem;
    & > div + div {
      margin-left: 0;
    }
  }
`;

const StyledInfoPopup = styled(InfoPopup)`
  float: right;
  font-size: 1.2rem;
  height: 1.6rem;
  line-height: 1.3;
  width: 1.6rem;
  right: 0;
`;

FlowPolicyHolderInfoFormPrivate.propTypes = {
  defaultValues: object.isRequired,
  sendSubmit: func.isRequired,
};

export default FlowPolicyHolderInfoFormPrivate;
