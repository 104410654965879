import React from "react";

const SvgComponent = ({ colors }) => (
  <svg width="100%" viewBox="0 0 50 50">
    <path
      fill={colors}
      d="M48.58,28.23c-1.92-2.42-9.83-3-13.13-3.18a5.09,5.09,0,0,1-2.69-.93L27,20H26l5,5H10V22.92a35.37,35.37,0,0,0-8.37,5.52,2.1,2.1,0,0,0-.57,2l.78,3.12A1.72,1.72,0,0,0,3.43,35H7.1a5,5,0,0,0,9.8,0H34.1a5,5,0,0,0,9.8,0h2.58a1.67,1.67,0,0,0,1.59-1.39l.87-3.54A2.19,2.19,0,0,0,48.58,28.23ZM14.82,35a3,3,0,0,1-5.64,0A2.77,2.77,0,0,1,9,34a3,3,0,0,1,6,0A2.77,2.77,0,0,1,14.82,35Zm27,0a3,3,0,0,1-5.64,0A2.77,2.77,0,0,1,36,34a3,3,0,0,1,6,0A2.77,2.77,0,0,1,41.82,35Z"
    />
  </svg>
);

export default SvgComponent;
