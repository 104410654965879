import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { LoadingSpinner } from "wg-fe-ui";
import styled from "styled-components";

import { retrieveSignature } from "../helpers/apiRouterService";
import { retrieveStorageById } from "../helpers/storeService.js";
import { getNestedObject } from "../helpers/objectService";

import i18n from "../i18n";
import { parseIncDateToObject } from "../helpers/dateService";

const FlowGeneratingDocuments = () => {
  const { id, affinity, insuranceType } = useParams();
  const { data } = retrieveStorageById(id, affinity, insuranceType) || {};
  const [error, setError] = useState(false);

  useEffect(() => {
    callSignature();
  }, []);

  // function getNumbersFromString(str = "") {
  //   return str.match(/\d/g).join("");
  // }

  async function callSignature() {
    let client = {
      billing_address: {
        street: getNestedObject(data, ["streetName"]),
        zipcode: getNestedObject(data, ["postalCode"]),
        housenr: getNestedObject(data, ["streetNumber"]),
        city: getNestedObject(data, ["municipalityName"]),
        boxnr: getNestedObject(data, ["boxNumber"]),
        country_code: "BE"
      },
      email: getNestedObject(data, ["email"]),
      telephonenr: getNestedObject(data, ["telephonenr"])
    };

    // TODO @Aaron, remove this piece of code below and replace by retrieving the gloabl person_type variable from memory
    let person_type = "natural";
    if (getNestedObject(data, ["companyName"])) {
      person_type = "legal";
    }

    if (person_type === "natural") {
      client.first_name = getNestedObject(data, ["firstName"]);
      client.last_name = getNestedObject(data, ["lastName"]);
      client.birth = parseIncDateToObject(
        getNestedObject(data, ["naturalPersonBirth"])
      );
      // if (data.national_register_nr) {
      //   client.national_register_nr = getNumbersFromString(
      //     getNestedObject(data, ["national_register_nr"])
      //   );
      // }
    }
    if (person_type === "legal") {
      client.name = getNestedObject(data, ["companyName"]);
      client.cbe = getNestedObject(data, ["cbe"]);
    }

    const arr = [];

    if (
      getNestedObject(data, ["attachments", "registrationForm"]) !== undefined
    ) {
      arr.push(getNestedObject(data, ["attachments", "registrationForm"]));
    }
    if (getNestedObject(data, ["attachments", "receipt"]) !== undefined) {
      arr.push(getNestedObject(data, ["attachments", "receipt"]));
    }

    const startDate = {
      day: getNestedObject(data, ["startDate"]).split("/")[0],
      month: getNestedObject(data, ["startDate"]).split("/")[1],
      year: getNestedObject(data, ["startDate"]).split("/")[2]
    };

    const payload = {
      client: client,
      policy_contracts: [
        {
          start_date: startDate,
          quote_id: getNestedObject(data, ["quote", "id"]),
          policy_documents: {
            terms: {
              type: "pdf",
              link: ``
            },
            ipid: {
              type: "pdf",
              link: ``
            }
          },
          insurance_name: "Honda Insurance"
        }
      ],
      notices: generateNotice(),
      information_requirement_sheets: [
        {
          insurance: {
            name: "Honda Insurance",
            company_tag: "allianz",
            type: "car"
          },
          specific_needs: generateSpecificNeeds()
        }
      ],
      attachments: arr
    };

    const [resp, status] = await retrieveSignature(payload);

    if (status === 201) {
      window.location.href = resp.url;
    } else {
      setError(true);
    }
  }

  var myObj = {
    style: "currency",
    currency: "EUR"
  };

  function generateSpecificNeeds() {
    const specificNeedsArray = [];
    const selectedOptions = data.quote.options.map(({ name }) => name);
    const { type, value } = data.quote.franchise;

    specificNeedsArray.push(
      i18n.t("The customer opted for a payment of the premium", {
        interval: i18n.t("annual")
      })
    );

    if (!selectedOptions.includes("assistance_vehicle_person"))
      specificNeedsArray.push(
        i18n.t(
          "The customer chose to not opt for a 'personal and vehicle assistance' coverage"
        )
      );

    if (!selectedOptions.includes("driver"))
      specificNeedsArray.push(
        i18n.t("The customer chose to not opt for a 'driver' coverage")
      );

    if (!selectedOptions.includes("legal"))
      specificNeedsArray.push(
        i18n.t("The client chose not to take 'legal' assistance")
      );

    if (selectedOptions.includes("omnium")) {
      specificNeedsArray.push(
        i18n.t("The customer opted for a franchise, amounting to ", {
          franchiseType: i18n.t(type),
          franchisePrice: parseFloat(value).toLocaleString("nl-BE", myObj)
        })
      );
      // specificNeedsArray.push(
      //   i18n.t(
      //     "The customer opted for a franchise, amounting to % of the value of the car"
      //   )
      // );
      // specificNeedsArray.push(
      //   i18n.t(
      //     "The customer opted for a franchise, amounting to % of the payment of a claim"
      //   )
      // );
    } else {
      specificNeedsArray.push(
        i18n.t("The customer chose to not opt for an 'omnium' coverage")
      );
    }

    console.log(specificNeedsArray);

    return specificNeedsArray.join("</br>");
  }

  function generateNotice() {
    if (getNestedObject(data, ["terminationDate"])) {
      const [day, month, year] = getNestedObject(data, [
        "terminationDate"
      ]).split("/");

      return [
        {
          insurance: {
            name: i18n.t(`Car insurance`),
            company_tag: getNestedObject(data, ["insuranceCompany"])
          },
          policy_number: getNestedObject(data, ["policyNumber"]),
          termination_date: { day, month, year },
          new_insurance_company_tag: "allianz"
        }
      ];
    } else {
      return [];
    }
  }

  return (
    <Container>
      <LoadingWrapper>
        {error ? (
          <P>
            {i18n.t(
              "Something went wrong with generating your documents please contact Honda Insurance"
            )}
          </P>
        ) : (
          <>
            <LoadingSpinner />
            <P>{i18n.t("Generating documents, please wait")}</P>
          </>
        )}
      </LoadingWrapper>
    </Container>
  );
};

const P = styled.p`
  margin-top: 2rem;
`;

const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const Container = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
`;

export default FlowGeneratingDocuments;
