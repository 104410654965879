import React, { useEffect, useState } from "react";
import styled from "styled-components";
import i18n from "../i18n";

import { MaskedInput, TextInput } from "wg-fe-ui";
import {
  telephonenr,
  companyName,
  cbe,
  email,
  captcha,
} from "../constants/validationSchemas";
import { checkIfCBEIsValid } from "../helpers/apiRouterService";
import debounce from "debounce-promise";
import useForm from "../hooks/useForm";
import * as Yup from "yup";
import FlowPolicyHolderInfoFormDefaultInputs from "./FlowPolicyHolderInfoFormDefaultInputs";
import CheckBox from "./CheckBox";

const phoneUtil = require("google-libphonenumber").PhoneNumberUtil.getInstance();

const FlowPolicyHolderInfoFormLegal = ({ sendSubmit, defaultValues }) => {
  const [cbeBeingFormatted, setCbeBeingFormatted] = useState(false);
  const validationSchema = Yup.object().shape({
    companyName: companyName,
    captcha: captcha,
    cbe: cbe.notRequired.test(
      "is-cbe-valid",
      i18n.t(`The entered CBE no is invalid`),
      async (cbe) => {
        return await debounceCBE(cbe);
      }
    ),
    telephonenr: telephonenr.notRequired.test(
      "Phone number",
      i18n.t(`Please enter a valid telephone number`),
      (value) => {
        if (value) {
          const input = value.replace(/ /g, "");
          console.log("tel value:", value);
          if (input.length >= 8) {
            try {
              const number = phoneUtil.parseAndKeepRawInput(input);
              if (phoneUtil.isValidNumber(number)) {
                return true;
              }
            } catch (e) {
              return false;
            }
            return false;
          } else if (value.length < 4) {
            return true;
          }
          return false;
        }
        return true;
      }
    ),
    email: email,
  });

  const debounceCBE = debounce(handleCBEValidation, 1000);

  async function handleCBEValidation(cbe = "") {
    if (cbe === null || cbe === undefined || cbe.length === 0) return 200;
    const [, status] = await checkIfCBEIsValid(cbe.replace(/\./g, ""));
    return status === 200;
  }

  const { errors, values, handleSubmit, handleChange } = useForm({
    validationSchema,
  });

  useEffect(() => {
    if (defaultValues) {
      Object.keys(defaultValues).forEach((name) => {
        handleChange({ name, value: defaultValues[name] });
      });
      setCbeBeingFormatted(defaultValues?.cbeBeingFormatted);
    }
  }, [defaultValues]);

  useEffect(() => {
    if (cbeBeingFormatted) {
      values.cbe = undefined;
    }
  }, [cbeBeingFormatted]);

  const handleForm = (e) => {
    e.preventDefault();
    if (cbeBeingFormatted) {
      values.cbeBeingFormatted = true;
      values.cbe = undefined;
    } else {
      values.cbeBeingFormatted = false;
    }
    handleSubmit(sendSubmit);
  };

  return (
    <form onSubmit={handleForm}>
      <FlexWrapper>
        <StyledTextInput
          name="companyName"
          error={errors.companyName}
          onChange={(val) => handleChange(val)}
          value={values?.companyName}
        >
          <InputWrapper>{i18n.t("Company name")} *</InputWrapper>
        </StyledTextInput>
        <StyledMaskedInput
          error={errors.cbe}
          mask="9999.999.999"
          name="cbe"
          onChange={(val) => handleChange(val)}
          value={values?.cbe}
          disabled={cbeBeingFormatted}
        >
          <InputWrapper>{i18n.t("Company registration number")}</InputWrapper>
          <StyledCheckBox
            checked={cbeBeingFormatted}
            disabled={false}
            name="being_formatted"
            onChange={() => setCbeBeingFormatted(!cbeBeingFormatted)}
            // otherProps={{}}
          >
            {i18n.t("Being formatted")}
          </StyledCheckBox>
        </StyledMaskedInput>
      </FlexWrapper>
      <FlowPolicyHolderInfoFormDefaultInputs
        defaultValues={defaultValues}
        errors={errors}
        values={values}
        handleChange={handleChange}
      />
    </form>
  );
};

const StyledCheckBox = styled(CheckBox)`
  bottom: -2.2rem;
  color: #5b5550;
  font-size: 1.4rem;
  position: absolute;
  div {
    height: 1.5rem;
    max-width: 1.5rem;
    min-width: 1.5rem;
    svg {
      width: 75%;
    }
  }
`;

const StyledMaskedInput = styled(MaskedInput)`
  position: relative;
`;

const InputWrapper = styled.p`
  height: 1rem;
`;

const StyledTextInput = styled(TextInput)`
  margin-bottom: 1rem;
`;

const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  & > div + div {
    margin-left: 1rem;
  }

  @media (max-width: 530px) {
    flex-direction: column;
    & > div + div {
      margin-left: 0;
      margin-bottom: 1rem;
    }
  }
`;

// FlowPolicyHolderInfoFormLegal.propTypes = {
//   errors: object.isRequired,
//   values: object.isRequired,
//   handleChange: func.isRequired
// };

export default FlowPolicyHolderInfoFormLegal;
