import React, { useEffect, useState } from "react";
import styled from "styled-components";
import i18n from "../i18n";
// import useForm from "../hooks/useForm";
import { useParams } from "react-router";
// import Checkmark from "../../src/assets/images/checkmark";
import HondaLogo from "../assets/images/brand-logos/Honda.svg";
import {
  SearchSelectInput,
  IconCarFilled,
  IconVanFilled,
  Label,
} from "wg-fe-ui";
import {
  retrieveCarBrandsV2,
  retrieveCarModelsV2,
  retrieveCarVersionsV2,
} from "../helpers/apiRouterService";
import SearchVersionList from "./SearchVersionList";
import {
  fuelType,
  transmissionType,
} from "../constants/FlowSearchSelectData.js";
import { useDebouncedCallback } from "use-debounce";

const FlowSearchForVehicleForm = ({
  defaultValues,
  handleBrandLogo,
  handleSubmit,
  handleManual,
}) => {
  const [selectedModel, setSelectedModel] = useState();
  const [selectedVersion, setSelectedVersion] = useState();
  const [versionList, setVersionList] = useState([]);
  const [fuelTypeOptions, setFuelTypeOptions] = useState([]);
  const [selectedMotorType, setSelectedMotorType] = useState();
  const [transmissionTypeOptions, setTransmissionTypeOptions] = useState([]);
  const [selectedTransmissionType, setSelectedTransmissionType] = useState();
  const [brandLogoOptions, setBrandLogoOptions] = useState({});

  const { affinity } = useParams();
  const [selectedBrand, setSelectedBrand] = useState();
  const [disableBrandInput, setDisableBrandInput] = useState(false);

  const [vehicleType, setVehicleType] = useState("PASSENGER_CAR");
  const SelectPlaceholder = i18n.t("Choose your option");
  const noOptionMessage = i18n.t("No option");
  const searchForBrandMessage = i18n.t("Please enter your brand");

  const handleRadioInput = (e) => {
    const value = e.currentTarget.value;
    console.log("!!!!!!!!!!");
    console.log(e);
    console.log(value);
    console.log("!!!!!!!!!!");
    setVehicleType(value);
  };

  useEffect(() => {
    handleBrandOptions();
  }, [defaultValues]);

  useEffect(() => {
    if (
      brandLogoOptions != null &&
      selectedBrand in brandLogoOptions &&
      !["honda", "mazda"].includes(affinity)
    ) {
      handleBrandLogo(brandLogoOptions[selectedBrand]);
    }
    setSelectedModel();
    setSelectedMotorType();
    setSelectedTransmissionType();
    setVersionList([]);
  }, [selectedBrand]);

  useEffect(() => {
    setSelectedMotorType();
    setSelectedTransmissionType();
    setVersionList([]);
  }, [selectedModel]);

  async function handleBrandOptions(value, callback) {
    let brandLogos = {};
    let brandOptions = [];
    if (affinity === "one_automotive") {
      const oneAutomotiveBrands = ["DFSK", "BAIC", "Seres", "FAW"];
      const [resp, status] = await retrieveCarBrandsV2(vehicleType, value);
      if (status !== 200) return;

      // eslint-disable-next-line array-callback-return
      Object.values(resp.items).map((brands) => {
        if (oneAutomotiveBrands.includes(brands.brand.name)) {
          brandOptions.push({
            label: brands.brand.name,
            value: brands.brand.key,
          });
          brandLogos[brands.brand.key] = brands.brand.logo_url;
        }
      });
      setSelectedMotorType("ELE");
    } else {
      if (value === null || value === "") return callback();
      setSelectedMotorType(undefined);
      setSelectedTransmissionType(undefined);
      const [resp, status] = await retrieveCarBrandsV2(vehicleType, value);

      if (status !== 200) return;

      Object.values(resp.items).forEach((brands) => {
        brandOptions.push({
          label: brands.brand.name,
          value: brands.brand.key,
        });
        brandLogos[brands.brand.key] = brands.brand.logo_url;
      });
    }

    setBrandLogoOptions(brandLogos);

    if (affinity === "honda") {
      setSelectedBrand("Honda");
      handleBrandLogo(HondaLogo);
      setDisableBrandInput(true);
    } else if (affinity === "mazda") {
      setSelectedBrand("Mazda");
      handleBrandLogo("https://files.wegroup.be/carlogos/mazda.svg");
      setDisableBrandInput(true);
    }

    if (callback) {
      callback(brandOptions);
    } else {
      return brandOptions;
    }
  }

  async function handleModelOptions(value, callback) {
    if (!selectedBrand) return callback();
    const [resp, status] = await retrieveCarModelsV2(
      vehicleType,
      selectedBrand,
      value
    );

    if (status !== 200) return;
    const modelsValues = [];

    Object.values(resp.items).forEach((models) => {
      modelsValues.push({
        label: `${models.model.model}`,
        value: models.model.id,
      });
    });

    if (callback) {
      callback(modelsValues);
    } else {
      return modelsValues;
    }
  }

  useEffect(() => {
    if (
      selectedBrand &&
      selectedModel &&
      selectedMotorType &&
      selectedTransmissionType
    ) {
      handleVersions();
    }
  }, [
    selectedBrand,
    selectedModel,
    selectedMotorType,
    selectedTransmissionType,
  ]);

  useEffect(() => {
    console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!");
    console.log(vehicleType);
    console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!");
    handleSubmit(selectedBrand, selectedModel, selectedVersion, vehicleType);
  }, [selectedVersion]);

  const [debounceFetchBrands] = useDebouncedCallback(handleBrandOptions, 500);
  const [debounceFetchModels] = useDebouncedCallback(handleModelOptions, 500);

  async function handleVersions() {
    setVersionList([]);
    if (!selectedBrand || !selectedModel) return;
    const [resp, status] = await retrieveCarVersionsV2(
      vehicleType,
      selectedBrand,
      selectedModel,
      selectedMotorType && selectedTransmissionType
        ? `motor_type=${selectedMotorType}&transmission_type=${selectedTransmissionType}`
        : null
    );

    if (status !== 200) {
      setVersionList(null);
    } else {
      console.log(resp);
      setVersionList(
        resp?.items && resp?.items.length > 0 ? resp?.items : null
      );
      // handleVersionFiltering(resp);
    }
  }

  useEffect(() => {
    handleMotorTypeOptions();
  }, [selectedModel]);

  function handleMotorTypeOptions() {
    if (selectedModel !== undefined) {
      setFuelTypeOptions(
        fuelType.map(({ value, label }) => {
          return { value, label: i18n.t(label) };
        })
      );
    } else {
      setFuelTypeOptions([]);
    }
  }

  useEffect(() => {
    handleTransmissionTypeOptions();
  }, [selectedMotorType]);

  function handleTransmissionTypeOptions() {
    if (selectedModel !== undefined) {
      setTransmissionTypeOptions(
        transmissionType.map(({ value, label }) => {
          return { value, label: i18n.t(label) };
        })
      );
    } else {
      setTransmissionTypeOptions([]);
    }
  }

  // function handleVersionFiltering(list) {
  //   const filtered = list.filter(({ version }) => {
  //     let _RETURN = false;
  //     if (selectedMotorType) {
  //       if (version.includes(`(${selectedMotorType})`)) {
  //         _RETURN = true;
  //       } else {
  //         return false;
  //       }
  //     }
  //     if (!selectedMotorType) return true;
  //     return _RETURN;
  //   })
  //   setFilterdVersionList(filtered.length === 0 ? null : filtered);
  // }

  return (
    <GridContainer
      extraInput={!["honda", "mazda", "one_automotive"].includes(affinity)}
    >
      {!["honda", "mazda", "one_automotive"].includes(affinity) ? (
        <div>
          <Label>{i18n.t("Car type")}</Label>
          <RadioBtnContainer>
            <StyledInput
              onChange={(e) => handleRadioInput(e)}
              type="radio"
              checked={vehicleType === "PASSENGER_CAR"}
              name="PASSENGER_CAR"
              id="PASSENGER_CAR"
              value="PASSENGER_CAR"
            />
            <StyledInputLabel htmlFor="PASSENGER_CAR">
              <IconCarFilled />
              <p>{i18n.t("PASSENGER_CAR")}</p>
            </StyledInputLabel>
            <StyledInput
              onChange={(e) => handleRadioInput(e)}
              type="radio"
              name="LIGHT_COMMERCIAL_VEHICLE"
              checked={vehicleType === "LIGHT_COMMERCIAL_VEHICLE"}
              id="LIGHT_COMMERCIAL_VEHICLE"
              value="LIGHT_COMMERCIAL_VEHICLE"
            />
            <StyledInputLabel htmlFor="LIGHT_COMMERCIAL_VEHICLE">
              <IconVanFilled />
              <p>{i18n.t("LIGHT_COMMERCIAL_VEHICLE")}</p>
            </StyledInputLabel>
          </RadioBtnContainer>
        </div>
      ) : (
        ""
      )}
      <StyledPlaceHolder>
        <SelectInput
          async
          name="brand"
          disabled={disableBrandInput}
          placeholder={
            disableBrandInput ? selectedBrand : searchForBrandMessage
          }
          onSelected={({ value }) => {
            setSelectedBrand(value);
          }}
          loadOptions={debounceFetchBrands}
          value={disableBrandInput ? selectedBrand : ""}
          color={disableBrandInput ? "black" : null}
          loadingMessage={searchForBrandMessage}
          noOptionsMessage={() => searchForBrandMessage}
          isClearable={!disableBrandInput}
          isSearchable={!disableBrandInput}
        >
          {i18n.t("Brand")}
        </SelectInput>
      </StyledPlaceHolder>
      <SelectInput
        async
        name="model"
        key={selectedBrand}
        onSelected={({ value }) => {
          setSelectedModel(value);
        }}
        disabled={!selectedBrand}
        loadOptions={debounceFetchModels}
        isClearable
        isSearchable
        noOptionMessage={noOptionMessage}
        placeholder={SelectPlaceholder}
      >
        {i18n.t("Model")}
      </SelectInput>
      <SelectInput
        name="motorType"
        onSelected={({ value }) => {
          setSelectedMotorType(value);
        }}
        disabled={!selectedModel}
        options={fuelTypeOptions}
        isClearable
        isSearchable
        noOptionMessage={noOptionMessage}
        placeholder={SelectPlaceholder}
      >
        {i18n.t("Fuel type")}
      </SelectInput>
      <SelectInput
        name="transmission_type"
        onSelected={({ value }) => {
          setSelectedTransmissionType(value);
        }}
        disabled={!selectedMotorType}
        options={transmissionTypeOptions}
        isClearable
        isSearchable
        noOptionMessage={noOptionMessage}
        placeholder={SelectPlaceholder}
      >
        {i18n.t("transmission_type")}
      </SelectInput>
      {/* <FilterContainer>
        <SelectInput
          name="model_year"
          disabled={filterdversionList.length === 0}
          onSelected={({ value }) => {
            console.log(value);
            setSelectedYear(value);
          }}
          options={yearList}
          isClearable
          isSearchable
          noOptionMessage={noOptionMessage}
          placeholder={SelectPlaceholder}
          value={selectedYear ? handleSelectValue(yearList, "model_year") : ""}
        >
          {i18n.t("Filter by model year")}
        </SelectInput>
        <SelectInput
          name="power"
          disabled={filterdversionList.length === 0}
          onSelected={({ value }) => {
            setSelectedPower(value);
          }}
          options={powerList}
          isClearable
          isSearchable
          noOptionMessage={noOptionMessage}
          value={selectedPower ? handleSelectValue(powerList, "power") : ""}
          placeholder={SelectPlaceholder}
        >
          {i18n.t("Filter by power")} (KW)
        </SelectInput>
      </FilterContainer> */}
      <SearchVersionList
        filterdversionList={versionList}
        disabled={!selectedTransmissionType}
        onSelected={(data) => setSelectedVersion(data)}
      />
      <ButtonContainer>
        {["one_automotive"].includes(affinity) ? (
          ``
        ) : (
          <ManualLink
            level="secondary"
            onClick={(e) => {
              e.preventDefault();
              handleManual();
            }}
          >
            {i18n.t("I cannot find my car")}
          </ManualLink>
        )}
        {/* <StyledActionButton
          affinity={affinity}
          onClick={() =>
            handleSubmit(
              selectedVersion,
              selectedBrand,
              selectedModel,
              vehicleType
            )
          }
        >
          {i18n.t("Next")}
        </StyledActionButton> */}
      </ButtonContainer>
    </GridContainer>
  );
};

const RadioBtnContainer = styled.div`
  display: grid;
  grid-template-columns: 49% 49%;
  grid-gap: 1rem;
  padding: 1rem 0 3rem 0;
  @media (max-width: 520px) {
    grid-template-columns: auto;
  }
`;

const StyledInput = styled.input`
  display: none;

  &:checked + label {
    justify-content: center;
    background-color: ${({ theme }) => theme.brand.lightest};
    border-color: ${({ theme }) => theme.brand.primary};
    @media (max-width: 1350px) {
      width: 100%;
      justify-content: center;
    }
  }
`;

const StyledInputLabel = styled.label`
  justify-content: center;
  border: 0.1rem solid rgb(204, 204, 204);
  color: rgb(80, 80, 80);
  border-style: solid;
  border-image: initial;
  border-radius: 0.2rem;
  height: 6rem;
  align-items: center;
  padding: 1rem;
  cursor: pointer;

  display: grid;
  grid-template-columns: 20% 80%;

  svg {
    left: 1rem;
    stroke: ${({ theme }) => theme.brand.primary};
    path {
      fill: ${({ theme }) => theme.brand.primary};
    }
  }
  @media (max-width: 1350px) {
    width: 100%;
    justify-content: center;
  }
  p {
    text-align: center;
    @media screen and (max-width: 425px) {
      overflow-wrap: anywhere;
      font-size: 1.4rem;
      width: 85%;
      padding-left: 1rem;
    }
    @media (max-width: 520px) {
      grid-template-columns: 10% 80%;
    }
  }
`;

// const StyledActionButton = styled(ActionButton)`
//   color: ${({ affinity }) => (affinity === "de-lijn" ? "black" : "white")};
//   background-color: ${({ affinity }) =>
//     affinity === "callant" || affinity === "uber" || affinity === "at-work" ? "#94C11A" : "default"};
//   font-weight: ${({ affinity }) => (affinity === "de-lijn" ? "700" : "normal")};
// `;

// const FilterContainer = styled.div`
//   display: flex;
//   justify-content: space-between;

//   & > div {
//     width: 48%;
//   }
// `;

const StyledPlaceHolder = styled.div`
  & .Select__placeholder {
    color: black;
  }
`;

const ManualLink = styled.a`
  color: ${({ theme }) => theme.typo.subTitle};
  margin-left: 0rem;
  cursor: pointer;
  text-decoration: underline;
  width: 100%;
  text-align: center;
  @media (max-width: 768px) {
    margin-bottom: 2rem;
    margin-top: 3rem;
    text-align: center;
    width: 100%;
  }
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-rows: ${({ extraInput }) =>
    extraInput ? "11rem repeat(3,8rem) auto auto" : "repeat(3, 8rem) auto"};
  height: 100%;
  p {
    color: #5b5550;
  }
  @media (max-width: 520px) {
    grid-template-rows: ${({ extraInput }) =>
      extraInput ? " 17.75rem repeat(2, 8rem) auto" : "repeat(3, 8rem) auto"};
  }
`;

const SelectInput = styled(SearchSelectInput)`
  & .Select__option {
    font-size: 1.6rem;
    color: ${({ theme }) => theme.typo.text};
  }

  & .Select__option--is-focused {
    color: ${({ theme }) => theme.typo.text};
    font-weight: 500;
    background-color: ${({ theme }) => theme.brand.lighter};
    :hover {
      color: white;
      background-color: ${({ theme }) => theme.brand.secondary};
    }
  }
  & div {
    margin-bottom: 0;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: auto;
  height: 8rem;
  @media (max-width: 768px) {
    flex-direction: column;
  }

  & button {
    margin-left: auto;
    width: 20rem;
    @media (max-width: 768px) {
      margin-left: 0;
      width: 100%;
    }
  }
`;

export default FlowSearchForVehicleForm;
