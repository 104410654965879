import { hondaCar, hondaTwoWheeler } from "../constants/routingDataHonda";
import { oneAutomotiveCar } from "../constants/routingDataOneAutomotive";
import {
  callantHome,
  callantCar,
  callantTwoWheeler,
  callantTeacher,
  callantLegal,
  callant6Wheeler,
} from "../constants/routingDataCallant";
import {
  deLijnHome,
  deLijnCar,
  deLijnTwoWheeler,
  deLijnLegal,
  deLijn6Wheeler,
} from "../constants/routingDataDeLijn";
import {
  agoriaHome,
  agoriaCar,
  agoriaTwoWheeler,
  agoriaLegal,
  agoria6Wheeler,
} from "../constants/routingDataAgoria";
import { valckenierCar } from "../constants/routingDataValckenier";
import { uberCar } from "../constants/routingDataUber";
import { getNestedObject } from "./objectService";

import AgoriaLogo from "../assets/images/brand-logos/Agoria.svg";
import CallantLogo from "../assets/images/brand-logos/Callant.png";
import DeLijnLogo from "../assets/images/brand-logos/DeLijn.svg";
import HondaLogo from "../assets/images/brand-logos/Honda.svg";
import ValckenierLogo from "../assets/images/brand-logos/Valckenier.png";
import OneAutomotiveLogo from "../assets/images/brand-logos/OneAutomotive.png";

const flowObject = {
  honda: {
    car: hondaCar,
    "two-wheeler": hondaTwoWheeler,
  },
  "one_automotive": {
    car: oneAutomotiveCar
  },
  "de-lijn": {
    home: deLijnHome,
    car: deLijnCar,
    "two-wheeler": deLijnTwoWheeler,
    legal: deLijnLegal,
    "6-wheeler": deLijn6Wheeler,
  },
  callant: {
    home: callantHome,
    car: callantCar,
    "two-wheeler": callantTwoWheeler,
    legal: callantLegal,
    "6-wheeler": callant6Wheeler,
  },
  "at-work": {
    home: callantHome,
    car: callantCar,
    "two-wheeler": callantTwoWheeler,
    legal: callantLegal,
    "6-wheeler": callant6Wheeler,
  },
  doceo: {
    home: callantHome,
    car: callantCar,
    "two-wheeler": callantTwoWheeler,
    teacher: callantTeacher,
    legal: callantLegal,
    "6-wheeler": callant6Wheeler,
  },
  "motor-insurance": {
    car: callantCar,
    "two-wheeler": callantTwoWheeler,
    "6-wheeler": callant6Wheeler,
  },
  agoria: {
    home: agoriaHome,
    car: agoriaCar,
    "two-wheeler": agoriaTwoWheeler,
    legal: agoriaLegal,
    "6-wheeler": agoria6Wheeler,
  },
  valckenier: {
    car: valckenierCar,
  },
  uber: {
    car: uberCar,
  }
};

const brandDataDict = {
  "agoria": {
    name: "Agoria",
    logo: AgoriaLogo,
  },
  "callant": {
    name: "Callant",
    logo: CallantLogo,
  },
  "at-work": {
    name: "@Work",
    logo: CallantLogo,
  },
  "doceo": {
    name: "Doceo",
    logo: CallantLogo,
  },
  "motor-insurance": {
    name: "Moto",
    logo: CallantLogo,
  },
  "de-lijn": {
    name: "De Lijn",
    logo: DeLijnLogo,
  },
  honda: {
    name: "Honda",
    logo: HondaLogo,
  },
  "one_automotive": {
    name: "OneAutomotive",
    logo: OneAutomotiveLogo
  },
  uber: {
    name: "Uber",
    logo: CallantLogo,
  },
  valckenier: {
    name: "Valckenier",
    logo: ValckenierLogo,
  },
}

let brandData = {};

export const getAffinityFlow = ({ affinity, insuranceType }) => {
  brandData = brandDataDict?.[affinity] || {};
  return getNestedObject(flowObject, [affinity, insuranceType, "flow"]) || [];
};

export const getBrandData = () => brandData;

export const doesFlowExist = ({ affinity, insuranceType }) =>
  getNestedObject(flowObject, [affinity, insuranceType, "flow"]) ? true : false;
