import React, { useEffect, useState } from "react";
import styled from "styled-components";
import i18n from "../i18n";
// import useForm from "../hooks/useForm";
import { useParams } from "react-router";
import { retrieveStorageById } from "../helpers/storeService";
import { SearchSelectInput, ActionButton, TextInput } from "wg-fe-ui";
import {
  retrieveBikeBrands,
  retrieveBikeSeries,
  retrieveBikeModels,
  retrieveBikeModelById,
} from "../helpers/apiRouterService";

import { useDebouncedCallback } from "use-debounce";

const FlowSearchForBikeForm = ({
  defaultValues,
  handleBrandLogo,
  handleSubmit,
  handleManual,
}) => {
  const [brandsFetched, setBrandsFetched] = useState(false);
  const [selectedModel, setSelectedModel] = useState();
  const [yearList, setYearList] = useState([]);
  const [modelList, setModelList] = useState([]);
  const [selectedYear, setSelectedYear] = useState();
  const [modelDataset, setModelDataset] = useState();
  const [brandLogoOptions, setBrandLogoOptions] = useState({});
  const [logo, setLogo] = useState();
  const [vinNumber] = useState();
  const [licensePlate] = useState();
  const [CCValue, setCCValue] = useState("");
  const { id, affinity, insuranceType } = useParams();
  const { data } = retrieveStorageById(id, affinity, insuranceType);
  const [carValues, setCarValues] = useState({});
  const [disableBrandInput, setDisableBrandInput] = useState(false);
  const [selectedBrand, setSelectedBrand] = useState(
    affinity === "honda" ? "Honda" : null
  );
  const [selectedSeries, setSelectedSeries] = useState();
  const [seriesList, setSeriesList] = useState();
  const [brandNamesDict, setBrandNamesDict] = useState({});

  useEffect(() => {
    if (brandLogoOptions != null && affinity !== "honda") {
      handleBrandLogo(brandLogoOptions[selectedBrand]);
    }
    setYearList(undefined);
    setSelectedModel(undefined);
    setSelectedSeries(undefined);
    setSelectedYear(undefined);
    setCCValue(undefined);
  }, [selectedBrand]);

  useEffect(() => {
    filterYears();
    if (affinity !== "honda") {
      handleBrandLogo(logo);
    }
  }, [selectedModel, selectedYear]);

  useEffect(() => {
    console.log("selectedSeries:", selectedSeries);
    if (seriesList !== undefined) {
      seriesList.forEach(({ label, value, cc }) => {
        if (selectedSeries === value) {
          console.log("MATCH", cc, value, label);
          setCCValue(cc);
          handleModelOptions(value);
        }
      });
    }
  }, [selectedSeries]);

  async function handleBrandOptions(value, callback) {
    setSelectedSeries(undefined);
    setSelectedYear(undefined);
    setCCValue(undefined);
    const [resp, status] = await retrieveBikeBrands(
      data?.twoWheeler?.details?.vehicleType,
      value
    );

    if (status !== 200) return;
    const brandOptions = [];
    const brandLogos = {};
    const brandNames = {};
    setBrandsFetched(true);

    Object.values(resp.items).forEach((brands) => {
      brandOptions.push({ label: brands.brand.name, value: brands.brand.key });
      brandLogos[brands.brand.key] = brands.brand.logo_url;
      brandNames[brands.brand.key] = brands.brand.name;
    });
    setBrandLogoOptions(brandLogos);
    setBrandNamesDict(brandNames);

    if (affinity === "honda") {
      setSelectedBrand("Honda");
      handleBrandLogo("https://files.wegroup.be/carlogos/honda.svg");
      setDisableBrandInput(true);
    }
    if (callback) {
      callback(brandOptions);
    } else {
      return brandOptions;
    }
  }

  async function handleSeriesOptions(value, callback) {
    console.log("fetching series");
    if (!selectedBrand) return callback();
    const [resp, status] = await retrieveBikeSeries(
      data?.twoWheeler?.details?.vehicleType,
      selectedBrand,
      value
    );

    if (status !== 200) return;
    const seriesIds = [];
    const seriesValues = [];

    Object.values(resp.items).forEach((series) => {
      seriesIds.push({
        label: series.series.name,
        value: series.series.id,
        cc: series.series.cc,
      });
      seriesValues.push({
        label: `${series.series.name} - ${series.series.cc} CC`,
        value: series.series.id,
      });
    });

    setSeriesList(seriesIds);
    if (callback) {
      callback(seriesValues);
    } else {
      return seriesValues;
    }
  }

  const [debounceFetchBrands] = useDebouncedCallback(handleBrandOptions, 500);
  const [debounceFetchSeries] = useDebouncedCallback(handleSeriesOptions, 500);

  async function handleSelectedBrand(brandName) {
    setSelectedSeries(undefined);
    setSelectedModel(undefined);
    setCCValue(undefined);
    setModelList(undefined);
    setSelectedBrand(brandName);
  }

  async function handleSelectedSeries(seriesId) {
    setSelectedModel(undefined);
    setModelList(undefined);
    setCCValue(undefined);
    setSelectedSeries(seriesId);
  }

  var modelArr = [];
  var modelYear = [];
  var modelData = [];

  async function handleModelOptions(selectedModel) {
    setSelectedModel(undefined);
    setSelectedYear(undefined);
    setYearList(undefined);

    const [resp, status] = await retrieveBikeModels(
      data?.twoWheeler?.details?.vehicleType,
      selectedBrand,
      selectedModel
    );

    if (status !== 200) return;
    Object.values(resp.items).forEach((models) => {
      modelData.push(models.model);
      modelYear.push({
        label: models.model.model_year.toString(),
        value: models.model.model_year,
      });
      modelArr.push({
        label: models.model.model,
        value: models.model.model,
      });
    });

    modelArr = modelArr.filter(
      (model, index, self) =>
        index ===
        self.findIndex(
          (t) => t.label === model.label && t.value === model.value
        )
    );

    modelYear = modelYear.filter(
      (year, index, self) =>
        index ===
        self.findIndex((t) => t.label === year.label && t.value === year.value)
    );
    setModelDataset(modelData);
    setYearList(modelYear);
    setModelList(modelArr);
    return modelArr;
  }

  async function filterYears() {
    if (!selectedModel) return;

    let modelId;
    if (modelDataset != null) {
      modelDataset.forEach((model) => {
        if (model.model === selectedModel) {
          if (selectedYear !== undefined) {
            if (model.model_year === selectedYear) {
              modelId = model.id;
            }
          } else {
            modelId = model.id;
          }
        }
      });
    }

    var newYearList = [];
    if (modelDataset != null) {
      modelDataset.forEach((model) => {
        if (model.model === selectedModel) {
          newYearList.push({
            label: model.model_year.toString(),
            value: model.model_year,
          });
        }
      });
    }

    // Get the initial bike already
    console.log("SELECTEDMODEL", selectedModel);

    const [resp, status] = await retrieveBikeModelById(
      data?.twoWheeler?.details?.vehicleType,
      selectedBrand,
      selectedSeries,
      modelId
    );
    console.log(resp, status);

    if (resp.motorcycle != null && resp.motorcycle.cc != null) {
      setCCValue(resp.motorcycle.cc);
    }
    setYearList(newYearList);
    if (resp?.motorcycle?.images && resp?.motorcycle?.images.length > 0) {
      setLogo(resp.motorcycle.images[0]);
    }
    setCarValues(resp.motorcycle);
  }

  const SelectPlaceholder = i18n.t("Choose your option");
  const noOptionMessage = i18n.t("No option");
  const searchForBrandMessage = i18n.t("Please enter your brand");

  return (
    <GridContainer>
      <SelectInput
        async
        className="brand"
        name="brand"
        disabled={disableBrandInput}
        placeholder={disableBrandInput ? selectedBrand : searchForBrandMessage}
        onSelected={({ value }) => {
          handleSelectedBrand(value);
        }}
        loadOptions={debounceFetchBrands}
        value={disableBrandInput ? selectedBrand : ""}
        color={disableBrandInput ? "black" : null}
        noOptionsMessage={() => searchForBrandMessage}
        isClearable={!disableBrandInput}
        isSearchable={!disableBrandInput}
        key={brandsFetched}
      >
        {i18n.t("Brand")}
      </SelectInput>
      <SelectInput
        async
        key={selectedBrand}
        className="series"
        disabled={!selectedBrand}
        loadOptions={debounceFetchSeries}
        onSelected={({ value }) => {
          handleSelectedSeries(value);
        }}
        isClearable
        isSearchable
        noOptionMessage={noOptionMessage}
        placeholder={SelectPlaceholder}
        // value={!selectedBrand ? selectedSeries : ""}
        // searchWithEmptyValue=true   // TODO: This should trigger the call as well when value is empty
      >
        {i18n.t("Series")}
      </SelectInput>
      <SelectInput
        key={selectedSeries}
        name="model"
        disabled={!selectedSeries}
        onSelected={({ value }) => {
          setSelectedModel(value);
        }}
        options={modelList}
        isClearable
        isSearchable
        noOptionMessage={noOptionMessage}
        placeholder={SelectPlaceholder}
      >
        {i18n.t("Model")}
      </SelectInput>

      <SelectInput
        name="model_year"
        disabled={!selectedModel}
        onSelected={({ value }) => {
          setSelectedYear(value);
        }}
        options={yearList}
        isClearable
        isSearchable
        noOptionMessage={noOptionMessage}
        placeholder={SelectPlaceholder}
      >
        {i18n.t("Model year")}
      </SelectInput>
      <StyledTextInput
        name="cylinder_capacity"
        // error={errors.cylinder_capacity}
        onChange={(val) => {
          console.log(val);
          setCCValue(val.value);
        }}
        value={CCValue ? CCValue : data.cc}
      >
        <StyledLabel>{i18n.t("Cylinder capacity")} *</StyledLabel>
        <p className="valueType">CC</p>
      </StyledTextInput>
      {/* <StyledTextInput
        name="licensePlate"
        onChange={(val) => {
          if (15 >= val.value.length && val.value.length >= 1) {
            setLicensePlateError("");
            setLicensePlate(val);
          } else {
            setLicensePlateError(
              "License plate must be between 1 and 15 characters"
            );
          }
        }}
        value={data.licensePlate}
      >
        {i18n.t("What is license plate of the motorcycle?")}
        <VinError>{i18n.t(licensePlateError)}</VinError>
      </StyledTextInput>
      <StyledTextInput
        name="vinNumber"
        errors={errors.vinNumber}
        onChange={(val) => {
          if (17 >= val.value.length && val.value.length >= 11) {
            setVinNumberError("");
            setVinNumber(val);
          } else {
            setVinNumberError(
              "Vin number must be between 11 and 17 characters"
            );
          }
        }}
        value={data.vinNumber}
      >
        {i18n.t("What is the VIN number of the motorcycle?")}
        <VinError>{i18n.t(vinNumberError)}</VinError>
      </StyledTextInput> */}
      <ButtonContainer>
        <ManualLink
          level="secondary"
          onClick={(e) => {
            e.preventDefault();
            handleManual();
          }}
        >
          {i18n.t("I cannot find my motorcycle")}
        </ManualLink>
        <StyledActionButton
          affinity={affinity}
          disabled={
            selectedBrand === undefined ||
            selectedSeries === undefined ||
            selectedModel === undefined
          }
          onClick={() => {
            if (CCValue !== "" && CCValue !== undefined) {
              carValues.cc = CCValue;
              carValues.brand = brandNamesDict?.[selectedBrand]
                ? brandNamesDict?.[selectedBrand]
                : selectedBrand;
            }
            handleSubmit(carValues, licensePlate, vinNumber);
          }}
        >
          {i18n.t("Next")}
        </StyledActionButton>
      </ButtonContainer>
    </GridContainer>
  );
};

// const VinError = styled.p`
//   color: #f74040;
//   position: absolute;
//   bottom: -2rem;
//   right: 0;
// `;

const StyledLabel = styled.div`
  height: 1rem;
  a {
    margin-top: -0.55rem;
  }
`;

const StyledTextInput = styled(TextInput)`
  div {
    p {
      color: #f74040;
    }
  }
  p.valueType {
    position: absolute;
    margin: 0;
    right: 1rem;
    top: 4rem;
  }
`;

const StyledActionButton = styled(ActionButton)`
  color: ${({ affinity }) => (affinity === "de-lijn" ? "black" : "white")};
  background-color: ${({ affinity }) =>
    affinity === "callant" ? "#94C11A" : "default"};
  font-weight: ${({ affinity }) => (affinity === "de-lijn" ? "700" : "normal")};
`;

const ManualLink = styled.a`
  color: ${({ theme }) => theme.typo.subTitle};
  margin-left: 0rem;
  cursor: pointer;
  text-decoration: underline;
  @media (max-width: 768px) {
    margin-bottom: 2rem;
    text-align: center;
    width: 100%;
  }
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-rows: repeat(5, 9rem) auto;
  padding: 5rem 4rem;
  @media screen and (max-width: 425px) {
    padding: 4rem 2.5rem;
  }
`;

const SelectInput = styled(SearchSelectInput)`
  & .Select__option {
    font-size: 1.6rem;
    color: ${({ theme }) => theme.typo.text};
  }

  & .Select__option--is-focused {
    color: ${({ theme }) => theme.typo.text};
    font-weight: 500;
    background-color: ${({ theme }) => theme.brand.lighter};
    :hover {
      color: white;
      background-color: ${({ theme }) => theme.brand.secondary};
    }
  }
  & div {
    margin-bottom: 0;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: auto;
  @media (max-width: 768px) {
    flex-direction: column;
  }

  & button {
    margin-left: auto;
    width: 20rem;
    @media (max-width: 768px) {
      margin-left: 0;
      width: 100%;
    }
  }
`;

export default FlowSearchForBikeForm;
