import React, { useState, useEffect } from "react";
import styled from "styled-components";
import i18n from "../i18n";

import useRouting from "../hooks/useRouting.js";
import { useParams } from "react-router";
import FlowAddressContainer from "../components/FlowAddressContainer";
import LoadingActionButton from "../components/LoadingActionButton";
import FlowLeftSideAvatar from "../components/FlowLeftSideAvatar.jsx";
import { retrieveStorageById, patchStorage } from "../helpers/storeService.js";
import useForm from "../hooks/useForm";
import { TextInput, ActionButton, SearchSelectInput, Label } from "wg-fe-ui";
import CheckBox from "../components/CheckBox";
import * as Yup from "yup";
import { string, boxnr } from "../constants/validationSchemas";
import { retrieveAddressInfo } from "../helpers/apiRouterService";
import { currentCompanyName as currentCompanyNameConstant } from "../constants/FlowSearchSelectData";

const FlowPolicyHolderInfo = () => {
  const [defaultValues, setDefaultValues] = useState();
  const [loading, setLoading] = useState(false);
  const { renderNextRoute } = useRouting();
  const { id, affinity, insuranceType } = useParams();
  const { data } = retrieveStorageById(id, affinity, insuranceType) || {};
  const SelectPlaceholder = i18n.t("Choose your option");
  const [currentCompanyOptions, setCurrentCompanyOptions] = useState([]);
  const [SAPError, setSAPError] = useState();
  const [subsidiaryError, setSubsidiaryError] = useState();
  const [emailError, setEmailError] = useState();
  useEffect(() => {
    loadSelectOptions();
    console.log(values);
  }, []);

  function loadSelectOptions() {
    setCurrentCompanyOptions(
      currentCompanyNameConstant.map(({ value, label }) => {
        return { value, label: i18n.t(label) };
      })
    );
  }

  const validationSchema = Yup.object().shape({
    streetName: string.required,
    municipalityName: string.required,
    postalCode: string.required,
    streetNumber: string.required,
    boxNumber: boxnr,
    currentCompany:
      affinity === "at-work" ? string.required : string.notRequired,
  });

  const { errors, values, handleSubmit, handleChange } = useForm({
    validationSchema,
  });

  useEffect(() => {
    const { data } = retrieveStorageById(id, affinity, insuranceType) || {};
    setDefaultValues(data?.policyHolder?.address || {});
  }, []);

  useEffect(() => {
    defaultValuesHandler(defaultValues);
  }, [defaultValues]);

  function defaultValuesHandler() {
    Object.keys(defaultValues || {}).forEach((name) => {
      handleChange({ name, value: defaultValues[name] });
    });
  }

  const handleForm = (e) => {
    e.preventDefault();
    handleSubmit(submitHandler);
  };

  const submitHandler = async (address) => {
    if (affinity === "at-work") {
      if (
        values.currentCompany === "DELOITTE_BELGIUM" &&
        values.currentCompanyInfo.length !== 8
      ) {
        setSAPError("Must be 8 numbers");
        return;
      } else if (
        values.currentCompany === "DELOITTE_BELGIUM" &&
        values.currentCompanyInfo.length === 8
      ) {
        setSAPError("");
      } else if (
        values.currentCompany === "EIFFAGE_BENELUX" &&
        values.currentCompanyInfo === undefined
      ) {
        setSubsidiaryError("Please enter subsidiary");
        return;
      } else if (
        values.currentCompany === "EIFFAGE_BENELUX" &&
        values.currentCompanyInfo != null
      ) {
        setSubsidiaryError("");
      } else if (
        values.currentCompany === "CALLANT" &&
        values.currentCompanyInfo === undefined
      ) {
        setEmailError("Please enter your callant email");
        return;
      } else if (
        values.currentCompany === "SPORTOASE" &&
        values.currentCompanyInfo === undefined
      ) {
        setEmailError("Please enter your sportoase email");
        return;
      } else if (
        values.currentCompany === "AON" &&
        values.currentCompanyInfo === undefined
      ) {
        setEmailError("Please enter your aon email");
        return;
      } else if (
        values.currentCompany === "MAZDA" &&
        values.currentCompanyInfo === undefined
      ) {
        setEmailError("Please enter your mazda email");
        return;
      } else if (
        values.currentCompany === "EUROCONTROL" &&
        values.currentCompanyInfo === undefined
      ) {
        setEmailError("Please enter your eurocontrol email");
        return;
      } else if (
        values.currentCompany === "CALLANT" &&
        values.currentCompanyInfo != null
      ) {
        console.log(values.currentCompanyInfo);
        const validate = values.currentCompanyInfo.split("@")[1];
        console.log(validate);
        if (validate === "callant.be") {
          setEmailError("");
        } else if (validate !== "callant.be") {
          setEmailError("Email must be of format 'example@callantbe'");
          return;
        }
      } else if (
        values.currentCompany === "SPORTOASE" &&
        values.currentCompanyInfo != null
      ) {
        console.log(values.currentCompanyInfo);
        const validate = values.currentCompanyInfo.split("@")[1];
        console.log(validate);
        if (validate === "sportoase.be") {
          setEmailError("");
        } else if (validate !== "sportoase.be") {
          setEmailError("Email must be of format 'voornaamfamilienaam@sportoasebe'");
          return;
        }
      } else if (
        values.currentCompany === "AON" &&
        values.currentCompanyInfo != null
      ) {
        console.log(values.currentCompanyInfo);
        const validate = values.currentCompanyInfo.split("@")[1];
        console.log(validate);
        if (validate === "aon.com") {
          setEmailError("");
        } else if (validate !== "aon.com") {
          setEmailError("Email must be of format 'example@aoncom'");
          return;
        }
      } else if (
        values.currentCompany === "MAZDA" &&
        values.currentCompanyInfo != null
      ) {
        console.log(values.currentCompanyInfo);
        const validate = values.currentCompanyInfo.split("@")[1];
        console.log(validate);
        if (validate === "mazda.be" || validate === "mazdaeur.com") {
          setEmailError("");
        } else if (validate !== "mazda.be" && validate !== "mazdaeur.com") {
          setEmailError("Email must be of format 'example@mazdabe'");
          return;
        }
      } else if (
        values.currentCompany === "EUROCONTROL" &&
        values.currentCompanyInfo != null
      ) {
        console.log(values.currentCompanyInfo);
        const validate = values.currentCompanyInfo.split("@")[1];
        console.log(validate);
        if (validate === "eurocontrol.int") {
          setEmailError("");
        } else if (validate !== "eurocontrol.int") {
          setEmailError("Email must be of format 'example@eurocontrolint'");
          return;
        }
      }
    }
    console.log("called");

    setLoading(true);
    const {
      streetName,
      streetNumber,
      boxNumber,
      postalCode,
      municipalityName,
      sameAsPolicy,
    } = address;

    if (affinity === "at-work") {
      patchStorage({
        payload: {
          info: {
            ...data?.info,
            currentCompany: values.currentCompany,
            currentCompanyInfo: values?.currentCompanyInfo,
          },
        },
        id,
        affinity,
        insuranceType,
      });
    }
    if (defaultValues?.sameAsPolicy) {
      patchStorage({
        payload: { risk_address: {} },
        id,
        affinity,
        insuranceType,
      });
    }

    delete values.currentCompany;
    delete values.currentCompanyInfo;

    patchStorage({
      payload: { policyHolder: { ...data?.policyHolder, address } },
      id,
      affinity,
      insuranceType,
    });

    if (data?.policyHolder?.address) {
      if (
        address.streetName === data?.policyHolder?.address.streetName &&
        address.streetNumber === data?.policyHolder?.address.streetNumber &&
        address.postalCode === data?.policyHolder?.address.postalCode &&
        sameAsPolicy
      ) {
        setLoading(false);
        return renderNextRoute(2);
      } else if (
        address.streetName === data?.policyHolder?.address.streetName &&
        address.streetNumber === data?.policyHolder?.address.streetNumber &&
        address.postalCode === data?.policyHolder?.address.postalCode
      ) {
        setLoading(false);
        return renderNextRoute();
      }
    }
    if (sameAsPolicy) {
      const [resp, status] = await retrieveAddressInfo({
        street: streetName,
        zipcode: postalCode,
        housenr: streetNumber.toLowerCase(),
        country_code: "BE",
        boxnr: boxNumber,
        city: municipalityName,
      });
      if (status / 100 === 2) {
        patchStorage({
          payload: { policyHolder: { ...data?.policyHolder, address } },
          id,
          affinity,
          insuranceType,
        });
        patchStorage({
          payload: { risk_address: resp },
          id,
          affinity,
          insuranceType,
        });
        setLoading(false);
        return renderNextRoute(2);
      } else {
        setLoading(false);
        return;
      }
    }
    renderNextRoute();
    setLoading(false);
  };

  function handleSelectValue(object, inputName) {
    if (!values[inputName]) return "";
    const key = values[inputName];
    return object.filter(({ value }) => value === key)[0];
  }
  function handleSelectChange(val, name) {
    handleChange({ name, value: val ? val.value : "" });
  }

  return (
    <SplitContainer>
      <FlowLeftSideAvatar
        chatText={i18n.t(`Fill in the address of the policy holder`)}
      />
      <RightSplit>
        <form onSubmit={handleForm}>
          <h1>{i18n.t("Policyholder address")}</h1>
          <FlowAddressContainer
            errors={errors}
            values={values}
            defaultValues={defaultValues}
            handleChange={handleChange}
          />
          {insuranceType === "home" ? (
            <CheckBox
              checked={values.sameAsPolicy}
              disabled={false}
              name="sameAsPolicy"
              onChange={handleChange}
              otherProps={{}}
            >
              {i18n.t("Policy holder address is the same as risk address")}
            </CheckBox>
          ) : (
            ""
          )}
          {affinity === "at-work" ? (
            <div>
              <StyledLabel className="padding">
                {i18n.t("Current company")} *
              </StyledLabel>
              <SelectInput
                error={errors.currentCompany}
                name="currentCompany"
                onChange={(val) => {
                  handleSelectChange(val, "currentCompany");
                }}
                options={currentCompanyOptions}
                placeholder={SelectPlaceholder}
                isClearable
                value={handleSelectValue(
                  currentCompanyOptions,
                  "currentCompany"
                )}
              />
              {values.currentCompany === "DELOITTE_BELGIUM" ? (
                <>
                  <TextInput
                    name="currentCompanyInfo"
                    error={errors.currentCompanyInfo}
                    onChange={(val) => handleChange(val)}
                    value={values.currentCompanyInfo}
                  >
                    <NameInput>{i18n.t("SAP Number")} *</NameInput>
                  </TextInput>
                  <SapError>{i18n.t(SAPError)}</SapError>
                </>
              ) : values.currentCompany === "EIFFAGE_BENELUX" ? (
                <>
                  <TextInput
                    name="currentCompanyInfo"
                    error={errors.currentCompanyInfo}
                    onChange={(val) => handleChange(val)}
                    value={values.currentCompanyInfo}
                  >
                    <NameInput>{i18n.t("Subsidiary")} *</NameInput>
                  </TextInput>
                  <SapError>{i18n.t(subsidiaryError)}</SapError>
                </>
              ) : ["CALLANT", "AON", "MAZDA", "EUROCONTROL", "SPORTOASE"].includes(
                  values.currentCompany
                ) ? (
                <>
                  <TextInput
                    name="currentCompanyInfo"
                    error={errors.currentCompanyInfo}
                    onChange={(val) => handleChange(val)}
                    value={values.currentCompanyInfo}
                  >
                    <NameInput>{i18n.t("Company Email")} *</NameInput>
                  </TextInput>
                  <SapError>{i18n.t(emailError)}</SapError>
                </>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
          <ButtonContainer>
            <Required>* {i18n.t("Required fields")}</Required>
            {loading ? (
              <StyledLoadingActionButton
                affinity={affinity}
                type="submit"
                value="Confirm"
                data-test-id="policy_holder_address_flow_loading"
              >
                {i18n.t("Loading")}
              </StyledLoadingActionButton>
            ) : (
              <StyledActionButton
                affinity={affinity}
                type="submit"
                value="Confirm"
                data-test-id="policy_holder_address_flow_submit"
              >
                {i18n.t("Next")}
              </StyledActionButton>
            )}
          </ButtonContainer>
        </form>
      </RightSplit>
    </SplitContainer>
  );
};

const SapError = styled.p`
  font-size: 1.2rem;
  text-align: right;
  margin-top: -1.5rem;
  color: #f74040;
`;

const NameInput = styled.p`
  height: 1rem;
`;

const StyledLabel = styled(Label)`
  color: ${({ theme }) => theme.typo.subTitle};
  font-size: 1.5rem;
`;

const SelectInput = styled(SearchSelectInput)`
  & .Select__option {
    font-size: 1.6rem;
    color: ${({ theme }) => theme.typo.text};
  }

  & .Select__option--is-focused {
    color: ${({ theme }) => theme.typo.text};
    font-weight: 500;
    background-color: ${({ theme }) => theme.brand.lighter};
    :hover {
      color: white;
      background-color: ${({ theme }) => theme.brand.secondary};
    }
  }
  & div {
    margin-bottom: 0;
  }
`;

const StyledActionButton = styled(ActionButton)`
  color: ${({ affinity }) => (affinity === "de-lijn" ? "black" : "white")};
  background-color: ${({ affinity }) =>
    affinity === "callant" || affinity === "uber" || affinity === "at-work"
      ? "#94C11A"
      : "default"};
  font-weight: ${({ affinity }) => (affinity === "de-lijn" ? "700" : "normal")};
`;

const StyledLoadingActionButton = styled(LoadingActionButton)`
  color: ${({ affinity }) => (affinity === "de-lijn" ? "black" : "white")};
  font-weight: ${({ affinity }) => (affinity === "de-lijn" ? "700" : "normal")};
`;

const Required = styled.p`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.typo.label};
  margin-top: 2rem;
  @media (max-width: 768px) {
    margin: 0 2rem 2rem 2rem;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: flex-end;

  & button {
    width: 20rem;
    margin-left: auto;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    & button {
      width: 100%;
    }
  }
`;

const RightSplit = styled.div`
  h1 {
    font-weight: 900;
    font-size: 1.65rem;
    letter-spacing: 1.19px;
    color: black;
    margin-bottom: 2rem;
  }
  width: 50%;
  padding: 5rem 4rem;
  overflow-y: scroll;

  form {
    display: flex;
    flex-direction: column;
  }

  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  ::-webkit-scrollbar:vertical {
    width: 11px;
  }

  ::-webkit-scrollbar:horizontal {
    height: 11px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
    background-color: rgba(0, 0, 0, 0.4);
  }

  @media (max-width: 768px) {
    width: 100%;
  }

  @media screen and (max-width: 425px) {
    padding: 4rem 2.5rem;
  }
`;

const SplitContainer = styled.div`
  display: flex;
  height: 100%;
`;

export default FlowPolicyHolderInfo;
