import React, { useReducer, useEffect, useState } from "react";
import { LoadingSpinner, ActionButton } from "wg-fe-ui";
import styled from "styled-components";
import { useParams } from "react-router";
import FlowPriceCalculatorOption from "../components/FlowPriceCalculatorOption";
import FlowLeftSideAvatar from "../components/FlowLeftSideAvatar.jsx";

import useRouting from "../hooks/useRouting";
import { patchStorage, retrieveStorageById } from "../helpers/storeService";
import i18n from "../i18n";
import {
  retrieveLegalPrice,
} from "../helpers/apiRouterService";
import { getNestedObject } from "../helpers/objectService";
import { getProspectObjectPyModels } from "../helpers/prospectObjectService";

const FlowPriceLegalCalculator = () => {
  const { id, affinity, insuranceType } = useParams();
  const storageData = retrieveStorageById(id, affinity, insuranceType);
  const [loading, setLoading] = useState(true);
  const [totalPremium, setTotalPremium] = useState("");
  const [quoteId, setQuoteId] = useState(null);
  const [quoteResp, setQuoteResp] = useState(null);
  const { renderNextRoute } = useRouting();
  const [prices, setPrices] = useState();
  const [webActivePrice, setWebActivePrice] = useState();
  const [webActiveSelected, setWebActiveSelected] = useState(false);

  function handleSubmit() {
    patchStorage({
      payload: {
        priceInformation: {
          totalPremium,
          webActiveSelected,
          quoteId,
          quoteResp,
        },
      },
      id,
      affinity,
      insuranceType,
    });
    renderNextRoute();
  }

  useEffect(() => {
    callPrice();
  }, []);

  // var myObj = {
  //   style: "currency",
  //   currency: "EUR",
  // };

  async function callPrice() {
    const { data } = storageData || {};
    const payload = {
      policy_holder: getProspectObjectPyModels(data),
      quote_specifications: {
        currency: "EUR",
        payment_interval: "annual",
        save_quote: true,
      },
    };

    const [resp, status] = await retrieveLegalPrice(
      payload,
      affinity,
      storageData.data.insurance_pack
    );

    if ((await status) === 200);
    else return {};
    const { premium, insurance, quote, id } = resp || {};
    setQuoteId(id);
    let guaranteesChosen = [];
    if (webActiveSelected) {
      guaranteesChosen = [quote?.base?.name].concat(insurance?.options || []);
      setTotalPremium(resp.premium.total_premium);
    } else {
      guaranteesChosen = [quote?.base?.name];
      setTotalPremium(resp.premium.total_premium - (webActivePrice || quote?.options?.[0]?.total_premium || 0));
    }
    setQuoteResp({ insurance, quote, premium, guaranteesChosen });
    console.log('!!!', guaranteesChosen);
    setPrices(resp);
    setLoading(false);
    return resp;
  }

  async function retrievePrice(queryString) {
    const { data } = storageData || {};
    const payload = {
      policy_holder: getProspectObjectPyModels(data),
      quote_specifications: {
        currency: "EUR",
        payment_interval: "annual",
        save_quote: true,
      },
    };

    const [resp, status] = await retrieveLegalPrice(
      payload,
      affinity,
      storageData.data.insurance_pack,
      queryString
    );

    if ((await status) === 200);
    else return {};
    const { premium, insurance, quote, id } = resp || {};
    setQuoteId(id);
    let guaranteesChosen = [];
    if (webActiveSelected) {
      guaranteesChosen = [quote?.base?.name].concat(insurance?.options || []);
      setTotalPremium(resp.premium.total_premium);
    } else {
      guaranteesChosen = [quote?.base?.name];
      setTotalPremium(resp.premium.total_premium - (webActivePrice || quote?.options?.[0]?.total_premium || 0));
    }
    setQuoteResp({ insurance, quote, premium, guaranteesChosen });
    console.log('!!!', guaranteesChosen);
    setPrices(resp);

    if (quote?.options?.[0]) {
      setWebActivePrice(quote.options[0].total_premium);
    }
  }

  function handleOptionChange(name, status) {
    if (status) {
      if (name === `webactive`) {
        setWebActiveSelected(true);
      }

      dispatch({ type: "add", value: name });
    } else {
      if (name === `webactive`) {
        setWebActiveSelected(false);
      }
      dispatch({ type: "remove", value: name });
    }
  }
  function processOptionsqueryString(_selectedOptions) {
    if (_selectedOptions === undefined) return;
    if (_selectedOptions.length === 1) return retrievePrice("");
    retrievePrice(
      `option=webactive_${storageData.data.insurance_pack.toLowerCase()}`
    );
  }

  const [selectedOptions, dispatch] = useReducer((arr, { type, value }) => {
    switch (type) {
      case "add":
        return [...arr, value];
      case "remove":
        return arr.filter((item) => item !== value);
      default:
        return arr;
    }
  }, []);

  useEffect(() => {
    processOptionsqueryString(
      selectedOptions.filter(
        (item) =>
          item !== "civil_liability" &&
          item !== "omnium" &&
          item !== "mini_omnium"
      )
    );
  }, [selectedOptions]);

  function handleSubPrices(name) {
    if (name === "private_life") {
      return getNestedObject(prices, ["quote", "base", "total_premium"]);
    }

    if (name === `webactive`) {
      return webActivePrice;
    }
  }

  const docsData = {
    "bronze": {
      "private_life": {
        "terms": {
          "nl": "https://docs.callant.eu/affinity/atwork/BV_Rechtsbijstand_Particulier Bronze_ARAG_NL.pdf",
          "fr": "https://docs.callant.eu/affinity/atwork/CS_Protection Juridique_Particulier Bronze_ARAG_FR.pdf",
        },
        "ipid": {
          "nl": "https://docs.callant.eu/affinity/atwork/IPID_Rechtsbijstand_Particulier Bronze_ARAG_NL.pdf",
          "fr": "https://docs.callant.eu/affinity/atwork/IPID_Protection Juridique_Particulier Bronze_ARAG_FR.pdf",
        }  
      },
    },
    "silver": {
      "private_life": {
        "terms": {
          "nl": "https://docs.callant.eu/affinity/atwork/BV_Rechtsbijstand_Particulier Silver_ARAG_NL.pdf",
          "fr": "https://docs.callant.eu/affinity/atwork/CS_Protection Juridique_Particulier Silver_ARAG_FR.pdf",
        },
        "ipid": {
          "nl": "https://docs.callant.eu/affinity/atwork/IPID_Rechtsbijstand_Particulier Silver_ARAG_NL.pdf",
          "fr": "https://docs.callant.eu/affinity/atwork/IPID_Protection Juridique_Particulier Silver_ARAG_FR.pdf",
        }  
      },
    },
    "gold": {
      "private_life": {
        "terms": {
          "nl": "https://docs.callant.eu/affinity/atwork/BV_Rechtsbijstand_Particulier Gold_ARAG_NL.pdf",
          "fr": "https://docs.callant.eu/affinity/atwork/CS_Protection Juridique_Particulier Gold_ARAG_FR.pdf",
        },
        "ipid": {
          "nl": "https://docs.callant.eu/affinity/atwork/IPID_Rechtsbijstand_Particulier Gold_ARAG_NL.pdf",
          "fr": "https://docs.callant.eu/affinity/atwork/IPID_Protection Juridique_Particulier Gold_ARAG_FR.pdf",
        }  
      },
    },
    "webactive": {
      "terms": {
        "nl": "https://docs.callant.eu/affinity/atwork/BV_Rechtsbijstand_Web@ctive_ARAG_NL.pdf",
        "fr": "https://docs.callant.eu/affinity/atwork/CS_Protection Juridique_Web@ctive_ARAG_FR.pdf",
      },
      "ipid": {
        "nl": "https://docs.callant.eu/affinity/atwork/IPID_Rechtsbijstand_Web@ctive_ARAG_NL.pdf",
        "fr": "https://docs.callant.eu/affinity/atwork/IPID_Protection Juridique_Web@ctive_ARAG_FR.pdf",
      }
    }
  }

  const pack = storageData.data.insurance_pack.toLowerCase();
  let ipidLink = docsData?.[pack]?.private_life?.ipid?.[i18n.language];
  let termsLink = docsData?.[pack]?.private_life?.terms?.[i18n.language];
  let ipidLinkWebActive = docsData?.webactive?.ipid?.[i18n.language];
  let termsLinkWebActive = docsData?.webactive?.terms?.[i18n.language];

  if (loading) {
    return (
      <LoadingContainer>
        <LoadingSpinner />
        <LoadingText>{i18n.t("Please wait")}</LoadingText>
      </LoadingContainer>
    );
  }
  return (
    <SplitContainer>
      <FlowLeftSideAvatar
        chatText={i18n.t(
          "Choose what deposit you want to take Depending on your cologne, your coverage will vary"
        )}
      />
      <RightSplit>
        <Title>{i18n.t("What guarantee do you wish to take?")}</Title>

        <PriceCalculatorContainer>
          <StyledFlowPriceCalculatorOption
            id="private_life"
            key="private_life"
            title={i18n.t("Private life")}
            onChange={handleOptionChange}
            mandatory={true}
            price={handleSubPrices("private_life")}
            helperText={i18n.t("private_life_description")}
            insuranceType={insuranceType}
            ipidLink={ipidLink}
            termsLink={termsLink}
          />
          <StyledFlowPriceCalculatorOption
            id="webactive"
            key="webactive"
            title={i18n.t("Web@ctive")}
            onChange={handleOptionChange}
            mandatory={false}
            price={handleSubPrices("webactive")}
            helperText={i18n.t("webactive_description")}
            insuranceType={insuranceType}
            ipidLink={ipidLinkWebActive}
            termsLink={termsLinkWebActive}
          />
        </PriceCalculatorContainer>
        <CheckoutContainer affinity={affinity}>
          <TotalPrice>
            {i18n.t(`Total price`)}{" "}
            <span>
              {parseFloat(totalPremium).toLocaleString("nl-BE", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}{" "}
              EUR/
              {i18n.t("y")}
            </span>
          </TotalPrice>
          <Button
            onClick={() => {
              handleSubmit();
            }}
          >
            {i18n.t(`Next`)}
          </Button>
        </CheckoutContainer>
      </RightSplit>
    </SplitContainer>
  );
};

const RightSplit = styled.div`
  width: 50%;
  display: grid;
  grid-template-rows: 10% 80% 10%;
  padding: 4rem 5rem;
  height: 100%;

  @media (max-width: 768px) {
    width: 100%;
  }
  @media screen and (max-width: 900px) {
    padding: 3rem 2rem;
  }
`;
const Title = styled.h1`
  font-weight: 900;
  font-size: 1.65rem;
  letter-spacing: 1.19px;
  color: black;
`;

const CheckoutContainer = styled.div`
  display: flex;
  > button {
    color: ${({ affinity }) => (affinity === "de-lijn" ? "black" : "white")};
    background-color: ${({ affinity }) =>
      affinity === "callant" || affinity === "uber" || affinity === "at-work" ? "#94C11A" : "default"};
    font-weight: ${({ affinity }) =>
      affinity === "de-lijn" ? "700" : "normal"};
  }
`;

const TotalPrice = styled.p`
  font-size: 2rem;
  align-self: center;
  margin-top: auto;
  > span {
    font-weight: 700;
  }
`;
const StyledFlowPriceCalculatorOption = styled(FlowPriceCalculatorOption)`
  display: grid;
  grid-template-columns: 62% 35%;
  .title {
    flex-direction: column;
  }
`;

const PriceCalculatorContainer = styled.div`
  display: flex;
  flex-direction: column;
  div {
    margin-bottom: 1.5rem;
  }
`;

const LoadingText = styled.p`
  margin-top: 2rem;
  font-size: 2rem;
`;

const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Button = styled(ActionButton)`
  width: 20rem;
  margin-left: auto;
  margin-top: auto;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const SplitContainer = styled.div`
  display: flex;
  height: 100%;
`;

export default FlowPriceLegalCalculator;
