import React from "react";

const SvgComponent = ({ colors }) => (
  <svg viewBox="0 0 50 50" width="100%">
    <path
      fill={colors}
      d="M45.21,27.67l-1-3.12c-.64-.43-12.68-2-12.68-2l-3.19-6.12a.86.86,0,0,0-.76-.45H8.34a.85.85,0,0,0-.84.64c-.48,2.11-2,8.81-2.45,11.28a1.71,1.71,0,0,0,0,.87l.79,4.39a1,1,0,0,0,1,.82H9a5,5,0,0,0,10,0H31a5,5,0,0,0,10,0h2.75a1.4,1.4,0,0,0,1.36-1.09L46,30.33V27.67ZM14,37a3,3,0,1,1,3-3A3,3,0,0,1,14,37Zm22,0a3,3,0,1,1,3-3A3,3,0,0,1,36,37Z"
    />
  </svg>
);

export default SvgComponent;
