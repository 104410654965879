import React from "react";

const SvgComponent = ({ colors }) => (
  <svg width="100%" viewBox="0 0 50 50">
    <path
      fill={colors}
      d="M48,32.07v2A1,1,0,0,1,47,35H40.82A2.77,2.77,0,0,0,41,34a3,3,0,0,0-6,0,2.77,2.77,0,0,0,.18,1H15.82A2.77,2.77,0,0,0,16,34a3,3,0,0,0-6,0,2.77,2.77,0,0,0,.18,1H4.56a1,1,0,0,1-.95-.63L2.26,30.66a4.24,4.24,0,0,1-.16-2.39l.64-1.82a1.15,1.15,0,0,1,1.09-.74,17.52,17.52,0,0,0,4.27-.52l10.65-2.61a18,18,0,0,1,1.94-.39,21.29,21.29,0,0,1,11,1.42l4.42,2a10.83,10.83,0,0,0,1.84.64L41,27c4,1,6,3,6.65,4.16A1.86,1.86,0,0,1,48,32.07Z"
    />
    <path
      fill={colors}
      d="M13,29a5,5,0,0,0-5,5,4.71,4.71,0,0,0,.1,1,5,5,0,0,0,9.8,0,4.71,4.71,0,0,0,.1-1A5,5,0,0,0,13,29Zm0,8a3,3,0,0,1-2.82-2A2.77,2.77,0,0,1,10,34a3,3,0,0,1,6,0,2.77,2.77,0,0,1-.18,1A3,3,0,0,1,13,37Z"
    />
    <path
      fill={colors}
      d="M38,29a5,5,0,0,0-5,5,4.71,4.71,0,0,0,.1,1,5,5,0,0,0,9.8,0,4.71,4.71,0,0,0,.1-1A5,5,0,0,0,38,29Zm0,8a3,3,0,0,1-2.82-2A2.77,2.77,0,0,1,35,34a3,3,0,0,1,6,0,2.77,2.77,0,0,1-.18,1A3,3,0,0,1,38,37Z"
    />
  </svg>
);

export default SvgComponent;
