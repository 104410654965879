import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import i18n from "../i18n";
import FlowLeftSideAvatar from "../components/FlowLeftSideAvatar.jsx";
import FlowDriversInformationExtraForm from "../components/FlowDriversInformationExtraForm.jsx";
import useRouting from "../hooks/useRouting";
import { patchStorage, retrieveStorageById } from "../helpers/storeService";

const FlowDriversInformationExtra = () => {
  const [defaultValues, setDefaultValues] = useState({});
  const { renderNextRoute } = useRouting();
  const { id, affinity, insuranceType } = useParams();
  const [additionalDrivers, setAdditionalDrivers] = useState([]);
  const { data } = retrieveStorageById(id, affinity, insuranceType);

  useEffect(() => {
    const { data } = retrieveStorageById(id, affinity, insuranceType);
    setDefaultValues(data);
  }, []);

  function handleSubmit(values) {
    patchStorage({
      payload: { car: { ...data?.car, mainDriver : { ...data?.car?.mainDriver, ...values }, additionalDrivers } },
      id,
      affinity,
      insuranceType,
    });

    if (data?.info?.mainDriverBikeSameAsCar) {
      patchStorage({
        payload: { twoWheeler: { ...data?.twoWheeler, mainDriver: ({ ...data?.car?.mainDriver, ...values } || {}), additionalDrivers: (additionalDrivers || []) } },
        id,
        affinity,
        insuranceType,
      });
    }

    renderNextRoute();
  }

  const handleAddedDrivers = (value) => {
    setAdditionalDrivers(value);
  };

  return (
    <SplitContainer>
      <FlowLeftSideAvatar
        chatText={i18n.t(
          `To start off, I will need the information about the main driver and possible additional drivers`
        )}
      />
      <RightSplit>
        <FlowDriversInformationExtraForm
          handleAddedDrivers={handleAddedDrivers}
          handleFormValues={handleSubmit}
          defaultValues={defaultValues?.car?.mainDriver || {}}
        />
      </RightSplit>
    </SplitContainer>
  );
};

const RightSplit = styled.div`
  width: 50%;
  overflow-y: auto;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const SplitContainer = styled.div`
  display: flex;
  height: 100%;
`;

export default FlowDriversInformationExtra;
