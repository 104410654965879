import React, { useState } from "react";
import styled from "styled-components";
import { useParams } from "react-router";
import useRouting from "../hooks/useRouting";
import { patchStorage, retrieveStorageById } from "../helpers/storeService.js";
import i18n from "../i18n.js";

import FlowOverviewForm from "../components/FlowOverviewForm";
import FlowOverviewDriversInformation from "../components/FlowOverviewDriversInformation";
// import InfoPopupRemoteSell from "../components/InfoPopupRemoteSell.jsx";

const FlowOverview = () => {
  const { renderNextRoute } = useRouting();
  const { id, affinity, insuranceType } = useParams();
  const { data } = retrieveStorageById(id, affinity, insuranceType) || {};
  const [errorMsg /*setErrorMsg*/] = useState("");

  function handleSubmit(values) {
    // Should be handled better
    let acknowledgements = [];
    Object.entries(values).forEach((item) => {
      if (
        (item[1]?.agreed === false || item[1]?.agreed === true) &&
        item[1]?.key !== "send_mail_to_customer"
      ) {
        acknowledgements.push({
          text: i18n.t(item[0]),
          agreed: item[1]?.agreed,
          key: item[1]?.key,
        });
      }
    });
    values.acknowledgements = acknowledgements;

    patchStorage({ payload: values, id, affinity, insuranceType });
    renderNextRoute();
  }

  return (
    <SplitContainer>
      <LeftSplit>
        <FlowOverviewDriversInformation
          errorMsg={errorMsg}
          storageData={data}
        />
      </LeftSplit>
      <RightSplit>
        <FlowOverviewForm handleFormValues={handleSubmit} />
      </RightSplit>
    </SplitContainer>
  );
};

const LeftSplit = styled.div`
  width: 50%;
  padding: 5rem 4rem;
  justify-content: center;
  display: flex;
  :after {
    @media screen and (min-width: 769px) {
      content: "";
      width: 1px;
      height: 68%;
      background: rgba(0, 0, 0, 0.3);
      position: absolute;
      left: 50%;
    }
  }

  @media (max-width: 768px) {
    padding: 3rem 2rem 0rem 2rem;
    width: 100%;
  }
`;

const RightSplit = styled.div`
  width: 50%;
  padding: 5rem 4rem;
  overflow-y: auto;

  @media (max-width: 768px) {
    width: 100%;
  }

  @media screen and (max-width: 425px) {
    padding: 4rem 2.5rem;
  }
`;

const SplitContainer = styled.div`
  display: flex;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export default FlowOverview;
