import React, { useEffect, useState } from "react";
import { SearchSelectInput, ActionButton, Label, TextInput } from "wg-fe-ui";
import styled from "styled-components";
// import DateInput from "../components/DateInput";
import * as Yup from "yup";
import i18n from "../i18n";
// import FlowAddAdditionalDriver from "./FlowAddAdditionalDriver.jsx";

import {
  usedForOptions as usedForOptionsConstant,
  currentProfessionOptions as currentProfessionOptionsConstant,
} from "../constants/FlowSearchSelectData";
import useForm from "../hooks/useForm";
import { string, bonusMalus } from "../constants/validationSchemas.js";
import { useParams } from "react-router";
import InfoPopup from "./InfoPopup";

const FlowDriversInformationExtraForm = ({
  handleAddedDrivers,
  handleFormValues,
  defaultValues,
}) => {
  const { affinity } = useParams();
  const SelectPlaceholder = i18n.t("Choose your option");
  const [usedForOptions, setUsedForOptions] = useState([]);
  const [currentProfessionOptions, setCurrentProfessionOptions] = useState([]);
  // const [toggle, setToggle] = useState(false);
  console.log(defaultValues);

  const SignupSchema = Yup.object().shape({
    usedFor: string.required,
    bonusMalus: bonusMalus,
    currentProfession: string.required,
  });

  const { handleChange, errors, handleSubmit, values } = useForm({
    validationSchema: SignupSchema,
    change: () => {},
  });

  useEffect(() => {
    Object.keys(defaultValues || {}).forEach((name) => {
      handleChange({ name, value: defaultValues[name] });
    });
    if (
      defaultValues?.sameDriverBikeAsCar &&
      !defaultValues?.currentProfession
    ) {
      handleChange({
        name: "currentProfession",
        value: defaultValues["currentProfession"],
      });
    }
  }, [defaultValues]);

  useEffect(() => {
    loadSelectOptions();
  }, []);

  // const handleDrivers = (value) => {
  //   handleAddedDrivers(value);
  // };

  function loadSelectOptions() {
    setUsedForOptions(
      usedForOptionsConstant.map(({ value, label }) => {
        return { value, label: i18n.t(label) };
      })
    );
    setCurrentProfessionOptions(
      currentProfessionOptionsConstant.map(({ value, label }) => {
        return { value, label: i18n.t(label) };
      })
    );
  }

  useEffect(() => {
    console.log(currentProfessionOptions);

    currentProfessionOptions.sort(function(a, b) {
      var nameA = a.label;
      var nameB = b.label;
      if (nameA < nameB) {
        return -1;
      } else if (nameA > nameB) {
        return 1;
      } else {
        return 0;
      }
    });
  }, [currentProfessionOptions]);

  function formSubmitHandler(e) {
    e.preventDefault();
    handleSubmit(handleFormValues);
  }

  function handleSelectValue(object, inputName) {
    if (!values[inputName]) return "";
    const key = values[inputName];
    return object.filter(({ value }) => value === key)[0];
  }

  function handleSelectChange(val, name) {
    handleChange({ name, value: val ? val.value : "" });
  }

  return (
    <Form onSubmit={formSubmitHandler}>
      <CatTitle>{i18n.t("Primary driver information")} </CatTitle>
      <div>
        <StyledLabel className="padding">
          {i18n.t("For what purpose is the car used")} *
          <StyledInfoPopup
            title={i18n.t("For what purpose is the car used")}
            buttonText={i18n.t("?")}
            info={i18n.t("carPurposePopupText")}
          />
        </StyledLabel>
        <SelectInput
          error={errors.usedFor}
          name="usedFor"
          onChange={(val) => {
            handleSelectChange(val, "usedFor");
          }}
          options={usedForOptions}
          placeholder={SelectPlaceholder}
          isClearable
          value={handleSelectValue(usedForOptions, "usedFor")}
        />
      </div>
      <TextInput
        name="bonusMalus"
        error={errors.bonusMalus}
        onChange={(val) => handleChange(val)}
        value={values.bonusMalus}
        data-lpignore="true"
      >
        <NameInput>{i18n.t("Bonus malus")} *</NameInput>
      </TextInput>
      <div>
        <StyledLabel className="padding">
          {i18n.t("Current profession")} *
        </StyledLabel>
        <SelectInput
          error={errors.currentProfession}
          name="currentProfession"
          onChange={(val) => {
            handleSelectChange(val, "currentProfession");
          }}
          options={currentProfessionOptions}
          placeholder={SelectPlaceholder}
          isClearable
          value={handleSelectValue(
            currentProfessionOptions,
            "currentProfession"
          )}
        />
      </div>
      {/* <FlowAddAdditionalDriver
        handleDrivers={handleDrivers}
        defaultAdditionalDrivers={data?.twoWheeler?.additionalDrivers}
        errors={errors}
        values={values}
      /> */}
      <ButtonContainer>
        <StyledActionButton
          affinity={affinity}
          type="submit"
          value="Login"
          data-test-id="social_login_submit"
        >
          {i18n.t("Next")}
        </StyledActionButton>
      </ButtonContainer>
    </Form>
  );
};

const StyledActionButton = styled(ActionButton)`
  color: ${({ affinity }) => (affinity === "de-lijn" ? "black" : "white")};
  background-color: ${({ affinity }) =>
    affinity === "callant" ? "#94C11A" : "default"};
  font-weight: ${({ affinity }) => (affinity === "de-lijn" ? "700" : "normal")};
`;

const NameInput = styled.p`
  height: 1rem;
`;

const StyledLabel = styled(Label)`
  color: ${({ theme }) => theme.typo.subTitle};
  font-size: 1.5rem;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 5rem 4rem;
`;

const SelectInput = styled(SearchSelectInput)`
  & .Select__option {
    font-size: 1.6rem;
    color: ${({ theme }) => theme.typo.text};
  }

  & .Select__option--is-focused {
    color: ${({ theme }) => theme.typo.text};
    font-weight: 500;
    background-color: ${({ theme }) => theme.brand.lighter};
    :hover {
      color: white;
      background-color: ${({ theme }) => theme.brand.secondary};
    }
  }
  & div {
    margin-bottom: 0;
  }
`;

const ButtonContainer = styled.div`
  margin-left: auto;
  margin-top: auto;
  @media (max-width: 768px) {
    margin-left: 0;
  }

  & button {
    width: 20rem;

    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;

const CatTitle = styled.h1`
  font-weight: 900;
  font-size: 1.65rem;
  letter-spacing: 1.19px;
  color: black;
  margin-bottom: 2rem;
`;

const StyledInfoPopup = styled(InfoPopup)`
  float: right;
  font-size: 1.2rem;
  height: 1.6rem;
  line-height: 1.3;
  width: 1.6rem;
  right: 0;
`;

export default FlowDriversInformationExtraForm;
