import React from "react";
import styled from "styled-components";
import i18n from "../i18n";
import { retrieveStorageById } from "../helpers/storeService.js";
import { useParams } from "react-router";

import { getBrandData } from "../helpers/tagRouterService";
const FlowLeftSideHomeInfo = () => {
  const { id, affinity, insuranceType } = useParams();
  const { data } = retrieveStorageById(id, affinity, insuranceType) || {};

  const buildingTypes = {
    open: "Detached",
    closed: "Terraced",
    halfopen: "Semi-detached",
    apartment: "Apartment",
    villa: "Villa",
  };

  const propertyType = {
    owner: "Owner (inhabitant)",
    landlord: "Owner (Not inhabitant)",
    tenant: "Renter",
  };

  const ageValues = {
    less_than_10: "Less than 10 years old",
    between_10_and_20: "Between 11 and 20 years old",
    between_20_and_50: "Between 21 and 50 years old",
    more_than_50: "More than 50 years old",
  };

  const propertyIsOfType =
    propertyType[data.risk_address.parcel.main_building.propertyType];

  const buildingIsOfType =
    buildingTypes[data.risk_address.parcel.main_building.house_type];

  const buildingAge =
    ageValues[data.risk_address.parcel.main_building.buildingAge];

  return (
    <LeftSplit>
      {["Callant", "Agoria"].includes(getBrandData().name) ? (
        <WideLogo src={getBrandData().logo} alt="" />
      ) : (
        <Logo src={getBrandData().logo} alt="" />
      )}
      <DriverInfoWrapper>
        <DriverInfoHeader>{i18n.t("Policyholder")}</DriverInfoHeader>
        {data.personType === "natural" ? (
          <>
            <DriverInfoListItem>
              <DriverInfoLabel>{i18n.t("Name")}</DriverInfoLabel>
              {data.firstName} {data.lastName}
            </DriverInfoListItem>
            <DriverInfoListItem>
              <DriverInfoLabel>{i18n.t("Date of birth")}</DriverInfoLabel>
              {data.birth}
            </DriverInfoListItem>
          </>
        ) : (
          <>
            <DriverInfoListItem>
              <DriverInfoLabel>{i18n.t("Company name")}</DriverInfoLabel>
              {data?.policyHolder.companyName}
            </DriverInfoListItem>
            {data.policyHolder.cbe != null ? (
              <DriverInfoListItem>
                <DriverInfoLabel>{i18n.t("CBE")}</DriverInfoLabel>
                {data?.policyHolder.cbe}
              </DriverInfoListItem>
            ) : (
              ""
            )}
          </>
        )}
        <DriverInfoListItem>
          <DriverInfoLabel>{i18n.t("Email")}</DriverInfoLabel>
          {i18n.t(data?.policyHolder.email)}
        </DriverInfoListItem>
        {data.telephonenr != null ? (
          <DriverInfoListItem>
            <DriverInfoLabel>{i18n.t("Phonenumber")}</DriverInfoLabel>+ 32{" "}
            {i18n.t(data.telephonenr)}
          </DriverInfoListItem>
        ) : (
          ""
        )}
        <DriverInfoHeader>{i18n.t("Risk address")}</DriverInfoHeader>
        <DriverInfoListItem>
          <DriverInfoLabel>{i18n.t("Address line 1")}</DriverInfoLabel>
          {i18n.t(
            data.risk_address.address.street +
              " " +
              data.risk_address.address.housenr
          )}
        </DriverInfoListItem>
        <DriverInfoListItem>
          <DriverInfoLabel>{i18n.t("Address line 2")}</DriverInfoLabel>
          {i18n.t(
            data.risk_address.address.zipcode +
              " " +
              data.risk_address.address.city
          )}
        </DriverInfoListItem>
        {data.risk_address.address.boxnr != null ? (
          <DriverInfoListItem>
            <DriverInfoLabel>{i18n.t("Box number")}</DriverInfoLabel>
            {data.risk_address.address.boxnr}
          </DriverInfoListItem>
        ) : (
          ""
        )}
        <DriverInfoListItem>
          <DriverInfoLabel>{i18n.t("Owner/Renter")}</DriverInfoLabel>
          {i18n.t(propertyIsOfType)}
        </DriverInfoListItem>
        <DriverInfoListItem>
          <DriverInfoLabel>{i18n.t("Building type")}</DriverInfoLabel>
          {i18n.t(buildingIsOfType)}
        </DriverInfoListItem>
        <DriverInfoListItem>
          <DriverInfoLabel>{i18n.t("Building age")}</DriverInfoLabel>
          {i18n.t(buildingAge)}
        </DriverInfoListItem>
      </DriverInfoWrapper>
    </LeftSplit>
  );
};

const DriverInfoWrapper = styled.ul`
  display: grid;
  width: 80%;
  align-self: center;
  @media screen and (max-width: 769px) {
    width: 93%;
  }
`;

const DriverInfoLabel = styled.label`
  text-align: left;
`;

const DriverInfoListItem = styled.li`
  display: grid;
  grid-template-columns: 14rem auto;
  text-align: right;
  padding: 0.5rem 0;
  word-break: break-word;
  @media (max-width: 570px) {
    font-size: 14px;
  }
`;

const DriverInfoHeader = styled.li`
  font-size: 18px;
  font-weight: bold;
  padding: 1rem 0;
  padding-top: 2rem;
  text-align: center;
  text-transform: uppercase;
`;

const Logo = styled.img`
  display: block;
  height: 100%;
  width: auto;
  max-height: 12.5rem;
`;
const WideLogo = styled.img`
  width: 85%;
`;

const LeftSplit = styled.div`
  width: 100%;
  display: grid;
  grid-template-rows: 12.5rem 35rem;
  grid-gap: 15px 0;
  justify-items: center;
  @media (max-width: 570px) {
    grid-template-rows: 6.5rem 35rem;
  }
`;

export default FlowLeftSideHomeInfo;
