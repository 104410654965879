import React, { useState, useEffect } from "react";
import styled from "styled-components";
import i18n from "../i18n";

import useRouting from "../hooks/useRouting.js";
import { useParams } from "react-router";
import FlowAddressContainer from "../components/FlowAddressContainer";
import FlowLeftSideAvatar from "../components/FlowLeftSideAvatar.jsx";
import LoadingActionButton from "../components/LoadingActionButton";
import { retrieveStorageById, patchStorage } from "../helpers/storeService.js";
import useForm from "../hooks/useForm";
import { ActionButton } from "wg-fe-ui";
import * as Yup from "yup";
import { string, boxnr } from "../constants/validationSchemas";
import { getNestedObject } from "../helpers/objectService";
import { retrieveAddressInfo } from "../helpers/apiRouterService";

const FlowPolicyHolderInfo = () => {
  const [loading, setLoading] = useState(false);
  const [defaultValues, setDefaultValues] = useState();
  const { renderNextRoute } = useRouting();
  const { id, affinity, insuranceType } = useParams();
  const validationSchema = Yup.object().shape({
    streetName: string.required,
    municipalityName: string.required,
    postalCode: string.required,
    streetNumber: string.required,
    boxNumber: boxnr,
  });

  const { errors, values, handleSubmit, handleChange } = useForm({
    validationSchema,
  });

  useEffect(() => {
    const { data } = retrieveStorageById(id, affinity, insuranceType) || {
      data: {},
    };
    const riskAddress =
      getNestedObject(data, ["risk_address", "address"]) || {};
    const { street, city, zipcode, boxnr, housenr } = riskAddress;
    const tempData = {
      streetName: street,
      municipalityName: city,
      postalCode: zipcode,
      boxNumber: boxnr,
      streetNumber: housenr,
    };
    Object.keys(tempData).forEach((key) =>
      tempData[key] === undefined ? delete tempData[key] : {}
    );
    setDefaultValues(tempData);
  }, []);

  useEffect(() => {
    defaultValuesHandler(defaultValues);
  }, [defaultValues]);

  function defaultValuesHandler() {
    Object.keys(defaultValues || {}).forEach((name) => {
      handleChange({ name, value: defaultValues[name] });
    });
  }

  const handleForm = (e) => {
    e.preventDefault();
    handleSubmit(submitHandler);
  };

  const submitHandler = async (address) => {
    setLoading(true);
    const {
      streetName,
      streetNumber,
      boxNumber,
      postalCode,
      municipalityName,
    } = address;
    const [resp, status] = await retrieveAddressInfo({
      street: streetName,
      zipcode: postalCode,
      housenr: streetNumber.toLowerCase(),
      country_code: "BE",
      boxnr: boxNumber,
      city: municipalityName,
    });
    if (status / 100 === 2) {
      patchStorage({
        payload: { risk_address: resp },
        id,
        affinity,
        insuranceType,
      });
      renderNextRoute();
    }
    setLoading(false);
  };

  return (
    <SplitContainer>
      <FlowLeftSideAvatar
        chatText={i18n.t(`Fill in the address you would like to insure`)}
      />
      <RightSplit>
        <form onSubmit={handleForm}>
          <FlowAddressContainer
            title={i18n.t(`Fill in the address you would like to insure`)}
            errors={errors}
            values={values}
            defaultValues={defaultValues}
            handleChange={handleChange}
          />
          <Required>* {i18n.t("Required fields")}</Required>
          <ButtonContainer>
            {loading ? (
              <StyledLoadingActionButton
                affinity={affinity}
                type="submit"
                value="Confirm"
                data-test-id="risk_address_flow_loading"
              >
                {i18n.t("Loading")}
              </StyledLoadingActionButton>
            ) : (
              <StyledActionButton
                affinity={affinity}
                type="submit"
                value="Confirm"
                data-test-id="risk_address_flow_submit"
              >
                {i18n.t("Next")}
              </StyledActionButton>
            )}
          </ButtonContainer>
        </form>
      </RightSplit>
    </SplitContainer>
  );
};

const StyledActionButton = styled(ActionButton)`
  color: ${({ affinity }) => (affinity === "de-lijn" ? "black" : "white")};
  background-color: ${({ affinity }) =>
    affinity === "callant" || affinity === "uber" || affinity === "at-work"
      ? "#94C11A"
      : "default"};
  font-weight: ${({ affinity }) => (affinity === "de-lijn" ? "700" : "normal")};
`;

const StyledLoadingActionButton = styled(LoadingActionButton)`
  color: ${({ affinity }) => (affinity === "de-lijn" ? "black" : "white")};
  font-weight: ${({ affinity }) => (affinity === "de-lijn" ? "700" : "normal")};
`;

const Required = styled.p`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.typo.label};
  margin-top: 2rem;
  @media (max-width: 768px) {
    margin: 0 2rem 2rem 2rem;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: flex-end;

  & button {
    width: 20rem;
    margin-left: auto;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    & button {
      width: 100%;
    }
  }
`;

const RightSplit = styled.div`
  width: 50%;
  padding: 5rem;
  overflow-y: scroll;

  form {
    display: flex;
    flex-direction: column;
  }

  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  ::-webkit-scrollbar:vertical {
    width: 11px;
  }

  ::-webkit-scrollbar:horizontal {
    height: 11px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
    background-color: rgba(0, 0, 0, 0.4);
  }

  @media (max-width: 768px) {
    width: 100%;
  }

  @media screen and (max-width: 425px) {
    padding: 4rem 2.5rem;
  }
`;

const SplitContainer = styled.div`
  display: flex;
  height: 100%;
`;

export default FlowPolicyHolderInfo;
