import {
  Honda,
  DeLijn,
  Callant,
  Agoria,
  Valckenier,
  AtWork,
  Doceo,
  OneAutomotive,
} from "../constants/themingData";
export let currentTheme = {};

const themes = {
  honda: Honda(),
  "one_automotive": OneAutomotive(),
  "de-lijn": DeLijn(),
  callant: Callant(),
  agoria: Agoria(),
  "at-work": AtWork(),
  doceo: Doceo(),
  "motor-insurance": Callant(),
  valckenier: Valckenier(),
  uber: Callant()
};

export function getThemeObject(affinity) {
  return themes[affinity ? affinity : "callant"];
}
