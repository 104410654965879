import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { retrieveCarModelByIdV2 } from "../helpers/apiRouterService.js";
import { LoadingSpinner } from "wg-fe-ui/dist";

import FlowSearchForVehicleForm from "../components/FlowSearchForVehicleForm.jsx";
import useRouting from "../hooks/useRouting.js";
import { useParams } from "react-router";
import { patchStorage, retrieveStorageById } from "../helpers/storeService.js";
import louise_logo from "../assets/images/louise_logo.svg";

import i18n from "../i18n";

const FlowSearchForVehicle = () => {
  const [carBrandImage, setCarBrandImage] = useState(louise_logo);
  const [carInfo, setCarInfo] = useState({});
  const [defaultValues, setDefaultValues] = useState({});
  const { renderNextRoute } = useRouting();
  const { id, affinity, insuranceType } = useParams();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const { data } = retrieveStorageById(id, affinity, insuranceType);
    setDefaultValues(data);
  }, []);

  useEffect(() => {
    const { data } = retrieveStorageById(id, affinity, insuranceType);

    if (Object.keys(carInfo).length > 0) {
      if (["honda", "mazda", "one_automotive"].includes(affinity)) {
        carInfo.vehicleType = "PASSENGER_CAR";
      }

      patchStorage({
        payload: { car: { ...data?.car, details: carInfo } },
        id,
        affinity,
        insuranceType,
      });
      renderNextRoute();
    }
  }, [carInfo]);

  function handleBrandLogo(img) {
    if (!img) {
      setCarBrandImage(louise_logo);
    } else {
      setCarBrandImage(img);
    }
  }

  function handleManual() {
    const { data } = retrieveStorageById(id, affinity, insuranceType);
    patchStorage({
      payload: { car: { ...data?.car, details: undefined } },
      id,
      affinity,
      insuranceType,
    });
    renderNextRoute();
  }

  function handleSubmit(brandKey, modelId, versionId, vehicleType) {
    loadCarData(brandKey, modelId, versionId, vehicleType);
  }

  const loadCarData = async (brandKey, modelId, versionId, vehicleType) => {
    setLoading(true);

    if (brandKey && modelId && versionId) {
      const [resp, status] = await retrieveCarModelByIdV2(
        brandKey,
        modelId,
        versionId
      );
      if (status !== 200) {
        setLoading(false);
      }

      const { car } = resp;

      const values = {
        carImg: car?.images.length > 0 ? car?.images[0] : carBrandImage,
        brand: car?.brand,
        model: car?.series,
        version: car?.version,
        // TODO: Add the carTypes from the response
        carTypes: {
          sport: car?.is_sportscar,
          suv: car?.category === "SUV",
          cabriolet: car?.category === "CABRIOLET",
          coupe: car?.category === "COUPE",
        },
        kw: car?.kw,
        cc: car?.cc,
        co2: car?.co2,
        modelYear: car?.model_year,
        motorType: car?.motor_type,
        seats: car?.amount_of_seats_incl_driver,
        catalogueValue: car?.price,
        vehicleType: vehicleType,
      };

      setLoading(false);
      setCarInfo(values);
    } else {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <LoadingContainer>
        <LoadingSpinner />
      </LoadingContainer>
    );
  }

  return (
    <SplitContainer>
      <LeftSplit>
        <RoundImageContainer>
          <RoundImage img={carBrandImage} alt="car brand logo" />
        </RoundImageContainer>
        <TextBalloon>
          <p
            dangerouslySetInnerHTML={{
              __html: i18n.t(
                "Next I need some information about your car You can fill it out here on the right"
              ),
            }}
          ></p>
        </TextBalloon>
      </LeftSplit>
      <RightSplit>
        <FlowSearchForVehicleForm
          defaultValues={defaultValues}
          handleBrandLogo={handleBrandLogo}
          handleSubmit={handleSubmit}
          handleManual={handleManual}
        />
      </RightSplit>
    </SplitContainer>
  );
};

const TextBalloon = styled.div`
  display: flex;
  font-size: 1.6rem;
  line-height: 1.5;
  align-self: center;
  width: 100%;
  padding: 2.2rem;
  border-radius: 0.5rem;
  color: #393939;
  background: #f3f3f3;
  margin-top: auto;
  > span {
    font-weight: 700;
  }
`;

const RightSplit = styled.div`
  width: 50%;
  padding: 5rem 4rem;
  overflow-y: auto;

  @media (max-width: 768px) {
    width: 100%;
  }

  @media screen and (max-width: 425px) {
    padding: 4rem 2.5rem;
  }
`;

const SplitContainer = styled.div`
  display: flex;
  height: 100%;
`;

const LeftSplit = styled.div`
  width: 50%;
  display: grid;
  grid-template-rows: 71% 25%;
  grid-gap: 2rem;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  padding: 5rem 4rem;

  :after {
    content: "";
    width: 1px;
    height: 70%;
    background: rgba(0, 0, 0, 0.3);
    position: absolute;
    right: 50%;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const RoundImage = styled.div`
  background-image: url(${(props) => props.img});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
  width: 100%;
  height: auto;
  padding-top: 100%;
`;

const RoundImageContainer = styled.div`
  max-width: 35rem;
  width: 100%;
  margin: auto;
`;

const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default FlowSearchForVehicle;
