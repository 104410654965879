import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import i18n from "../i18n";
import FlowLeftSideAvatar from "../components/FlowLeftSideAvatar.jsx";
import FlowDriversInformationExtraForm from "../components/FlowDriversInformationExtraFormBike.jsx";
import useRouting from "../hooks/useRouting";
import { patchStorage, retrieveStorageById } from "../helpers/storeService";

const FlowDriversInformationExtraBike = () => {
  const [defaultValues, setDefaultValues] = useState({});
  const { renderNextRoute } = useRouting();
  const { id, affinity, insuranceType } = useParams();
  const [additionalDrivers, setAdditionalDrivers] = useState([]);
  const { data } = retrieveStorageById(id, affinity, insuranceType);

  useEffect(() => {
    const { data } = retrieveStorageById(id, affinity, insuranceType);
    setDefaultValues(data);
  }, []);

  function handleSubmit(values) {
    patchStorage({
      payload: { twoWheeler: { ...data?.twoWheeler, mainDriver : { ...data?.twoWheeler?.mainDriver, ...values }, additionalDrivers } },
      id,
      affinity,
      insuranceType,
    });

    isPersonAccepted() ? renderNextRoute(1) : renderNextRoute(2);
  }

  function getAge(dateString) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
  }

  function isPersonAccepted() {
    const { data } = retrieveStorageById(id, affinity, insuranceType);
    console.log(data);
    // driver <= 75 yo; if driver >= 65, then bm <= 3; in general bm <= 11
    const birthDate = data.main_driver.birth;
    const age = getAge(birthDate);
    const bm = parseInt(data.bonusMalus);
    console.log("BM ", bm, ";AGE ", age)
    // if (age > 75) {
    //   patchStorage({ payload: {rejectionDescription: i18n.t(`Age > 75`)}, id, affinity, insuranceType});
    //   return false;
    if (bm > 11) {
      patchStorage({ payload: {rejectionDescription: i18n.t(`Bonus malus > 11`)}, id, affinity, insuranceType});
      return false;
    } else if (age >= 65 && bm > 3) {
      patchStorage({ payload: {rejectionDescription: i18n.t(`Age > 65 & Bonus malus > 3`)}, id, affinity, insuranceType});
      return false;
    }
    return true;
  }

  const handleAddedDrivers = value => {
    setAdditionalDrivers(value);
  };

  return (
    <SplitContainer>
      <FlowLeftSideAvatar
        chatText={i18n.t(
          `To start off, I will need the information about the main driver and possible additional drivers`
        )}
      />
      <RightSplit>
        <FlowDriversInformationExtraForm
          handleAddedDrivers={handleAddedDrivers}
          handleFormValues={handleSubmit}
          defaultValues={defaultValues?.twoWheeler?.mainDriver || {}}
        />
      </RightSplit>
    </SplitContainer>
  );
};

const RightSplit = styled.div`
  width: 50%;
  overflow-y: auto;

  @media (max-width: 768px) {
    width: 100%;
  }
  @media (max-width: 601px) {
    padding: 2.5rem;
  }
`;

const SplitContainer = styled.div`
  display: flex;
  height: 100%;
`;

export default FlowDriversInformationExtraBike;
