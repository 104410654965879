import React, { useEffect, useState } from "react";
import styled from "styled-components";
import i18n from "../i18n";

import eBike from "../assets/callant/eBike.svg";
import heavyScooter from "../assets/callant/heavyScooter.svg";
import lightScooter from "../assets/callant/lightScooter.svg";
import motorcycle from "../assets/callant/motorcycle.svg";

import six_wheels from "../assets/images/6wheels.svg";
import motor_company_car from "../assets/images/moto_insurance_companycar.svg";
import car_already_insured from "../assets/images/moto_insurance.svg";
import MOTOR_ALREADY_INSURED from "../assets/images/car_insurance.svg";

import { patchStorage } from "../helpers/storeService";
import { useParams } from "react-router";
import useRouting from "../hooks/useRouting.js";

import { ActionButton, TextInput, SearchSelectInput, Label } from "wg-fe-ui";
import DocumentIcon from "../assets/callant/DocumentIcon.jsx";
import UploadButtonIcon from "../assets/callant/UploadButtonIcon.jsx";
import InfoPopup from "../components/InfoPopup";
import { primaryOptions as primaryOptionsConstant } from "../constants/FlowSearchSelectData";
import FlowLeftSideAvatar from "../components/FlowLeftSideAvatar.jsx";
import { retrieveStorageById } from "../helpers/storeService";

const FlowBikeType = () => {
  const [selected, setSelected] = useState();
  const { id, affinity, insuranceType } = useParams();
  const { data } = retrieveStorageById(id, affinity, insuranceType);

  const { renderNextRoute } = useRouting();
  const [alert, setAlert] = useState();
  const [input, setInput] = useState();
  const [primaryOptions, setPrimaryOptions] = useState([]);
  const [sameDriverAsCar, setSameDriverAsCar] = useState();
  const [sameDriverAsCarError, setSameDriverAsCarError] = useState();
  const [motorCompanyCarError, setMotorCompanyCarError] = useState();
  const [hasGreenCardFile, setHasGreenCardFile] = useState(false);
  const [autoPolicyNumberError, setAutoPolicyNumberError] = useState();
  const [attachments, setAttachments] = useState([]);
  const [carPolicyNumber, setCarPolicyNumber] = useState();
  const [motorPolicyNumber, setmotorPolicyNumber] = useState();
  const [motorPolicyNumberError, setmotorPolicyNumberError] = useState();

  useEffect(() => {
    if (selected === "MOTOR_COMPANY_CAR") {
      setInput(true);
    } else if (selected === "CAR_ALREADY_INSURED") {
      setInput(true);
    } else if (selected === "MOTOR_ALREADY_INSURED") {
      setInput(true);
    } else if (selected === "6WHEELS") {
      setInput(true);
    }
  }, [selected]);

  const vehicleTypes = [
    {
      label: eBike,
      key: "E_BIKE",
    },
    {
      label: heavyScooter,
      key: "HEAVY_SCOOTER",
    },
    {
      label: lightScooter,
      key: "LIGHT_SCOOTER",
    },
    {
      label: motorcycle,
      key: "MOTORCYCLE",
    },
  ];

  const sixWheelerTypes = [
    {
      label: six_wheels,
      key: "6WHEELS",
    },
    {
      label: motor_company_car,
      key: "MOTOR_COMPANY_CAR",
    },
    {
      label: car_already_insured,
      key: "CAR_ALREADY_INSURED",
    },
    {
      label: MOTOR_ALREADY_INSURED,
      key: "MOTOR_ALREADY_INSURED",
    },
  ];

  const infoHelper = {
    MOTORCYCLE: "A regular motorcycle",
    HEAVY_SCOOTER: "A scooter with >= 125cc",
    LIGHT_SCOOTER: "A scooter with < 125cc",
    E_BIKE: "A bike with full pedal assistance and more than 250 Watt",
  };

  function loadSelectOptions() {
    setPrimaryOptions(
      primaryOptionsConstant.map(({ value, label }) => {
        return { value, label: i18n.t(label) };
      })
    );
  }

  function handleFileSelect(evt) {
    var f = evt.target.files[0];
    var fileType = f.name.split(".")[f.name.split(".").length - 1].toLowerCase();
    if (
      evt.target.name === "green-card-upload" &&
      f.size < 3000001 &&
      ["pdf", "docx", "doc", "jpg", "png", "heic", "heif"].includes(fileType)
    ) {
      setHasGreenCardFile(evt.target.files[0]);
    }
    if (f.size > 3000001 && evt.target.name === "green-card-upload") {
      setMotorCompanyCarError(i18n.t("File size must not exceed 3MB"));
    } else if (
      !["pdf", "docx", "doc", "jpg", "png", "heic", "heif"].includes(
        fileType
      ) &&
      evt.target.name === "green-card-upload"
    ) {
      setMotorCompanyCarError(
        i18n.t("File type must be pdf, docx, doc, jpg, png, heic, heif")
      );
    } else {
      var reader = new FileReader();
      reader.onload = (function() {
        if (evt.target.name === "green-card-upload") {
          if (f) {
            setMotorCompanyCarError("");
          }
          return function(e) {
            var binaryDataSignupForm = e.target.result;
            var type = `image/${f.name
              .split(".")[f.name.split(".").length - 1].toLowerCase()}`;

            if (type.includes('pdf')) {
              type = "application/pdf"
            }

            setAttachments([
              {
                type,
                base64: window.btoa(binaryDataSignupForm),
                filename: f.name.replace(/ /g, "_").toLowerCase(),
              },
            ]);
            setHasGreenCardFile(true);
          };
        }
      })(f);

      reader.readAsBinaryString(f);
    }
  }

  const SelectPlaceholder = i18n.t("Choose your option");

  useEffect(() => {
    loadSelectOptions();
  }, []);

  useEffect(() => {
    if (!attachments) return;
    const { data } = retrieveStorageById(id, affinity, insuranceType);
    patchStorage({
      payload: { info: { ...data?.info, attachments: [...attachments] } },
      id,
      affinity,
      insuranceType,
    });
  }, [attachments]);

  function handleSubmit(selected) {
    if (!selected) {
      setAlert("Please select an insurance type");
      return;
    } else if (selected === "MOTOR_COMPANY_CAR" && attachments.length === 0) {
      setMotorCompanyCarError(
        i18n.t("Please upload your company car Green Card")
      );
      return;
    } else if (
      selected === "CAR_ALREADY_INSURED" &&
      carPolicyNumber === undefined
    ) {
      setAutoPolicyNumberError(i18n.t("Please enter your car policy number"));
      return;
    } else if (
      selected === "MOTOR_ALREADY_INSURED" &&
      motorPolicyNumber === undefined
    ) {
      setmotorPolicyNumberError(i18n.t("Please enter your moto policy number"));
      return;
    } else if (selected === "6WHEELS" && sameDriverAsCar === undefined) {
      setSameDriverAsCarError(i18n.t("mandatory"));
      return;
    }
    const isMainVehicle = insuranceType === "6-wheeler" ? false : true;

    const { data } = retrieveStorageById(id, affinity, insuranceType);

    if (insuranceType === "two-wheeler") {
      patchStorage({
        payload: {
          twoWheeler: {
            ...data?.twoWheeler,
            details: {
              ...data?.twoWheeler?.details,
              vehicleType: selected,
              isMainVehicle,
            },
          },
        },
        id,
        affinity,
        insuranceType,
      });
    } else if (insuranceType === "6-wheeler") {
      patchStorage({
        payload: { selectedFormula: selected },
        id,
        affinity,
        insuranceType,
      });
    }

    if (selected === "E_BIKE") {
      if (affinity === "agoria") {
        window.open(
          "https://broker.callant.be/Calculation/NewBikeCalc?dealerCode=K92SA1",
          "_blank"
        );
      } else if (affinity === "de-lijn") {
        window.open(
          "https://broker.callant.be/Calculation/NewBikeCalc?dealerCode=Y15QF9",
          "_blank"
        );
      } else if (affinity === "motor-insurance") {
        window.open(
          "https://broker.callant.be/Calculation/NewBikeCalc?dealerCode=B84LQ6",
          "_blank"
        );
      } else if (affinity === "at-work") {
        const currentCompany = data?.info?.currentCompany;
        if (currentCompany === "AON") {
          window.open(
            "https://broker.callant.be/Calculation/NewBikeCalc?dealerCode=Y46ME1",
            "_blank"
          );
        } else if (currentCompany === "ARCELORMITTAL") {
          window.open(
            "https://broker.callant.be/Calculation/NewBikeCalc?dealerCode=X26FN9",
            "_blank"
          );
        } else if (currentCompany === "CHIREC") {
          window.open(
            "https://broker.callant.be/Calculation/NewBikeCalc?dealerCode=L35DM4",
            "_blank"
          );
        } else if (currentCompany === "DAIKIN_EUROPE") {
          window.open(
            "https://broker.callant.be/Calculation/NewBikeCalc?dealerCode=S47PM8",
            "_blank"
          );
        } else if (currentCompany === "DELOITTE_BELGIUM") {
          window.open(
            "https://broker.callant.be/Calculation/NewBikeCalc?dealerCode=T82FP3",
            "_blank"
          );
        } else if (currentCompany === "EIFFAGE_BENELUX") {
          window.open(
            "https://broker.callant.be/Calculation/NewBikeCalc?dealerCode=E96KL4",
            "_blank"
          );
        } else if (currentCompany === "FEDEX_EXPRESS") {
          window.open(
            "https://broker.callant.be/Calculation/NewBikeCalc?dealerCode=R67AQ4",
            "_blank"
          );
        } else if (currentCompany === "G4S_BELGIUM") {
          window.open(
            "https://broker.callant.be/Calculation/NewBikeCalc?dealerCode=K62QE9",
            "_blank"
          );
        } else if (currentCompany === "MAZDA") {
          window.open(
            "https://broker.callant.be/Calculation/NewBikeCalc?dealerCode=Y34CA9",
            "_blank"
          );
        } else if (currentCompany === "MENSURA") {
          window.open(
            "https://broker.callant.be/Calculation/NewBikeCalc?dealerCode=H85NA7",
            "_blank"
          );
        } else if (currentCompany === "ORANGE_BELGIUM") {
          window.open(
            "https://broker.callant.be/Calculation/NewBikeCalc?dealerCode=N26HT7",
            "_blank"
          );
        } else if (currentCompany === "RANDSTAD_BELGIUM") {
          window.open(
            "https://broker.callant.be/Calculation/NewBikeCalc?dealerCode=Y25QT4",
            "_blank"
          );
        } else if (currentCompany === "SPORTOASE") {
          window.open(
            "https://broker.callant.be/Calculation/NewBikeCalc?dealerCode=W24RH9",
            "_blank"
          );
        } else if (currentCompany === "UMICORE") {
          window.open(
            "https://broker.callant.be/Calculation/NewBikeCalc?dealerCode=M92EY6",
            "_blank"
          );
        } else {
          window.open(
            "https://broker.callant.be/Calculation/NewBikeCalc?dealerCode=T82FP3",
            "_blank"
          );
        }
      } else {
        window.open(
          "https://broker.callant.be/Calculation/NewBikeCalc",
          "_blank"
        );
      }
    } else {
      if (selected === "MOTOR_COMPANY_CAR" && attachments.length > 0) {
        setMotorCompanyCarError("");
        renderNextRoute(2);
      } else if (
        selected === "CAR_ALREADY_INSURED" &&
        carPolicyNumber != null
      ) {
        setAutoPolicyNumberError("");
        console.log(carPolicyNumber);

        const { data } = retrieveStorageById(id, affinity, insuranceType);
        patchStorage({
          payload: {
            info: { ...data?.info, carPolicyNumber: carPolicyNumber.value },
          },
          id,
          affinity,
          insuranceType,
        });
        renderNextRoute(2);
      } else if (
        selected === "MOTOR_ALREADY_INSURED" &&
        motorPolicyNumber != null
      ) {
        setmotorPolicyNumberError("");
        const { data } = retrieveStorageById(id, affinity, insuranceType);
        patchStorage({
          payload: {
            info: { ...data?.info, motorPolicyNumber: motorPolicyNumber.value },
          },
          id,
          affinity,
          insuranceType,
        });
        renderNextRoute(1);
      } else if (selected === "6WHEELS") {
        const { data } = retrieveStorageById(id, affinity, insuranceType);
        patchStorage({
          payload: {
            info: {
              ...data?.info,
              mainDriverBikeSameAsCar: sameDriverAsCar.value === "yes",
            },
          },
          id,
          affinity,
          insuranceType,
        });
        if (sameDriverAsCar.value === "no") {
          patchStorage({
            payload: {
              twoWheeler: { ...data?.twoWheeler, mainDriver: undefined },
            },
            id,
            affinity,
            insuranceType,
          });
        } else {
          patchStorage({
            payload: {
              twoWheeler: {
                ...data?.twoWheeler,
                mainDriver: data?.car?.mainDriver || {},
              },
            },
            id,
            affinity,
            insuranceType,
          });
        }
        renderNextRoute(1);
        //car route
      } else {
        renderNextRoute();
      }
    }
  }

  function handleSelectValue(object) {
    if (!sameDriverAsCar) return "";
    const key = sameDriverAsCar;
    return object.filter(({ value }) => value === key)[0];
  }

  useEffect(() => {
    if (data?.twoWheeler?.details?.vehicleType != null) {
      setSelected(data.twoWheeler.details.vehicleType);
    }
  }, []);
  console.log(selected);

  return (
    <SplitContainer>
      <FlowLeftSideAvatar
        chatText={i18n.t(
          `Hello I am Louise In order to calculate your personalised premium please answer the following questions on the right`
        )}
      />
      <RightSplit>
        <Title>{i18n.t("What do you wish to insure?")}</Title>
        <InsuranceList>
          {insuranceType === "6-wheeler"
            ? sixWheelerTypes.map((item, key) => {
                return (
                  <Insurance
                    affinity={affinity}
                    key={key}
                    name={item.key}
                    checked={selected === item.key}
                    onClick={() => {
                      setAlert("");
                      setSelected(item.key);
                    }}
                  >
                    <div>
                      <img src={item.label} alt={item.label} />
                    </div>
                    <p>{i18n.t(item.key)}</p>
                  </Insurance>
                );
              })
            : vehicleTypes.map((item, key) => {
                return (
                  <Insurance
                    affinity={affinity}
                    key={key}
                    name={item.key}
                    checked={selected === item.key}
                    onClick={() => {
                      setAlert("");
                      setSelected(item.key);
                    }}
                  >
                    <div>
                      <img src={item.label} alt={item.label} />
                      <StyledInfoPopup
                        title={i18n.t(item.key)}
                        info={i18n.t(infoHelper[item.key])}
                      />
                    </div>
                    <p>{i18n.t(item.key)}</p>
                  </Insurance>
                );
              })}
        </InsuranceList>
        <div>
          {input && selected === "6WHEELS" ? (
            <Inputwrapper>
              <StyledLabel className="padding">
                {i18n.t("Is the car main driver also bike main driver")}
              </StyledLabel>
              <SelectInput
                error={sameDriverAsCarError}
                name="primary"
                onChange={(val) => {
                  setSameDriverAsCar(val);
                }}
                options={primaryOptions}
                placeholder={SelectPlaceholder}
                isClearable
                value={handleSelectValue(primaryOptions)}
              />
            </Inputwrapper>
          ) : null}
          {input && selected === "MOTOR_COMPANY_CAR" ? (
            <>
              <Inputwrapper>
                <InputLabel htmlFor="green-card-upload">
                  {hasGreenCardFile ? <DocumentIcon /> : <UploadButtonIcon />}

                  <p>{i18n.t("Upload your company car Green Card")}</p>
                </InputLabel>
                <ErrorMsg>{i18n.t(motorCompanyCarError)}</ErrorMsg>
              </Inputwrapper>
              <StyledInput
                id="green-card-upload"
                name="green-card-upload"
                onChange={(e) => handleFileSelect(e)}
                accept="file/pdf, file/docx, file/doc, file/jpg, file/png, file/heic, file/heif"
                type="file"
              />
            </>
          ) : input && selected === "CAR_ALREADY_INSURED" ? (
            <Inputwrapper>
              <TextInput
                name="car-policy-number"
                onChange={(val) => {
                  setCarPolicyNumber(val);
                }}
              >
                {i18n.t("Enter your car policy number")}
              </TextInput>
              <ErrorMsg>{i18n.t(autoPolicyNumberError)}</ErrorMsg>
            </Inputwrapper>
          ) : input && selected === "MOTOR_ALREADY_INSURED" ? (
            <Inputwrapper>
              <TextInput
                name="moto-policy-number"
                onChange={(val) => {
                  setmotorPolicyNumber(val);
                }}
              >
                {i18n.t("Enter your moto policy number")}
              </TextInput>
              <ErrorMsg>{i18n.t(motorPolicyNumberError)}</ErrorMsg>
            </Inputwrapper>
          ) : (
            ""
          )}
        </div>
        <Error>{i18n.t(alert)}</Error>
        <ButtonContainer>
          <StyledActionButton
            affinity={affinity}
            onClick={() => handleSubmit(selected)}
          >
            {i18n.t("Next")}
          </StyledActionButton>
        </ButtonContainer>
      </RightSplit>
    </SplitContainer>
  );
};

const SelectInput = styled(SearchSelectInput)`
  & .Select__option {
    font-size: 1.6rem;
    color: ${({ theme }) => theme.typo.text};
  }

  & .Select__option--is-focused {
    color: ${({ theme }) => theme.typo.text};
    font-weight: 500;
    background-color: ${({ theme }) => theme.brand.lighter};
    :hover {
      color: white;
      background-color: ${({ theme }) => theme.brand.secondary};
    }
  }
  & div {
    margin-bottom: 0;
  }
`;

const StyledLabel = styled(Label)`
  color: ${({ theme }) => theme.typo.subTitle};
  line-height: 1rem;
  font-size: 1.4rem;
`;

const ErrorMsg = styled.p`
  color: #f74040;
  font-size: 1.2rem;
  height: 1.5rem;
  margin: 1rem 0;
  text-align: end;
`;

const Error = styled.div`
  color: #f74040;
  right: 0;
  text-align: center;
  margin-top: auto;
  font-size: 1.2rem;
`;

const StyledInput = styled.input`
  display: none;
  opacity: 0;
`;

const InputLabel = styled.label`
  align-items: center;
  background-color: #188bee;
  border: 0;
  border-radius: 0.5rem;
  color: white;
  justify-content: space-evenly;
  word-break: keep-all;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 1.4rem;
  height: 5rem;
  line-height: 2rem;
  min-width: 14rem;
  padding: 1rem 0.4rem;
  padding-right: 5rem;
  text-align: center;
  p {
    line-height: 1.5rem;
  }
  &.signUpload {
    margin-top: 3.5rem;
  }
`;

const Inputwrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 2.5rem;
  div {
    height: auto;
  }
`;

const StyledInfoPopup = styled(InfoPopup)`
  float: right;
  font-size: 1.2rem;
  height: 1.6rem;
  line-height: 1.3;
  width: 1.6rem;
  right: 0;
  position: absolute;
`;

const Title = styled.h1`
  font-weight: 900;
  font-size: 1.65rem;
  letter-spacing: 1.19px;
  color: black;
  margin-bottom: 2rem;
`;

const StyledActionButton = styled(ActionButton)`
  color: ${({ affinity }) => (affinity === "de-lijn" ? "black" : "white")};
  background-color: ${({ affinity }) =>
    affinity === "callant" ? "#94C11A" : "default"};
  font-weight: ${({ affinity }) => (affinity === "de-lijn" ? "700" : "normal")};
`;

const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  margin-top: auto;

  & button {
    margin-left: auto;
    width: 20rem;
    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;

const InsuranceList = styled.div`
  display: grid;
  grid-template-rows: 46.5% 46.5%;
  grid-gap: 2rem;
  grid-template-columns: 47.5% 47.5%;
  justify-content: center;
  @media screen and (max-width: 425px) {
    grid-gap: 0.75rem;
  }
`;
const Insurance = styled.div`
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  display: grid;
  grid-template-rows: 70% 30%;
  padding: 1rem;
  border: ${({ checked, theme, affinity }) =>
    checked && affinity === "callant"
      ? "1px solid #94C11A"
      : checked
      ? `1px solid ${theme.brand.secondary}`
      : "1px solid #CCCCCC"};
  background-color: ${({ checked, theme, disabled, affinity }) =>
    affinity === "callant" && checked
      ? theme.brand.lightest
      : checked
      ? theme.brand.lightest
      : disabled
      ? "#AEAEAE"
      : "white"};
  div {
    text-align: center;
    position: relative;
  }
  img {
    /* margin: auto; */
    align-self: center;
    max-width: 10rem;
    max-height: 100%;
    justify-self: center;
  }
  p {
    font-size: 1.5rem;
    font-weight: 700;
    align-self: center;
    justify-self: center;
    color: ${({ checked, theme, affinity }) =>
      affinity === "callant" && checked
        ? "black"
        : checked
        ? theme.brand.secondary
        : "black"};
    text-align: center;
    margin-top: 1rem;
    line-height: 1.3rem;
    @media screen and (max-width: 425px) {
      font-size: 1.3rem;
    }
  }
`;

const SplitContainer = styled.div`
  display: flex;
  height: 100%;
`;

const RightSplit = styled.div`
  display: grid;
  grid-template-rows: 8% 60% 20%;
  width: 50%;
  padding: 5rem 4rem;
  overflow-y: auto;

  @media (max-width: 768px) {
    width: 100%;
  }

  @media screen and (max-width: 425px) {
    padding: 4rem 2.5rem;
  }
`;

export default FlowBikeType;
