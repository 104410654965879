import React, { useEffect, useState } from "react";
import styled from "styled-components";
import i18n from "../i18n";
import cogoToast from "cogo-toast";
import "../assets/css/cogoToast.css";

import { useHistory, useLocation, useParams } from "react-router";

import FlowLeftSideAvatar from "../components/FlowLeftSideAvatar.jsx";
import FlowPolicyHolderInfoForm from "../components/FlowPolicyHolderInfoForm.jsx";
import { captchaAuth } from "../helpers/apiRouterService";
import {
  patchStorage,
  retrieveStorageInfo,
  initializeStorage,
  retrieveStorageById,
} from "../helpers/storeService";
import qs from "query-string";
import { generateUUID } from "../helpers/uuidService";
import ReactGA from 'react-ga';
import { usePostHog } from "posthog-js/react";

const Login = () => {
  const { affinity, insuranceType } = useParams();
  const [latestSessionData, setLatestSessionData] = useState();
  const data = retrieveStorageInfo(affinity, insuranceType);
  const { search } = useLocation();
  const { sessionId } = qs.parse(search);
  const history = useHistory();
  const posthog = usePostHog();

  useEffect(() => {
    console.log(search);

    checkForUrlParameters();
  }, [search]);

  function checkForUrlParameters() {
    const queryObject = qs.parse(search);

    if ("lang" in queryObject) {
      const { lang: toSetLanguage } = queryObject;
      const currentLanguage = i18n.language;
      if (toSetLanguage !== currentLanguage) i18n.changeLanguage(toSetLanguage);
      removeKeyFromQueryString("lang");
    }

    if (
      "logoutStatus" in queryObject &&
      queryObject.logoutStatus === "expired"
    ) {
      cogoToast.error(i18n.t("Your session has expired  Please log in again"), {
        position: "top-right",
      });

      removeKeyFromQueryString("logoutStatus");
    }

    if (
      "sessionId" in queryObject &&
      queryObject.sessionId.match(
        /[a-f0-9]{8}-?[a-f0-9]{4}-?4[a-f0-9]{3}-?[89ab][a-f0-9]{3}-?[a-f0-9]{12}/
      )
    ) {
      const [sessionsData] = data.filter(
        ({ id }) => id === queryObject.sessionId
      );
      setLatestSessionData(sessionsData);
    } else {
      const _id = generateUUID();
      queryObject.sessionId = _id;
      initializeStorage(_id, affinity, insuranceType);
      history.push({ search: qs.stringify(queryObject) });
    }
  }

  function removeKeyFromQueryString(key) {
    let queryObject = qs.parse(search);
    delete queryObject[key];
    history.push({ search: qs.stringify(queryObject) });
  }

  function handleSubmit(client) {
    onSubmit(client);
  }

  const onSubmit = (values) => {
    const { firstName, lastName, email, captcha, companyName } = values;
    storeValues(values);
    authenticate({ firstName, lastName, email, captcha, companyName });
  };

  const authenticate = async ({
    firstName,
    lastName,
    email,
    captcha,
    companyName,
  }) => {
    const [, status] = await captchaAuth({
      captcha,
      email,
      firstName: companyName ? affinity : firstName,
      lastName: companyName ? companyName : lastName,
      askBrokerId: "9954b851-6edc-426f-bdc5-ca7106503046",
    });
    if (status !== 200) return;
    if (posthog) {
      posthog.identify(sessionId, {
        email: email || '',
        name: `${firstName} ${lastName}`,
      });
    }
    ReactGA.ga(`${affinity.replace('-', '')}.set`, 'userId', email);
    ReactGA.ga(`${affinity.replace('-', '')}.send`, 'event', {
      eventCategory: 'Louise Flow',
      eventAction: 'Logged in',
      eventLabel: insuranceType,
    });
  };

  const storeValues = async (values) => {
    const { sessionId: id } = qs.parse(search);
    if (values?.captcha) delete values.captcha;
    let mainDriver = {};
    if (values?.primary && values.primary === "yes") {
      mainDriver = {
        firstName: values.firstName,
        lastName: values.lastName,
        birth: values.birth,
      };
    }
  
    const { data } = retrieveStorageById(id, affinity, insuranceType) || {};

    let refId = null;
    const queryObject = qs.parse(search);
    console.log(queryObject);
    if ("refId" in queryObject) {
      console.log(`Found ref id for broker: ${queryObject.refId}`)
      refId = queryObject.refId;
    }
    let moderator = false;
    if ("moderator" in queryObject) {
      console.log(`Moderator mode is set to ${queryObject.moderator}`)
      moderator = queryObject.moderator;
    }
    let consessionHolder = null;
    if ("consessionHolder" in queryObject) {
      console.log(`Found consession holder uuid: ${queryObject.consessionHolder}`)
      consessionHolder = queryObject.consessionHolder;
    }

    patchStorage({
      payload: {
        policyHolder: { ...data?.policyHolder, ...values },
        car: { mainDriver },
        twoWheeler: { mainDriver },
        refId,
        moderator,
        consessionHolder,
      },
      id,
      affinity,
      insuranceType,
    });
  
    await history.push(`/${affinity}/${insuranceType}/session`);
  }

  return (
    <SplitContainer>
      <FlowLeftSideAvatar
        chatText={i18n.t(
          `Hello I am Louise In order to calculate your personalised premium please answer the following questions on the right`
        )}
      />
      <RightSplit>
        <FlowPolicyHolderInfoForm
          retrieveSubmit={handleSubmit}
          defaultValues={latestSessionData?.data?.policyHolder || {}}
        />
      </RightSplit>
    </SplitContainer>
  );
};

const SplitContainer = styled.div`
  display: flex;
  height: 100%;
`;

const RightSplit = styled.div`
  width: 50%;
  padding: 5rem 4rem;
  overflow-y: auto;

  @media (max-width: 768px) {
    width: 100%;
  }

  @media screen and (max-width: 425px) {
    padding: 4rem 2.5rem;
  }
  @media screen and (max-width: 375px) {
    padding: 2.5rem 1.5rem;
  }
`;

export default Login;
