import { retrieveBrokerById } from "./apiRouterService";
import { isObjectEmpty } from "./objectService";

let brokerData = {};
const DIVA_BROKER_ID = "9954b851-6edc-426f-bdc5-ca7106503046";
export const DISTRIBUTION_ID = "12aece4f-7630-453b-8543-c66daf76cb5a";

export async function setBrokerData() {
  const [resp, status] = await retrieveBrokerById(DIVA_BROKER_ID);
  if (status === 200) {
    brokerData = resp.broker;
    return brokerData;
  }
}

export async function getBrokerData() {
  if (isObjectEmpty(brokerData)) return await setBrokerData();
  return await brokerData;
}
