import React from "react";

const SvgComponent = ({ colors }) => (
  <svg width="100%" viewBox="0 0 50 50">
    <g id="icons">
      <path
        fill={colors}
        d="M46.2,26.66l-5.56-4.47a3.26,3.26,0,0,1-.8-1l-3.26-5.87A4.38,4.38,0,0,0,32.79,13H6.33a1.87,1.87,0,0,0-1.75,1.49L3.34,20.33A15.65,15.65,0,0,0,3,23.6v9.3A2.08,2.08,0,0,0,5.05,35H7A4.5,4.5,0,0,0,16,35H32A4.5,4.5,0,0,0,41,35h4a2.08,2.08,0,0,0,2-2.1V28.4A2.23,2.23,0,0,0,46.2,26.66ZM11.5,36.75A2.25,2.25,0,0,1,9.31,35a2.07,2.07,0,0,1-.06-.5,2.25,2.25,0,0,1,4.5,0,2.07,2.07,0,0,1-.06.5A2.25,2.25,0,0,1,11.5,36.75Zm25,0A2.25,2.25,0,0,1,34.31,35a2.07,2.07,0,0,1-.06-.5,2.25,2.25,0,0,1,4.5,0,2.07,2.07,0,0,1-.06.5A2.25,2.25,0,0,1,36.5,36.75Z"
      />
    </g>
  </svg>
);

export default SvgComponent;
