import React, { useEffect, useState } from "react";
import { SearchSelectInput, ActionButton, Label, TextInput } from "wg-fe-ui";
import styled from "styled-components";
// import DateInput from "../components/DateInput";
import * as Yup from "yup";
import i18n from "../i18n";
// import FlowAddAdditionalDriver from "./FlowAddAdditionalDriver.jsx";

import {
  usedForOptions as usedForOptionsConstant,
  currentProfessionOptions as currentProfessionOptionsConstant,
  numberOfAccidentsLastFiveYearsOptions as numberOfAccidentsLastFiveYearsOptionsConstant,
} from "../constants/FlowSearchSelectData";
import useForm from "../hooks/useForm";
import InfoPopup from "./InfoPopup";
import {
  string,
  bonusMalus,
  vatRegime,
  number,
  averageKmPerYear,
} from "../constants/validationSchemas.js";
import { useParams } from "react-router";
import { retrieveStorageById } from "../helpers/storeService";
import {
  parseIncDateToObject,
  parseObjectToNewDate,
} from "../helpers/dateService";
import { differenceInYears } from "date-fns";

const FlowDriversInformationExtraForm = ({
  handleAddedDrivers,
  handleFormValues,
  defaultValues,
}) => {
  const { id, affinity, insuranceType } = useParams();
  const { data } = retrieveStorageById(id, affinity, insuranceType);
  const SelectPlaceholder = i18n.t("Choose your option");
  const [usedForOptions, setUsedForOptions] = useState([]);
  const [usedFor, setUsedFor] = useState();
  const [
    numberOfAccidentsLastFiveYearsOptions,
    setNumberOfAccidentsLastFiveYearsOptions,
  ] = useState([]);
  const [currentProfessionOptions, setCurrentProfessionOptions] = useState([]);

  const SignupSchema = Yup.object().shape({
    usedFor: string.required,
    bonusMalus: bonusMalus.test(
      "valid-bonus-malus",
      i18n.t("The bonus malus entered is impossible given the driver's age"),
      handleBonusMalusValidation
    ),
    currentProfession: string.required,
    claims_in_fault: string.required,
    vatRegime:
      (data?.policyHolder?.personType &&
        data?.policyHolder?.personType === "legal") ||
      (data?.policyHolder?.personType &&
        data?.policyHolder?.personType === "natural" &&
        usedFor === "Professional")
        ? vatRegime
        : number.notRequired,
    averageKmPerYear:
      affinity === "valckenier" && insuranceType === "car"
        ? averageKmPerYear
        : string.notRequired,
  });

  const { handleChange, errors, handleSubmit, values } = useForm({
    validationSchema: SignupSchema,
    change: () => {},
  });

  useEffect(() => {
    Object.keys(defaultValues || {}).forEach((name) => {
      handleChange({ name, value: defaultValues[name] });
    });
  }, [defaultValues]);

  useEffect(() => {
    loadSelectOptions();
  }, []);

  // const handleDrivers = (value) => {
  //   handleAddedDrivers(value);
  // };

  function handleBonusMalusValidation() {
    const { bonusMalus } = this.parent;

    const dateObject = parseIncDateToObject(data?.car?.mainDriver?.birth);
    const birthDate = parseObjectToNewDate(dateObject);

    console.log("!!!!!!!!!!!!!!!!!!!!!!");
    console.log(birthDate);
    console.log(bonusMalus);
    console.log(
      parseInt(differenceInYears(new Date(), birthDate)) + parseInt(bonusMalus)
    );
    console.log("!!!!!!!!!!!!!!!!!!!!!!");

    // 11 can be obtained at 18yo, higher is possible, lower not
    return (
      parseInt(differenceInYears(new Date(), birthDate)) +
        parseInt(bonusMalus) >=
      29
    );
  }

  function loadSelectOptions() {
    setUsedForOptions(
      usedForOptionsConstant.map(({ value, label }) => {
        return { value, label: i18n.t(label) };
      })
    );
    setCurrentProfessionOptions(
      currentProfessionOptionsConstant.map(({ value, label }) => {
        return { value, label: i18n.t(label) };
      })
    );
    setNumberOfAccidentsLastFiveYearsOptions(
      numberOfAccidentsLastFiveYearsOptionsConstant.map(({ value, label }) => {
        return { value, label: i18n.t(label) };
      })
    );

    currentProfessionOptions.sort(function(a, b) {
      var nameA = a.label;
      var nameB = b.label;
      if (nameA < nameB) {
        return -1;
      } else if (nameA > nameB) {
        return 1;
      } else {
        return 0;
      }
    });
  }
  useEffect(() => {
    currentProfessionOptions.sort(function(a, b) {
      var nameA = a.label;
      var nameB = b.label;
      if (nameA < nameB) {
        return -1;
      } else if (nameA > nameB) {
        return 1;
      } else {
        return 0;
      }
    });
  }, [currentProfessionOptions]);

  function formSubmitHandler(e) {
    e.preventDefault();

    handleSubmit(handleFormValues);
  }

  function handleSelectValue(object, inputName) {
    if (!values[inputName]) return "";
    const key = values[inputName];
    return object.filter(({ value }) => value === key)[0];
  }

  function handleSelectChange(val, name) {
    handleChange({ name, value: val ? val.value : "" });
  }

  return (
    <Form onSubmit={formSubmitHandler}>
      <CatTitle>{i18n.t("Primary driver information")} </CatTitle>
      <div>
        <StyledLabel>
          {i18n.t("For what purpose is the car used")} *
          <StyledInfoPopup
            title={i18n.t("For what purpose is the car used")}
            buttonText={i18n.t("?")}
            info={i18n.t("carPurposePopupText")}
          />
        </StyledLabel>
        <SelectInput
          error={errors.usedFor}
          name="usedFor"
          onChange={(val) => {
            handleSelectChange(val, "usedFor");
            console.log(val);
            setUsedFor(val.value);
          }}
          options={usedForOptions}
          placeholder={SelectPlaceholder}
          isClearable
          value={handleSelectValue(usedForOptions, "usedFor")}
        />
      </div>
      {(data?.policyHolder?.personType &&
        data?.policyHolder?.personType === "legal") ||
      (data?.policyHolder?.personType &&
        data?.policyHolder?.personType === "natural" &&
        values.usedFor === "Professional") ? (
        <div>
          <StyledTextInput
            name="vatRegime"
            error={errors.vatRegime}
            onChange={(val) => {
              handleChange(val);
            }}
            value={values.vatRegime}
          >
            {i18n.t("vatRegime")} *<p className="valueType">%</p>
          </StyledTextInput>
        </div>
      ) : null}
      <div>
        <TextInput
          name="bonusMalus"
          error={errors.bonusMalus}
          onChange={(val) => handleChange(val)}
          value={values.bonusMalus}
          data-lpignore="true"
        >
          <NameInput>
            {i18n.t("Bonus malus")} *
            <StyledInfoPopup
              title={i18n.t("Bonus malus")}
              buttonText={i18n.t("?")}
              info={i18n.t("bonusMalusPopupText")}
            />
          </NameInput>
        </TextInput>
      </div>
      <div>
        <StyledLabel>{i18n.t("Current profession")} *</StyledLabel>
        <SelectInput
          error={errors.currentProfession}
          name="currentProfession"
          onChange={(val) => {
            handleSelectChange(val, "currentProfession");
          }}
          options={currentProfessionOptions}
          placeholder={SelectPlaceholder}
          isClearable
          value={handleSelectValue(
            currentProfessionOptions,
            "currentProfession"
          )}
        />
      </div>
      {affinity === "valckenier" && insuranceType === "car" ? (
        <StyledTextInput
          name="averageKmPerYear"
          error={errors.averageKmPerYear}
          onChange={(val) => handleChange(val)}
          value={values.averageKmPerYear}
        >
          <p className="valueType">{i18n.t("KM")}</p>
          {i18n.t("Average km travelled yearly")} *
        </StyledTextInput>
      ) : (
        ""
      )}
      {insuranceType === "two-wheeler" ||
      insuranceType === "car" ||
      insuranceType === "6-wheeler" ? (
        <div>
          <StyledLabel>
            {i18n.t(
              "How many accidents have you  had in the last year where you were at fault?"
            )}{" "}
            *
          </StyledLabel>
          <SelectInput
            error={errors.claims_in_fault}
            name="claims_in_fault"
            onChange={(val) => {
              handleSelectChange(val, "claims_in_fault");
            }}
            options={numberOfAccidentsLastFiveYearsOptions}
            placeholder={SelectPlaceholder}
            isClearable
            value={handleSelectValue(
              numberOfAccidentsLastFiveYearsOptions,
              "claims_in_fault"
            )}
          />
        </div>
      ) : (
        ""
      )}
      {/* <FlowAddAdditionalDriver
        handleDrivers={handleDrivers}
        defaultAdditionalDrivers={data?.car?.additionalDrivers}
        errors={errors}
        values={values}
      /> */}
      <ButtonContainer>
        <Required>* {i18n.t("Required fields")}</Required>
        <StyledActionButton
          affinity={affinity}
          type="submit"
          value="Login"
          data-test-id="social_login_submit"
        >
          {i18n.t("Next")}
        </StyledActionButton>
      </ButtonContainer>
    </Form>
  );
};

const StyledTextInput = styled(TextInput)`
  p.valueType {
    padding: 1.4rem;
    border-left: 1px solid #cccccc;
    position: absolute;
    margin: 0;
    right: 0rem;
    top: 2.5rem;
  }
`;

const Required = styled.p`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.typo.label};
  margin-top: 0;
  @media (max-width: 768px) {
    margin: 0 2rem 2rem 2rem;
  }
`;

const StyledActionButton = styled(ActionButton)`
  color: ${({ affinity }) => (affinity === "de-lijn" ? "black" : "white")};
  background-color: ${({ affinity }) =>
    affinity === "callant" || affinity === "uber" || affinity === "at-work"
      ? "#94C11A"
      : "default"};
  font-weight: ${({ affinity }) => (affinity === "de-lijn" ? "700" : "normal")};
`;

const NameInput = styled.p`
  height: 1rem;
`;

const StyledLabel = styled(Label)`
  color: ${({ theme }) => theme.typo.subTitle};
  font-size: 1.5rem;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  padding: 5rem 4rem;

  @media screen and (max-width: 425px) {
    padding: 4rem 2.5rem;
  }
`;

const SelectInput = styled(SearchSelectInput)`
  & .Select__option {
    font-size: 1.6rem;
    color: ${({ theme }) => theme.typo.text};
  }

  & .Select__option--is-focused {
    color: ${({ theme }) => theme.typo.text};
    font-weight: 500;
    background-color: ${({ theme }) => theme.brand.lighter};
    :hover {
      color: white;
      background-color: ${({ theme }) => theme.brand.secondary};
    }
  }
  & div {
    margin-bottom: 0;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: flex-end;

  & button {
    width: 20rem;
    margin-left: auto;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const CatTitle = styled.h1`
  font-weight: 900;
  font-size: 1.65rem;
  letter-spacing: 1.19px;
  color: black;
  margin-bottom: 2rem;
`;

const StyledInfoPopup = styled(InfoPopup)`
  float: right;
  font-size: 1.2rem;
  height: 1.6rem;
  line-height: 1.3;
  width: 1.6rem;
  right: 0;
`;

export default FlowDriversInformationExtraForm;
