import { parseIntSafely } from "./intService";
import { parseIncDateToObject } from "./dateService";

export const getTwoWheelerObject = (data) => {
  return {
    hp: parseIntSafely(data?.twoWheeler?.details?.horsepower),
    brand: data?.twoWheeler?.details?.brand,
    model: data?.twoWheeler?.details?.series,
    version: data?.twoWheeler?.details?.model,
    category: data?.twoWheeler?.details?.category,
    cc: parseIntSafely(data?.twoWheeler?.details?.cc),
    value: parseIntSafely(data?.twoWheeler?.details?.catalogueValue),
    options_value: parseIntSafely(data?.twoWheeler?.details?.options_value),
    clothing_value: parseIntSafely(data?.twoWheeler?.details?.clothing_value),
    vehicle_type: data?.twoWheeler?.details?.vehicleType,
    motor_type: data?.twoWheeler?.details?.motorType,
    is_main_vehicle: data?.twoWheeler?.details?.isMainVehicle,
    registration_first: parseIncDateToObject(
      "01/01/" + data?.twoWheeler?.details?.registrationYear
    ),
    number_plate:
      data?.twoWheeler?.details?.licensePlate !== ""
        ? data?.twoWheeler?.details?.licensePlate
        : null,
    chassis_number:
      data?.twoWheeler?.details?.vinNumber !== ""
        ? data?.twoWheeler?.details?.vinNumber
        : null,
    drivers: [
      {
        first_name: data?.twoWheeler?.mainDriver?.firstName,
        last_name: data?.twoWheeler?.mainDriver?.lastName,
        birth: parseIncDateToObject(data?.twoWheeler?.mainDriver?.birth),
        issue_license_date: data?.twoWheeler?.mainDriver?.issueLicenseDate
          ? parseIncDateToObject(data?.twoWheeler?.mainDriver?.issueLicenseDate)
          : null,
        driver_license_type: data?.twoWheeler?.mainDriver?.driverLicenseType,
        address: {
          street: data?.policyHolder?.address.streetName,
          zipcode: data?.policyHolder?.address.postalCode,
          housenr: data?.policyHolder?.address.streetNumber,
          boxnr:
            data?.policyHolder?.address.boxNumber === ""
              ? null
              : data?.policyHolder?.address.boxNumber,
          city: data?.policyHolder?.address.municipalityName,
          country_code: "BE",
        },
        primary: true,
        profession: data?.twoWheeler?.mainDriver?.currentProfession,
        number_of_accidents_last_five_years: parseIntSafely(
          data?.twoWheeler?.mainDriver?.claims_in_fault
        ),
      },
      ...(data?.twoWheeler?.additionalDrivers
        ? data?.twoWheeler?.additionalDrivers.map((driver) => {
            return {
              first_name: driver.firstName,
              last_name: driver.lastName,
              birth: parseIncDateToObject(driver.birth),
              issue_license_date: parseIncDateToObject(driver.issueLicenseDate),
              primary: false,
            };
          })
        : []),
    ],
  };
};

export const getCarObject = (data) => {
  return {
    kw: parseIntSafely(data?.car?.details?.kw),
    cc:
      data?.car?.details?.cc && data?.car?.details?.cc >= 1
        ? data?.car?.details?.cc
        : null,
    co2:
      data?.car?.details?.co2 && data?.car?.details?.co2 >= 1
        ? data?.car?.details?.co2
        : null,
    model_year: data?.car?.detail?.modelYear,
    motor_type: data?.car?.details?.motorType,
    brand: data?.car?.details?.brand,
    model: data?.car?.details?.model,
    version: data?.car?.details?.version
      ? data?.car?.details?.version.substring(0, 34)
      : null,
    used_for:
      data?.car?.mainDriver?.usedFor !== undefined
        ? data?.car?.mainDriver?.usedFor.toLowerCase()
        : null,
    value: parseIntSafely(data?.car?.details?.catalogueValue),
    options_value: parseIntSafely(data?.car?.details?.options),
    registration_first: parseIncDateToObject(
      "01/01/" + data?.car?.details?.registrationYear
    ),
    seats: data?.car?.details?.seats,
    drive_assistance_system: data?.car?.details?.driveAssistanceSystems,
    is_sportscar: data?.car?.details?.carTypes?.sport,
    is_jeep: data?.car?.details?.carTypes?.suv,
    is_cabrio: data?.car?.details?.carTypes?.cabriolet,
    is_coupe: data?.car?.details?.carTypes?.coupe,
    number_plate:
      data?.car?.details?.licensePlate !== ""
        ? data?.car?.details?.licensePlate
        : null,
    chassis_number:
      data?.car?.details?.vinNumber !== ""
        ? data?.car?.details?.vinNumber
        : null,
    vehicle_type: data?.car?.details?.vehicleType,
    vat_regime: data?.car?.mainDriver?.vatRegime
      ? data?.car?.mainDriver?.vatRegime / 100
      : null,
    average_km_per_year: data?.car?.mainDriver?.averageKmPerYear,
    drivers: [
      {
        first_name: data?.car?.mainDriver?.firstName,
        last_name: data?.car?.mainDriver?.lastName,
        birth: parseIncDateToObject(data?.car?.mainDriver?.birth),
        issue_license_date: data?.car?.mainDriver?.issueLicenseDate
          ? parseIncDateToObject(data?.car?.mainDriver?.issueLicenseDate)
          : null,
        driver_license_type: data?.car?.mainDriver?.driverLicenseType,
        address: {
          street: data?.policyHolder?.address.streetName,
          zipcode: data?.policyHolder?.address.postalCode,
          housenr: data?.policyHolder?.address.streetNumber,
          boxnr:
            data?.policyHolder?.address.boxNumber === ""
              ? null
              : data?.policyHolder?.address.boxNumber,
          city: data?.policyHolder?.address.municipalityName,
          country_code: "BE",
        },
        primary: true,
        profession: data?.car?.mainDriver?.currentProfession,
        number_of_accidents_last_five_years: parseIntSafely(
          data?.car?.mainDriver?.claims_in_fault
        ),
        home_to_work_distance: data?.car?.mainDriver?.home_to_work_distance,
      },
      ...(data?.car?.additionalDrivers
        ? data?.car?.additionalDrivers.map((driver) => {
            return {
              first_name: driver.firstName,
              last_name: driver.lastName,
              birth: parseIncDateToObject(driver.birth),
              issue_license_date: parseIncDateToObject(driver.issueLicenseDate),
              primary: false,
            };
          })
        : []),
    ],
  };
};

export const getTeacherObject = (data) => {
  return {
    manipulates_heavy_machinery: data.teacher_values.teacher_type === "HEAVY",
  };
};

export const getFamilyObject = (data) => {
  return {
    status:
      data.teacher_values.family_type === "FAMILY"
        ? "couple"
        : "single_without_children",
    birth: parseIncDateToObject(data?.policyHolder?.birth),
  };
};

export const getHomeObject = (data) => {
  const { parcel } = data.risk_address || {};
  const { main_building, annexes } = parcel || {};
  const { enquiries, room_estimation } = main_building || {};
  return {
    address: data.risk_address.address,
    holder_state: main_building.propertyType,
    attachment: main_building.house_type,
    main_building: {
      rooms: {
        living_rooms: room_estimation.living_rooms,
        kitchens: room_estimation.kitchens,
        bedrooms: room_estimation.bedrooms,
        bathrooms: room_estimation.bathrooms,
        garages: room_estimation.garages,
        other_rooms: room_estimation.other_rooms,
        storage_rooms: room_estimation.storage_rooms,
      },
      surface: main_building.surface,
      volume: main_building.volume,
      height: main_building.height,
      age: main_building.buildingAge,
      has_special_flooring: enquiries.special_flooring,
      has_special_roofing: enquiries.special_roofing,
      has_cellar: enquiries.has_basement,
      has_attic: enquiries.has_attic,
      is_cellar_unhabited: !enquiries.inhabitable_basement,
      is_attic_unhabited: !enquiries.inhabitable_attic,
      // is_under_construction: null  Question is not asked yet
      // was_recently_renovated: null  Question is not asked yet
      construction_type: enquiries.traditional_materials ? "BRICK" : "WOOD",
      has_elevator: enquiries.has_elevator,
      has_porch: room_estimation.porch === 1,
      distance_nearest_neighbor_in_meters: parcel.distances.nearest_neighbor,
      facade: {
        width: main_building.facade_width,
        finishing_type: enquiries.facade_natural_stone
          ? "NATURAL_STONE"
          : "BRICK",
        is_protected: enquiries.dwelling_protected,
      },
      has_inside_swimming_pool: enquiries.indoor_pool,
    },
    parcel_area: parcel.surface,
    // rental_price: null  Question is not asked yet
    annexes: annexes ? annexes : [],
    // has_outside_swimming_pool: null  Question is not asked yet
    has_solar_panels: parcel.properties.ha_solar_panels,
    // are_solar_panels_anchored":null  Question is not asked yet
    has_garden: parcel.has_garden,
    is_primary: enquiries.main_residence,
    occupation: enquiries.regularly_occupied
      ? "LESS_THAN_90_CONSECUTIVE_DAYS_GONE"
      : "MORE_THAN_90_CONSECUTIVE_DAYS_GONE", // TODO: Might switch depending on the contract chosen
    has_company_registered: enquiries.exercising_liberal_profession,
    was_flooded: enquiries.flood_last_10_years
      ? "ONCE_OR_MORE_IN_LAST_10_YEARS"
      : "NOT_IN_LAST_10_YEARS",
  };
};
