import React, { useState, useEffect } from "react";
import styled from "styled-components";
import FlowLeftSideMap from "../components/FlowLeftSideMap";
import { useParams } from "react-router";
import { retrieveStorageById } from "../helpers/storeService";
import FlowHomeInformationForm from "../components/FlowHomeInformationForm";
import useRouting from "../hooks/useRouting";
import { getNestedObject } from "../helpers/objectService";
import i18n from "../i18n";

const FlowHomeInformation = () => {
  const { id, affinity, insuranceType } = useParams();
  const { data } = retrieveStorageById(id, affinity, insuranceType) || {};
  const { risk_address } = data;
  const { renderNextRoute } = useRouting();
  const [defaultValues, setDefaultValues] = useState({});

  useEffect(() => {
    loadDefaultValues();
  }, []);

  const loadDefaultValues = async () => {
    const { data } = retrieveStorageById(id, affinity, insuranceType);
    const defaultValuesTemp = {
      propertyType: getNestedObject(data, [
        "risk_address",
        "parcel",
        "main_building",
        "propertyType",
      ]),
      buildingAge: getNestedObject(data, [
        "risk_address",
        "parcel",
        "main_building",
        "buildingAge",
      ]),
      house_type: getNestedObject(data, [
        "risk_address",
        "parcel",
        "main_building",
        "house_type",
      ]),
      main_residence: getNestedObject(data, [
        "risk_address",
        "parcel",
        "main_building",
        "enquiries",
        "main_residence",
      ]),
      exercising_liberal_profession: getNestedObject(data, [
        "risk_address",
        "parcel",
        "main_building",
        "enquiries",
        "exercising_liberal_profession",
      ]),
      flood_last_10_years: getNestedObject(data, [
        "risk_address",
        "parcel",
        "main_building",
        "enquiries",
        "flood_last_10_years",
      ]),
      regularly_occupied: getNestedObject(data, [
        "risk_address",
        "parcel",
        "main_building",
        "enquiries",
        "regularly_occupied",
      ]),
    };
    setDefaultValues(defaultValuesTemp);
  };

  return (
    <SplitContainer>
      <FlowLeftSideMap
        chatText={
          <>
            <Label>{i18n.t("Address")}</Label>
            <Address>
              {risk_address.address.street} {risk_address.address.housenr},{" "}
              {risk_address.address.zipcode} {risk_address.address.city}
            </Address>
          </>
        }
        addressInfo={risk_address}
      />
      <RightSplit>
        <FlowHomeInformationForm
          renderNextRoute={renderNextRoute}
          defaultValues={defaultValues}
        />
      </RightSplit>
    </SplitContainer>
  );
};

const Label = styled.p`
  font-size: 1.2rem;
  color: ${({ theme }) => theme.typo.title};
`;

const Address = styled.p`
  font-size: 1.5rem;
  color: ${({ theme }) => theme.typo.text};
  font-weight: 600;
`;

const RightSplit = styled.div`
  width: 50%;
  padding: 5rem 4rem;
  overflow-y: auto;

  h1 {
    font-weight: bold;
    text-align: center;
    width: 100%;
    font-size: 1.65rem;
    margin-bottom: 2.5rem;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
  @media screen and (max-width: 425px) {
    padding: 4rem 2.5rem;
  }
`;

const SplitContainer = styled.div`
  display: flex;
  height: 100%;
`;

export default FlowHomeInformation;
