import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import i18n from "../i18n";

import FlowDriversInformationForm from "../components/FlowDriversInformationFormBike.jsx";
import FlowLeftSideAvatar from "../components/FlowLeftSideAvatar.jsx";

import useRouting from "../hooks/useRouting";
import { patchStorage, retrieveStorageById } from "../helpers/storeService";

const FlowDriversInformationBike = () => {
  const [defaultValues, setDefaultValues] = useState({});
  const { renderNextRoute } = useRouting();
  const { id, affinity, insuranceType } = useParams();
  const { data } = retrieveStorageById(id, affinity, insuranceType);

  const [additionalDrivers, setAdditionalDrivers] = useState([]);

  useEffect(() => {
    const { data } = retrieveStorageById(id, affinity, insuranceType);
    setDefaultValues(data);
  }, []);

  // function capitalizeFirstLetter(type, string) {
  //   if (type === "firstName") {
  //     setCapitalizeFirstName(string.charAt(0).toUpperCase() + string.slice(1));
  //   } else {
  //     setCapitalizeLastName(string.charAt(0).toUpperCase() + string.slice(1));
  //   }
  // }
  function handleSubmit(values) {
    patchStorage({
      payload: {
        twoWheeler: {
          ...data?.twoWheeler,
          mainDriver: { ...data?.twoWheeler?.mainDriver, ...values },
          additionalDrivers,
        },
      },
      id,
      affinity,
      insuranceType,
    });
    renderNextRoute();
  }

  const handleAddedDrivers = (value) => {
    setAdditionalDrivers(value);
  };

  return (
    <SplitContainer>
      <FlowLeftSideAvatar
        chatText={i18n.t(
          `To start off, I will need the information about the main driver for the bike and possible additional drivers`
        )}
      />
      <RightSplit>
        <FlowDriversInformationForm
          handleAddedDrivers={handleAddedDrivers}
          handleFormValues={handleSubmit}
          defaultValues={defaultValues?.twoWheeler?.mainDriver || {}}
        />
      </RightSplit>
    </SplitContainer>
  );
};

const SplitContainer = styled.div`
  display: flex;
  height: 100%;
`;

const RightSplit = styled.div`
  width: 50%;
  padding: 5rem 4rem;
  overflow-y: auto;

  @media (max-width: 768px) {
    width: 100%;
  }

  @media screen and (max-width: 425px) {
    padding: 4rem 2.5rem;
  }
`;

export default FlowDriversInformationBike;
