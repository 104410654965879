import React from "react";

import FlowProgressBarStep from "./FlowProgressBarStep";
import { getAffinityFlow } from "../helpers/tagRouterService";

import useRouting from "../hooks/useRouting.js";
import { useParams } from "react-router";

const FlowProgressBarStepContainer = ({ icon, id }) => {
  const { affinity, insuranceType } = useParams();
  const routingData = getAffinityFlow({affinity, insuranceType});
  const { currentRouteObject } = useRouting() || {};

  function retrieveAllCurrentStepRoutes() {
    return routingData.filter(({ step }) => step === id);
  }

  function retrieveMaxStep() {
    if (routingData.length > 0) {
      return routingData.reduce(function(prev, cur) {
        return (prev.step > cur.step) ? prev.step : cur.step
      });
    }
    return null;
  }

  function percentHandler() {
    let stepsLength = retrieveAllCurrentStepRoutes().length;
    const maxStep = retrieveMaxStep();
    if (id === maxStep) {
      stepsLength += 1;
    }
    const { step, subStep, nextRoutes } = currentRouteObject() || {};
    if (nextRoutes && Object.keys(nextRoutes).length === 0) return 100;
    if (step !== id) return (step > id ? 100 : 0);
    if (stepsLength === 0) return 0;
    return (subStep / stepsLength) * 100;
  }

  return <FlowProgressBarStep icon={icon} percent={percentHandler} />;
};
export default FlowProgressBarStepContainer;
