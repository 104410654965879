import React, { useEffect } from "react";
import styled from "styled-components";
import useForm from "../hooks/useForm.js";
import { TextInput, ActionButton } from "wg-fe-ui";
import * as Yup from "yup";
import {
  string,
  vinNumber,
  licensePlate,
  // vatRegime,
  averageKmPerYear,
} from "../constants/validationSchemas.js";
import i18n from "../i18n";
import { patchStorage, retrieveStorageById } from "../helpers/storeService.js";
import { useParams } from "react-router";

const FlowManualVehicleFormExtra = ({
  defaultValues,
  resetImage,
  renderNextRoute,
}) => {
  const { id, affinity, insuranceType } = useParams();

  const validationSchema = Yup.object().shape({
    // vatRegime:
    //   (defaultValues?.policyHolder?.personType && defaultValues?.policyHolder?.personType === "legal") ||
    //   (defaultValues?.policyHolder?.personType && defaultValues?.policyHolder?.personType === "natural")
    //     ? vatRegime
    //     : number.notRequired,
    licensePlate: licensePlate,
    vinNumber: vinNumber,
    averageKmPerYear:
      affinity === "valckenier" && insuranceType === "car"
        ? averageKmPerYear
        : string.notRequired,
  });

  const { errors, values, handleSubmit, handleChange } = useForm({
    validationSchema,
  });

  useEffect(() => {
    Object.keys(defaultValues || {}).forEach((name) => {
      if (name === "carImg") {
        resetImage(defaultValues[name]);
      }
      handleChange({ name, value: defaultValues[name] });
    });
  }, [defaultValues]);

  useEffect(() => {
    const affinityCapitalized =
      affinity.charAt(0).toUpperCase() + affinity.slice(1);
    handleChange({ name: "brand", value: affinityCapitalized });
  }, []);

  const formSubmitHandler = (e) => {
    e.preventDefault();
    handleSubmit(handleFormValues);
  };

  const handleFormValues = () => {
    const { data } = retrieveStorageById(id, affinity, insuranceType);
    patchStorage({
      payload: {
        car: { ...data?.car, details: { ...data?.car?.details, ...values } },
      },
      id,
      affinity,
      insuranceType,
    });
    renderNextRoute();
  };
  console.log(values);

  return (
    <Form onSubmit={formSubmitHandler}>
      {/* {defaultValues?.policyHolder?.personType && defaultValues?.policyHolder?.personType === "legal" ? (
        <StyledTextInput
          name="vatRegime"
          error={errors.vatRegime}
          onChange={(val) => {
            handleChange(val);
          }}
          value={values.vatRegime}
        >
          {i18n.t("vatRegime")} *
        </StyledTextInput>
      ) : null} */}
      <StyledTextInput
        name="licensePlate"
        error={errors.licensePlate}
        onChange={(val) => handleChange(val)}
        value={values.licensePlate}
      >
        {i18n.t("What is license plate of the car?")}
      </StyledTextInput>
      <StyledTextInput
        name="vinNumber"
        error={errors.vinNumber}
        onChange={(val) => handleChange(val)}
        value={values.vinNumber}
      >
        {i18n.t("What is the VIN number of the car?")}
      </StyledTextInput>
      {affinity === "valckenier" && insuranceType === "car" ? (
        <StyledTextInput
          name="averageKmPerYear"
          error={errors.averageKmPerYear}
          onChange={(val) => handleChange(val)}
          value={values.averageKmPerYear}
        >
          {i18n.t("Average km travelled yearly")} *
        </StyledTextInput>
      ) : (
        ""
      )}
      <ButtonContainer>
        <Required>* {i18n.t("Required fields")}</Required>

        <StyledActionButton
          affinity={affinity}
          type="submit"
          data-test-id="manual_vehicle_flow_submit"
        >
          {i18n.t("Next")}
        </StyledActionButton>
      </ButtonContainer>
    </Form>
  );
};
const Required = styled.p`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.typo.label};
  margin-top: 2rem;
  @media (max-width: 768px) {
    margin: 0 2rem 2rem 2rem;
  }
`;

const StyledActionButton = styled(ActionButton)`
  color: ${({ affinity }) => (affinity === "de-lijn" ? "black" : "white")};
  background-color: ${({ affinity }) =>
    affinity === "callant" || affinity === "uber" || affinity === "at-work"
      ? "#94C11A"
      : "default"};
  font-weight: ${({ affinity }) => (affinity === "de-lijn" ? "700" : "normal")};
`;

const Form = styled.form`
  height: 100%;
  display: flex;
  flex-direction: column;
  p {
    color: grey;
    font-size: 1.3rem;
    /* margin: -2rem 0 2rem 0.5rem; */
  }
`;

const StyledTextInput = styled(TextInput)`
  margin-bottom: 3rem;
  div {
    p {
      color: #f74040;
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: flex-end;

  & button {
    width: 20rem;
    margin-left: auto;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    & button {
      width: 100%;
    }
  }
`;

export default FlowManualVehicleFormExtra;
