import React, { useState, useEffect } from "react";
import styled from "styled-components";
import i18n from "../i18n";
import { LoadingSpinner } from "wg-fe-ui";

const SearchVersionList = ({ filterdversionList, disabled, onSelected }) => {
  const [selectedVersion, setSelectedVersion] = useState();
  const [displayedList, setDisplayedList] = useState([]);
  const [sorted, setSorted] = useState({});
  const [areVersionsLoading, setAreVersionsLoading] = useState(true);

  useEffect(() => {
    onSelected(selectedVersion);
  }, [selectedVersion]);

  useEffect(() => {
    setDisplayedList(filterdversionList);
  }, [filterdversionList]);

  useEffect(() => {
    if (displayedList === null) setAreVersionsLoading(false);
    else setAreVersionsLoading(displayedList.length === 0);
  }, [displayedList]);

  const handleSort = (type) => {
    if (displayedList === null) return;
    let inversed = false;
    if (sorted?.type === type && sorted?.inversed) {
      setDisplayedList(filterdversionList);
      setSorted({});
    } else {
      if (sorted?.type === type && !sorted?.inversed) {
        inversed = true;
      }
      const sortedList = [...filterdversionList];
      sortedList.sort((a, b) => {
        if (a.version[type] > b.version[type]) return inversed ? -1 : 1;
        if (a.version[type] < b.version[type]) return inversed ? 1 : -1;
        return 0;
      });
      setDisplayedList(sortedList);
      setSorted({ type, inversed });
    }
  };
  const addedHashes = [];
  return (
    <div>
      <VersionLabel>
        <VersionSpan
          className="version"
          sorted={sorted?.type === "version"}
          onClick={() => handleSort("version")}
        >
          {i18n.t("Select your version") +
            (sorted?.type === "version"
              ? ` ${sorted?.inversed ? "v" : "^"}`
              : "")}
        </VersionSpan>
        <VersionSpan
          sorted={sorted?.type === "kw"}
          onClick={() => handleSort("kw")}
        >
          {i18n.t("kw") +
            (sorted?.type === "kw" ? ` ${sorted?.inversed ? "v" : "^"}` : "")}
        </VersionSpan>
        <VersionSpan sorted={sorted?.type === "price"} onClick={() => handleSort("price")}>
          {i18n.t("Invoice value") + (sorted?.type === "price" ? ` ${sorted?.inversed ? 'v' : '^'}` : '')} 
        </VersionSpan>
      </VersionLabel>
      <VersionList disabled={disabled}>
        {areVersionsLoading && !disabled ? (
          <LoadingBox>
            <LoadingSpinner className="spinner" />
          </LoadingBox>
        ) : ((!disabled && displayedList === null) ? <Information>{i18n.t("No results")}</Information> : null)}
        {displayedList ? displayedList.map((versionObj, i) => {
          const { id, kw, price, version } = versionObj?.version || {};
          // Small hash system used to filter out the duplicates with model year
          const hash = `${kw}${price}${version}`
          if (addedHashes.includes(hash) || price === null) return '';
          else addedHashes.push(hash);
          return (
            <Version
              key={`${version}-${i}`}
              onClick={() => setSelectedVersion(id)}
              isSelected={
                id === selectedVersion
              }
            >
              <span className="version">{version}</span>
              <span>{kw}</span>
              <span>{price ? parseFloat(price).toLocaleString(
                      "nl-BE",
                      {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      }
                    ) : '--'} EUR</span>
            </Version>
          );
        }) : ""}
      </VersionList>
    </div>
  );
};

const LoadingBox = styled.div`
  height: inherit;
  display: flex;
  justify-content: center;
`;

const VersionLabel = styled.li`
  padding: 0 2rem;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  font-size: 1.2rem;
  line-height: 1.6;
  color: ${({ theme }) => theme.typo.interactive};

  @media screen and (max-width: 425px) {
    font-size: 1rem;
  }

  & .version {
    width: 50%;
  }

  & span {
    cursor: pointer;
  }
`;

const VersionSpan = styled.span`
  ${({ sorted }) => (sorted ? `font-weight: 900; color: #6d6d6d;` : null)}
  position: relative;
  display: inline-block;
  margin-left: -20px;

  &::after {
    position: absolute;
    right: 0;
    content: "v";
    display: flex;
    align-items: center;
    top: 0;
    height: 100%;
    transition: all 0.3s ease;
    opacity: ${(props) => (props.ordered ? 1 : 0)};
    transform: rotate(
        ${(props) =>
          props.orderDirection && props.orderDirection !== null ? 0 : "-180deg"}
      )
      translateY(
        ${(props) =>
          props.orderDirection && props.orderDirection !== null
            ? "-1px"
            : "-3px"}
      );
  }
`;

const Information = styled.span`
  margin: 20px;
  position: absolute;
`;

const Version = styled.li`
  display: flex;
  justify-content: space-between;
  font-size: 1.5rem;
  line-height: 1.6;
  text-align: left;
  color: ${({ theme, isSelected }) => (isSelected ? "white" : theme.typo.text)};

  background-color: ${({ theme, isSelected }) =>
    isSelected ? theme.brand.primary : "none"};
  cursor: pointer;
  padding: 1rem 2rem;

  & > span:last-child {
    text-align: right;
  }

  & .version {
    width: 42%;
  }

  & .power {
    width: 22%;
  }

  & .year {
    width: 14%;
  }

  :hover {
    background-color: ${({ theme }) => theme.brand.secondary};
    color: white;
  }
`;

const VersionList = styled.ul`
  background-color: ${({ disabled }) => (disabled ? "hsl(0,0%,95%)" : "white")};
  border: 1px solid ${({ theme }) => theme.ui.outline};
  border-radius: 3px;
  height: 30vh;
  overflow-y: scroll;
`;

export default SearchVersionList;
