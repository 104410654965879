import React, { useEffect, useState } from "react";
import styled from "styled-components";
import useForm from "../hooks/useForm.js";
import { SearchSelectInput, ActionButton } from "wg-fe-ui";
import * as Yup from "yup";
import { string } from "../constants/validationSchemas.js";
import OfferedPolicy from "../components/OfferedPolicy";
import {
  propertyType,
  buildingType,
  buildingAge,
} from "../constants/FlowSearchSelectData.js";
import i18n from "../i18n";
import { patchStorage } from "../helpers/storeService.js";
import { useParams } from "react-router";

const FlowHomeInformationForm = ({ defaultValues, renderNextRoute }) => {
  const { id, affinity, insuranceType } = useParams();
  const SelectPlaceholder = i18n.t("Choose your option");
  const [propertyTypeOptions, setPropertyTypeOptions] = useState([]);
  const [house_typeOptions, sethouse_typeOptions] = useState([]);
  const [buildingAgeOptions, setBuildingAgeOptions] = useState([]);
  const enquiries = [
    {
      label: "The house is my main residence",
      key: "main_residence",
    },
    {
      label: "The building is used for the exercise of",
      key: "exercising_liberal_profession",
      popUp: {
        button: "liberal profession",
        title: "Liberal profession",
        text: "liberal_profession_helper_text",
      },
    },
    {
      label: "The building is ",
      key: "regularly_occupied",
      popUp: {
        button: "regularly occupied",
        title: "Regularly occupied",
        text:
          "Locations are regularly inhabited, if an insured occupies them every night However, during the twelve months preceding the claim, the premises may not be occupied for one hundred and twenty nights, of which a maximum of sixty consecutive nights may be occupied",
      },
    },
    {
      label: "The building was hit by a flood in the last 10 years",
      key: "flood_last_10_years",
    },
  ];

  const validationSchema = Yup.object().shape({
    propertyType: string.required,
    house_type: string.required,
    buildingAge: string.required,
  });

  const { errors, values, handleSubmit, handleChange } = useForm({
    validationSchema,
  });

  useEffect(() => {
    enquiries.forEach((item) => {
      handleChange({ name: item.key, value: false });
    });
    Object.keys(defaultValues || {}).forEach((name) => {
      handleChange({ name, value: defaultValues[name] });
      console.log(name, defaultValues[name]);
    });
  }, [defaultValues]);

  const formSubmitHandler = (e) => {
    e.preventDefault();
    handleSubmit(handleFormValues);
  };

  const handleFormValues = () => {
    const payload = {
      propertyType: values["propertyType"],
      house_type: values["house_type"],
      buildingAge: values["buildingAge"],
    };

    const payloadEnquiries = {
      main_residence: values["main_residence"] ? true : false,
      exercising_liberal_profession: values["exercising_liberal_profession"]
        ? true
        : false,
      regularly_occupied: values["regularly_occupied"] ? true : false,
      flood_last_10_years: values["flood_last_10_years"] ? true : false,
    };
    patchStorage({
      payload: payload,
      path: ["risk_address", "parcel", "main_building"],
      id,
      affinity,
      insuranceType,
    });
    patchStorage({
      payload: payloadEnquiries,
      path: ["risk_address", "parcel", "main_building", "enquiries"],
      id,
      affinity,
      insuranceType,
    });
    renderNextRoute();
  };

  useEffect(() => {
    loadSelectOptions();
  }, []);

  function loadSelectOptions() {
    setPropertyTypeOptions(
      propertyType.map(({ value, label }) => {
        return { value, label: i18n.t(label) };
      })
    );
    sethouse_typeOptions(
      buildingType.map(({ value, label }) => {
        return { value, label: i18n.t(label) };
      })
    );
    setBuildingAgeOptions(
      buildingAge.map(({ value, label }) => {
        return { value, label: i18n.t(label) };
      })
    );
  }

  function handleSelectValue(object, inputName) {
    if (!values[inputName]) return "";
    const key = values[inputName];
    return object.filter(({ value }) => value === key)[0];
  }

  function handleSelectChange(val, name) {
    handleChange({ name, value: val ? val.value : "" });
  }

  return (
    <Form onSubmit={formSubmitHandler}>
      <h1>{i18n.t("Risk address")}</h1>
      <SearchSelectInput
        error={errors.propertyType}
        name="propertyType"
        onChange={(val) => {
          handleSelectChange(val, "propertyType");
        }}
        options={propertyTypeOptions}
        placeholder={SelectPlaceholder}
        isClearable
        value={handleSelectValue(propertyTypeOptions, "propertyType")}
      >
        {i18n.t("Property type")} *
      </SearchSelectInput>
      <SearchSelectInput
        error={errors.house_type}
        name="house_type"
        onChange={(val) => {
          handleSelectChange(val, "house_type");
        }}
        options={house_typeOptions}
        placeholder={SelectPlaceholder}
        isClearable
        value={handleSelectValue(house_typeOptions, "house_type")}
      >
        {i18n.t("Building type")} *
      </SearchSelectInput>
      <SearchSelectInput
        error={errors.buildingAge}
        name="buildingAge"
        onChange={(val) => {
          handleSelectChange(val, "buildingAge");
        }}
        options={buildingAgeOptions}
        placeholder={SelectPlaceholder}
        isClearable
        value={handleSelectValue(buildingAgeOptions, "buildingAge")}
      >
        {i18n.t("Building age")} *
      </SearchSelectInput>
      <RisicoAdresVragen>
        {enquiries.map((item, key) => {
          return (
            <OfferedPolicy
              key={key}
              name={item.key}
              checked={values[item.key]}
              onChange={handleChange}
              popUp={item.popUp}
            >
              {i18n.t(item.label)}
            </OfferedPolicy>
          );
        })}
      </RisicoAdresVragen>
      <ButtonContainer>
        <Required>* {i18n.t("Required fields")}</Required>
        <StyledActionButton
          affinity={affinity}
          type="submit"
          data-test-id="home_information_flow_submit"
        >
          {i18n.t("Next")}
        </StyledActionButton>
      </ButtonContainer>
    </Form>
  );
};
const Required = styled.p`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.typo.label};
  @media (max-width: 768px) {
    align-self: center;
    margin: 0 2rem 2rem 2rem;
  }
`;

const StyledActionButton = styled(ActionButton)`
  color: ${({ affinity }) => (affinity === "de-lijn" ? "black" : "white")};
  background-color: ${({ affinity }) =>
    affinity === "callant" ? "#94C11A" : "default"};
  font-weight: ${({ affinity }) => (affinity === "de-lijn" ? "700" : "normal")};
`;

const RisicoAdresVragen = styled.div`
  display: grid;
  grid-gap: 1.5rem;
  grid-template-columns: 49% 49%;
  @media (max-width: 700px) {
    grid-template-columns: auto;
    grid-template-rows: repeat(4, auto);
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const ButtonContainer = styled.div`
  margin-top: 2rem;
  display: flex;
  height: 100%;
  align-items: flex-end;
  @media (max-width: 768px) {
    flex-direction: column;
    margin: 2rem 0;
  }

  & > button {
    margin-left: auto;
    width: 20rem;

    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;

export default FlowHomeInformationForm;
