import React from "react";
import styled from "styled-components";
import louise_logo from "../assets/images/louise_logo.svg";
import InfoPopup from "./InfoPopup";
import { getBrandData } from "../helpers/tagRouterService";
const FlowLeftSideAvatar = ({ chatText, infoHelper, width = "50%" }) => {
  console.log(["Callant"].includes(getBrandData().name));

  return (
    <LeftSplit width={width}>
      {["Callant", "Agoria"].includes(getBrandData().name) ? (
        <WideLogo src={getBrandData().logo} alt="" />
      ) : (
        <Logo src={getBrandData().logo} alt="" />
      )}
      <RoundImage
        checked={["Callant"].includes(getBrandData().name)}
        src={louise_logo}
        alt="louise"
      />
      <TextBalloonWrapper>
        <TextBalloon>
          <p
            className="text"
            dangerouslySetInnerHTML={{ __html: chatText }}
          ></p>
          {infoHelper ? (
            <StyledInfoPopup
              inverted
              title={infoHelper.title}
              info={infoHelper.info}
            />
          ) : null}
        </TextBalloon>
      </TextBalloonWrapper>
    </LeftSplit>
  );
};

const StyledInfoPopup = styled(InfoPopup)`
  background-color: white;
  color: #188bee;
  float: right;
`;

const Logo = styled.img`
  display: block;
  height: 100%;
  width: auto;
  max-height: 11.5rem;
`;
const WideLogo = styled.img`
  width: 85%;
  max-width: 40rem;
`;

const TextBalloon = styled.div`
  display: flex;
  font-size: 1.6rem;
  line-height: 1.5;
  align-self: center;
  width: 100%;
  padding: 2.2rem;
  border-radius: 0.5rem;
  color: #393939;
  background: #f3f3f3;
  margin-top: auto;
  > span {
    font-weight: 700;
  }
`;

const TextBalloonWrapper = styled.div`
  display: flex;
  margin: 0 auto;
  align-self: center;
  flex-direction: column;
`;

const LeftSplit = styled.div`
  width: ${({ width }) => width};
  padding: 5rem 4rem;
  display: grid;
  height: 100%;
  overflow: hidden;
  grid-template-rows: 20% 49% 25%;
  grid-gap: 2rem;
  justify-items: center;

  :after {
    content: "";
    width: 1px;
    height: 68%;
    background: rgba(0, 0, 0, 0.3);
    position: absolute;
    left: ${({ width }) => width};
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const RoundImage = styled.img`
  display: block;
  max-height: 130%;
  margin-bottom: -2rem;
  width: auto;
  align-self: center;
`;

export default FlowLeftSideAvatar;
