import { getAffinityFlow } from "../helpers/tagRouterService";
import { useLocation, useHistory, useParams } from "react-router-dom";

const useRouting = () => {
  const { pathname } = useLocation();
  const history = useHistory();
  const { affinity, insuranceType, id } = useParams();
  const routingData = getAffinityFlow({ affinity, insuranceType });

  function currentRouteKey() {
    let foundCurrentKey;
    routingData.forEach(({ title }) => {
      if (pathname.includes(title)) foundCurrentKey = title;
    });
    return foundCurrentKey;
  }

  function getFirstRoute() {
    return routingData[0] ? routingData[0].title : null;
  }

  function currentRouteObject() {
    const [currentRoute] = routingData.filter(
      ({ title }) => title === currentRouteKey()
    );
    return currentRoute;
  }

  function renderNextRoute(routeIndex = 1) {
    const { nextRoutes } = currentRouteObject();
    console.log(
      `/${affinity}/${insuranceType}/session/${nextRoutes[routeIndex]}/${id}`,
      routeIndex
    );
    history.push(
      `/${affinity}/${insuranceType}/session/${nextRoutes[routeIndex]}/${id}`
    );
  }

  function getNextRoute(routeIndex = 1) {
    const { nextRoutes } = currentRouteObject();

    return nextRoutes[routeIndex];
  }

  return { renderNextRoute, currentRouteObject, getNextRoute, getFirstRoute };
};

export default useRouting;
