import React, { useEffect } from "react";
import { useParams } from "react-router";
import styled from "styled-components";
import FlowLeftSideAvatar from "../components/FlowLeftSideAvatar.jsx";
import LouiseSupport from "../assets/images/louise_support.png";
import i18n from "../i18n";

import { getProspectObject } from "../helpers/prospectObjectService";
import { getEnquiries } from "../helpers/acknowledgementService";
import {
  getHomeObject,
  getCarObject,
  getTwoWheelerObject,
  getTeacherObject,
  getFamilyObject,
} from "../helpers/riskObjectForCRMService";
import { createLead } from "../helpers/apiRouterService";
import { retrieveStorageById } from "../helpers/storeService";
import { getBrandData } from "../helpers/tagRouterService";
import ReactGA from 'react-ga';

const FlowAcceptanceCondition = () => {
  const { id, affinity, insuranceType } = useParams();
  const { data } = retrieveStorageById(id, affinity, insuranceType);
  const affinityName = getBrandData().name;

  useEffect(() => {
    ReactGA.ga(`${affinity.replace('-', '')}.send`, 'event', {
      eventCategory: 'Louise Flow',
      eventAction: 'Rejected Lead/Offer',
      eventLabel: insuranceType,
    });
    callCreateRejectedLead();
  }, []);

  function getPhoneNr() {
    if (['de-lijn', 'agoria', 'at-work', 'uber', 'one_automotive'].includes(affinity)) {
      return "03/270.09.02"
    } else if (affinity === "doceo") {
      return "03/650.05.23"
    } else if (affinity === "honda") {
      return "03/650.05.22"
    } else if (["callant", "motor-insurance"].includes(affinity)) {
      return "03/270.09.01"
    } else if (affinity === "valckenier") {
      return "03/650.05.21"
    } else {
      // Should never come here, but just in case
      return "03/270.09.01"
    }
  }

  async function callCreateRejectedLead() {
    const prospect = getProspectObject(data);
    const enquiries = getEnquiries(insuranceType);
    const acknowledgements = enquiries.map(e => {
      return {agreed: false, text: i18n.t(e.label)}
    });
    const riskObjects = {};
    if (insuranceType === "home") {
      riskObjects["residences"] = [getHomeObject(data)];
    } else if (insuranceType === "car") {
      riskObjects["cars"] = [getCarObject(data)];
    } else if (insuranceType === "two-wheeler") {
      riskObjects["two_wheelers"] = [getTwoWheelerObject(data)];
    } else if (insuranceType === "teacher") {
      riskObjects["teachers"] = [getTeacherObject(data)];
      riskObjects["families"] = [getFamilyObject(data)];
    }
    const [resp, status] = await createLead(
      prospect,
      riskObjects,
      affinityName,
      acknowledgements,
      true,
      data?.rejectionDescription,
      data?.refId,
      null,
      data?.consessionHolder
    );
    return [resp, status];
  }

  return (
    <SplitContainer>
      <FlowLeftSideAvatar
        chatText={i18n.t(
          "It is not possible to obtain an price for the provided information For further steps please contact us"
        )}
        width="50%"
      />
      <RightSplit>
        <LouiseContainer>
          <LouiseText>
            <H1>{i18n.t(`Sorry`)}</H1>
            <P>
              {i18n.t(
                `Unfortunately due to the information we received from you we are unable to complete your simulation online`
              )}
            </P>
            <P>
              {i18n.t(
                `We advise you to contact one of our agents on the number below from Monday to Friday from 8 30am to 7 30pm`
              )}
            </P>
            <P>
              <span>{getPhoneNr()}</span>
            </P>
          </LouiseText>
          <Louise src={LouiseSupport} />
        </LouiseContainer>
      </RightSplit>
    </SplitContainer>
  );
};

const Louise = styled.img`
  margin: auto;
  margin-bottom: 0;
  width: 36vw;
  max-width: -webkit-fill-available;

  @media (max-width: 768px) {
    width: 70vw;
  }
`;

const LouiseText = styled.div`
  > p {
    margin-top: 2rem;
    > b {
      font-weight: 700;
      > span {
        color: #ff8000;
      }
    }
    > span {
      font-weight: bold;
    }
  }
  margin-bottom: 4rem;
`;

const LouiseContainer = styled.div`
  letter-spacing: 0.03rem;
  line-height: 2.5rem;
  margin: auto;
  text-align: center;
`;

const P = styled.p`
  > span {
    font-weight: 700;
  }
`;

const H1 = styled.h1`
  font-weight: 700;
  font-size: 120%;
`;

const RightSplit = styled.div`
  width: 50%;
  padding: 5rem;
  overflow-y: auto;

  @media (max-width: 768px) {
    width: 100%;
  }

  @media screen and (max-width: 425px) {
    padding: 4rem 2.5rem;
  }
`;

const SplitContainer = styled.div`
  display: flex;
  height: 100%;
`;

export default FlowAcceptanceCondition;
