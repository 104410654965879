import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import ReactGA from "react-ga";
import {
  BASE_URL,
  SENTRY_DSN,
  PROJECT_NAME,
  RELEASE,
  ENVIRONMENT,
} from "./env.config";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import packageJson from "../package.json";

function getGAtrackID(affinity) {
  if (affinity === "honda") {
    return "UA-171728681-2";
  } else if (affinity === "de-lijn") {
    return "UA-171713534-2";
  } else if (affinity === "one_automotive") {
    return "UA-171728681-6";
  } else if (affinity === "motor-insurance" || affinity === "callant") {
    return "UA-171725339-1";
  } else if (affinity === "agoria") {
    return "UA-171713534-3";
  } else if (affinity === "at-work") {
    return "UA-171713534-1";
  } else if (affinity === "doceo") {
    return "UA-171728681-3";
  } else if (affinity === "valckenier") {
    return "UA-171728681-1";
  } else if (affinity === "uber") {
    return "UA-171728681-4";
  }
}

function getGAtrackIDs() {
  const trackers = [];
  const affinities = [
    "honda",
    "one_automotive",
    "de-lijn",
    "motor-insurance",
    "callant",
    "agoria",
    "at-work",
    "doceo",
    "valckenier",
    "uber",
  ];
  for (var i = 0; i < affinities.length; i++) {
    trackers.push({
      trackingId: getGAtrackID(affinities[i]),
      gaOptions: {
        name: affinities[i].replace("-", ""),
      },
    });
  }
  return trackers;
}

function isDev() {
  return BASE_URL.includes(".dev.");
}
console.log("Development mode:", isDev());

 // When route is not a hashRoute, replace url with hash
if (window.location.href.split('/')[3] !== '#') {
  const urlArrayWithoutSlash  = window.location.href.split('/');
  // Put the hash inside the array
  urlArrayWithoutSlash.splice(3, 0, '#');
  const hashUrl = urlArrayWithoutSlash.join('/');
  window.location.replace(hashUrl);
}

if (!isDev()) {
  const GAconfig = { debug: isDev() };
  ReactGA.initialize(getGAtrackIDs(), GAconfig);
}

Sentry.setTag("name", PROJECT_NAME);
Sentry.setTag("wg-fe-ui-version", packageJson.devDependencies["wg-fe-ui"]);

Sentry.init({
  serverName: PROJECT_NAME,
  dsn: SENTRY_DSN,
  environment: ENVIRONMENT,
  release: RELEASE,
  ignoreErrors: [],
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0.1, // Be sure to lower this in production
});

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
