import React, { useState, useEffect } from "react";
import styled from "styled-components";
import useRouting from "../hooks/useRouting.js";
import { useParams } from "react-router";
import {
  retrieveStorageById /*, patchStorage */,
} from "../helpers/storeService.js";
import FlowManualVehicleForm from "../components/FlowManualVehicleForm.jsx";
import i18n from "../i18n";
import louise_logo from "../assets/images/louise_logo.svg";

const FlowCarInformation = () => {
  const defaultImg = louise_logo;
  const [carBrandImage, setCarBrandImage] = useState(defaultImg);
  const { renderNextRoute } = useRouting();
  const { id, affinity, insuranceType } = useParams();
  const [defaultValues, setDefaultValues] = useState({});

  useEffect(() => {
    loadDefaultValues();
  }, []);

  const loadDefaultValues = async () => {
    const { data } = retrieveStorageById(id, affinity, insuranceType);
    setDefaultValues(data?.car?.details || {});
  };

  return (
    <SplitContainer>
      <LeftSplit>
        <RoundImageContainer>
          <RoundImage img={carBrandImage} alt="car brand logo" />
        </RoundImageContainer>
        <TextBalloon>
          <p
            dangerouslySetInnerHTML={{
              __html: i18n.t(
                "Next I need some information about your car You can fill it out here on the right"
              ),
            }}
          ></p>
        </TextBalloon>
      </LeftSplit>
      <RightSplit>
        <FlowManualVehicleForm
          resetImage={(img) => setCarBrandImage(img)}
          renderNextRoute={renderNextRoute}
          defaultValues={defaultValues}
        />
      </RightSplit>
    </SplitContainer>
  );
};

const TextBalloon = styled.div`
  display: flex;
  font-size: 1.6rem;
  line-height: 1.5;
  align-self: center;
  width: 100%;
  padding: 2.2rem;
  border-radius: 0.5rem;
  color: #393939;
  background: #f3f3f3;
  margin-top: auto;
  > span {
    font-weight: 700;
  }
`;

const RightSplit = styled.div`
  width: 50%;
  padding: 5rem 4rem;
  overflow-y: auto;

  @media (max-width: 768px) {
    width: 100%;
  }

  @media screen and (max-width: 425px) {
    padding: 4rem 2.5rem;
  }
`;

const SplitContainer = styled.div`
  display: flex;
  height: 100%;
`;

const LeftSplit = styled.div`
  width: 50%;
  display: grid;
  grid-template-rows: 71% 25%;
  grid-gap: 2rem;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  padding: 5rem 4rem;

  :after {
    content: "";
    width: 1px;
    height: 70%;
    background: rgba(0, 0, 0, 0.3);
    position: absolute;
    right: 50%;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const RoundImage = styled.div`
  background-image: url(${(props) => props.img});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
  width: 100%;
  height: auto;
  padding-top: 100%;
`;

const RoundImageContainer = styled.div`
  max-width: 35rem;
  width: 100%;
  margin: auto;
`;

export default FlowCarInformation;
