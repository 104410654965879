import { retrieveAddressStreets, retrieveAddressCities } from "./apiRouterService";

export const cleanAddressData = payload => {
    const arr = [];
    arr.push(
      payload.map(r => {
        if (!('housenr' in r)) {
          return undefined;
        }
  
        const { housenr, city, street, boxnr, zipcode, lat, lng } = r;
  
        const cleanedStreetName = street
          .toLowerCase()
          .split(' ')
          .map(s => s.charAt(0).toUpperCase() + s.substring(1))
          .join(' ');
        const cleanedHouseNr = housenr
          .toLowerCase()
          .split(' ')
          .map(s => s.charAt(0).toUpperCase() + s.substring(1))
          .join(' ');
        const cleanedMunicipalityName = city
          .toLowerCase()
          .split(' ')
          .map(s => s.charAt(0).toUpperCase() + s.substring(1))
          .join(' ');
        const obj = {
          value: {
            streetName: cleanedStreetName,
            boxNumber: boxnr,
            streetNumber: cleanedHouseNr,
            postalCode: zipcode,
            municipalityName: cleanedMunicipalityName,
            latitude: lat,
            longitude: lng,
          },
          label: `${cleanedStreetName} ${cleanedHouseNr}${
            boxnr ? `/${boxnr}` : ``
          }, ${zipcode} ${cleanedMunicipalityName} `,
        };
        return housenr !== undefined ? obj : undefined;
      }),
    );
    return arr[0].filter(e => e !== undefined);
  };

  export const fetchStreets = async (value, values) => {
    if (
      (value && value.searchQuery && value.searchQuery.length >= 2) ||
      (value && value.municipalityName)
    ) {
      let streetsOptionList = [];
      let payload;
      if (value.searchQuery) {
        payload = {
          city: value.municipalityName
            ? value.municipalityName
            : values['municipalityName'],
          searchQuery: value.searchQuery,
        };
      } else {
        payload = {
          city: value.municipalityName,
        };
      }
      const [resp, status] = await retrieveAddressStreets(payload);
      if(status !== 200) return;
      resp.forEach(street => {
        streetsOptionList.push({
          'value': street.name,
          'label': street.name,
        });
      });
      return streetsOptionList;
    }
  };
  
  export const fetchCities = async (value) => {
    if (
      (value && value.searchQuery && value.searchQuery.length >= 2) ||
      (value && value.zipcode)
    ) {
      let citiesOptionList = [];
      let cityPostals = {};
      const [resp, status] = await retrieveAddressCities(value);
      if(status !== 200) return;
        resp.forEach(city => {
          cityPostals[city.name] = city.zipcode;
          citiesOptionList.push({
            'value': city.name,
            'label': city.name,
          });
        });

        return {cityPostals, citiesOptionList};
    }
  };