import React from "react";
import styled from "styled-components";
// import { currentTheme } from "../helpers/themingService.js";

const UploadButtonIcon = () => {
  // const { action } = currentTheme;
  return (
    <UploadIcon
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill={"white"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M38 23H27V12H23V23H12V27H23V38H27V27H38V23Z" />
    </UploadIcon>
  );
};

const UploadIcon = styled.svg`
  align-self: center;
  margin-left: 1rem;
  padding-right: 2rem;
`;

export default UploadButtonIcon;
