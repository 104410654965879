export default [
  {
    id: "civil_liability",
    title: "Civil liablity",
    ipidLink: {
      nl: "",
      fr: "",
      en: "",
    },
    termsLink: {
      nl: "",
      fr: "",
      en: "",
    },
    mandatory: true,
    helper: "civil_liability_description",
  },
  {
    id: "omnium",
    title: "Omnium",
    ipidLink: {
      nl: "",
      fr: "",
      en: "",
    },
    termsLink: {
      nl: "",
      fr: "",
      en: "",
    },
    mandatory: true,
    helper: "omnium_description",
  },
  {
    id: "mini_omnium",
    title: "Mini omnium",
    ipidLink: {
      nl: "",
      fr: "",
      en: "",
    },
    termsLink: {
      nl: "",
      fr: "",
      en: "",
    },
    mandatory: true,
    helper: "mini_omnium_description",
  },
  {
    id: "legal",
    title: "Legal aid",
    ipidLink: {
      nl: "",
      fr: "",
      en: "",
    },
    termsLink: {
      nl: "",
      fr: "",
      en: "",
    },
    mandatory: false,
    helper: "legal_aid_description",
  },
  {
    id: "driver",
    title: "Driver insurance",
    ipidLink: {
      nl: "",
      fr: "",
      en: "",
    },
    termsLink: {
      nl: "",
      fr: "",
      en: "",
    },
    mandatory: false,
    helper: "driver_description",
  },
  {
    id: "assistance_vehicle",
    title: "Assistance vehicle person",
    ipidLink: {
      nl: "",
      fr: "",
      en: "",
    },
    termsLink: {
      nl: "",
      fr: "",
      en: "",
    },
    mandatory: false,
    helper: "assistance_description",
  },
];
