import React, { useEffect, useState } from "react";
import { Label, SearchSelectInput, ActionButton } from "wg-fe-ui";
import useForm from "../hooks/useForm";
import * as Yup from "yup";
import styled from "styled-components";
import i18n from "../i18n.js";
import DateInput from "./DateInput";
import { string } from "../constants/validationSchemas.js";
import AcknowledgementCheckbox from "./AcknowledgementCheckbox";
import { useParams } from "react-router";
import { isFuture } from "date-fns";
import { getEnquiries } from "../helpers/acknowledgementService";
import InfoPopup from "./InfoPopup";

const FlowHomeOverviewForm = ({
  startDateError,
  onChildClick,
  handleFormValues,
}) => {
  const { affinity, insuranceType } = useParams();
  const [hasCurrentInsurance, setHasCurrentInsurance] = useState(true);
  const [startDate, setStartDate] = useState("");
  const [startDateErr, setStartDateErr] = useState(""); // const [canOverWriteStartDate, setCanOverWriteStartDate] = useState(true);
  const SelectPlaceholder = i18n.t("Choose your option");
  const [defaultValues] = useState({});

  // const [chosenInsurances, setChosenInsurances] = useState([]);

  const enquiries = getEnquiries(insuranceType);

  const SignupSchema = Yup.object().shape({
    paymentSpread: string.required,
    startDate: string.notRequired,
    damageReports: string.required,
  });

  const { handleChange, errors, handleSubmit, values } = useForm({
    validationSchema: SignupSchema,
    change: () => {},
  });

  const paymentOptions = [
    { value: "monthly", label: i18n.t("Monthly") },
    { value: "quarterly", label: i18n.t("Quarterly") },
    { value: "half_annual", label: i18n.t("HalfAnnual") },
    { value: "annual", label: i18n.t("Annual") },
  ];

  const damageOptions = [
    { value: "0", label: i18n.t("None") },
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "more_than_5", label: i18n.t("More than 5") },
  ];

  function handleSelectValue(object, inputName) {
    if (!values[inputName]) return "";
    const key = values[inputName];
    return object.filter(({ value }) => value === key)[0];
  }

  function handleSelectChange(val, name) {
    handleChange({ name, value: val ? val.value : "" });
  }

  function handleCheckboxChange(e) {
    if (e.name === "Do you currently have house insurance?") {
      setHasCurrentInsurance(!e.value?.agreed);
      if (e.value?.agreed === false) {
        handleChange({
          name: "Do you wish to cancel your current insurance through us?",
          value: { agreed: false, key: "cancel_current_insurance" },
        });
      }
    }
    handleChange(e);
  }

  function formSubmitHandler(e) {
    e.preventDefault();
    console.log(values);

    handleSubmit((values) => {
      handleFormValues(values);
    });
  }

  function generateNewTerminationDate(teminationDate, proposedYear) {
    const proposedTermDateString = proposedYear
      ? `${teminationDate}/${proposedYear}`
      : teminationDate;
    const [day, month, year] = proposedTermDateString.split("/");
    return new Date(year, month - 1, day);
  }

  function handleStartDateBlur() {
    if (isFuture(generateNewTerminationDate(startDate))) {
      setStartDateErr(i18n.t(""));
      onChildClick(startDate);
    } else if (!isFuture(generateNewTerminationDate(startDate))) {
      setStartDateErr(i18n.t("Date should be in the future"));
      // setCanOverWriteStartDate(false);
    }
  }

  useEffect(() => {
    console.log(startDate);

    handleChange({ name: "startDate", value: startDate });
  }, [startDate]);

  useEffect(() => {
    enquiries.forEach((item) => {
      handleChange({
        name: item.label,
        value: { agreed: false, key: item.key },
      });
    });
    Object.keys(defaultValues || {}).forEach((name) => {
      handleChange({ name, value: defaultValues[name] });
    });
  }, [defaultValues]);

  return (
    <Form onSubmit={(e) => formSubmitHandler(e)}>
      <StyledLabel>
        {i18n.t(
          "How many home related claims have there been in the last five years?"
        )}
      </StyledLabel>
      <SelectInput
        error={errors.damageReports}
        name="damageReports"
        onChange={(val) => {
          handleSelectChange(val, "damageReports");
        }}
        options={damageOptions}
        placeholder={SelectPlaceholder}
        isClearable
        value={handleSelectValue(damageOptions, "damageReports")}
      />
      <StyledLabel>{i18n.t("Payment spread")}</StyledLabel>
      <SelectInput
        error={errors.paymentSpread}
        name="paymentSpread"
        onChange={(val) => {
          handleSelectChange(val, "paymentSpread");
        }}
        options={paymentOptions}
        placeholder={SelectPlaceholder}
        isClearable
        value={handleSelectValue(paymentOptions, "paymentSpread")}
      />
      <StyledDateInput
        name="startDate"
        type="startDate"
        value={startDate}
        error={startDateErr}
        onChange={({ value }) => setStartDate(value)}
        onBlur={handleStartDateBlur}
      >
        <p>
          {i18n.t(`Start date`)}
          <StyledInfoPopup
            title={i18n.t(`Start date`)}
            buttonText={i18n.t("?")}
            info={i18n.t("startDatePopupText")}
          />
        </p>
      </StyledDateInput>
      <ErrorMsg>{startDateError}</ErrorMsg>
      <SelectedCoverages>
        {enquiries.map((item) => {
          return (
            <AcknowledgementCheckbox
              itemKey={item.key}
              key={item.key}
              name={item.label}
              checked={values[item.label]?.agreed}
              onChange={handleCheckboxChange}
              disabled={
                item.key === "cancel_current_insurance"
                  ? hasCurrentInsurance
                  : false
              }
            >
              {i18n.t(item.label)}
            </AcknowledgementCheckbox>
          );
        })}
      </SelectedCoverages>
      <ButtonContainer>
        <StyledActionButton
          affinity={affinity}
          type="submit"
          value="Submit"
          data-test-id="drivers_information_submit"
        >
          {i18n.t("Confirm")}
          <StyledInfoPopup
            title={i18n.t("Confirm")}
            buttonText={i18n.t("?")}
            info={i18n.t("confirmPopupText")}
          />
        </StyledActionButton>
      </ButtonContainer>
    </Form>
  );
};

const ErrorMsg = styled.p`
  color: #f74040;
  font-size: 1.1rem;
  margin-top: -1rem;
  margin-left: 0.5rem;
  text-align: right;
`;

const StyledDateInput = styled(DateInput)`
  padding-bottom: 3rem;
`;

const StyledActionButton = styled(ActionButton)`
  color: ${({ affinity }) => (affinity === "de-lijn" ? "black" : "white")};
  background-color: ${({ affinity }) =>
    affinity === "callant" ? "#94C11A" : "default"};
  font-weight: ${({ affinity }) => (affinity === "de-lijn" ? "700" : "normal")};
`;

const ButtonContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: flex-end;

  margin-left: auto;
  margin-top: auto;
  @media (max-width: 768px) {
    margin-left: 0;
  }

  & button {
    margin-left: auto;
    margin-top: 2rem;
    width: 20rem;

    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;

const SelectedCoverages = styled.div`
  h1 {
    font-weight: bold;
    font-size: 2.5rem;
    padding-bottom: 3.5rem;
    letter-spacing: 0.07rem;
    text-align: center;
    text-decoration: underline;
  }
`;

const SelectInput = styled(SearchSelectInput)`
  height: 7rem;
  margin-bottom: 2rem;
  & .Select__option {
    font-size: 1.6rem;
    color: ${({ theme }) => theme.typo.text};
  }

  & .Select__option--is-focused {
    color: ${({ theme }) => theme.typo.text};
    font-weight: 500;
    background-color: ${({ theme }) => theme.brand.lighter};
    :hover {
      color: white;
      background-color: ${({ theme }) => theme.brand.secondary};
    }
  }
  & div {
    margin-bottom: 0;
  }
`;

const StyledLabel = styled(Label)`
  color: ${({ theme }) => theme.typo.subTitle};
  font-size: 1.5rem;
`;

// const StyledDateInput = styled(DateInput)`
//   padding-top: 2rem;
//   padding-bottom: 2rem;
// `;
const Form = styled.form`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledInfoPopup = styled(InfoPopup)`
  float: right;
  font-size: 1.2rem;
  height: 1.6rem;
  line-height: 1.3;
  width: 1.6rem;
  right: 0;
`;

export default FlowHomeOverviewForm;
