import React, { useEffect, useState } from "react";
import { LoadingSpinner, ActionButton, Modal } from "wg-fe-ui";
import styled from "styled-components";
import { useParams } from "react-router";
import FlowPriceCalculatorOptions from "../components/FlowPriceCalculatorOptions";
import InfoPopup from "../components/InfoPopup";

import useRouting from "../hooks/useRouting";
import { patchStorage, retrieveStorageById } from "../helpers/storeService";
import { parseIncDateToObject } from "../helpers/dateService";
import { getCarObject } from "../helpers/riskObjectService";
import i18n from "../i18n";
import { retrieveCarPrice } from "../helpers/apiRouterService";
import { differenceInCalendarYears, format } from "date-fns";
import { getNestedObject } from "../helpers/objectService";
import { getProspectObjectPyModels } from "../helpers/prospectObjectService";

const FlowPriceCalculator = () => {
  const { id, affinity, insuranceType } = useParams();
  const storageData = retrieveStorageById(id, affinity, insuranceType);

  const [loading, setLoading] = useState(true);
  const [specialRequirementModal, setSpecialRequirementModal] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [carAge, setCarAge] = useState(null);
  const [omniumPrice, setOmniumPrice] = useState("");
  const [miniOmniumPrice, setMiniOmniumPrice] = useState("");
  const [civilLiabilityPrice, setCivilLiabilityPrice] = useState("");
  const [quoteId, setQuoteId] = useState(null);
  const [quoteResp, setQuoteResp] = useState(null);
  const [subPrices, setSubPrices] = useState({});
  const [totalPremium, setTotalPremium] = useState("");
  const { renderNextRoute } = useRouting();
  const [disableOmnium, setDisableOmnium] = useState(false);
  const [disableMiniOmnium, setDisableMiniOmnium] = useState(false);

  async function setOptionsPrices() {
    const response = await callCarPrice(
      "",
      selectedType
    );
    setSubPrices(response);
  }

  function handleSubmit() {
    if (storageData.data?.selectedFormula === "6WHEELS") {
      patchStorage({
        payload: { priceInformationCar: { quoteId, quoteResp } },
        id,
        affinity,
        insuranceType,
      });
    } else {
      patchStorage({
        payload: { priceInformation: { quoteId, quoteResp } },
        id,
        affinity,
        insuranceType,
      });
    }
    if (storageData.data?.selectedFormula === "6WHEELS") {
      if (
        storageData.data?.twoWheeler?.details?.vehicleType ===
          "LIGHT_SCOOTER" ||
        storageData.data?.twoWheeler?.details?.vehicleType === "LIGHT_SCOOTER"
      ) {
        return renderNextRoute(3);
      } else {
        return renderNextRoute(2);
      }
    } else if (
      storageData.data?.selectedFormula === "MOTOR_ALREADY_INSURED" ||
      storageData.data?.selectedFormula === "6WHEELS" ||
      storageData.data?.selectedFormula === "MOTOR_COMPANY_CAR" ||
      storageData.data?.selectedFormula === "CAR_ALREADY_INSURED"
    ) {
      return renderNextRoute(1);
    } else {
      return renderNextRoute();
    }
  }

  function getAge(dateString) {
    var today = new Date();
    const newDateString = dateString.split("/");
    let newVal =
      newDateString[1] + "/" + newDateString[0] + "/" + newDateString[2];
    var birthDate = new Date(newVal);
    console.log(newVal);

    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  function checkSpecialRequirements() {
    const { data } = retrieveStorageById(id, affinity, insuranceType);
    const birthDateString = data?.car?.mainDriver?.birth;
    let age = getAge(birthDateString);
    let driverLicenseAge = getAge(data?.car?.mainDriver?.issueLicenseDate);
    if (age < 25) {
      setModalContent("youngAgePremium")
      setSpecialRequirementModal(true);
    } else if (age < 30 && driverLicenseAge < 1) {
      setModalContent("middleAgeAndRecentDriverPremium")
      setSpecialRequirementModal(true);
    } else if (age >= 75) {
      setModalContent("oldAgePremium")
      setSpecialRequirementModal(true);
    } else {
      handleSubmit();
    }
  }

  useEffect(() => {
    calculateCarAge();
    calculateBasePrices();
  }, []);

  useEffect(() => {
    setOptionsPrices();
  }, [selectedType]);

  async function calculateBasePrices() {
    const civilLiabilityResp = await callCarPrice(
      "option=legal",
      "civil_liability"
    );
    setCivilLiabilityPrice(
      getNestedObject(civilLiabilityResp, ["premium", "total_premium"])
    );
    await setLoading(false);
  }

  async function calculateMiniOmniumPrice() {
    const miniOmniumResp = await callCarPrice("option=legal", "mini_omnium");
    setMiniOmniumPrice(
      getNestedObject(miniOmniumResp, ["premium", "total_premium"])
    );
  }

  async function calculateOmniumPrice() {
    const omniumResp = await callCarPrice("option=legal", "omnium");
    setOmniumPrice(getNestedObject(omniumResp, ["premium", "total_premium"]));
  }

  function calculateCarAge() {
    const { registrationYear } = storageData?.data?.car?.details || {};
    const carAge = differenceInCalendarYears(
      new Date(),
      new Date(registrationYear, 0, 1)
    );

    setCarAge(carAge);
  }
  useEffect(() => {
    if (carAge === null) return;
    if (carAge >= 8) {
      // Civil liability select
      setDisableOmnium(true);
      setDisableMiniOmnium(true);
      setSelectedType("civil_liability");
    } else if (carAge >= 5 && carAge < 8) {
      // MiniOmnium select
      calculateMiniOmniumPrice();
      setDisableOmnium(true);
      setSelectedType("mini_omnium");
    } else {
      // Omnium select
      calculateOmniumPrice();
      calculateMiniOmniumPrice();
      setSelectedType("omnium");
    }
  }, [carAge]);

  async function callCarPrice(query = "", insurance) {
    // patchStorage({ pricingQuery: query }, id);

    if (insurance === "" || insurance === undefined) return null;

    const { data } = storageData || {};

    const currentDateObj = parseIncDateToObject(
      format(new Date(), "dd/MM/yyyy")
    );
    data.currentDateObj = currentDateObj;

    const payload = {
      car: getCarObject(data),
      policy_holder: getProspectObjectPyModels(data),
      quote_specifications: {
        currency: "EUR",
        payment_interval: "annual",
        save_quote: true,
        bonus_malus: data?.car?.mainDriver?.bonusMalus,
      },
    };
    const [resp, status] = await retrieveCarPrice(
      payload,
      affinity,
      insuranceType === "6-wheeler" ? "car" : insuranceType,
      insurance,
      query
    );

    if ((await status) === 200);
    else return {};

    // Check for eligible promotions
    let eligiblePromotionId = null;
    for (let j = 0; j < resp?.promotions?.length; j++) {
      if (resp?.promotions?.[j]?.eligible) {
        eligiblePromotionId = resp?.promotions?.[j]?.id;
      }
    }

    // If a promotion can be taken, but is not taken, take it
    if (!query.includes('promotion') && eligiblePromotionId !== null) {
      if (query === '') {
        query = `promotion=${eligiblePromotionId}`
      } else {
        query += `&promotion=${eligiblePromotionId}`
      }
      const [resp, status] = await retrieveCarPrice(
        payload,
        affinity,
        insuranceType === "6-wheeler" ? "car" : insuranceType,
        insurance,
        query
      );
  
      if ((await status) === 200);
      else return {};  

      return resp;
    }
    
    return resp;
  }

  async function retrievePrice(queryString) {
    let { premium, insurance, quote, id } =
      (await callCarPrice(queryString, selectedType)) || {};
    let { total_premium } = premium || {};
    let guaranteesChosen = [];
    
    // Handle the premiums and prices properly
    if (!queryString.includes('option')) {
      let noGuaranteeTotalPremium = quote?.base?.total_premium;
      guaranteesChosen.push(quote?.base?.name);
      let i;
      for (i = 0; i < quote?.options?.length; i++) {
        if (quote?.options?.[i]?.mandatory || quote?.options?.[i]?.name === selectedType) {
          noGuaranteeTotalPremium += quote?.options?.[i]?.total_premium;
          guaranteesChosen.push(quote?.options?.[i]?.name);
        }
      }
      setTotalPremium(noGuaranteeTotalPremium);
    } else {
      setTotalPremium(total_premium);
      guaranteesChosen = [quote?.base?.name].concat(insurance?.options || []);
    }
    setQuoteId(id);
    setQuoteResp({ insurance, quote, premium, guaranteesChosen });
  }

  // var myObj = {
  //   style: "currency",
  //   currency: "EUR",
  // };

  if (loading) {
    return (
      <LoadingContainer>
        <LoadingSpinner />
        <LoadingText>{i18n.t("Please wait")}</LoadingText>
      </LoadingContainer>
    );
  }
  // if (omniumPrice === undefined || !omniumPrice) {
  //   return (
  //     <LoadingContainer>
  //       <LoadingText>
  //         {i18n.t(
  //           "There was an error calculating your prices, please try again."
  //         )}
  //       </LoadingText>
  //     </LoadingContainer>
  //   );
  // }
  console.log(carAge);

  return (
    <SplitContainer>
      <Title>{i18n.t(`Choose the formula for your car insurance`)} </Title>
      <Modal showModal={specialRequirementModal} setShowModal={setSpecialRequirementModal} medium>
        <ModalTitle>{i18n.t(modalContent)}</ModalTitle>
        <Button
          affinity={affinity}
          onClick={() => {
            handleSubmit();
          }}
        >
          {i18n.t(`I agree`)}
        </Button>
      </Modal>
      <PriceCalculatorContainer>
        <StyledDiv>
          {disableOmnium ? (
            ""
          ) : (
            <Label
              affinity={affinity}
              for="omnium"
              disable={disableOmnium}
              checked={selectedType === "omnium" && !disableOmnium}
            >
              <RadioInput
                type="radio"
                name="price"
                value="omnium"
                id="omnium"
                onClick={() => {
                  if (!disableOmnium) {
                    setSelectedType("omnium");
                  } else {
                    return;
                  }
                }}
                checked={selectedType === "omnium"}
              />
              {disableOmnium ? (
                <InfoPopup
                  title={i18n.t("Vehicle age not qualified")}
                  info={i18n.t(
                    "A vehicle older than 5 years doesn't qualify for this option"
                  )}
                ></InfoPopup>
              ) : (
                ""
              )}
              <TitleLabel>{i18n.t(`Omnium + BA + Legal aid`)}</TitleLabel>
              <PriceLabel>
                {!omniumPrice ? (
                  <Error>{i18n.t("Not possible for this vehicle")}</Error>
                ) : (
                  `${i18n.t(`From`)} ${parseFloat(omniumPrice).toLocaleString(
                    "nl-BE",
                    {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }
                  )} EUR`
                )}
              </PriceLabel>
              {carAge < 5 && omniumPrice ? (
                <span>{i18n.t(`Recommended`)}</span>
              ) : null}
            </Label>
          )}
          {disableMiniOmnium ? (
            ""
          ) : (
            <Label
              affinity={affinity}
              for="mini_omnium"
              disable={disableMiniOmnium}
              checked={selectedType === "mini_omnium" && !disableMiniOmnium}
            >
              <RadioInput
                type="radio"
                name="price"
                value="mini_omnium"
                id="mini_omnium"
                onClick={() => {
                  if (!disableMiniOmnium) {
                    setSelectedType("mini_omnium");
                  } else {
                    return;
                  }
                }}
                checked={selectedType === "mini_omnium"}
              />
              {disableMiniOmnium ? (
                <InfoPopup
                  title={i18n.t("Vehicle age not qualified")}
                  info={i18n.t(
                    "A vehicle older than 8 years doesn't qualify for this option"
                  )}
                ></InfoPopup>
              ) : (
                ""
              )}
              <TitleLabel>{i18n.t(`Mini Omnium + BA + Legal aid`)}</TitleLabel>
              <PriceLabel>
                {!miniOmniumPrice ? (
                  <Error>{i18n.t("Not possible for this vehicle")}</Error>
                ) : (
                  `${i18n.t(`From`)} ${parseFloat(
                    miniOmniumPrice
                  ).toLocaleString("nl-BE", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })} EUR`
                )}
              </PriceLabel>
              {5 <= carAge && carAge <= 8 && miniOmniumPrice ? (
                <span>{i18n.t(`Recommended`)}</span>
              ) : null}
            </Label>
          )}
          <Label
            affinity={affinity}
            for="civil_liability"
            checked={selectedType === "civil_liability"}
          >
            <RadioInput
              type="radio"
              name="price"
              value="civil_liability"
              id="civil_liability"
              onClick={() => setSelectedType("civil_liability")}
              checked={selectedType === "civil_liability"}
            />
            <TitleLabel>{i18n.t(`BA + Legal aid`)}</TitleLabel>
            <PriceLabel>
              {i18n.t(`From`)}{" "}
              {parseFloat(civilLiabilityPrice).toLocaleString("nl-BE", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}{" "}
              EUR
            </PriceLabel>
            {carAge > 8 ? <span>{i18n.t(`Recommended`)}</span> : null}
          </Label>
          {disableOmnium ? (
            <Label
              affinity={affinity}
              for="omnium"
              disable={disableOmnium}
              checked={selectedType === "omnium" && !disableOmnium}
            >
              <RadioInput
                type="radio"
                name="price"
                value="omnium"
                id="omnium"
                onClick={() => {
                  if (!disableOmnium) {
                    setSelectedType("omnium");
                  } else {
                    return;
                  }
                }}
                checked={selectedType === "omnium"}
              />
              {disableOmnium ? (
                <InfoPopup
                  title={i18n.t("Vehicle age not qualified")}
                  info={i18n.t(
                    "A vehicle older than 5 years doesn't qualify for this option"
                  )}
                ></InfoPopup>
              ) : (
                ""
              )}
              <TitleLabel>{i18n.t(`Omnium + BA + Legal aid`)}</TitleLabel>
              <PriceLabel>
                {!omniumPrice ? (
                  <Error>{i18n.t("Not possible for this vehicle")}</Error>
                ) : (
                  `${i18n.t(`From`)} ${parseFloat(omniumPrice).toLocaleString(
                    "nl-BE",
                    {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }
                  )} EUR`
                )}
              </PriceLabel>
              {carAge < 5 && omniumPrice ? (
                <span>{i18n.t(`Recommended`)}</span>
              ) : null}
            </Label>
          ) : (
            ""
          )}
          {disableMiniOmnium ? (
            <Label
              affinity={affinity}
              for="mini_omnium"
              disable={disableMiniOmnium}
              checked={selectedType === "mini_omnium" && !disableMiniOmnium}
            >
              <RadioInput
                type="radio"
                name="price"
                value="mini_omnium"
                id="mini_omnium"
                onClick={() => {
                  if (!disableMiniOmnium) {
                    setSelectedType("mini_omnium");
                  } else {
                    return;
                  }
                }}
                checked={selectedType === "mini_omnium"}
              />
              {disableMiniOmnium ? (
                <InfoPopup
                  title={i18n.t("Vehicle age not qualified")}
                  info={i18n.t(
                    "A vehicle older than 8 years doesn't qualify for this option"
                  )}
                ></InfoPopup>
              ) : (
                ""
              )}
              <TitleLabel>{i18n.t(`Mini Omnium + BA + Legal aid`)}</TitleLabel>
              <PriceLabel>
                {!miniOmniumPrice ? (
                  <Error>{i18n.t("Not possible for this vehicle")}</Error>
                ) : (
                  `${i18n.t(`From`)} ${parseFloat(
                    miniOmniumPrice
                  ).toLocaleString("nl-BE", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })} EUR`
                )}
              </PriceLabel>
              {5 <= carAge && carAge <= 8 && miniOmniumPrice ? (
                <span>{i18n.t(`Recommended`)}</span>
              ) : null}
            </Label>
          ) : (
            ""
          )}
        </StyledDiv>
        <div>
          <FlowPriceCalculatorOptions
            carAge={carAge}
            subPrices={subPrices}
            onChange={(queryString) => retrievePrice(queryString)}
            selectedType={selectedType}
          />
        </div>
      </PriceCalculatorContainer>
      <CheckoutContainer>
        <TotalPrice>
          {i18n.t(`Total price`)}{" "}
          <span>
            {parseFloat(totalPremium).toLocaleString("nl-BE", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}{" "}
            EUR
          </span>
        </TotalPrice>
        <Button
          affinity={affinity}
          onClick={() => {
            checkSpecialRequirements();
          }}
        >
          {i18n.t(`Next`)}
        </Button>
      </CheckoutContainer>
      <FootNote>*{i18n.t(`All prices shown are taxes included`)}</FootNote>
    </SplitContainer>
  );
};

const Error = styled.p`
  font-size: 12pt;
`;

const CheckoutContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: flex-end;
  flex-direction: column;
`;

const TitleLabel = styled.p``;

const FootNote = styled.p`
  margin-left: auto;
  margin-top: auto;
  font-size: 10pt;
`;

const TotalPrice = styled.p`
  font-size: 2rem;
  margin-left: auto;
  padding-bottom: 2rem;
  > span {
    font-weight: 700;
  }
`;

const Title = styled.p`
  font-size: 2rem;
  margin-right: auto;
  padding-bottom: 2rem;
  font-weight: 700;
`;

const ModalTitle = styled.p`
  font-size: 2rem;
  margin-right: auto;
  padding-top: 2rem;
  padding-bottom: 2rem;
  font-weight: 700;
`;

const PriceLabel = styled.p`
  margin-top: 1rem;
  margin-bottom: 1rem;
  @media screen and (max-width: 900px) {
    margin-left: 1rem;
  }
`;

const Label = styled.label`
  padding: 1rem;
  transition: 0.2s;
  cursor: ${({ disable }) => (disable ? "not-allowed" : "pointer")};
  border: 2px solid
    ${({ checked, theme, affinity }) =>
      affinity === "callant"
        ? "#94C11A"
        : checked
        ? theme.brand.primary
        : "black"};
  text-align: center;
  background-color: ${({ checked, theme, disable }) =>
    disable ? "#CCCC" : checked ? theme.brand.lightest : "white"};
  color: ${({ checked, theme, affinity }) =>
    affinity === "callant" ? "black" : checked ? theme.brand.primary : "black"};
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  margin: auto;
  line-height: 3rem;
  font-weight: 700;
  font-size: 2rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  > span {
    font-size: 1.4rem;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 50%);
    bottom: 0;
    background-color: ${({ theme, affinity }) =>
      affinity === "callant" ? "#94C11A" : theme.brand.secondary};
    color: white;
    padding: 0.2rem 2rem;
    font-weight: 600;
    border-radius: 2rem;
  }
  a {
    bottom: 1rem;
    right: 1rem;
    position: absolute;
  }
  @media screen and (max-width: 900px) {
    flex-direction: row;
    font-size: 1.75rem;
    justify-content: space-between;
    > span {
      font-size: 1.2rem;
      line-height: 1.5rem;
    }
    p {
      font-size: 1.5rem;
      line-height: 2rem;
    }
  }
`;

const RadioInput = styled.input`
  display: none;
`;

const StyledDiv = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 2rem;

  @media screen and (max-width: 900px) {
    display: grid;
    grid-template-columns: unset;
    grid-column-gap: unset;
    grid-template-rows: repeat(3, 6rem);
    grid-row-gap: 2rem;
  }
`;

const PriceCalculatorContainer = styled.div`
  display: grid;
  grid-gap: 4.5rem;
  grid-template-rows: 12rem auto;
  height: 100%;
  @media screen and (max-width: 900px) {
    grid-template-rows: 19rem auto;
  }
`;

const LoadingText = styled.p`
  margin-top: 2rem;
  font-size: 2rem;
`;

const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Button = styled(ActionButton)`
  width: 20rem;
  margin-left: auto;
  margin-top: auto;
  background-color: ${({ affinity }) =>
    affinity === "callant" ? "#94C11A" : "default"};

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const SplitContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 3rem 4rem;
  /* height: -webkit-fill-available; */
  @media (max-width: 1140px) {
    justify-content: center;
  }
  @media screen and (max-width: 900px) {
    padding: 3rem 2rem;
  }
`;

export default FlowPriceCalculator;
