import * as Yup from "yup";
import i18n from "../i18n";

export const string = {
  required: Yup.string().required(i18n.t(`required`)),
  notRequired: Yup.string().nullable(),
};

export const number = {
  required: Yup.number()
    .typeError(i18n.t(`not a number`))
    .required(i18n.t(`required`)),
  notRequired: Yup.number()
    .nullable()
    .typeError(i18n.t(`not a number`)),
};

export const date = Yup.string()
  .matches(
    /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/,
    i18n.t(`not a valid date`)
  )
  .required(i18n.t(`required`));

export const dateNotReq = Yup.string()
  .matches(
    /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$|^$/,
    i18n.t(`not a valid date`)
  )
  .nullable()

export const averageKmPerYear = Yup.number()
  .typeError(i18n.t(`not a number`))
  .min(0, i18n.t(`Value cannot be 0`))
  .max(100001, i18n.t(`Value too high`))
  .nullable()
  .required(i18n.t(`required`));

export const dateWithOutYear = Yup.string()
  .matches(
    /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))$/,
    i18n.t(`not a valid date`)
  )
  .required(i18n.t(`required`));

export const firstName = Yup.string()
  .required(i18n.t(`required`))
  .min(1, i18n.t(`too short`))
  .max(50, i18n.t(`too long`));

export const lastName = Yup.string()
  .required(i18n.t(`required`))
  .min(1, i18n.t(`too short`))
  .max(50, i18n.t(`too long`));

export const companyName = Yup.string()
  .required(i18n.t(`required`))
  .matches(
    /^[0-9A-Za-zéèùàçâêîôûäëïöüÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ_& ]{1,50}$/,
    i18n.t(`incorrect format`)
  )
  .min(1, i18n.t(`too short`))
  .max(50, i18n.t(`too long`));

export const cbe = {
  required: Yup.string()
    .matches(/0[0-9]{3}.[0-9]{3}.[0-9]{3}/, i18n.t(`incorrect format`))
    .required(i18n.t(`required`)),
  notRequired: Yup.string()
    .nullable()
    .matches(/0[0-9]{3}.[0-9]{3}.[0-9]{3}/, i18n.t(`incorrect format`)),
};

export const email = Yup.string()
  .email(i18n.t(`email`))
  .required(i18n.t(`required`))
  .min(2, i18n.t(`too short`))
  .max(100, i18n.t(`too long`));

export const telephonenr = {
  required: Yup.string()
    .matches(
      /^[0-9+]{1,3}$|^\+32[0-9]{8,9}$/,
      i18n.t(`Please enter a valid telephone number`)
    )
    .required(i18n.t(`required`)),
  notRequired: Yup.string()
    .nullable()
    .matches(
      /^[0-9+]{1,3}$|^\+32[0-9]{8,9}$/,
      i18n.t(`Please enter a valid telephone number`)
    ),
};

export const password = Yup.string()
  .required(i18n.t(`required`))
  .min(2, i18n.t(`too short`))
  .max(100, i18n.t(`too long`));

export const year = {
  required: Yup.number()
    .typeError(i18n.t(`not a number`))
    .required(i18n.t(`required`))
    .min(1885, i18n.t(`car too old`))
    .max(2030, i18n.t(`car too young`)),
  notRequired: Yup.number()
    .nullable()
    .typeError(i18n.t(`not a number`))
    .min(1885, i18n.t(`car too old`))
    .max(2030, i18n.t(`car too young`)),
};

export const options = Yup.number()
  .nullable()
  .typeError(i18n.t(`not a number`))
  .max(2500, i18n.t(`too long`));

export const licensePlate = Yup.string()
  .nullable()
  .matches(/^$|^[A-Za-z0-9 -]{1,15}$/, i18n.t(`incorrect format`));

export const vinNumber = Yup.string()
  .min(11, i18n.t('Vin number must be between 11 and 17 character'))
  .max(17, i18n.t('Vin number must be between 11 and 17 character'))
  .nullable()
  .matches(/^$|^[A-Z0-9]{11,17}$/, i18n.t(`incorrect format`));

export const captcha = Yup.string(i18n.t(`required`))
  .matches(/[0-9a-zA-Z_-]{40}/)
  .nullable()
  .required(i18n.t(`required`));

export const website = Yup.string(i18n.t(`required`))
  .matches()
  .nullable()
  .required(i18n.t(`required`));

export const numberplate = Yup.string(i18n.t(`required`));

export const nationalRegisterNr = Yup.string(i18n.t(`required`))
  .matches(
    /^[0-9][0-9].[0-9][0-9].[0-9][0-9]-[0-9][0-9][0-9].[0-9][0-9]$|^[0-9][0-9].[0-9][0-9].[0-9][0-9]- {3}. {2}$|^ {2}. {2}. {2}- {3}. {2}$/,
    i18n.t(`incorrect format`)
  )
  .nullable();

export const street = Yup.string()
  .matches(
    /^[0-9A-Za-zéèùàçâêîôûäëïöüÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ\- '.&’]{1,150}$/,
    i18n.t(`incorrect format`)
  )
  .min(1, i18n.t(`too short`))
  .max(150, i18n.t(`too long`))
  .nullable()
  .required(i18n.t(`required`));

export const zipcode = Yup.number()
  .typeError(i18n.t(`not a number`))
  .min(1000, i18n.t(`too short`))
  .max(9999, i18n.t(`too long`))
  .nullable()
  .required(i18n.t(`required`));

export const housenr = Yup.number()
  .typeError(i18n.t(`not a number`))
  .min(1, i18n.t(`too short`))
  .max(99999999, i18n.t(`too long`))
  .nullable()
  .required(i18n.t(`required`));

export const boxNr = Yup.string()
  .matches(/[a-zA-Z0-9\- /]{1,20}/, i18n.t(`incorrect format`))
  .min(1, i18n.t(`too short`))
  .max(20, i18n.t(`too long`))
  .nullable();

export const city = Yup.string()
  .matches(
    /^[0-9A-Za-zéèùàçâêîôûäëïöüÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ\- '.&’]{1,150}$/,
    i18n.t(`incorrect format`)
  )
  .min(1, i18n.t(`too short`))
  .max(150, i18n.t(`too long`))
  .nullable()
  .required(i18n.t(`required`));

export const bonusMalus = Yup.number()
  .typeError(i18n.t(`not a number`))
  .min(-2, i18n.t(`bm too low`))
  .max(22, i18n.t(`bm too high`))
  .required(i18n.t(`required`));

export const vatRegime = Yup.number()
  .typeError(i18n.t(`not a number`))
  .min(0, i18n.t(`give percentage between 0 and 100`))
  .max(100, i18n.t(`give percentage between 0 and 100`))
  .required(i18n.t(`required`));

export const cylinder_capacity = Yup.number()
  .required(i18n.t(`required`))
  .typeError(i18n.t(`not a number`))
  .min(125, i18n.t(`Must be atleast 125 CC`))
  .nullable();

export const cylinder_capacity_light = Yup.number()
  .required(i18n.t(`required`))
  .typeError(i18n.t(`not a number`))
  .max(125, i18n.t(`Must be less than 125 CC`))
  .nullable();

export const seats = Yup.number()
  .typeError(i18n.t(`not a number`))
  .min(2, i18n.t(`minSeats`))
  .max(10, i18n.t(`maxSeats`))
  .nullable()
  .required(i18n.t(`required`));

export const catalogueValue = Yup.number()
  .typeError(i18n.t(`not a number`))
  .min(1000, i18n.t(`minCatValue`))
  .max(200000, i18n.t(`maxCatValue`))
  .nullable()
  .required(i18n.t(`required`));

export const catalogueValueBike = Yup.number()
  .typeError(i18n.t(`not a number`))
  .min(1000, i18n.t(`minCatValue`))
  .max(1000000, i18n.t(`maxCatValueBike`))
  .nullable()
  .required(i18n.t(`required`));

export const boxnr = Yup.string().nullable().max(4, i18n.t(`max 4 chars`));
  