import { setLoggedIn } from "./authService";
import { request } from "./httpSessionService";
import { DISTRIBUTION_ID } from "./brokerDataService";
import { getStartDateInIsoFormat } from "./dateService";
import i18n from "../i18n.js";

/* * * * * * * * * * * * * * *
 * AUTHENTICATION
 * * * * * * * * * * * * * * */
export async function authMe() {
  const path = "v1/api/aut/v1/api/auth/me";
  const [resp, status] = await request(path, "GET");
  if (status === 200) {
    setLoggedIn({
      email: resp.me.email,
      language: resp.me.language,
      role: resp.me.role,
    });
  } else {
    console.error(resp, status);
  }
  return [resp, status];
}

export async function auth(email, password) {
  const path = "v1/api/aut/v1/api/auth";
  const [resp, status] = await request(path, "POST", {
    email: email,
    password: password,
  });
  if (status === 200) {
    sessionStorage.setItem("access_token", resp?.access_token);
    sessionStorage.setItem("refresh_token", resp?.refresh_token);
  }
  return [resp, status];
}

export async function verifyInit() {
  /*
   * the verify method that is called on page load
   * it checks if you have cookies set.
   *
   * if there are expired cookies, renew them and go to dashboard
   * if no cookies found redirect to login page
   *
   */
  const path = "v1/api/aut/v1/api/auth/verify";
  const [resp, status] = await request(path, "GET");
  if (status === 401) {
    // This scenario is triggered due to the storage being set with the access token, but not fast enough, which is why it isn't sent along to this call at login
    if (resp.msg === "NOT_AUTHENTICATED") {
      return [{}, 200];
    } else {
      return [resp, status];
    }
  } else {
    return await authMe();
  }
}

export async function captchaAuth({
  captcha,
  email,
  firstName,
  lastName,
  askBrokerId,
  companyName,
}) {
  const path = "v1/api/aut/v1/api/auth";
  const payload = {
    ask_role: "social_login",
    captcha,
    captcha_user_information: {
      email: email,
      first_name: firstName,
      last_name: lastName,
      companyName: companyName,
    },
    ask_broker_id: askBrokerId,
  };
  const [resp, status] = await request(path, "POST", payload);

  if (status === 200) {
    sessionStorage.setItem("access_token", resp?.access_token);
    sessionStorage.setItem("refresh_token", resp?.refresh_token);
  }

  return [resp, status];
}

/* * * * * * * * * * * * * * *
 * BROKER DATA
 * * * * * * * * * * * * * * */

export async function retrieveBrokerById(id) {
  const path = `v1/api/broker/v1/api/broker/${id}`;
  const [resp, status] = await request(path, "GET");
  return [resp, status];
}

/* * * * * * * * * * * * * * *
 * CAR DATA
 * * * * * * * * * * * * * * */
export async function retrieveCarBrands(query) {
  const q = query ? `?${query}` : "";
  const path = `v1/api/car/v1/api/brands${q}`;
  const [resp, status] = await request(path, "GET");
  return [resp, status];
}

export async function retrieveCarModels(query, brand) {
  const q = query ? `?${query}` : "";
  const path = `v1/api/car/v1/api/models${q}`;
  const payload = { brand };
  const [resp, status] = await request(path, "POST", payload);
  return [resp, status];
}

export async function retrieveCarVerions(query, brand, model) {
  const q = query ? `?${query}` : "";
  const path = `v1/api/car/v1/api/versions${q}`;
  const payload = { brand, model };
  const [resp, status] = await request(path, "POST", payload);
  return [resp, status];
}

export async function retrieveCarInfo(brand, model, version, model_year, kw) {
  const path = `v1/api/car/v1/api/car`;
  const payload = { brand, model, version, model_year: model_year, kw: kw };
  const [resp, status] = await request(path, "POST", payload);
  return [resp, status];
}

export async function retrieveCarPrice(
  payload,
  affinity,
  insuranceType,
  insurance,
  queryStrings
) {
  console.log(payload, affinity, insuranceType, insurance, queryStrings);

  let affinityTag = affinity; // honda, valckenier, doceo, agoria, one_automotive
  if (affinity === "at-work") {
    affinityTag = "at_work";
  } else if (affinity === "de-lijn") {
    affinityTag = "de_lijn";
  } else if (["motor-insurance", "callant"].includes(affinity)) {
    affinityTag = "motor_insurance";
  }

  const query = queryStrings ? `?${queryStrings}` : "";
  const path = `v1/api/quotes/v1/api/quote/affinity/callant/${affinityTag}/${insuranceType}/${insurance}${query}`;
  const [resp, status] = await request(path, "POST", payload);
  return [resp, status];
}

export async function retrieveCarBrandsV2(category, query) {
  const q = query ? `q=${query}` : null;
  let cat = category ? `category=${category}` : null;
  const params =
    "?" + (query ? (category ? q + "&" + cat : q) : cat) + "&pagelen=500";
  const path = `v1/api/vehicle/v1/api/automobile/brands${params}`;
  const [resp, status] = await request(path, "GET");
  return [resp, status];
}

export async function retrieveCarModelsV2(category, brandKey, query) {
  const q = query ? `q=${query}` : null;
  let cat = category ? `category=${category}` : null;
  const params =
    "?" + (query ? (category ? q + "&" + cat : q) : cat) + "&pagelen=500";
  const path = `v1/api/vehicle/v1/api/automobile/brands/${brandKey.toLowerCase()}/models${params}`;
  const [resp, status] = await request(path, "GET");
  return [resp, status];
}

export async function retrieveCarVersionsV2(
  category,
  brandKey,
  modelKey,
  query
) {
  let cat = category ? `category=${category}` : null;
  const params =
    "?" +
    (query ? (category ? query + "&" + cat : query) : cat) +
    "&pagelen=500";
  const path = `v1/api/vehicle/v1/api/automobile/brands/${brandKey.toLowerCase()}/models/${modelKey}/versions${params}`;
  const [resp, status] = await request(path, "GET");
  return [resp, status];
}

export async function retrieveCarModelByIdV2(brandKey, modelKey, versionKey) {
  const path = `v1/api/vehicle/v1/api/automobile/brands/${brandKey.toLowerCase()}/models/${modelKey}/versions/${versionKey}`;
  const [resp, status] = await request(path, "GET");
  return [resp, status];
}

/* * * * * * * * * * * * * * *
 * CRM DATA
 * * * * * * * * * * * * * * */

export async function createLead(
  prospect,
  risk_objects,
  affinityName,
  acknowledgements,
  aborted,
  description,
  created_by,
  documents,
  consessionHolder
) {
  const prospectType = prospect.type;
  delete prospect.type;
  const payload = {
    origin: `Website - ${affinityName}`,
    risk_objects,
    prospect,
    status: aborted ? "REFUSED" : "PENDING",
    acknowledgements,
    language: i18n.language.toUpperCase(),
    created_by,
    lead_provider_id: consessionHolder
  };
  if (documents && documents.length > 0) payload.documents = [...documents];
  if (description) {
    payload.description = description;
  }
  const path = `v1/api/broker/v1/api/distributions/${DISTRIBUTION_ID}/leads/${prospectType}`;
  const [resp, status] = await request(path, "POST", payload);
  return [resp, status];
}

export async function createOffer(
  quoteId,
  guarantees,
  acknowledgements,
  commencementDate,
  endDate,
  prospect,
  risk_objects,
  affinityName,
  paymentSpread,
  description,
  created_by,
  send_mail_to_prospect,
  documents,
  consessionHolder
) {
  const prospectType = prospect.type;
  delete prospect.type;
  const payload = Object.assign({ risk_objects }, prospect);
  const path = `v1/api/broker/v1/api/distributions/${DISTRIBUTION_ID}/prospects/${prospectType}`;
  const [resp, status] = await request(path, "POST", payload);
  const prospectId = resp.id;

  // Parse the returned risk_object_revisions response
  const risk_object_revisions = {};
  Object.keys(resp.risk_object_revisions).forEach((cat) => {
    risk_object_revisions[cat] = resp.risk_object_revisions[cat].map((r) => { return r['risk_object_revision_id'] });
  });
  console.log('RISK OBJECT REVISIONS:', risk_object_revisions);

  if (status === 201) {
    const payload = {
      quote_id: quoteId,
      guarantees,
      acknowledgements,
      start_date: getStartDateInIsoFormat(),
      end_date: endDate,
      commencement_date: commencementDate,
      risk_object_revisions,
      description: description,
      origin: `Website - ${affinityName}`,
      send_mail_to_prospect,
      language: i18n.language.toUpperCase(),
      payment_spread: paymentSpread.toUpperCase(),
      created_by,
      lead_provider_id: consessionHolder
    };
    if (documents && documents.length > 0) payload.documents = [...documents];
    const path = `v1/api/broker/v1/api/distributions/${DISTRIBUTION_ID}/prospects/${prospectId}/offers`;
    const [resp, status] = await request(path, "POST", payload);
    return [resp, status];
  }
  return [resp, status];
}

export async function createSixWheelsOffer(
  quoteIdTwoWheeler,
  guaranteesTwoWheeler,
  quoteIdCar,
  guaranteesCar,
  acknowledgements,
  commencementDate,
  endDate,
  prospect,
  risk_objects,
  affinityName,
  paymentSpread,
  description,
  created_by,
  send_mail_to_prospect,
  documents,
  consessionHolder
) {
  const prospectType = prospect.type;
  delete prospect.type;
  const payload = Object.assign({ risk_objects }, prospect);
  const path = `v1/api/broker/v1/api/distributions/${DISTRIBUTION_ID}/prospects/${prospectType}`;
  const [resp, status] = await request(path, "POST", payload);
  const prospectId = resp.id;

  // Parse the returned risk_object_revisions response
  const risk_object_revisions = {};
  Object.keys(resp.risk_object_revisions).forEach((cat) => {
    risk_object_revisions[cat] = resp.risk_object_revisions[cat].map((r) => { return r['risk_object_revision_id'] });
  });
  console.log('RISK OBJECT REVISIONS:', risk_object_revisions);

  if (status === 201) {
    // Create Car offer
    const carRevisions = {
      cars: risk_object_revisions["cars"],
    };
    const payloadCar = {
      quote_id: quoteIdCar,
      guarantees: guaranteesCar,
      acknowledgements,
      start_date: getStartDateInIsoFormat(),
      end_date: endDate,
      commencement_date: commencementDate,
      risk_object_revisions: carRevisions,
      description: description,
      origin: `Website - ${affinityName}`,
      send_mail_to_prospect,
      language: i18n.language.toUpperCase(),
      payment_spread: paymentSpread.toUpperCase(),
      created_by,
      lead_provider_id: consessionHolder
    };
    const pathCar = `v1/api/broker/v1/api/distributions/${DISTRIBUTION_ID}/prospects/${prospectId}/offers`;
    const [respCar, statusCar] = await request(pathCar, "POST", payloadCar);
    // Create TwoWheeler offer
    const twoWheelerRevisions = {
      two_wheelers: risk_object_revisions["two_wheelers"],
    };
    const payloadTwoWheeler = {
      quote_id: quoteIdTwoWheeler,
      guarantees: guaranteesTwoWheeler,
      acknowledgements,
      start_date: getStartDateInIsoFormat(),
      end_date: endDate,
      commencement_date: commencementDate,
      risk_object_revisions: twoWheelerRevisions,
      description: description,
      origin: `Website - ${affinityName}`,
      send_mail_to_prospect,
      language: i18n.language.toUpperCase(),
      payment_spread: paymentSpread.toUpperCase(),
      created_by,
      lead_provider_id: consessionHolder
    };
    if (documents && documents.length > 0)
      payloadTwoWheeler.documents = [...documents];
    const pathTwoWheeler = `v1/api/broker/v1/api/distributions/${DISTRIBUTION_ID}/prospects/${prospectId}/offers`;
    const [respTwoWheeler, statusTwoWheeler] = await request(
      pathTwoWheeler,
      "POST",
      payloadTwoWheeler
    );
    if (statusTwoWheeler === statusCar) {
      return [respTwoWheeler, statusTwoWheeler];
    } else if (statusTwoWheeler > statusCar) {
      return [respTwoWheeler, statusTwoWheeler];
    } else {
      return [respCar, statusCar];
    }
  }
  return [resp, status];
}

export async function uploadFileToOffer(offerId, formData) {
  const path = `v1/api/broker/v1/api/distributions/${DISTRIBUTION_ID}/offers/${offerId}/upload/document`;
  const [resp, status] = await request(path, "POST", formData);
  return [resp, status];
}

export async function uploadFileToBucket(_formData, _link) {
  const [resp, status] = await request(null, "PUT", _formData, _link);
  return [resp, status];
}

export async function createUploadSignedUrl(_distributionId, _type, _fileName) {
  const path = `v1/api/broker/v1/api/distributions/${DISTRIBUTION_ID}/uploads?resource_type=${_type}`;
  const [resp, status] = await request(path, "POST", {
    filename: _fileName,
  });
  return [resp, status];
}

/* * * * * * * * * * * * * * *
 * TWO-WHEELER DATA
 * * * * * * * * * * * * * * */

export async function retrieveBikeBrands(category, query) {
  const q = query ? `q=${query}` : null;
  let cat = category ? `category=${category}` : null;
  if (category === "LIGHT_SCOOTER") {
    cat += `&category=MOPED`;
  }
  const params = "?" + (query ? (category ? q + "&" + cat : q) : cat);
  const path = `v1/api/vehicle/v1/api/bikes/brands${params}`;
  const [resp, status] = await request(path, "GET");
  return [resp, status];
}

export async function retrieveBikeSeries(category, brandKey, query) {
  const q = query ? `q=${query}` : null;
  let cat = category ? `category=${category}` : null;
  if (category === "LIGHT_SCOOTER") {
    cat += `&category=MOPED`;
  }
  const params =
    "?" + (query ? (category ? q + "&" + cat : q) : cat) + "&pagelen=100";
  const path = `v1/api/vehicle/v1/api/bikes/brands/${brandKey.toLowerCase()}/series${params}`;
  const [resp, status] = await request(path, "GET");
  return [resp, status];
}

export async function retrieveBikeModels(category, brandKey, seriesKey, query) {
  const q = query ? `q=${query}` : null;
  let cat = category ? `category=${category}` : null;
  if (category === "LIGHT_SCOOTER") {
    cat += `&category=MOPED`;
  }
  const params =
    "?" + (query ? (category ? q + "&" + cat : q) : cat) + "&pagelen=100";
  const path = `v1/api/vehicle/v1/api/bikes/brands/${brandKey.toLowerCase()}/series/${seriesKey}/models${params}`;
  const [resp, status] = await request(path, "GET");
  return [resp, status];
}

export async function retrieveBikeModelById(
  category,
  brandKey,
  seriesKey,
  modelKey
) {
  const path = `v1/api/vehicle/v1/api/bikes/brands/${brandKey.toLowerCase()}/series/${seriesKey}/models/${modelKey}`;
  const [resp, status] = await request(path, "GET");
  return [resp, status];
}

export async function retrieveLegalPrice(
  payload,
  affinity,
  insurance,
  queryStrings
) {
  let affinityTag = affinity; // honda, valckenier, doceo, agoria
  if (affinity === "at-work") {
    affinityTag = "at_work";
  } else if (affinity === "de-lijn") {
    affinityTag = "de_lijn";
  } else if (["motor-insurance", "callant"].includes(affinity)) {
    // This situation will normally never occur due to front-end websites not allowing this flow
    affinityTag = "at_work";
  }

  const query = queryStrings ? `?${queryStrings}` : "";
  const path = `v1/api/quotes/v1/api/quote/affinity/callant/${affinityTag}/legal/${insurance.toLowerCase()}${query}`;
  const [resp, status] = await request(path, "POST", payload);
  return [resp, status];
}

export async function retrieveBikePrice(
  payload,
  affinity,
  insurance,
  queryStrings
) {
  let affinityTag = affinity; // honda, valckenier, doceo, agoria
  if (affinity === "at-work") {
    affinityTag = "at_work";
  } else if (affinity === "de-lijn") {
    affinityTag = "de_lijn";
  } else if (["motor-insurance", "callant"].includes(affinity)) {
    affinityTag = "motor_insurance";
  }

  const query = queryStrings ? `?${queryStrings}` : "";
  const path = `v1/api/quotes/v1/api/quote/affinity/callant/${affinityTag}/two_wheeler/${insurance}${query}`;
  const [resp, status] = await request(path, "POST", payload);
  return [resp, status];
}

export async function retrieveTeacherPrice(payload, affinity, insurance) {
  let affinityTag = affinity; // honda, valckenier, doceo, agoria
  if (affinity === "at-work") {
    affinityTag = "at_work";
  } else if (affinity === "de-lijn") {
    affinityTag = "de_lijn";
  } else if (["motor-insurance", "callant"].includes(affinity)) {
    affinityTag = "motor_insurance";
  }

  const path = `v1/api/quotes/v1/api/quote/affinity/callant/${affinityTag}/${insurance}/pack`;
  const [resp, status] = await request(path, "POST", payload);
  return [resp, status];
}

/* * * * * * * * * * * * * * *
 * ADDRESS
 * * * * * * * * * * * * * * */

export async function retrieveAddress(payload) {
  const path = `v1/api/address/v1/api/search?q=${payload}&language_code=${i18n.language.toUpperCase()}`;
  const [resp, status] = await request(path, "GET");
  return [resp, status];
}

export async function retrieveAddressInfo(payload) {
  const path = `v1/api/address/v1/api/info`;
  const [resp, status] = await request(path, "POST", payload);
  return [resp, status];
}

export async function retrieveAddressStreets(payload) {
  let path;
  if (payload.searchQuery) {
    path = `v1/api/address/v1/api/streets?q=${payload.searchQuery}&q_city=${payload.city}`;
  } else if (payload.city) {
    path = `v1/api/address/v1/api/streets?q_city=${payload.city}`;
  }
  const [resp, status] = await request(path, "GET");
  return [resp, status];
}

export async function retrieveAddressCities(payload) {
  let path;
  if (payload.zipcode && !payload.searchQuery) {
    path = `v1/api/address/v1/api/cities?zipcode=${payload.zipcode}`;
  } else if (payload.searchQuery && !payload.zipcode) {
    path = `v1/api/address/v1/api/cities?q=${payload.searchQuery}`;
  }
  const [resp, status] = await request(path, "GET");
  return [resp, status];
}

/* * * * * * * * * * * * * * *
 * POLICY
 * * * * * * * * * * * * * * */

export async function retrieveSignature(payload) {
  const path = `v1/api/policy/v1/api/purchase?lang=${i18n.language}`;
  const [resp, status] = await request(path, "POST", payload);
  return [resp, status];
}

/* * * * * * * * * * * * * * *
 * INSURERS
 * * * * * * * * * * * * * * */

export async function retrieveInsurers() {
  const path = `v1/api/marketplace/v1/api/retrieve_insurance_companies`;
  const [resp, status] = await request(path, "GET");
  return [resp, status];
}

/* * * * * * * * * * * * * * *
 * DOCGEN
 * * * * * * * * * * * * * * */

export async function generateCallantProposition(affinity, payload) {
  const path = `v1/api/docgen/v1/api/callant/${affinity}/proposition`;
  const [resp, status] = await request(path, "POST", payload);
  return [resp, status];
}

export async function getDocumentById(id) {
  const path = `v1/api/docgen/v1/api/documents/${id}`;
  const [resp, status] = await request(path, "GET");
  return [resp, status];
}

/* * * * * * * * * * * * * * *
 * MAILER
 * * * * * * * * * * * * * * */

export async function sendAffinityMail(payload) {
  const path = `v1/api/mailer/v1/api/mail/affinities/callant/proposition`;
  const [resp, status] = await request(path, "POST", payload);
  return [resp, status];
}

/* * * * * * * * * * * * * * *
 * VALIDATE
 * * * * * * * * * * * * * * */

export async function checkIfCBEIsValid(_cbe) {
  const path = `v1/api/broker/v1/api/validate/cbe`;
  const [resp, status] = await request(path, "POST", { cbe: _cbe });
  return [resp, status];
}

/* * * * * * * * * * * * * * *
 * QUOTES
 * * * * * * * * * * * * * * */

export async function retrievePrice(payload, insuranceDict) {
  // const query = queryStrings ? `?${queryStrings}` : "";
  let query = "";
  if (insuranceDict?.options && insuranceDict?.options.length > 0) {
    query = `?option=${insuranceDict.options.join("?option=")}`;
  }
  const path = `v1/api/quotes/v1/api/quote/affinity/callant/${insuranceDict?.affinity}/${insuranceDict?.type}/${insuranceDict?.name}${query}`;
  const [resp, status] = await request(path, "POST", payload);
  return [resp, status];
}
