/* eslint-disable no-unexpected-multiline */
/* eslint-disable no-extra-boolean-cast */
import React from "react";
import { getBrandData } from "../helpers/tagRouterService";
import styled from "styled-components";
import i18n from "../i18n";
import { useParams } from "react-router";
import { retrieveStorageById } from "../helpers/storeService.js";

const FlowOverviewDriversInformation = ({ storageData }) => {
  const { id, affinity, insuranceType } = useParams();
  const { data } = retrieveStorageById(id, affinity, insuranceType) || {};

  function capitalizeFirstLetter(string) {
    if (!string) return string;
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }
  console.log(affinity, insuranceType);

  // var myObj = {
  //   style: "currency",
  //   currency: "EUR",
  // };

  return (
    <DriversInformation>
      <DriverInfoWrapper>
        {["Callant", "Agoria"].includes(getBrandData().name) ? (
          <WideLogo src={getBrandData().logo} alt="" />
        ) : (
          <Logo src={getBrandData().logo} alt="" />
        )}
        <DriverInfoHeader>{i18n.t("Policyholder")}</DriverInfoHeader>
        {data?.policyHolder?.personType === "natural" ? (
          <DriverInfoListItem>
            <DriverInfoLabel>{i18n.t("Name")}</DriverInfoLabel>
            {capitalizeFirstLetter(data?.policyHolder?.firstName)}{" "}
            {capitalizeFirstLetter(data?.policyHolder?.lastName)}
          </DriverInfoListItem>
        ) : (
          ""
        )}
        {data?.policyHolder?.personType === "legal" ? (
          <DriverInfoListItem>
            <DriverInfoLabel>{i18n.t("Company name")}</DriverInfoLabel>
            {data?.policyHolder?.companyName}{" "}
          </DriverInfoListItem>
        ) : (
          ""
        )}
        {insuranceType === "teacher" || insuranceType === "legal" ? (
          <>
            <DriverInfoListItem>
              <DriverInfoLabel>{i18n.t("Address line 1")}</DriverInfoLabel>
              {data?.policyHolder?.address.streetName}{" "}
              {data?.policyHolder?.address.streetNumber}
              {data?.policyHolder?.address.boxNumber
                ? "/" + data?.policyHolder?.address.boxNumber
                : ""}
            </DriverInfoListItem>
            <DriverInfoListItem>
              <DriverInfoLabel>{i18n.t("Address line 2")}</DriverInfoLabel>
              {data?.policyHolder?.address.postalCode}{" "}
              {data?.policyHolder?.address.municipalityName}
            </DriverInfoListItem>
          </>
        ) : (
          ""
        )}
        {data?.policyHolder?.personType === "legal" ? (
          data.policyHolder.cbe != null ? (
            <DriverInfoListItem>
              <DriverInfoLabel>{i18n.t("CBE")}</DriverInfoLabel>
              {data?.policyHolder.cbe}
            </DriverInfoListItem>
          ) : (
            ""
          )
        ) : (
          <DriverInfoListItem>
            <DriverInfoLabel>{i18n.t("Date of birth")}</DriverInfoLabel>
            {data?.policyHolder?.birth}
          </DriverInfoListItem>
        )}
        {data?.policyHolder?.email ? (
          <DriverInfoListItem>
            <DriverInfoLabel>{i18n.t("Email")}</DriverInfoLabel>
            {data?.policyHolder?.email}
          </DriverInfoListItem>
        ) : (
          ""
        )}
        {insuranceType === "teacher" ? (
          <>
            <DriverInfoHeader>{i18n.t("Data risk")}</DriverInfoHeader>
            <DriverInfoListItem>
              <DriverInfoLabel>{i18n.t("Family type")}</DriverInfoLabel>
              {i18n.t(data.teacher_values.family_type)}
            </DriverInfoListItem>
            <DriverInfoListItem>
              <DriverInfoLabel>
                {i18n.t("Use of heavy materials")}
              </DriverInfoLabel>
              {i18n.t(data.teacher_values.teacher_type)}
            </DriverInfoListItem>
          </>
        ) : insuranceType === "legal" ? (
          <>
            <DriverInfoHeader>{i18n.t("Selected package")}</DriverInfoHeader>
            <DriverInfoListItem>
              <DriverInfoLabel>{i18n.t("Type")}</DriverInfoLabel>
              {i18n.t(data.insurance_pack)}
            </DriverInfoListItem>
            <DriverInfoListItem>
              <DriverInfoLabel>{i18n.t("Web@ctive warranty")}</DriverInfoLabel>
              {data.priceInformation.webActiveSelected === true
                ? i18n.t("yes")
                : i18n.t("no")}
            </DriverInfoListItem>
            <DriverInfoListItem>
              <DriverInfoLabel>{i18n.t("Total premium")}</DriverInfoLabel>
              {parseFloat(data.priceInformation.totalPremium).toLocaleString(
                "nl-BE",
                {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }
              )}{" "}
              EUR
            </DriverInfoListItem>
          </>
        ) : (
          ""
        )}
        {insuranceType === "car" ||
        (insuranceType === "6-wheeler" &&
          ["MOTOR_ALREADY_INSURED", "6WHEELS"].includes(
            data?.selectedFormula
          )) ? (
          <>
            <DriverInfoHeader>
              {i18n.t("Main driver")} - {i18n.t("car")}
            </DriverInfoHeader>
            <DriverInfoListItem>
              <DriverInfoLabel>{i18n.t("Name")}</DriverInfoLabel>
              {capitalizeFirstLetter(data?.car?.mainDriver?.firstName)}{" "}
              {capitalizeFirstLetter(data?.car?.mainDriver?.lastName)}
            </DriverInfoListItem>
            <DriverInfoListItem>
              <DriverInfoLabel>{i18n.t("Date of birth")}</DriverInfoLabel>
              {data?.car?.mainDriver?.birth}
            </DriverInfoListItem>
            <DriverInfoListItem>
              <DriverInfoLabel>{i18n.t("License type")}</DriverInfoLabel>
              {i18n.t(data?.car?.mainDriver?.driverLicenseType)}
            </DriverInfoListItem>
            <DriverInfoListItem>
              <DriverInfoLabel>{i18n.t("License issue date")}</DriverInfoLabel>
              {data?.car?.mainDriver?.issueLicenseDate}
            </DriverInfoListItem>
            <DriverInfoListItem>
              <DriverInfoLabel>{i18n.t("Bonus malus")}</DriverInfoLabel>
              {data?.car?.mainDriver?.bonusMalus}
            </DriverInfoListItem>
            {data?.car?.mainDriver?.additionalDrivers &&
            data?.car?.mainDriver?.additionalDrivers.length > 0 ? (
              <>
                <DriverInfoHeader>
                  {i18n.t("Additional drivers")}
                </DriverInfoHeader>
                {data?.car?.mainDriver?.additionalDrivers.map((values, key) => {
                  return (
                    <div key={key}>
                      <DriverInfoListItem>
                        <DriverInfoLabel>
                          {i18n.t("Driver")} {key + 1}
                        </DriverInfoLabel>
                        {values.extraDriverName} {values.extraDriverLastName}
                      </DriverInfoListItem>
                    </div>
                  );
                })}
              </>
            ) : (
              ""
            )}
            <DriverInfoHeader>
              {i18n.t("Vehicle information")} - {i18n.t("car")}
            </DriverInfoHeader>
            <DriverInfoListItem>
              <DriverInfoLabel>{i18n.t("Brand")}</DriverInfoLabel>
              {data?.car?.details?.brand}
            </DriverInfoListItem>
            <DriverInfoListItem>
              <DriverInfoLabel>{i18n.t("Model")}</DriverInfoLabel>
              {data?.car?.details?.model}
            </DriverInfoListItem>
            <DriverInfoListItem>
              <DriverInfoLabel>{i18n.t("Power")}</DriverInfoLabel>
              {data?.car?.details?.kw} {i18n.t("KW")}
            </DriverInfoListItem>
            <DriverInfoListItem>
              <DriverInfoLabel>{i18n.t("Invoice value")}</DriverInfoLabel>
              {parseFloat(data?.car?.details?.catalogueValue).toLocaleString(
                "nl-BE",
                {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }
              )}{" "}
              EUR ex.BTW
            </DriverInfoListItem>
            {/* {data?.car?.details?.vinNumber ? (
              <DriverInfoListItem>
                <DriverInfoLabel>{i18n.t("VIN")}</DriverInfoLabel>
                {data?.car?.details?.vinNumber}
              </DriverInfoListItem>
            ) : (
              ""
            )} */}
            {/* {data?.car?.details?.licensePlate ? (
              <DriverInfoListItem>
                <DriverInfoLabel>{i18n.t("License plate")}</DriverInfoLabel>
                {data?.car?.details?.licensePlate}
              </DriverInfoListItem>
            ) : (
              ""
            )} */}
          </>
        ) : (
          ""
        )}
        {insuranceType === "two-wheeler" ||
        (insuranceType === "6-wheeler" &&
          ["MOTOR_COMPANY_CAR", "CAR_ALREADY_INSURED", "6WHEELS"].includes(
            data?.selectedFormula
          )) ? (
          <>
            <DriverInfoHeader>
              {i18n.t("Main driver")} - {i18n.t("moto")}
            </DriverInfoHeader>
            <DriverInfoListItem>
              <DriverInfoLabel>{i18n.t("Name")}</DriverInfoLabel>
              {capitalizeFirstLetter(
                data?.twoWheeler?.mainDriver?.firstName
              )}{" "}
              {capitalizeFirstLetter(data?.twoWheeler?.mainDriver?.lastName)}
            </DriverInfoListItem>
            <DriverInfoListItem>
              <DriverInfoLabel>{i18n.t("Date of birth")}</DriverInfoLabel>
              {data?.twoWheeler?.mainDriver?.birth}
            </DriverInfoListItem>
            <DriverInfoListItem>
              <DriverInfoLabel>{i18n.t("License type")}</DriverInfoLabel>
              {i18n.t(data?.twoWheeler?.mainDriver?.driverLicenseType)}
            </DriverInfoListItem>
            {data?.twoWheeler?.mainDriver?.driverLicenseType !== "NO_LICENSE" ? 
              (<DriverInfoListItem>
                <DriverInfoLabel>{i18n.t("License issue date")}</DriverInfoLabel>
                {data?.twoWheeler?.mainDriver?.issueLicenseDate}
              </DriverInfoListItem>) : ''
            }
            {data?.twoWheeler?.mainDriver?.additionalDrivers &&
            data?.twoWheeler?.mainDriver?.additionalDrivers.length > 0 ? (
              <>
                <DriverInfoHeader>
                  {i18n.t("Additional drivers")}
                </DriverInfoHeader>
                {data?.twoWheeler?.mainDriver?.additionalDrivers.map(
                  (values, key) => {
                    return (
                      <div key={key}>
                        <DriverInfoListItem>
                          <DriverInfoLabel>
                            {i18n.t("Driver")} {key + 1}
                          </DriverInfoLabel>
                          {values.extraDriverName} {values.extraDriverLastName}
                        </DriverInfoListItem>
                      </div>
                    );
                  }
                )}
              </>
            ) : (
              ""
            )}
            <DriverInfoHeader>
              {i18n.t("Vehicle information")} - {i18n.t("moto")}
            </DriverInfoHeader>
            <DriverInfoListItem>
              <DriverInfoLabel>{i18n.t("Brand")}</DriverInfoLabel>
              {data?.twoWheeler?.details?.brand}
            </DriverInfoListItem>
            <DriverInfoListItem>
              <DriverInfoLabel>{i18n.t("Model")}</DriverInfoLabel>
              {data?.twoWheeler?.details?.model}
            </DriverInfoListItem>
            <DriverInfoListItem>
              <DriverInfoLabel>{i18n.t("Power")}</DriverInfoLabel>
              {data?.twoWheeler?.details?.horsepower} {i18n.t("HP")}
            </DriverInfoListItem>
            <DriverInfoListItem>
              <DriverInfoLabel>{i18n.t("Cylinder capacity")}</DriverInfoLabel>
              {data?.twoWheeler?.details?.cc} CC
            </DriverInfoListItem>
            {["MOTORCYCLE", "HEAVY_SCOOTER"].includes(data?.twoWheeler?.details?.vehicleType) ? 
              (<DriverInfoListItem>
                <DriverInfoLabel>{i18n.t("Invoice value")}</DriverInfoLabel>
                {data?.twoWheeler?.details?.catalogueValue
                  ? parseFloat(
                      data?.twoWheeler?.details?.catalogueValue
                    ).toLocaleString("nl-BE", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : "--"}{" "}
                EUR ex.BTW
              </DriverInfoListItem>) : ''
            }
            {/* {data?.twoWheeler?.details?.vinNumber ? (
              <DriverInfoListItem>
                <DriverInfoLabel>{i18n.t("VIN")}</DriverInfoLabel>
                {data?.twoWheeler?.details?.vinNumber}
              </DriverInfoListItem>
            ) : (
              ""
            )}
            {data?.twoWheeler?.details?.licensePlate ? (
              <DriverInfoListItem>
                <DriverInfoLabel>{i18n.t("License plate")}</DriverInfoLabel>
                {data?.twoWheeler?.details?.licensePlate}
              </DriverInfoListItem>
            ) : (
              ""
            )} */}
          </>
        ) : (
          ""
        )}
      </DriverInfoWrapper>
    </DriversInformation>
  );
};

const Logo = styled.img`
  width: 30rem;
  max-width: 30rem;
  max-height: 20rem;
  margin: auto;
  object-fit: contain;
  padding: 1rem;
`;
const WideLogo = styled.img`
  width: 85%;
  margin: auto;
`;

const DriversInformation = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const DriverInfoHeader = styled.li`
  font-size: 18px;
  font-weight: bold;
  padding: 1rem 0;
  padding-top: 3rem;
  text-align: center;
  text-transform: uppercase;
`;
const DriverInfoWrapper = styled.ul`
  display: grid;
  width: 80%;
  grid-gap: 0.45rem;
  align-self: center;
  h1 {
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;
    font-size: 1.3rem;
  }
  @media screen and (max-width: 769px) {
    width: 93%;
  }
`;

const DriverInfoLabel = styled.label`
  text-align: left;
`;

const DriverInfoListItem = styled.li`
  display: grid;
  grid-template-columns: 14rem auto;
  text-align: right;
  padding: 0.5rem 0;
  word-break: break-word;
  @media (max-width: 570px) {
    font-size: 14px;
  }
`;

export default FlowOverviewDriversInformation;
