import React, { useReducer, useEffect, useState } from "react";
import { LoadingSpinner, ActionButton } from "wg-fe-ui";
import styled from "styled-components";
import { useParams } from "react-router";
import FlowPriceCalculatorOptionTeacher from "../components/FlowPriceCalculatorOptionTeacher";
import FlowLeftSideAvatar from "../components/FlowLeftSideAvatar.jsx";
import {
  getTeacherObject,
  getFamilyObject,
} from "../helpers/riskObjectService";
import { patchStorage, retrieveStorageById } from "../helpers/storeService";
import useRouting from "../hooks/useRouting";
import i18n from "../i18n";
import { retrieveTeacherPrice } from "../helpers/apiRouterService";
import { getNestedObject } from "../helpers/objectService";
import { getProspectObjectPyModels } from "../helpers/prospectObjectService";

const FlowPriceBikeLightScooterCalculator = () => {
  const { id, affinity, insuranceType } = useParams();

  const { data } = retrieveStorageById(id, affinity, insuranceType);
  const [loading, setLoading] = useState(true);

  // const [BikeAge, setBikeAge] = useState(0);
  const [, setCivilLiabilityPrice] = useState("");
  const [totalPremium, setTotalPremium] = useState("");
  const [quoteId, setQuoteId] = useState(null);
  const [quoteResp, setQuoteResp] = useState(null);
  const { renderNextRoute } = useRouting();
  const [teacherPrices, setTeacherPrices] = useState();

  function handleSubmit() {
    patchStorage({
      payload: { priceInformation: { quoteId, quoteResp } },
      id,
      affinity,
      insuranceType,
    });
    renderNextRoute();
  }

  useEffect(() => {
    calculateBasePrices();
  }, []);

  async function calculateBasePrices() {
    const civilLiabilityResp = await callTeacherPrice();
    const { premium, insurance, quote, id } = civilLiabilityResp || {};
    setCivilLiabilityPrice(
      `€ ${getNestedObject(civilLiabilityResp, ["premium", "total_premium"])}`
    );
    setTotalPremium(
      getNestedObject(civilLiabilityResp, ["premium", "total_premium"])
    );
    setQuoteId(id);
    const guaranteesChosen = [quote?.base?.name].concat(insurance?.options || []);
    setQuoteResp({ insurance, quote, premium, guaranteesChosen });
    setTeacherPrices(civilLiabilityResp);
    await setLoading(false);
  }

  // var myObj = {
  //   style: "currency",
  //   currency: "EUR",
  // };

  async function callTeacherPrice() {
    const payload = {
      teacher: getTeacherObject(data),
      family: getFamilyObject(data),
      policy_holder: getProspectObjectPyModels(data),
      quote_specifications: {
        currency: "EUR",
        payment_interval: "annual",
        save_quote: true,
      },
    };
    const [resp, status] = await retrieveTeacherPrice(
      payload,
      affinity,
      insuranceType
    );

    if ((await status) === 200);
    else return {};

    return resp;
  }

  function handleOptionChange(name, status) {
    if (status) {
      dispatch({ type: "add", value: name });
    } else {
      dispatch({ type: "remove", value: name });
    }
  }

  const [, dispatch] = useReducer((arr, { type, value }) => {
    switch (type) {
      case "add":
        return [...arr, value];
      case "remove":
        return arr.filter((item) => item !== value);
      default:
        return arr;
    }
  }, []);

  function handleSubPrices(name) {
    if (name === "civil_liability") {
      return getNestedObject(teacherPrices, ["quote", "base", "total_premium"]);
    }

    const options = getNestedObject(teacherPrices, ["quote", "options"]) || [];
    const currentOption = options.filter((item) => item.name === name)[0] || {};
    return currentOption.total_premium || 0;
  }

  const docsData = {
    "family": {
      "terms": {
        "nl": "https://docs.callant.eu/affinity/Doceo/AV_Callant Doceo Pack_NL.pdf",
        "fr": "https://docs.callant.eu/affinity/Doceo/CG_Callant Doceo Pack_FR.pdf",
      },
      "ipid": {
        "nl": "",
        "fr": "",
      }
    },
    "civil_liability": {
      "terms": {
        "nl": "https://docs.callant.eu/affinity/Doceo/AV_Callant Doceo Pack_NL.pdf",
        "fr": "https://docs.callant.eu/affinity/Doceo/CG_Callant Doceo Pack_FR.pdf",
      },
      "ipid": {
        "nl": "",
        "fr": "",
      }
    },
    "legal": {
      "terms": {
        "nl": "https://docs.callant.eu/affinity/Doceo/AV_Callant Doceo Pack_NL.pdf",
        "fr": "https://docs.callant.eu/affinity/Doceo/CG_Callant Doceo Pack_FR.pdf",
      },
      "ipid": {
        "nl": "",
        "fr": "",
      }
    },
    "bodily_injury_after_assault": {
      "terms": {
        "nl": "https://docs.callant.eu/affinity/Doceo/AV_Callant Doceo Pack_NL.pdf",
        "fr": "https://docs.callant.eu/affinity/Doceo/CG_Callant Doceo Pack_FR.pdf",
      },
      "ipid": {
        "nl": "",
        "fr": "",
      }
    },
    "teaching_equipment": {
      "terms": {
        "nl": "https://docs.callant.eu/affinity/Doceo/AV_Callant Doceo Pack_NL.pdf",
        "fr": "https://docs.callant.eu/affinity/Doceo/CG_Callant Doceo Pack_FR.pdf",
      },
      "ipid": {
        "nl": "",
        "fr": "",
      }
    },
  }
  let ipidLink = docsData?.[affinity]?.ipid?.[i18n.language];
  let termsLink = docsData?.[affinity]?.terms?.[i18n.language];
  if (!ipidLink || !termsLink) {
    ipidLink = docsData?.callant?.ipid?.[i18n.language];
    termsLink = docsData?.callant?.terms?.[i18n.language];
  }

  if (loading) {
    return (
      <LoadingContainer>
        <LoadingSpinner />
        <LoadingText>{i18n.t("Please wait")}</LoadingText>
      </LoadingContainer>
    );
  }
  return (
    <SplitContainer>
      <FlowLeftSideAvatar
        chatText={i18n.t("chat_message_tarrification_doceo")}
      />
      <RightSplit>
        <>
          <Title>{i18n.t("What guarantee do you wish to take?")}</Title>

          <PriceCalculatorContainer>
            <FlowPriceCalculatorOptionTeacher
              id="family"
              key="family"
              title={i18n.t("Civil liability private life")}
              ipidLink={docsData?.family?.ipid?.[i18n.language]}
              termsLink={docsData?.family?.terms?.[i18n.language]}
              onChange={handleOptionChange}
              mandatory={true}
              price={handleSubPrices("family")}
              helperText={i18n.t("civil_liability_description_doceo")}
            />
            <FlowPriceCalculatorOptionTeacher
              id="civil_liability"
              key="civil_liability"
              title={i18n.t("Civil liability teacher")}
              ipidLink={docsData?.civil_liability?.ipid?.[i18n.language]}
              termsLink={docsData?.civil_liability?.terms?.[i18n.language]}
              onChange={handleOptionChange}
              mandatory={true}
              price={handleSubPrices("civil_liability")}
              helperText={i18n.t("civil_liability_teacher_description_doceo")}
            />
            <FlowPriceCalculatorOptionTeacher
              id="legal"
              key="legal"
              title={i18n.t("Legal aid")}
              ipidLink={docsData?.legal?.ipid?.[i18n.language]}
              termsLink={docsData?.legal?.terms?.[i18n.language]}
              onChange={handleOptionChange}
              mandatory={true}
              price={handleSubPrices("legal")}
              helperText={i18n.t("legal_aid_description_doceo")}
            />
            <FlowPriceCalculatorOptionTeacher
              id="bodily_injury_after_assault"
              key="bodily_injury_after_assault"
              title={i18n.t("injury after assault")}
              ipidLink={docsData?.bodily_injury_after_assault?.ipid?.[i18n.language]}
              termsLink={docsData?.bodily_injury_after_assault?.terms?.[i18n.language]}
              onChange={handleOptionChange}
              mandatory={true}
              price={handleSubPrices("bodily_injury_after_assault")}
              helperText={i18n.t("bodily_injury_after_assault_doceo")}
            />
            <FlowPriceCalculatorOptionTeacher
              id="teaching_equipment"
              key="teaching_equipment"
              title={i18n.t("teacher equipment")}
              ipidLink={docsData?.teaching_equipment?.ipid?.[i18n.language]}
              termsLink={docsData?.teaching_equipment?.terms?.[i18n.language]}
              onChange={handleOptionChange}
              mandatory={true}
              price={handleSubPrices("teaching_equipment")}
              helperText={i18n.t("teaching_equipment_description_doceo")}
            />
          </PriceCalculatorContainer>
          <CheckoutContainer>
            <TotalPrice>
              {i18n.t(`Total price`)}{" "}
              <span>
                {parseFloat(totalPremium).toLocaleString("nl-BE", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}{" "}
                EUR/
                {i18n.t("y")}
              </span>
            </TotalPrice>
            <Button
              onClick={() => {
                handleSubmit();
              }}
            >
              {i18n.t(`Next`)}
            </Button>
          </CheckoutContainer>
        </>
      </RightSplit>
    </SplitContainer>
  );
};

const Title = styled.h1`
  font-weight: 900;
  font-size: 1.65rem;
  letter-spacing: 1.19px;
  color: black;
  margin-bottom: 2rem;
`;

const CheckoutContainer = styled.div`
  display: flex;
`;

const TotalPrice = styled.p`
  font-size: 2rem;
  align-self: center;
  > span {
    font-weight: 700;
  }
`;

const PriceCalculatorContainer = styled.div`
  display: flex;
  flex-direction: column;
  div {
    margin-bottom: 1.5rem;
  }
`;

const LoadingText = styled.p`
  margin-top: 2rem;
  font-size: 2rem;
`;

const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Button = styled(ActionButton)`
  width: 20rem;
  margin-left: auto;
  margin-top: auto;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const SplitContainer = styled.div`
  display: flex;
  height: 100%;
`;

const RightSplit = styled.div`
  width: 50%;
  padding: 5rem 4rem;
  overflow-y: auto;
  /* height: 100%; */

  @media (max-width: 768px) {
    width: 100%;
  }

  @media screen and (max-width: 900px) {
    padding: 3rem 2rem;
  }
  @media (max-width: 1140px) {
    justify-content: center;
  }
`;

export default FlowPriceBikeLightScooterCalculator;
