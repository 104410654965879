import React, { useState } from "react";
import EyeIcon from '../assets/icons/eye-icon';
import CrossedEyeIcon from '../assets/icons/crossed-eye-icon';
import styled, { keyframes } from "styled-components";
import { useParams } from "react-router";
import { getThemeObject } from "../helpers/themingService";
// import { getBrandData } from "../helpers/tagRouterService";
const FlowLeftSideMap = ({ addressInfo, chatText, width = "50%" }) => {
  const [showMarked, setShowMarked] = useState(true);
  const [showPictureDialog, setShowPictureDialog] = useState(false);
  const { affinity } = useParams()
  const { aerial_images } = addressInfo || {};
  const { blank, marked } = aerial_images || {};
  const { satellite } = blank || {};
  const { hybrid } = marked || {};
  return (
    <>
      {showPictureDialog ? (
        <PictureDialogWrapper onClick={() => setShowPictureDialog(false)}>
          <PictureDialog
            src={`data:image/png;base64, ${
              showMarked ? hybrid : satellite
            }`}
            alt=""
          />
        </PictureDialogWrapper>
      ) : null}
      <LeftSplit width={width}>
        <TextBalloonWrapper>
          <TextBalloon>
            <p
              className="text"
            >
              {chatText}
            </p>
            <EyeButton onClick={() => setShowMarked(prevValue => !prevValue)}>
              {showMarked ? (
                <CrossedEyeIcon colors={getThemeObject(affinity)} />
              ) : (
                <EyeIcon colors={getThemeObject(affinity)} />
              )}
            </EyeButton>
          </TextBalloon>
        </TextBalloonWrapper>

        <BGImage onClick={() => setShowPictureDialog(true)} src={`data:image/png;base64, ${satellite}`} show={true} />
        <BGImage onClick={() => setShowPictureDialog(true)} src={`data:image/png;base64, ${hybrid}`} show={showMarked} />
      </LeftSplit>
    </>
  );
};

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const PictureDialogWrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  cursor: pointer;
  display: flex;
  justify-content: space-around;
  align-items: center;
  transition: 1s;
  animation: ${fadeIn} .2s linear 1;
`;

const PictureDialog = styled.img`
  display: block;
  max-width: 90%;
  max-height: 90%;
  width: 100%;
  object-fit: contain;
  height: 100%;
  border-radius: 1rem;
  ::after {
    content: 'x';
  }
`;

const EyeButton = styled.div`
  background-color: ${({ theme }) => theme.brand.primary};
  user-select: none;
  height: 4rem;
  width: 4rem;
  z-index: 5;
  display: flex;
  position: absolute;
  right: 2rem;
  margin-left: 2rem;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 50%;
`;

const BGImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
  overflow: hidden;
  width: 100%;
  height: 105%;
  z-index: 1;
  transition: opacity .1s ease;
  cursor: pointer;
  opacity: ${({show}) => show ? 1 : 0};
`;

// const Logo = styled.img`
//   display: block;
//   height: 100%;
//   width: auto;
//   max-height: 12.5rem;
//   z-index: 2;
//   border-radius: 4px;
// `;

const TextBalloon = styled.div`
  display: flex;
  font-size: 1.6rem;
  line-height: 1.5;
  width: 100%;
  border-radius: 0.5rem;
  color: #393939;
  background: #f3f3f3;
  padding: 2rem;
  align-items: center;
  margin-top: auto;
  > span {
    font-weight: 700;
  }
`;

const TextBalloonWrapper = styled.div`
  margin: 0 auto;
  position: relative;
  width: 100%;
  z-index: 3;
`;

const LeftSplit = styled.div`
  width: ${({ width }) => width};
  padding: 5rem 4rem;
  display: flex;
  align-items: flex-end;
  position: relative;
  overflow: hidden;

  :after {
    content: "";
    width: 1px;
    height: 68%;
    background: rgba(0, 0, 0, 0.3);
    position: absolute;
    right: 0;
    align-self: center;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

export default FlowLeftSideMap;
