import React, { useState, useEffect } from "react";
import styled from "styled-components";
import i18n from "../i18n";

import eBike from "../assets/callant/eBike.svg";
import heavyScooter from "../assets/callant/heavyScooter.svg";
import lightScooter from "../assets/callant/lightScooter.svg";
import motorcycle from "../assets/callant/motorcycle.svg";

import { patchStorage, retrieveStorageById } from "../helpers/storeService";
import { useParams } from "react-router";
import useRouting from "../hooks/useRouting.js";
import { ActionButton } from "wg-fe-ui";
import InfoPopup from "../components/InfoPopup";

import FlowLeftSideAvatar from "../components/FlowLeftSideAvatar.jsx";

const FlowInsuranceTypeMoto = () => {
  const [selected, setSelected] = useState();
  const { id, affinity, insuranceType } = useParams();
  const { data } = retrieveStorageById(id, affinity, insuranceType);

  const { renderNextRoute } = useRouting();
  const [alert, setAlert] = useState();

  const vehicleTypes = [
    {
      label: eBike,
      key: "E_BIKE",
    },
    {
      label: heavyScooter,
      key: "HEAVY_SCOOTER",
    },
    {
      label: lightScooter,
      key: "LIGHT_SCOOTER",
    },
    {
      label: motorcycle,
      key: "MOTORCYCLE",
    },
  ];

  const infoHelper = {
    MOTORCYCLE: "A regular motorcycle",
    HEAVY_SCOOTER: "A scooter with >= 125cc",
    LIGHT_SCOOTER: "A scooter with < 125cc",
    E_BIKE: "A bike with full pedal assistance and more than 250 Watt",
  };

  function handleSubmit(selected) {
    if (!selected) {
      setAlert("Please select an insurance type");
      return;
    }
    if (
      data?.selectedFormula === "6WHEELS" &&
      (selected === "E_BIKE" || selected === "LIGHT_SCOOTER")
    ) {
      return;
    }

    patchStorage({
      payload: {
        twoWheeler: {
          ...data?.twoWheeler,
          details: {
            ...data?.twoWheeler?.details,
            vehicleType: selected,
            isMainVehicle: false,
          },
        },
      },
      id,
      affinity,
      insuranceType,
    });

    if (selected === "E_BIKE") {
      if (affinity === "agoria") {
        window.open(
          "https://broker.callant.be/Calculation/NewBikeCalc?dealerCode=K92SA1",
          "_blank"
        );
      } else if (affinity === "de-lijn") {
        window.open(
          "https://broker.callant.be/Calculation/NewBikeCalc?dealerCode=Y15QF9",
          "_blank"
        );
      } else if (affinity === "motor-insurance") {
        window.open(
          "https://broker.callant.be/Calculation/NewBikeCalc?dealerCode=B84LQ6",
          "_blank"
        );
      } else if (affinity === "at-work") {
        const currentCompany = data?.info?.currentCompany;
        if (currentCompany === "AON") {
          window.open(
            "https://broker.callant.be/Calculation/NewBikeCalc?dealerCode=Y46ME1",
            "_blank"
          );
        } else if (currentCompany === "ARCELORMITTAL") {
          window.open(
            "https://broker.callant.be/Calculation/NewBikeCalc?dealerCode=X26FN9",
            "_blank"
          );
        } else if (currentCompany === "CHIREC") {
          window.open(
            "https://broker.callant.be/Calculation/NewBikeCalc?dealerCode=L35DM4",
            "_blank"
          );
        } else if (currentCompany === "DAIKIN_EUROPE") {
          window.open(
            "https://broker.callant.be/Calculation/NewBikeCalc?dealerCode=S47PM8",
            "_blank"
          );
        } else if (currentCompany === "DELOITTE_BELGIUM") {
          window.open(
            "https://broker.callant.be/Calculation/NewBikeCalc?dealerCode=T82FP3",
            "_blank"
          );
        } else if (currentCompany === "EIFFAGE_BENELUX") {
          window.open(
            "https://broker.callant.be/Calculation/NewBikeCalc?dealerCode=E96KL4",
            "_blank"
          );
        } else if (currentCompany === "FEDEX_EXPRESS") {
          window.open(
            "https://broker.callant.be/Calculation/NewBikeCalc?dealerCode=R67AQ4",
            "_blank"
          );
        } else if (currentCompany === "G4S_BELGIUM") {
          window.open(
            "https://broker.callant.be/Calculation/NewBikeCalc?dealerCode=K62QE9",
            "_blank"
          );
        } else if (currentCompany === "MAZDA") {
          window.open(
            "https://broker.callant.be/Calculation/NewBikeCalc?dealerCode=Y34CA9",
            "_blank"
          );
        } else if (currentCompany === "MENSURA") {
          window.open(
            "https://broker.callant.be/Calculation/NewBikeCalc?dealerCode=H85NA7",
            "_blank"
          );
        } else if (currentCompany === "ORANGE_BELGIUM") {
          window.open(
            "https://broker.callant.be/Calculation/NewBikeCalc?dealerCode=N26HT7",
            "_blank"
          );
        } else if (currentCompany === "RANDSTAD_BELGIUM") {
          window.open(
            "https://broker.callant.be/Calculation/NewBikeCalc?dealerCode=Y25QT4",
            "_blank"
          );
        } else if (currentCompany === "SPORTOASE") {
          window.open(
            "https://broker.callant.be/Calculation/NewBikeCalc?dealerCode=W24RH9",
            "_blank"
          );
        } else if (currentCompany === "UMICORE") {
          window.open(
            "https://broker.callant.be/Calculation/NewBikeCalc?dealerCode=M92EY6",
            "_blank"
          );
        } else {
          window.open(
            "https://broker.callant.be/Calculation/NewBikeCalc?dealerCode=T82FP3",
            "_blank"
          );
        }
      } else {
        window.open(
          "https://broker.callant.be/Calculation/NewBikeCalc",
          "_blank"
        );
      }
    } else {
      if (selected === "6WHEELS" || selected === "MOTOR_ALREADY_INSURED") {
        renderNextRoute(1);
        //car route
      } else if (
        selected === "MOTOR_COMPANY_CAR" ||
        selected === "CAR_ALREADY_INSURED"
      ) {
        renderNextRoute(2);
      } else {
        renderNextRoute();
      }
    }
  }

  useEffect(() => {
    if (data?.twoWheeler?.details?.vehicleType != null) {
      setSelected(data?.twoWheeler?.details?.vehicleType);
    }
  }, []);

  return (
    <SplitContainer>
      <FlowLeftSideAvatar
        chatText={i18n.t(
          `Hello I am Louise In order to calculate your personalised premium please answer the following questions on the right`
        )}
      />
      <RightSplit>
        <Title>{i18n.t("What do you wish to insure?")}</Title>
        <InsuranceList>
          {vehicleTypes.map((item, key) => {
            return (
              <Insurance
                key={key}
                affinity={affinity}
                name={item.key}
                disabled={
                  data?.selectedFormula === "6WHEELS" &&
                  (item.key === "E_BIKE" || item.key === "LIGHT_SCOOTER")
                    ? true
                    : false
                }
                checked={selected === item.key ? true : false}
                onClick={() => {
                    if (data?.selectedFormula === "6WHEELS" &&
                    (item.key === "E_BIKE" || item.key === "LIGHT_SCOOTER")) {
                      setAlert(i18n.t("Please select an available option"))
                    } else {
                      setAlert("");
                    }
                  setSelected(item.key);
                }}
              >
                <div>
                  <img alt={item.label} src={item.label} />
                  <StyledInfoPopup
                    title={i18n.t(item.key)}
                    info={i18n.t(infoHelper[item.key])}
                  />
                </div>
                <p>{i18n.t(item.key)}</p>
              </Insurance>
            );
          })}
        </InsuranceList>
        <Error>{i18n.t(alert)}</Error>
        <ButtonContainer>
          <StyledActionButton
            affinity={affinity}
            onClick={() => handleSubmit(selected)}
          >
            {i18n.t("Next")}
          </StyledActionButton>
        </ButtonContainer>
      </RightSplit>
    </SplitContainer>
  );
};

const Error = styled.div`
  color: #f74040;
  right: 0;
  text-align: center;
  margin-top: auto;
  font-size: 1.2rem;
`;

const StyledInfoPopup = styled(InfoPopup)`
  float: right;
  font-size: 1.2rem;
  height: 1.6rem;
  line-height: 1.3;
  width: 1.6rem;
  right: 0;
  position: absolute;
`;

const Title = styled.h1`
  font-weight: 900;
  font-size: 1.65rem;
  letter-spacing: 1.19px;
  color: black;
  margin-bottom: 2rem;
`;

const StyledActionButton = styled(ActionButton)`
  color: ${({ affinity }) => (affinity === "de-lijn" ? "black" : "white")};
  background-color: ${({ affinity }) =>
    affinity === "callant" ? "#94C11A" : "default"};
  font-weight: ${({ affinity }) => (affinity === "de-lijn" ? "700" : "normal")};
`;

const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  margin-top: auto;

  & button {
    margin-left: auto;
    width: 20rem;
    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;

const InsuranceList = styled.div`
  display: grid;
  grid-template-rows: 46.5% 46.5%;
  grid-gap: 2rem;
  grid-template-columns: 47.5% 47.5%;
`;
const Insurance = styled.div`
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  display: grid;
  grid-template-rows: 80% 20%;
  padding: 1rem;
  border: ${({ disabled, checked, theme }) =>
    disabled
      ? "1px solid #CCCCCC"
      : checked
      ? `1px solid ${theme.brand.primary}`
      : "1px solid #CCCCCC"};
  background-color: ${({ checked, theme, disabled }) =>
    disabled ? "#AEAEAE" : checked ? theme.brand.lightest : "white"};
  div {
    text-align: center;
    position: relative;
  }
  img {
    /* margin: auto; */
    align-self: center;
    max-width: 10rem;
    justify-self: center;
  }
  p {
    font-weight: 700;
    align-self: center;
    justify-self: center;
    color: ${({ disabled, checked, theme, affinity }) =>
      disabled
        ? "black"
        : checked && affinity === "callant"
        ? "black"
        : checked
        ? theme.brand.primary
        : "black"};
    text-align: center;
    margin-top: 1rem;
  }
`;

const SplitContainer = styled.div`
  display: flex;
  height: 100%;
`;

const RightSplit = styled.div`
  display: grid;
  grid-template-rows: 10% 60% 10% 20%;
  width: 50%;
  padding: 5rem 4rem;
  overflow-y: auto;

  @media (max-width: 768px) {
    width: 100%;
  }

  @media screen and (max-width: 425px) {
    padding: 4rem 2.5rem;
  }
`;

export default FlowInsuranceTypeMoto;
