import React from "react";
import styled from "styled-components";
import i18n from "../i18n";
import InfoPopup from "./InfoPopup";
import ReCAPTCHA from "react-google-recaptcha";
import { ActionButton, TextInput, PhoneInput } from "wg-fe-ui";
import { useParams } from "react-router";

const FlowPolicyHolderInfoFormDefaultInputs = ({
  defaultValues,
  errors,
  values,
  handleChange,
}) => {
  const { affinity } = useParams();

  const handlePhoneInput = (e) => {
    e.target.value = e.target.value.replace("+320", "+32");
    if (
      e.target.value.length > 4 &&
      e.target.value.substring(0, 4) === "0032"
    ) {
      e.target.value = "+32" + e.target.value.substring(4);
    }
    if (e.target.value.length > 0 && e.target.value[0] === "0") {
      e.target.value = "+32" + e.target.value.substring(1);
    }
    if (e.target.value.length < 3) {
      e.preventDefault();
      values.telephonenr = "+32";
    } else {
      handleChange({ name: e.target.name, value: e.target.value });
    }
  };

  return (
    <>
      <FlexWrapper>
        <StyledPhoneInput
          name="telephonenr"
          error={errors.telephonenr}
          onChange={handlePhoneInput}
          // onKeyPress={(e) => handlePhoneInput(e)}
          // onChange={(e) => handlePhoneInput(e)}
          value={
            defaultValues?.telephonenr != null
              ? defaultValues?.telephonenr
              : values.telephonenr
          }
          autoComplete="off"
        >
          {i18n.t("Phonenumber")}
        </StyledPhoneInput>
        <TextInput
          name="email"
          error={errors.email}
          onChange={(val) => handleChange(val)}
          value={
            defaultValues?.email != null
              ? defaultValues?.email
              : values.email
          }
        >
          <InputWrapper>
            {i18n.t("Email")} *
            <StyledInfoPopup
              title={i18n.t("Email")}
              buttonText={i18n.t("?")}
              info={i18n.t("emailPopupText")}
            />
          </InputWrapper>
        </TextInput>
      </FlexWrapper>
      <Required>* {i18n.t("Required fields")}</Required>
      <CaptchaContainer>
        <Captcha
          sitekey="6LfXtssUAAAAAHPd30jLlGBYw2BjuyOu_QAqcRDq"
          onChange={(e) => handleChange({ name: "captcha", value: e })}
          hl={i18n.language}
        />
        <CaptchaError>
          <p>{errors.captcha}</p>
        </CaptchaError>
      </CaptchaContainer>
      <ButtonContainer>
        <StyledActionButton
          affinity={affinity}
          type="submit"
          value="Next"
          data-test-id="policy_holder_flow_submit"
        >
          {i18n.t("Next")}
        </StyledActionButton>
      </ButtonContainer>
    </>
  );
};

const StyledActionButton = styled(ActionButton)`
  color: ${({ affinity }) => (affinity === "de-lijn" ? "black" : "white")};
  background-color: ${({ affinity }) =>
    affinity === "callant" || affinity === "uber" || affinity === "at-work"
      ? "#94C11A"
      : "default"};
  font-weight: ${({ affinity }) => (affinity === "de-lijn" ? "700" : "normal")};
`;

const CaptchaContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: flex-end;
`;

const CaptchaError = styled.div`
  color: #f74040;
  display: flex;
  font-size: 1.2rem;
  justify-content: flex-end;
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 100%;
  -webkit-box-pack: end;
  > p {
    text-align: right;
    width: 304px;
    /* margin-right: auto; */
  }
`;

const Captcha = styled(ReCAPTCHA)`
  align-self: flex-end;
  margin-top: 1rem;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: flex-end;
`;

const InputWrapper = styled.p`
  height: 1rem;
`;

const Required = styled.p`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.typo.label};
  margin-top: 0;
  @media (max-width: 768px) {
    margin: 0 2rem 2rem 2rem;
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  & > div + div {
    margin-left: 1rem;
  }
  @media (max-width: 490px) {
    flex-direction: column;
    height: unset;
    margin-bottom: 2rem;
    & > div + div {
      margin-left: 0;
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;

  & button {
    width: 20rem;
    margin-left: auto;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    & button {
      width: 100%;
    }
  }
`;

const StyledInfoPopup = styled(InfoPopup)`
  float: right;
  font-size: 1.2rem;
  height: 1.6rem;
  line-height: 1.3;
  width: 1.6rem;
  right: 0;
  position: absolute;
`;

const StyledPhoneInput = styled(PhoneInput)`
  z-index: 10;
`;

export default FlowPolicyHolderInfoFormDefaultInputs;
