import { createUploadSignedUrl, uploadFileToBucket } from "./apiRouterService";

export const uploadDocument = async (
  _distributionId,
  _type,
  _formData,
  _fileSize,
  _fileName,
  _fileType
) => {
  const [resp, status] = await createUploadSignedUrl(
    _distributionId,
    _type,
    _fileName
  );
  if (status !== 200 && status !== 201) return [resp, status];
  const [respUpload, statusUpload] = await uploadFileToBucket(
    _formData,
    resp?.link
  );
  if (statusUpload !== 200 && statusUpload !== 201)
    return [respUpload, statusUpload];
  return [
    {
      location: resp?.location,
      size: _fileSize,
      filename: _fileName,
      type: _fileType,
    },
    statusUpload,
  ];
};
