import { stringLength } from "./stringService";
import { formatISO, getUnixTime } from 'date-fns';

/**
 * Parses date from object format to Date
 * @param {object} date {year, month, day, hour, minute, second}
 */
export const parseIncDate = (date = { year: 0, month: 0, day: 0 }) => {
  const parsedDate = new Date(
    stringLength(date.year) === 4 ? date.year : undefined,
    date.month - 1,
    date.day,
    date.hour || 0,
    date.minute || 0,
    date.second || 0
  );
  return isValidDate(parsedDate) ? parsedDate : {};
};

/**
 * Parses date to the locale string separated by '/'
 * @param {object} date {year, month, day, hour, minute, second}
 */

export const parseIncDateToString = (date = {}) => {
  const parsedDate = parseIncDate(date);
  if (isValidDate(parsedDate)) {
    return parsedDate.toLocaleDateString().replace(/\./g, "/");
  } else {
    return "";
  }
};

/**
 * Parses date to an object { day: DD, month: MM, year: YYYY }
 * @param {string} date DD/MM/YYYY
 */

export function parseIncDateToObject(string) {
  console.log(string);

  if (string) {
    const [day, month, year] = string.split("/");
    if (day && month && year) {
      return { day, month, year };
    }
  }

  return {};
}

/**
 * Parses object to an new date Sun Dec 17 1995 03:24:00 GMT...
 * @param {object} date { day: DD, month: MM, year: YYYY }
 */

export function parseObjectToNewDate(obj) {
  return new Date(obj.year, obj.month - 1, obj.day);
}

/**
 * Checkes if the passed date is an dateObject
 * @param {object} "[object Date]""
 */

export function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}

/**
 * Converts a regular DD/MM/YYYY string to an iso formatted string
 * @param {string} date DD/MM/YYYY
 */

export function setOffsetRight(d) {
  console.log(d);
  const utc = d.getTime();
  const offset = (new Date().getTimezoneOffset()) - d.getTimezoneOffset();
  const nd = new Date(utc + (1000 * 60 * offset));
  console.log(nd);
  return nd
}

export function parseIncDateToIsoFormat(dateString) {
  console.log(dateString);
  const { day, month, year } = parseIncDateToObject(dateString);
  let date = new Date(year, month - 1, day);
  console.log(date);
  // date = setOffsetRight(date);  // Isn't necessary as the backend formatting will reformat this again
  console.log(date);
  return formatISO(date)
}
export function getStartDateInIsoFormat() {
  let date = new Date();
  return formatISO(date)
}
export function getEndDateInIsoFormat() {
  let date = new Date();
  date.setHours(23);
  date.setMinutes(59);
  date.setSeconds(59);
  date.setMonth(date.getMonth() + 3);
  return formatISO(date)
}

export function createDateAsUTC(date) {
  return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()));
}

export function convertDateToUTC(date) { 
  return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds()); 
}

/**
 * Converts a regular DD/MM/YYYY string to a Unix timestamp
 * @param {integer} date 1234567890
 */
export function parseIncDateToUnixTimestamp(dateString) {
  const { day, month, year } = parseIncDateToObject(dateString);
  return getUnixTime(new Date(year, month - 1, day))
}