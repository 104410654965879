/* eslint-disable react/jsx-key */
import React, { useState } from "react";
import styled from "styled-components";
import useRouting from "../hooks/useRouting.js";
import { useParams } from "react-router";
import i18n from "../i18n";
import { ActionButton } from "wg-fe-ui";
// import CheckBoxBorderedBlockWithIcon from "../components/CheckBoxBorderedBlockWithIcon.jsx";
import { patchStorage, retrieveStorageById } from "../helpers/storeService";
// import Checkmark from "../assets/images/checkmark.jsx";
import CarTypes from "../components/CarTypes";
import { parseIntSafely } from "../helpers/intService";
import louise_logo from "../assets/images/louise_logo.svg";

const FlowCarType = () => {
  const defaultImg = louise_logo;
  const { renderNextRoute } = useRouting();
  const { id, affinity, insuranceType } = useParams();
  const { data } = retrieveStorageById(id, affinity, insuranceType);
  const carBrandImage = data?.car?.details?.carImg || defaultImg;
  const [selected, setSelected] = useState(
    data.car_types || {
      sport: false,
      suv: false,
      coupe: false,
      cabriolet: false,
    }
  );
  console.log(selected);

  const carTypes = [
    {
      type: "sport",
      value: "Sports car",
    },
    {
      type: "suv",
      value: "Jeep",
    },
    { type: "coupe", value: "Passenger car" },
    { type: "cabriolet", value: "Convertible" },
    // { type: "van", value: "Van" },
  ];
  console.log(data);

  function handleSubmit(carTypes) {
    patchStorage({
      payload: {
        car: { ...data?.car, details: { ...data?.car?.details, carTypes } },
      },
      id,
      affinity,
      insuranceType,
    });
    if (insuranceType === "two-wheeler") {
      // 1. BikePriceCalculation
      // 2. LightScooterPriceCalculation
      // 3. NotAcceptedPage
      if (data?.twoWheeler?.details?.vehicleType === "LIGHT_SCOOTER") {
        isPersonAccepted() ? renderNextRoute(2) : renderNextRoute(3);
      } else {
        isPersonAccepted() ? renderNextRoute(1) : renderNextRoute(3);
      }
    } else if (insuranceType === "6-wheeler") {
      // 1. CarPriceCalculation
      // 2. StartBikeFlowPage
      // 3. NotAcceptedPage
      if (data?.selectedFormula === "MOTOR_ALREADY_INSURED") {
        isPersonAccepted() ? renderNextRoute(1) : renderNextRoute(3);
      } else if (data?.selectedFormula === "MOTOR_COMPANY_CAR") {
        // 1. BikePriceCalculation
        // 2. LightScooterPriceCalculation
        // 3. NotAcceptedPage
        if (data?.selectedFormula === "LIGHT_SCOOTER") {
          isPersonAccepted() ? renderNextRoute(2) : renderNextRoute(3);
        } else {
          isPersonAccepted() ? renderNextRoute(1) : renderNextRoute(3);
        }
      } else if (data?.selectedFormula === "6WHEELS") {
        isPersonAccepted() ? renderNextRoute(2) : renderNextRoute(3);
      }
    } else {
      // 1. CarPriceCalculation
      // 2. NotAcceptedPage
      isPersonAccepted() ? renderNextRoute(1) : renderNextRoute(2);
    }
  }

  function getAge(dateString) {
    var today = new Date();
    const newDateString = dateString.split("/");
    let newVal =
      newDateString[1] + "/" + newDateString[0] + "/" + newDateString[2];
    var birthDate = new Date(newVal);
    console.log(newVal);

    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  function isPersonAccepted() {
    const { data } = retrieveStorageById(id, affinity, insuranceType);
    console.log(data);
    if (
      insuranceType === "car" ||
      (insuranceType === "6-wheeler" &&
        ["MOTOR_ALREADY_INSURED", "6WHEELS"].includes(data?.selectedFormula))
    ) {
      const birthDateString = data?.car?.mainDriver?.birth;
      let age = getAge(birthDateString);
      let driverLicenseAge = getAge(data?.car?.mainDriver?.issueLicenseDate);
      const bm = parseInt(data?.car?.mainDriver?.bonusMalus);
      const claimsInFault = parseIntSafely(
        data?.car?.mainDriver?.claims_in_fault
      );
      if (age < 18) {
        patchStorage({
          payload: { rejectionDescription: i18n.t(`Age < 18`) },
          id,
          affinity,
          insuranceType,
        });
        return false;
      } else if (claimsInFault > 2) {
        patchStorage({
          payload: {
            rejectionDescription: i18n.t(`No. of claims last 5 years > 2`),
          },
          id,
          affinity,
          insuranceType,
        });
        return false;
      } else if (data?.car?.details?.kw > 200) {
        patchStorage({
          payload: { rejectionDescription: i18n.t(`Car KW > 200`) },
          id,
          affinity,
          insuranceType,
        });
        return false;
      } else if (data?.car?.details?.catalogueValue > 75000) {
        patchStorage({
          payload: { rejectionDescription: i18n.t(`Car value > 75000`) },
          id,
          affinity,
          insuranceType,
        });
        return false;
      } else if (data?.car?.mainDriver?.driverLicenseType === "PROVISIONAL") {
        patchStorage({
          payload: {
            rejectionDescription: i18n.t(`Main driver has provisional license`),
          },
          id,
          affinity,
          insuranceType,
        });
        return false;
      } else if (
        age < 25 &&
        affinity === "honda" &&
        data?.car?.details?.kw > 90
      ) {
        patchStorage({
          payload: { rejectionDescription: i18n.t(`Age < 25 & Car KW > 90`) },
          id,
          affinity,
          insuranceType,
        });
        return false;
      } else if (
        age < 25 &&
        affinity !== "honda" &&
        data?.car?.details?.kw > 85
      ) {
        patchStorage({
          payload: { rejectionDescription: i18n.t(`Age < 25 & Car KW > 85`) },
          id,
          affinity,
          insuranceType,
        });
        return false;
      } else if (age < 25 && claimsInFault > 0) {
        patchStorage({
          payload: {
            rejectionDescription: i18n.t(
              `Age < 25 & No. of claims last 5 years > 0`
            ),
          },
          id,
          affinity,
          insuranceType,
        });
        return false;
      } else if (
        age < 30 &&
        driverLicenseAge < 1 &&
        affinity !== "honda" &&
        data?.car?.details?.kw > 85
      ) {
        patchStorage({
          payload: {
            rejectionDescription: i18n.t(
              `Age < 30 & Less than one year driver license & Car KW > 85`
            ),
          },
          id,
          affinity,
          insuranceType,
        });
        return false;
      } else if (
        age < 30 &&
        driverLicenseAge < 1 &&
        affinity === "honda" &&
        data?.car?.details?.kw > 85
      ) {
        patchStorage({
          payload: {
            rejectionDescription: i18n.t(
              `Age < 30 & Less than one year driver license & Car KW > 90`
            ),
          },
          id,
          affinity,
          insuranceType,
        });
        return false;
      } else if (age < 30 && driverLicenseAge < 1 && claimsInFault > 0) {
        patchStorage({
          payload: {
            rejectionDescription: i18n.t(
              `Age < 30 & Less than one year driver license & No. of claims last year > 0`
            ),
          },
          id,
          affinity,
          insuranceType,
        });
        return false;
        // } else if (age > 75) {
        //   patchStorage({ payload: {rejectionDescription: i18n.t(`Age > 75`)}, id, affinity, insuranceType});
        //   return false;
      } else if (bm > 11) {
        patchStorage({
          payload: { rejectionDescription: i18n.t(`Bonus malus > 11`) },
          id,
          affinity,
          insuranceType,
        });
        return false;
      } else if (age >= 65 && bm > 3) {
        patchStorage({
          payload: {
            rejectionDescription: i18n.t(`Age > 65 & Bonus malus > 3`),
          },
          id,
          affinity,
          insuranceType,
        });
        return false;
      } else {
        return true;
      }
    }

    if (
      insuranceType === "two-wheeler" ||
      (insuranceType === "6-wheeler" &&
        ["MOTOR_COMPANY_CAR", "CAR_ALREADY_INSURED", "6WHEELS"].includes(
          data?.selectedFormula
        ))
    ) {
      const birthDateString = data?.twoWheeler?.mainDriver?.birth;
      let age = getAge(birthDateString);
      let driverLicenseAge = data?.twoWheeler?.mainDriver?.issueLicenseDate
        ? getAge(data?.twoWheeler?.mainDriver?.issueLicenseDate)
        : null;
      const claimsInFault = parseIntSafely(
        data?.twoWheeler?.mainDriver?.claims_in_fault
      );
      if (driverLicenseAge !== null && age < 18) {
        patchStorage({
          payload: { rejectionDescription: i18n.t(`Age < 18`) },
          id,
          affinity,
          insuranceType,
        });
        return false;
      } else if (claimsInFault > 2) {
        patchStorage({
          payload: {
            rejectionDescription: i18n.t(`No. of claims last 5 years > 2`),
          },
          id,
          affinity,
          insuranceType,
        });
        return false;
      } else if (data?.twoWheeler?.details?.cc > 2000) {
        patchStorage({
          payload: { rejectionDescription: i18n.t(`Moto CC > 2000`) },
          id,
          affinity,
          insuranceType,
        });
        return false;
      } else if (
        data?.twoWheeler?.mainDriver?.driverLicenseType === "PROVISIONAL"
      ) {
        patchStorage({
          payload: {
            rejectionDescription: i18n.t(`Main driver has provisional license`),
          },
          id,
          affinity,
          insuranceType,
        });
        return false;
      } else if (
        driverLicenseAge != null &&
        age < 25 &&
        data?.twoWheeler?.details?.cc > 2000
      ) {
        patchStorage({
          payload: {
            rejectionDescription: i18n.t(`Age < 25 & Moto CC > 2000`),
          },
          id,
          affinity,
          insuranceType,
        });
        return false;
      } else if (driverLicenseAge != null && age < 25 && claimsInFault > 0) {
        patchStorage({
          payload: {
            rejectionDescription: i18n.t(
              `Age < 25 & No. of claims last 5 years > 0`
            ),
          },
          id,
          affinity,
          insuranceType,
        });
        return false;
      } else if (
        driverLicenseAge !== null &&
        age < 30 &&
        driverLicenseAge < 1 &&
        data?.twoWheeler?.details?.cc > 2000
      ) {
        patchStorage({
          payload: {
            rejectionDescription: i18n.t(
              `Age < 30 & Less than one year driver license & Moto CC > 2000`
            ),
          },
          id,
          affinity,
          insuranceType,
        });
        return false;
      } else if (
        driverLicenseAge != null &&
        age < 30 &&
        driverLicenseAge < 1 &&
        claimsInFault > 0
      ) {
        patchStorage({
          payload: {
            rejectionDescription: i18n.t(
              `Age < 30 & Less than one year driver license & No. of claims last year > 0`
            ),
          },
          id,
          affinity,
          insuranceType,
        });
        return false;
        // } else if (age > 75) {
        //   patchStorage({
        //     payload: { rejectionDescription: i18n.t(`Age > 75`) },
        //     id,
        //     affinity,
        //     insuranceType,
        //   });
        //   return false;
      } else {
        return true;
      }
    }

    return true;
  }

  function handleSelected(key) {
    const tmpObj = {};
    carTypes.forEach(({ type, value }) => {
      tmpObj[type] = type === key ? !selected[key] : false;
    });
    setSelected(tmpObj);
  }

  return (
    <SplitContainer>
      <LeftSplit>
        <RoundImageContainer>
          <RoundImage img={carBrandImage} alt="car brand logo" />
        </RoundImageContainer>
        <TextBalloon>
          <p
            dangerouslySetInnerHTML={{
              __html: i18n.t(
                `Does the vehicle belong to one of these categories? If not, click on next`
              ),
            }}
          ></p>
        </TextBalloon>
      </LeftSplit>
      <RightSplit>
        <TitleForm>{i18n.t("Car type")}</TitleForm>
        <div>
          {carTypes.map((question) => {
            return (
              <CarTypes
                question={question}
                onChildClick={handleSelected}
                selected={selected}
              />
            );
          })}
        </div>
        <ButtonContainer>
          <StyledActionButton
            affinity={affinity}
            onClick={() => handleSubmit(selected)}
          >
            {i18n.t("Next")}
          </StyledActionButton>
        </ButtonContainer>
      </RightSplit>
    </SplitContainer>
  );
};

// const BoundingBox = styled.div`
//   width: 4rem;
//   height: 3.5rem;
//   margin-right: 1rem;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// `;

// const StyledLoadingDriveAssistIcons = styled(LoadingDriveAssistIcons)`
//   fill: ${({ theme }) => theme.brand.primary};
//   width: 100%;
//   height: 100%;
// `;

// const StandardInputCheckbox = styled.input`
//   border-radius: 0.3rem;
//   opacity: 0;
//   margin-left: 1.5rem;
//   margin-right: -1.6rem;
//   cursor: pointer;
// `;

// const StyledLabelCheckbox = styled.label`
//   display: flex;
//   position: relative;
//   margin-top: 0.5rem;
//   cursor: pointer;
//   line-height: 1.5;
//   background-color: ${(props) => (!props.disabled ? "white" : "none")};
//   flex: 0 0 50%;
//   color: #aeaeae;
//   padding: 0.65rem 1rem;
//   border-radius: 3px;
//   border: 1px solid #ccc;
//   align-items: center;
//   margin-bottom: 0.75rem;
//   & > span a {
//     font-size: 1.4rem;
//     transition: color 0.1s ease;
//     color: ${({ theme }) => theme.brand.primary};
//     font-weight: bold;
//   }
//   & > span {
//     font-size: 1.4rem;
//     width: 90%;
//     transition: color 0.1s ease;
//     color: #aeaeae;

//     & > span {
//       font-weight: bold;
//       color: ${(props) => (props.disabled ? "#aeaeae" : "black")};
//     }
//   }
// `;

// const CheckboxItem = styled.div`
//   width: 2rem;
//   height: 2rem;
//   margin-top: 0.3rem;
//   background-color: ${({ theme, checked }) =>
//     checked ? theme.brand.primary : "none"};
//   transition: all 0.1s ease;
//   flex-grow: 0;
//   flex-shrink: 0;
//   border: 0.2rem solid #d3d4d8;
//   border-color: ${({ theme, checked }) =>
//     checked ? theme.brand.primary : "#D3D4D8"};
//   border-radius: 0.3rem;
//   display: flex;
//   justify-content: center;
//   align-items: center;

//   & > img {
//     height: 100%;
//     width: 100%;
//     object-fit: contain;
//   }
// `;

const StyledActionButton = styled(ActionButton)`
  color: ${({ affinity }) => (affinity === "de-lijn" ? "black" : "white")};
  background-color: ${({ affinity }) =>
    affinity === "callant" ? "#94C11A" : "default"};
  font-weight: ${({ affinity }) => (affinity === "de-lijn" ? "700" : "normal")};
`;

const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  margin-top: auto;

  & button {
    margin-left: auto;
    width: 20rem;
    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;

const TitleForm = styled.h2`
  font-weight: 900;
  font-size: 1.6rem;
  flex-shrink: 0;
  margin-bottom: 3rem;
`;

const TextBalloon = styled.div`
  display: flex;
  font-size: 1.6rem;
  line-height: 1.5;
  align-self: center;
  width: 100%;
  padding: 2.2rem;
  border-radius: 0.5rem;
  color: #393939;
  background: #f3f3f3;
  margin-top: auto;
  > span {
    font-weight: 700;
  }
`;

const RightSplit = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 5rem 4rem;
  overflow-y: auto;

  @media (max-width: 768px) {
    width: 100%;
  }

  @media screen and (max-width: 425px) {
    padding: 4rem 2.5rem;
  }
`;

const SplitContainer = styled.div`
  display: flex;
  height: 100%;
`;

const LeftSplit = styled.div`
  width: 50%;
  display: grid;
  grid-template-rows: 71% 25%;
  grid-gap: 2rem;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  padding: 5rem 4rem;

  :after {
    content: "";
    width: 1px;
    height: 70%;
    background: rgba(0, 0, 0, 0.3);
    position: absolute;
    right: 50%;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const RoundImage = styled.div`
  background-image: url(${(props) => props.img});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  width: 100%;
  height: auto;
  padding-top: 100%;
  border-radius: 50%;
`;

const RoundImageContainer = styled.div`
  max-width: 35rem;
  width: 100%;
  justify-self: center;
`;

export default FlowCarType;
