import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { func, string, number } from "prop-types";
import InfoPopup from "./InfoPopup";
import i18n from "../i18n";

import ToggleButton from "./ToggleButton.jsx";
import DownloadButton from "./DownloadButton.jsx";

const FlowPriceCalculatorOption = ({
  title,
  ipidLink,
  termsLink,
  onChange,
  id,
  mandatory,
  price,
  helperText,
  defaultValue,
  insuranceType,
  lightScooter,
  disabled,
  discount,
}) => {
  const [isSelected, setIsSelected] = useState(mandatory || defaultValue);

  useEffect(() => {
    onChange(id, isSelected);
  }, [isSelected]);

  // var myObj = {
  //   style: "currency",
  //   currency: "EUR",
  // };
  console.log(disabled);

  return (
    <OptionWrapper>
      <ToggleButton
        className="toggle"
        disabled={disabled ? true : mandatory}
        onChange={(toggle) => setIsSelected(toggle)}
        defaultCheck={mandatory || defaultValue}
      />
      <OptionContent
        isSelected={isSelected}
        insuranceType={insuranceType}
        lightScooter={lightScooter}
      >
        <TitleAndPrice>
          <StyledPopUp title={title} info={helperText} />
          <OptionTitle>
            {i18n.t(title) + ' '}
            <DiscountText>
              {discount !== null ? <p>{`(${i18n.t('XX discount applied', {'discount': (discount*100).toFixed(0)})})`}</p> : ''}
            </DiscountText>
            {disabled ? (
              <InfoPopup
                isLink={true}
                buttonText={"(" + i18n.t("disabled") + ")"}
                title={i18n.t("Vehicle age not qualified")}
                info={i18n.t(
                  "A vehicle older than 10 years doesn't qualify for this option"
                )}
              >
                disabled
              </InfoPopup>
            ) : (
              ""
            )}
          </OptionTitle>
          <OptionPrice>
            {typeof price === 'number'
              ? `${parseFloat(price).toLocaleString("nl-BE", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })} EUR/${i18n.t("y")}`
              : price}
          </OptionPrice>
        </TitleAndPrice>
        <DownloadContainer>
          <DownloadIpid
            href={ipidLink}
          >
            {i18n.t("IPID")}
          </DownloadIpid>
          <DownloadTerms href={termsLink}>{i18n.t("Terms and Conditions")}</DownloadTerms>
        </DownloadContainer>
      </OptionContent>
    </OptionWrapper>
  );
};

const StyledPopUp = styled(InfoPopup)`
  @media (max-width: 900px) {
    height: 2.4rem;
  }
`;

const DiscountText = styled.span`
  color: red;
`;

const DownloadTerms = styled(DownloadButton)`
  padding: 0;
  width: 23rem;
  margin-left: 2rem;
  @media (max-width: 1140px) {
    font-size: 1.4rem;
    padding-left: 2rem;
    width: 16.5rem;
    margin-left: 0.5rem;
    img {
      margin-left: 0rem;
    }
  }
`;

const DownloadIpid = styled(DownloadButton)`
  padding: 0;
  margin-left: ${({ insuranceType, lightScooter }) =>
    insuranceType === "legal"
      ? "auto"
      : lightScooter === "lightScooter"
      ? "auto"
      : "0"};
  min-width: 19rem;
  @media (max-width: 1140px) {
    font-size: 1.4rem;
    padding-left: 3rem;
    padding-right: 1rem;
    min-width: 8rem;
    img {
      margin-left: 0rem;
    }
  }
`;

const TitleAndPrice = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-right: 4rem;
  margin-bottom: 1rem;
`;

const DownloadContainer = styled.div`
  /* margin-top: 1rem; */
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 900px) {
    justify-content: space-between;
    margin-left: 2rem;
  }
`;

const OptionContent = styled.div`
  flex-direction: ${({ insuranceType, lightScooter }) =>
    insuranceType === "legal"
      ? "column"
      : lightScooter === "lightScooter"
      ? "column"
      : "initial"};
  display: flex;
  justify-content: space-between;
  margin-left: 2rem;
  width: 100%;
  opacity: ${({ isSelected }) => (isSelected ? 1 : 0.6)};
  transition-duration: 0.4s;
  transition-property: opacity;
  @media (max-width: 570px) {
    margin-left: 1rem;
  }
  @media screen and (max-width: 900px) {
    flex-direction: column;
    margin-bottom: 1rem;
  }
`;

const OptionWrapper = styled.div`
  display: flex;
  margin-bottom: 2.5rem;
  border-radius: 0.75rem;
  align-items: center;
  .toggle {
    /* margin: auto; */
  }
  @media (max-width: 570px) {
    margin-bottom: 1.5rem;
  }
`;

const OptionPrice = styled.p`
  font-size: 1.8rem;
  color: ${({ theme }) => theme.typo.subTitle};
  margin-left: auto;
  width: 27%;
  text-align: right;
`;

const OptionTitle = styled.h2`
  margin-left: 2rem;
  width: 70%;
  font-size: 1.8rem;
  word-break: break-word;
  font-weight: 700;
  color: ${({ theme }) => theme.typo.subTitle};
  @media (max-width: 570px) {
    font-size: 1.5rem;
  }
`;

FlowPriceCalculatorOption.defaultProps = {
  onChange: () => {},
  title: "",
  ipidLink: "",
  termsLink: "",
  price: 0,
  helperText: "",
  discount: null,
};

FlowPriceCalculatorOption.propTypes = {
  onChange: func,
  title: string,
  ipidLink: string,
  termsLink: string,
  price: number,
  helperText: string,
  discount: number,
};

export default FlowPriceCalculatorOption;
