import OneAutomotiveLogo from "../assets/images/brand-logos/OneAutomotive.png";
const general = [];

export const oneAutomotiveCar = {
  brand: {
    name: "OneAutomotive",
    logo: OneAutomotiveLogo,
  },
  flow: [
    ...general,
    {
      title: "user-address",
      step: 1,
      subStep: 1,
      nextRoutes: {
        1: "drivers-information",
      },
    },
    {
      title: "drivers-information",
      step: 1,
      subStep: 2,
      nextRoutes: {
        1: "drivers-information-extra",
      },
    },
    {
      title: "drivers-information-extra",
      step: 1,
      subStep: 3,
      nextRoutes: {
        1: "search-vehicle",
      },
    },
    {
      title: "search-vehicle",
      step: 2,
      subStep: 0,
      nextRoutes: {
        1: "car-information",
      },
    },
    {
      title: "car-information",
      step: 2,
      subStep: 1,
      nextRoutes: {
        1: "safety-systems",
      },
    },
    {
      title: "safety-systems",
      step: 2,
      subStep: 2,
      nextRoutes: {
        1: "price-calculator",
        2: "acceptance-conditions",
      },
    },
    {
      title: "acceptance-conditions",
      step: 2,
      subStep: 3,
      nextRoutes: {},
    },
    {
      title: "price-calculator",
      step: 3,
      subStep: 0,
      nextRoutes: {
        1: "overview",
      },
    },
    {
      title: "overview",
      step: 4,
      subStep: 0,
      nextRoutes: {
        1: "thankyou",
      },
    },
    {
      title: "thankyou",
      step: 4,
      subStep: 1,
      nextRoutes: {
        1: "verify-user",
      },
    },
  ],
};
