import React from "react";
import Main from "./pages/Main.js";
import GlobalStyle from "./resetStyling";
import { ThemeContextProvider } from "./contexts/ThemingContext";
import posthog from "posthog-js";
import { PostHogProvider } from 'posthog-js/react';
import { Helmet } from "react-helmet";
import i18next from "i18next";
import * as Sentry from "@sentry/react";

import "./i18n";

if (process.env.NODE_ENV !== "development") {
  posthog.init(
    process.env.REACT_APP_POSTHOG_TOKEN,
    {
      api_host: 'https://eu.posthog.com',
      autocapture: false,
        capture_pageleave: false,
        session_recording: {
          maskInputOptions: {
            password: true,
          },
        },
    }
  );  
}



function App() {
  return (
    <div className="App">
    <PostHogProvider
      client={posthog}
    >
      <ThemeContextProvider>
        <GlobalStyle />
        <Helmet>
          <html lang={i18next.language} />
        </Helmet>
        <Main />
      </ThemeContextProvider>
      </PostHogProvider>
    </div>
  );
}

export default Sentry.withProfiler(App);
