import { parseIncDateToIsoFormat, parseIncDateToObject } from "./dateService";
import i18n from "../i18n.js";

function parseCBE(cbe) {
  return cbe ? cbe.replace(/\./g, "") : cbe;
}

export const getProspectObject = (data) => {
  const generalInfo = {
    native_language: i18n.language.toUpperCase(),
    email: data?.policyHolder?.email,
    telephonenr: data?.policyHolder?.telephonenr,
    address: {
      street: data?.policyHolder?.address.streetName,
      zipcode: data?.policyHolder?.address.postalCode,
      housenr: data?.policyHolder?.address.streetNumber,
      boxnr: data?.policyHolder?.address.boxNumber === "" ? null : data?.policyHolder?.address.boxNumber,
      city: data?.policyHolder?.address.municipalityName,
      country_code: "BE",
    },
  };
  if (data?.policyHolder?.personType === "natural") {
    return {
      ...generalInfo,
      first_name: data?.policyHolder?.firstName,
      last_name: data?.policyHolder?.lastName,
      birth: parseIncDateToIsoFormat(data?.policyHolder?.birth),
      // national_register_nr: data?.policyHolder?.national_register_nr,
      profession: data?.policyHolder?.primary === "yes" ? data?.car?.mainDriver?.currentProfession || data?.twoWheeler?.mainDriver?.currentProfession : null,
      type: "customers",
    };
  } else {
    return {
      ...generalInfo,
      name: data?.policyHolder?.companyName,
      cbe: data?.policyHolder?.cbe ? parseCBE(data.policyHolder.cbe) : null,
      type: "companies",
    };
  }
};


export const getProspectObjectPyModels = (data) => {
  const generalInfo = {
    email: data?.policyHolder?.email,
    telephonenr: data?.policyHolder?.telephonenr,
    billing_address: {
      street: data?.policyHolder?.address.streetName,
      zipcode: data?.policyHolder?.address.postalCode,
      housenr: data?.policyHolder?.address.streetNumber,
      boxnr: data?.policyHolder?.address.boxNumber === "" ? null : data?.policyHolder?.address.boxNumber,
      city: data?.policyHolder?.address.municipalityName,
      country_code: "BE",
    },
  };
  if (data?.policyHolder?.personType === "natural") {
    return {
      ...generalInfo,
      first_name: data?.policyHolder?.firstName,
      last_name: data?.policyHolder?.lastName,
      birth: parseIncDateToObject(data?.policyHolder?.birth),
      // language: i18n.language.toUpperCase(),
      // national_register_nr: data?.policyHolder?.national_register_nr,
    };
  } else {
    return {
      ...generalInfo,
      name: data?.policyHolder?.companyName,
      cbe: data?.policyHolder?.cbe ? parseCBE(data.policyHolder.cbe) : null,
    };
  }
};
