import React from "react";
import styled from "styled-components";
import { useHistory, useParams } from "react-router-dom";
import i18n from "../i18n";

import {
  IconConsumerFilled,
  IconCarFilled,
  IconMotor,
  IconLookingForCarFilled,
  IconLegalFilled,
  IconIdFilled,
  IconStatusCheck,
} from "wg-fe-ui";

import FlowProgressBarStepContainer from "./FlowProgressBarStepContainer";
import GoBack from "../assets/icons/GoBack";

const FlowProgressBar = ({ errorState }) => {
  const { affinity, insuranceType } = useParams();
  const history = useHistory();

  let historyPath = history.location.pathname.split("/");
  console.log('DEBUG', history.location.pathname);
  
  const firstIcon =
    // storageData != null && storageData/
    insuranceType === "two-wheeler" ? (
      <IconMotor key={1} color="white" />
    ) : insuranceType === "teacher" ? (
      <IconConsumerFilled key={1} color="white" />
    ) : insuranceType === "legal" ? (
      <IconLegalFilled key={1} color="white" />
    ) : (
      <IconCarFilled key={1} color="white" />
    );
  const icons = [
    <IconIdFilled key={0} color="white" />,
    firstIcon,
    <IconLookingForCarFilled key={2} color="white" />,
    <IconStatusCheck key={3} color="white" />,
  ];

  const sixWheelIcons = [
    <IconIdFilled key={0} color="white" />,

    <IconCarFilled key={1} color="white" />,
    <IconMotor key={2} color="white" />,
    <IconLookingForCarFilled key={3} color="white" />,
    <IconStatusCheck key={4} color="white" />,
  ];

  function handleNewSession() {
    history.push(`/${affinity}/${insuranceType}/verify-user`);
  }

  return (
    <StyledBar>
      {!errorState && historyPath.includes("thankyou") ? (
        <StartNewSessionButton
          affinity={affinity}
          onClick={() => handleNewSession()}
        >
          {i18n.t("Calculate another rate")}
        </StartNewSessionButton>
      ) : historyPath.includes("verify-user") ? (
        <StyledGoBack
          data-test-it="underwriting_goback_nav"
          onClick={() => history.goBack()}
        />
      ) : (
        <GoBack
          data-test-it="underwriting_goback_nav"
          onClick={() => history.goBack()}
        />
      )}

      <ProgressBar>
        {insuranceType === "6-wheeler"
          ? sixWheelIcons.map((icon, key) => {
              return (
                <FlowProgressBarStepContainer
                  icon={icon}
                  key={key}
                  id={key + 1}
                />
              );
            })
          : icons.map((icon, key) => {
              return (
                <FlowProgressBarStepContainer
                  icon={icon}
                  key={key}
                  id={key + 1}
                />
              );
            })}
      </ProgressBar>
    </StyledBar>
  );
};

const StyledGoBack = styled(GoBack)`
  visibility: hidden;
`;

const StartNewSessionButton = styled.button`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #df2815;
  border-radius: 15px;
  border: none;
  margin-right: 2rem;
  height: 5.6rem;
  color: white;
  font-weight: bold;
  font-size: 1.5rem;

  @media screen and (max-width: 800px) {
    margin-right: 3.5rem;
  }
`;

const ProgressBar = styled.div`
  display: flex;
  justify-content: space-around;
  width: 80%;
`;

const StyledBar = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1440px;
  width: 85%;
  margin-bottom: 3rem;

  @media screen and (max-width: 425px) {
    display: none;
  }
`;

export default FlowProgressBar;
