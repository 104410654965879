import React, { useState, useEffect } from "react";
import styled from "styled-components";
import i18n from "../i18n";
import single from "../assets/callant/single.svg";
import family from "../assets/callant/family.svg";
import heavy from "../assets/callant/heavy.svg";
import noHeavy from "../assets/callant/no-heavy.svg";
import { patchStorage } from "../helpers/storeService";
import { useParams } from "react-router";
import useRouting from "../hooks/useRouting.js";
import { ActionButton } from "wg-fe-ui";
import InfoPopup from "../components/InfoPopup";

import FlowLeftSideAvatar from "../components/FlowLeftSideAvatar.jsx";

const FlowBikeType = () => {
  const [selectedFamilyType, setSelectedFamilyType] = useState();
  const [selectedTeacherType, setSelectedTeacherType] = useState();
  const [teacherValues, setTeacherValues] = useState();

  const { id, affinity, insuranceType } = useParams();
  const { renderNextRoute } = useRouting();
  const [alert, setAlert] = useState();

  useEffect(() => {
    setTeacherValues({
      teacher_type: selectedTeacherType,
      family_type: selectedFamilyType,
    });
  }, [selectedTeacherType, selectedFamilyType]);

  const familyType = [
    {
      label: single,
      key: "SINGLE",
    },
    {
      label: family,
      key: "FAMILY",
    },
  ];
  const teacherType = [
    {
      label: heavy,
      key: "HEAVY",
    },
    {
      label: noHeavy,
      key: "NO_HEAVY",
    },
  ];

  function handleSubmit(teacherValues) {
    console.log(teacherValues);

    if (
      teacherValues.teacher_type === undefined ||
      teacherValues.family_type === undefined
    ) {
      setAlert("Please answer both questions");
      return;
    }
    patchStorage({
      payload: { teacher_values: teacherValues },
      id,
      affinity,
      insuranceType,
    });

    renderNextRoute();
  }

  return (
    <SplitContainer>
      <FlowLeftSideAvatar
        chatText={i18n.t(
          `Hello I am Louise In order to calculate your personalised premium please answer the following questions on the right`
        )}
      />
      <RightSplit>
        <>
          <Title>{i18n.t("What is your home situation?")}</Title>
          <InsuranceList>
            {familyType.map((item, key) => {
              console.log(item, key);
              return (
                <Insurance
                  key={key}
                  name={item.key}
                  checked={selectedFamilyType === item.key ? true : false}
                  onClick={() => {
                    setAlert("");
                    setSelectedFamilyType(item.key);
                  }}
                >
                  <img src={item.label} alt={item.label}/>
                  <p>{i18n.t(item.key)}</p>
                </Insurance>
              );
            })}
          </InsuranceList>
        </>
        <>
          <Title>
            {i18n.t("Do you use heavy equipment?")}
            <StyledInfoPopup
              title={i18n.t("Do you use heavy equipment?")}
              info={i18n.t(
                "Heavy equipment means that the teacher and his pupils manipulate more than 10% of the total activities heavy equipment, including, but not limited to, sawing and grinding machines, printing machines and welding equipment"
              )}
            />
          </Title>
          <InsuranceList>
            {teacherType.map((item, key) => {
              console.log(item, key);
              return (
                <Insurance
                  key={key}
                  name={item.key}
                  checked={selectedTeacherType === item.key ? true : false}
                  onClick={() => {
                    setAlert("");
                    setSelectedTeacherType(item.key);
                  }}
                >
                  <img src={item.label} alt={item.label} />
                  <p>{i18n.t(item.key)}</p>
                </Insurance>
              );
            })}
          </InsuranceList>
        </>
        <ButtonContainer>
          <Error>{i18n.t(alert)}</Error>
          <StyledActionButton
            affinity={affinity}
            onClick={() => handleSubmit(teacherValues)}
          >
            {i18n.t("Next")}
          </StyledActionButton>
        </ButtonContainer>
      </RightSplit>
    </SplitContainer>
  );
};

const Error = styled.div`
  color: #f74040;
  margin: auto;
  margin-left: 0;
  font-size: 1.2rem;
`;

const StyledInfoPopup = styled(InfoPopup)`
  float: right;
  font-size: 1.2rem;
  height: 1.6rem;
  line-height: 1.3;
  width: 1.6rem;
  position: absolute;
`;

const Title = styled.h1`
  align-self: end;
  font-weight: 900;
  font-size: 1.65rem;
  letter-spacing: 1.19px;
  color: black;
  margin-bottom: 2rem;
`;

const StyledActionButton = styled(ActionButton)`
  color: ${({ affinity }) => (affinity === "de-lijn" ? "black" : "white")};
  font-weight: ${({ affinity }) => (affinity === "de-lijn" ? "700" : "normal")};
`;

const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  margin-top: auto;

  & button {
    margin-left: auto;
    width: 20rem;
    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;

const InsuranceList = styled.div`
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: 47.5% 47.5%;
`;
const Insurance = styled.div`
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  display: grid;
  grid-template-rows: 80% 20%;
  padding: 1rem;
  border: ${({ checked, theme }) =>
    checked ? `1px solid ${theme.brand.primary}` : "1px solid #CCCCCC"};
  background-color: ${({ checked, theme, disabled }) =>
    checked ? theme.brand.lightest : disabled ? "#AEAEAE" : "white"};
  div {
    text-align: center;
    position: relative;
  }
  img {
    /* margin: auto; */
    align-self: center;
    max-width: 10rem;
    justify-self: center;
  }
  p {
    font-weight: 700;
    align-self: center;
    justify-self: center;
    color: ${({ checked, theme }) => (checked ? theme.brand.primary : "black")};
  }
`;

const SplitContainer = styled.div`
  display: flex;
  height: 100%;
`;

const RightSplit = styled.div`
  display: grid;
  grid-template-rows: 7% 30% 14% 30% 19%;
  width: 50%;
  padding: 5rem 4rem;
  overflow-y: auto;

  @media (max-width: 768px) {
    width: 100%;
  }

  @media screen and (max-width: 425px) {
    padding: 4rem 2.5rem;
  }
`;

export default FlowBikeType;
