import React, { useEffect, Suspense, useState } from "react";
import styled from "styled-components";
import { ActionButton, SearchSelectInput, Label, TextInput } from "wg-fe-ui";
import DateInput from "../components/DateInput";
import * as Yup from "yup";
import i18n from "../i18n";
import { currentProfessionOptions as currentProfessionOptionsConstant } from "../constants/FlowSearchSelectData";
// import FlowAddAdditionalDriver from "./FlowAddAdditionalDriver.jsx";

import { differenceInYears } from "date-fns";

import { driverLicenseTypeOptions } from "../constants/FlowSearchSelectData";

import {
  string,
  date,
  firstName,
  lastName,
} from "../constants/validationSchemas.js";
import {
  parseObjectToNewDate,
  parseIncDateToObject,
  parseIncDate,
} from "../helpers/dateService.js";
import useForm from "../hooks/useForm";
import { useParams } from "react-router";
import { retrieveStorageById } from "../helpers/storeService";
import { workDistanceOptions as workDistanceOptionsConstance } from "../constants/FlowSearchSelectData";

const FlowDriversInformationForm = ({
  handleAddedDrivers,
  handleFormValues,
  defaultValues,
}) => {
  const { id, affinity, insuranceType } = useParams();
  const { data } = retrieveStorageById(id, affinity, insuranceType);
  // const [defaultValues, setDefaultValues] = useState();
  const [licenseOptions, setLicenseOptions] = useState([]);
  const [currentProfessionOptions, setCurrentProfessionOptions] = useState([]);
  const [workDistanceOptions, setWorkDistanceOptions] = useState([]);

  const SignupSchema = Yup.object().shape({
    firstName: firstName,
    lastName: lastName,
    birth: date
      .test(
        "valid-birth",
        data?.twoWheeler?.details?.vehicleType === "LIGHT_SCOOTER"
          ? i18n.t("The driver must be at least 16 years of age")
          : insuranceType === "two-wheeler"
          ? i18n.t("The driver must be at least 18 years of age")
          : i18n.t("The driver must be at least 17 years of age"),
        handleBirthValidationTooYoung
      )
      .test(
        "valid-birth",
        i18n.t("The driver is too old"),
        handleBirthValidationTooOld
      ),
    driverLicenseType: string.required,
    issueLicenseDate: date.test(
      "valid-license-date",
      i18n.t("The driver is too young to obtain a driving license"),
      handleDriveLicenseAge
    ),
    home_to_work_distance:
      affinity === "doceo" && insuranceType === "car"
        ? string.required
        : string.notRequired,
  });

  const SelectPlaceholder = i18n.t("Choose your option");

  const { handleChange, errors, handleSubmit, values } = useForm({
    validationSchema: SignupSchema,
    change: () => {},
  });

  const capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1).toLocaleLowerCase();
  };

  function handleNameChange(e) {
    const words = e.value.split(" ");
    const res = words.map((x) => capitalize(x));
    const name = res.join(" ");
    handleChange({ name: e.name, value: name });
  }

  useEffect(() => {
    loadSelectOptions();
    console.log(values);
  }, []);

  function loadSelectOptions() {
    setLicenseOptions(
      driverLicenseTypeOptions.map(({ value, label }) => {
        return { value, label: i18n.t(label) };
      })
    );
    setCurrentProfessionOptions(
      currentProfessionOptionsConstant.map(({ value, label }) => {
        return { value, label: i18n.t(label) };
      })
    );
    setWorkDistanceOptions(
      workDistanceOptionsConstance.map(({ value, label }) => {
        return { value, label: i18n.t(label) };
      })
    );
  }

  useEffect(() => {
    console.log(currentProfessionOptions);

    currentProfessionOptions.sort(function(a, b) {
      var nameA = a.label;
      var nameB = b.label;
      if (nameA < nameB) {
        return -1;
      } else if (nameA > nameB) {
        return 1;
      } else {
        return 0;
      }
    });
  }, [currentProfessionOptions]);

  useEffect(() => {
    defaultValuesHandler(defaultValues);
  }, [defaultValues]);

  function defaultValuesHandler() {
    Object.keys(defaultValues || {}).forEach((name) => {
      handleChange({ name, value: defaultValues[name] });
    });
  }

  function handleBirthValidationTooYoung() {
    const { birth } = this.parent;

    const dateObject = parseIncDateToObject(birth);
    const birthDate = parseObjectToNewDate(dateObject);

    if (insuranceType === "two-wheeler") {
      return 18 <= differenceInYears(new Date(), birthDate);
    } else if (data?.twoWheeler?.details?.vehicleType === "LIGHT_SCOOTER") {
      return 16 <= differenceInYears(new Date(), birthDate);
    } else {
      return 17 <= differenceInYears(new Date(), birthDate);
    }
  }

  function handleBirthValidationTooOld() {
    const { birth } = this.parent;
    const dateObject = parseIncDateToObject(birth);
    const birthDate = parseObjectToNewDate(dateObject);

    return differenceInYears(new Date(), birthDate) <= 100;
  }

  function handleDriveLicenseAge() {
    const { issueLicenseDate, birth, driverLicenseType } = this.parent;

    let minimumDrivingAge = 18;
    if (driverLicenseType) {
      minimumDrivingAge = driverLicenseType.includes("PROVISIONAL") ? 17 : 18;
    }

    const dateObject = parseIncDateToObject(birth);
    const birthDate = parseIncDate(dateObject);

    const licenseObject = parseIncDateToObject(issueLicenseDate);
    const licenseDate = parseIncDate(licenseObject);

    return differenceInYears(licenseDate, birthDate) >= minimumDrivingAge;
  }

  function formSubmitHandler(e) {
    e.preventDefault();
    handleSubmit((values) => handleFormValues(values));
  }

  function handleSelectValue(object, inputName) {
    if (!values[inputName]) return "";
    const key = values[inputName];
    return object.filter(({ value }) => value === key)[0];
  }

  function handleSelectChange(val, name) {
    handleChange({ name, value: val ? val.value : "" });
  }
  // const handleDrivers = (value) => {
  //   console.log(value);

  //   handleAddedDrivers(value);
  // };
  console.log(values);
  console.log(defaultValues);

  return (
    <Form onSubmit={formSubmitHandler}>
      <Suspense fallback={<div>Loading...</div>}>
        <CatTitle>{i18n.t("Primary driver information")} </CatTitle>
        <FlexWrapper>
          <TextInput
            name="firstName"
            error={errors.firstName}
            onChange={(val) => handleNameChange(val)}
            value={values.firstName}
          >
            <NameInput>{i18n.t("First name")} *</NameInput>
          </TextInput>
          <TextInput
            name="lastName"
            error={errors.lastName}
            onChange={(val) => handleNameChange(val)}
            value={values.lastName}
          >
            <NameInput>{i18n.t("Last name")} *</NameInput>
          </TextInput>
        </FlexWrapper>
        <div>
          <StyledLabel>
            {i18n.t("What is the date of birth of the main driver")} *
          </StyledLabel>
          <StyledDateInput
            type="birth"
            name="birth"
            error={errors.birth}
            onChange={handleChange}
            value={defaultValues?.birth}
          />
        </div>
        <div>
          <StyledLabel>
            {i18n.t("When did the main driver obtain the driving license")} *
          </StyledLabel>
          <StyledDateInput
            type="license"
            onChange={handleChange}
            error={errors.issueLicenseDate}
            name="issueLicenseDate"
            value={defaultValues != null ? defaultValues?.issueLicenseDate : ""}
          />
        </div>
        {["MOTORCYCLE", "HEAVY_SCOOTER"].includes(
          data?.twoWheeler?.details?.vehicleType
        ) ? (
          <div>
            <StyledLabel className="padding">
              {i18n.t("Current profession")}
            </StyledLabel>
            <SelectInput
              error={errors.currentProfession}
              name="currentProfession"
              onChange={(val) => {
                handleSelectChange(val, "currentProfession");
              }}
              options={currentProfessionOptions}
              placeholder={SelectPlaceholder}
              isClearable
              value={handleSelectValue(
                currentProfessionOptions,
                "currentProfession"
              )}
            />
          </div>
        ) : (
          ""
        )}
        <div>
          <StyledLabel>
            {i18n.t("What kind of driving license does the main driver hold")} *
          </StyledLabel>
          <SelectInput
            error={errors.driverLicenseType}
            name="driverLicenseType"
            onChange={(val) => {
              handleSelectChange(val, "driverLicenseType");
            }}
            options={licenseOptions}
            placeholder={SelectPlaceholder}
            isClearable
            value={handleSelectValue(licenseOptions, "driverLicenseType")}
          />
        </div>
        {/* {insuranceType === "two-wheeler" ? (
          <FlowAddAdditionalDriver
            handleDrivers={handleDrivers}
            defaultAdditionalDrivers={data?.car?.additionalDrivers}
            defaultValues={defaultValues}
            errors={errors}
            values={values}
          />
        ) : (
          ""
        )} */}
        {affinity === "doceo" &&
        (insuranceType === "car" || insuranceType === "6-wheeler") ? (
          <div>
            <StyledLabel className="padding">
              {i18n.t("What is your home-work commute distance?")} *
            </StyledLabel>
            <SelectInput
              error={errors.home_to_work_distance}
              name="home_to_work_distance"
              onChange={(val) => {
                handleSelectChange(val, "home_to_work_distance");
              }}
              options={workDistanceOptions}
              placeholder={SelectPlaceholder}
              isClearable
              value={handleSelectValue(
                workDistanceOptions,
                "home_to_work_distance"
              )}
            />
          </div>
        ) : (
          ""
        )}
        <ButtonContainer>
          <Required>* {i18n.t("Required fields")}</Required>
          <StyledActionButton
            affinity={affinity}
            type="submit"
            value="Submit"
            data-test-id="drivers_information_submit"
          >
            {i18n.t("Next")}
          </StyledActionButton>
        </ButtonContainer>
      </Suspense>
    </Form>
  );
};

const Required = styled.p`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.typo.label};
  margin-top: 0;
  @media (max-width: 768px) {
    margin: 0 2rem 2rem 2rem;
  }
`;

const StyledActionButton = styled(ActionButton)`
  color: ${({ affinity }) => (affinity === "de-lijn" ? "black" : "white")};
  background-color: ${({ affinity }) =>
    affinity === "callant" || affinity === "uber" || affinity === "at-work"
      ? "#94C11A"
      : "default"};
  font-weight: ${({ affinity }) => (affinity === "de-lijn" ? "700" : "normal")};
`;

const NameInput = styled.p`
  height: 1rem;
`;

const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  height: 9rem;

  & > div + div {
    /* width: 48%; */
    margin-left: 1rem;
  }
`;

const StyledDateInput = styled(DateInput)`
  margin-bottom: 3rem;
`;

const StyledLabel = styled(Label)`
  color: ${({ theme }) => theme.typo.subTitle};
  font-size: 1.5rem;
`;

const Form = styled.form`
  /* height: 100%; */
  display: flex;
  flex-direction: column;
`;

const SelectInput = styled(SearchSelectInput)`
  & .Select__option {
    font-size: 1.6rem;
    color: ${({ theme }) => theme.typo.text};
  }

  & .Select__option--is-focused {
    color: ${({ theme }) => theme.typo.text};
    font-weight: 500;
    background-color: ${({ theme }) => theme.brand.lighter};
    :hover {
      color: white;
      background-color: ${({ theme }) => theme.brand.secondary};
    }
  }
  & div {
    margin-bottom: 0;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: flex-end;

  & button {
    width: 20rem;
    margin-left: auto;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    & button {
      width: 100%;
    }
  }
`;

const CatTitle = styled.h1`
  font-weight: 900;
  font-size: 1.65rem;
  letter-spacing: 1.19px;
  color: black;
  margin-bottom: 2rem;
`;

export default FlowDriversInformationForm;
