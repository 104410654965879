import React from "react";
import styled from "styled-components";

const SvgComponent = ({ colors }) => (
  <StyledSvg viewBox="0 0 50 50" colors={colors} width="100%">
    {/* <path
      d="m200 400c110.46 0 200-89.54 200-200s-89.54-200-200-200-200 89.54-200 200 89.54 200 200 200z"
      fill={colors}
    /> */}
    <g fill="#fff">
      <path d="M43,13.36h5V11H26.5a9.33,9.33,0,0,0-8.67,5.89A2.33,2.33,0,0,0,16,19.2V30.86a2.33,2.33,0,0,0,1.83,2.28A9.33,9.33,0,0,0,26.5,39H48V36.7H43a.19.19,0,0,1-.05-.38l5-1.42h0V15.17h0l-5-1.42A.19.19,0,0,1,43,13.36ZM24.32,36.63a7.75,7.75,0,0,1-4.82-6.26c-.06-.65.89-.93,1.31-.38l4.38,5.69C25.63,36.23,25,36.92,24.32,36.63Zm.87-22.25-4.38,5.69c-.42.55-1.37.27-1.31-.38a7.75,7.75,0,0,1,4.82-6.26C25,13.14,25.63,13.83,25.19,14.38Zm18.34,2a.57.57,0,0,1,.43.76,23.52,23.52,0,0,0,0,15.82.57.57,0,0,1-.43.76L35.3,35.44a.63.63,0,0,1-.67-.33,23.24,23.24,0,0,1,0-20.16.6.6,0,0,1,.67-.33Z" />
      <path d="M9.12,12.93,6.29,11A23.94,23.94,0,0,0,6.88,39L9.63,37a20.52,20.52,0,0,1-.51-24Z" />
      <path d="M12.63,29.9a16.09,16.09,0,0,1,2-14.39l-2.83-2a19.43,19.43,0,0,0,.49,22.85L15,34.31A15.89,15.89,0,0,1,12.63,29.9Z" />
    </g>
  </StyledSvg>
);

const StyledSvg = styled.svg`
  background-color: ${({ colors }) => colors};
  border-radius: 50%;
  padding: 0.6rem;
`;

export default SvgComponent;
