/* eslint-disable react/jsx-key */
import React, { useState, useEffect } from "react";
import { isFuture } from "date-fns";
import { useParams } from "react-router";
import { Label, SearchSelectInput, ActionButton } from "wg-fe-ui";
import useForm from "../hooks/useForm";
import { retrieveStorageById } from "../helpers/storeService.js";
import * as Yup from "yup";
import styled from "styled-components";
import i18n from "../i18n.js";
import DateInput from "./DateInput";
import { TextInput } from "wg-fe-ui";
import {
  string,
  vinNumber,
  licensePlate,
} from "../constants/validationSchemas.js";
import AcknowledgementCheckbox from "./AcknowledgementCheckbox";
import InfoPopup from "../components/InfoPopup";
import { getEnquiries } from "../helpers/acknowledgementService";

const FlowOverviewForm = ({ handleFormValues }) => {
  const { id, affinity, insuranceType } = useParams();
  const { data } = retrieveStorageById(id, affinity, insuranceType) || {};
  const [startDate, setStartDate] = useState("");
  // const [canOverWriteStartDate, setCanOverWriteStartDate] = useState(true);
  // const [chosenInsurances, setChosenInsurances] = useState([]);
  const [defaultValues] = useState({});
  const [selectedScheme, setSelectedScheme] = useState({});

  const enquiries = getEnquiries(insuranceType);

  const DriverScheme = Yup.object().shape({
    startDate: string.required.test(
      "valid-start-date",
      i18n.t("date should be in the future"),
      handleStartDateValidation
    ),
    paymentSpread: string.required,
    licensePlateCar: licensePlate,
    licensePlateTwoWheeler: licensePlate,
    vinNumberCar: vinNumber,
    vinNumberTwoWheeler: vinNumber,
    "Do you wish to terminate your current contract (if applicable)?": Yup.object().shape(
      {
        agreed: Yup.mixed()
          .oneOf([true, false, "none"])
          .nullable(),
      }
    ),
    "Does the main driver have any physical peculiarity or illness that may reduce fitness to drive?": Yup.object().shape(
      {
        agreed: Yup.mixed()
          .oneOf([true, false])
          .required(i18n.t(`required`)),
      }
    ),
    "Has the main driver been convicted or prosecuted for drunkenness, alcohol intoxication, refusal of a blood test or hiding offence?": Yup.object().shape(
      {
        agreed: Yup.mixed()
          .oneOf([true, false])
          .required(i18n.t(`required`)),
      }
    ),
    "Has the main driver already been banned from driving?": Yup.object().shape(
      {
        agreed: Yup.mixed()
          .oneOf([true, false])
          .required(i18n.t(`required`)),
      }
    ),
    "I give permission to Callant Affinity to use my personal data for future marketing campaigns": Yup.object().shape(
      {
        agreed: Yup.mixed()
          .oneOf([true, false])
          .required(i18n.t(`required`)),
      }
    ),
  });

  const TeacherScheme = Yup.object().shape({
    startDate: string.required.test(
      "valid-start-date",
      i18n.t("date should be in the future"),
      handleStartDateValidation
    ),
    paymentSpread: string.required,
    "Do you wish to terminate your current contract (if applicable)?": Yup.object().shape(
      {
        agreed: Yup.mixed()
          .oneOf([true, false, "none"])
          .nullable(),
      }
    ),
    "I give permission to Callant Affinity to use my personal data for future marketing campaigns": Yup.object().shape(
      {
        agreed: Yup.mixed()
          .oneOf([true, false])
          .required(i18n.t(`required`)),
      }
    ),
    "Has the policyholder already subscribed to a family civil liability policy in the past?": Yup.object().shape(
      {
        agreed: Yup.mixed()
          .oneOf([true, false])
          .required(i18n.t(`required`)),
      }
    ),
    "Has the policyholder had any claims in the last 5 years?": Yup.object().shape(
      {
        agreed: Yup.mixed()
          .oneOf([true, false])
          .required(i18n.t(`required`)),
      }
    ),
  });

  const LegalScheme = Yup.object().shape({
    startDate: string.required.test(
      "valid-start-date",
      i18n.t("date should be in the future"),
      handleStartDateValidation
    ),
    paymentSpread: string.required,
    "Do you wish to terminate your current contract (if applicable)?": Yup.object().shape(
      {
        agreed: Yup.mixed()
          .oneOf([true, false, "none"])
          .nullable(),
      }
    ),
    "I give permission to Callant Affinity to use my personal data for future marketing campaigns": Yup.object().shape(
      {
        agreed: Yup.mixed()
          .oneOf([true, false])
          .required(i18n.t(`required`)),
      }
    ),
    "Did the policyholder sign up for a legal aid policy in the past?": Yup.object().shape(
      {
        agreed: Yup.mixed()
          .oneOf([true, false])
          .required(i18n.t(`required`)),
      }
    ),
    "Has the policyholder had any claims in the last 5 years?": Yup.object().shape(
      {
        agreed: Yup.mixed()
          .oneOf([true, false])
          .required(i18n.t(`required`)),
      }
    ),
  });

  const HomeScheme = Yup.object().shape({
    startDate: string.required.test(
      "valid-start-date",
      i18n.t("date should be in the future"),
      handleStartDateValidation
    ),
    paymentSpread: string.required,
    "Do you currently have house insurance?": Yup.object().shape({
      agreed: Yup.mixed()
        .oneOf([true, false])
        .required(i18n.t(`required`)),
    }),
    "Do you wish to cancel your current insurance through us?": Yup.object().shape(
      {
        agreed: Yup.mixed()
          .oneOf([true, false])
          .required(i18n.t(`required`)),
      }
    ),
    "I give Callant permission to use my personal information for future marketing campaigns": Yup.object().shape(
      {
        agreed: Yup.mixed()
          .oneOf([true, false])
          .required(i18n.t(`required`)),
      }
    ),
  });

  const { handleChange, errors, handleSubmit, values } = useForm({
    validationSchema: selectedScheme,
    change: () => {},
  });

  function handleStartDateValidation() {
    const { startDate } = this.parent;
    console.log("!!!!!!!!!!!!!!!!!!!!");
    console.log(startDate);
    console.log(isFuture(generateNewTerminationDate(startDate)));
    return isFuture(generateNewTerminationDate(startDate));
  }

  const paymentOptionsDoceo = [
    { value: "monthly", label: i18n.t("Monthly") },
    { value: "quarterly", label: i18n.t("Quarterly") },
    { value: "half_annual", label: i18n.t("HalfAnnual") },
    { value: "annual", label: i18n.t("Annual") },
  ];

  const paymentOptions = [
    { value: "quarterly", label: i18n.t("Quarterly") },
    { value: "half_annual", label: i18n.t("HalfAnnual") },
    { value: "annual", label: i18n.t("Annual") },
  ];

  function generateNewTerminationDate(teminationDate, proposedYear) {
    const proposedTermDateString = proposedYear
      ? `${teminationDate}/${proposedYear}`
      : teminationDate;
    const [day, month, year] = proposedTermDateString.split("/");
    return new Date(year, month - 1, day);
  }

  useEffect(() => {
    if (!values?.paymentSpread) {
      handleChange({ name: "paymentSpread", value: "annual" });
    }

    handleChange({ name: "startDate", value: startDate });
  }, [startDate]);

  // function handleSelectValue(object, inputName) {
  //   if (!values[inputName]) return "";
  //   const key = values[inputName];
  //   return object.filter(({ value }) => value === key)[0];
  // }

  function handleSelectChange(val, name) {
    handleChange({ name, value: val ? val.value : "" });
  }

  function formSubmitHandler(e) {
    e.preventDefault();
    console.log(values);
    console.log("startDate", startDate);
    if (errors) {
      console.log("ERRORS", errors);
    }

    handleSubmit((values) => {
      handleFormValues(values);
    });
  }

  useEffect(() => {
    switch (insuranceType) {
      case "teacher":
        setSelectedScheme(TeacherScheme);
        break;
      case "legal":
        setSelectedScheme(LegalScheme);
        break;
      case "home":
        setSelectedScheme(HomeScheme);
        break;
      default:
        setSelectedScheme(DriverScheme);
        break;
    }
  }, [insuranceType]);

  useEffect(() => {
    enquiries.forEach((item) => {
      if (item.key === "cancel_current_contract") {
        handleChange({
          name: item.label,
          value: { agreed: false, key: item.key },
        });
      } else {
        handleChange({
          name: item.label,
          value: { agreed: "none", key: item.key },
        });
      }
    });
    Object.keys(defaultValues || {}).forEach((name) => {
      handleChange({ name, value: defaultValues[name] });
    });
  }, [defaultValues]);

  return (
    <Form onSubmit={(e) => formSubmitHandler(e)}>
      <StyledDateInput
        name="startDate"
        value={startDate}
        error={errors.startDate}
        onChange={({ value }) => setStartDate(value)}
      >
        <p>
          {i18n.t(`Start date`)}
          <StyledInfoPopup
            title={i18n.t(`Start date`)}
            buttonText={i18n.t("?")}
            info={i18n.t("startDatePopupText")}
          />
        </p>
      </StyledDateInput>
      {insuranceType === "car" ||
      (insuranceType === "6-wheeler" &&
        ["MOTOR_ALREADY_INSURED", "6WHEELS"].includes(
          data?.selectedFormula
        )) ? (
        <>
          <StyledTextInput
            name="licensePlateCar"
            error={errors.licensePlateCar}
            onChange={(val) => handleChange(val)}
            value={values.licensePlateCar}
            data-lpignore="true"
          >
            {i18n.t("What is license plate of the car?")}
          </StyledTextInput>
          <StyledTextInput
            name="vinNumberCar"
            error={errors.vinNumberCar}
            onChange={(val) => handleChange(val)}
            value={values.vinNumberCar}
          >
            <p>
              {i18n.t("What is the VIN number of the car?")}
              <StyledInfoPopup
                title={i18n.t("What is the VIN number of the car?")}
                buttonText={i18n.t("?")}
                info={i18n.t("vinPopupText")}
              />
            </p>
          </StyledTextInput>
        </>
      ) : (
        ""
      )}
      {insuranceType === "two-wheeler" ||
      (insuranceType === "6-wheeler" &&
        ["MOTOR_COMPANY_CAR", "CAR_ALREADY_INSURED", "6WHEELS"].includes(
          data?.selectedFormula
        )) ? (
        <>
          <StyledTextInput
            name="licensePlateTwoWheeler"
            error={errors.licensePlateTwoWheeler}
            onChange={(val) => handleChange(val)}
            value={values.licensePlateTwoWheeler}
            data-lpignore="true"
          >
            {i18n.t("What is license plate of the motorcycle?")}
          </StyledTextInput>
          <StyledTextInput
            name="vinNumberTwoWheeler"
            error={errors.vinNumberTwoWheeler}
            onChange={(val) => handleChange(val)}
            value={values.vinNumberTwoWheeler}
          >
            <p>
              {i18n.t("What is the VIN number of the motorcycle?")}
              <StyledInfoPopup
                title={i18n.t("What is the VIN number of the motorcycle?")}
                buttonText={i18n.t("?")}
                info={i18n.t("vinPopupText")}
              />
            </p>
          </StyledTextInput>
        </>
      ) : (
        ""
      )}
      {affinity === "doceo" && insuranceType === "teacher" ? (
        <>
          <StyledLabel>{i18n.t("Payment spread")}</StyledLabel>
          <SelectInput
            name="paymentSpread"
            error={errors.paymentSpread}
            onSelected={(val) => {
              handleSelectChange(val, "paymentSpread");
            }}
            options={paymentOptionsDoceo}
            placeholder={paymentOptionsDoceo[3].label}
            isClearable
            // initial={paymentOptionsDoceo[3]}
          />
        </>
      ) : (
        <>
          <StyledLabel>{i18n.t("Payment spread")}</StyledLabel>
          <SelectInput
            name="paymentSpread"
            error={errors.paymentSpread}
            onSelected={(val) => {
              handleSelectChange(val, "paymentSpread");
            }}
            options={paymentOptions}
            placeholder={paymentOptions[2].label}
            isClearable
            // initial={paymentOptions[2]}
          />
        </>
      )}
      <SelectedCoverages>
        {enquiries.map((item) => {
          return (
            <QuestionContainer>
              <AcknowledgementCheckbox
                itemKey={item.key}
                key={item.key}
                name={item.label}
                checked={values[item.label]?.agreed}
                onChange={handleChange}
                error={errors[`${item.label}.agreed`]}
              >
                {i18n.t(item.label)}
              </AcknowledgementCheckbox>
              {item.helper ? <InfoPopup info={i18n.t(item.helper)} /> : ""}
            </QuestionContainer>
          );
        })}
      </SelectedCoverages>
      {data?.moderator ? (
        <>
          <StyledLabel>{i18n.t("Moderator mode")}</StyledLabel>
          <QuestionContainer>
            <ModeratorCheckbox
              itemKey={"send_mail_to_customer"}
              key={"send_mail_to_customer"}
              name={"send_mail_to_customer"}
              checked={values?.["send_mail_to_customer"]?.agreed}
              onChange={handleChange}
            >
              {i18n.t(
                "Do you want to send the offer automatically to the customer"
              )}
            </ModeratorCheckbox>
          </QuestionContainer>
        </>
      ) : (
        ""
      )}
      <ButtonContainer>
        <StyledActionButton
          affinity={affinity}
          type="submit"
          value="Submit"
          data-test-id="drivers_information_submit"
        >
          {i18n.t("Confirm")}
          <StyledInfoPopup
            title={i18n.t("Confirm")}
            buttonText={i18n.t("?")}
            info={i18n.t("confirmPopupText")}
          />
        </StyledActionButton>
      </ButtonContainer>
    </Form>
  );
};

const QuestionContainer = styled.div`
  position: relative;
  a {
    position: absolute;
    top: 2rem;
    right: 1rem;
    width: 2rem;
    height: 2rem;
    font-size: 1.4rem;
  }
`;

const StyledActionButton = styled(ActionButton)`
  color: ${({ affinity }) => (affinity === "de-lijn" ? "black" : "white")};
  background-color: ${({ affinity }) =>
    affinity === "callant" || affinity === "uber" || affinity === "at-work"
      ? "#94C11A"
      : "default"};
  font-weight: ${({ affinity }) => (affinity === "de-lijn" ? "700" : "normal")};
`;

const ButtonContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: flex-end;
  @media (max-width: 768px) {
    margin-left: 0;
  }

  & button {
    margin-left: auto;
    margin-top: 2rem;
    width: 20rem;

    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;

const SelectedCoverages = styled.div`
  h1 {
    font-weight: bold;
    font-size: 2.5rem;
    padding-bottom: 3.5rem;
    letter-spacing: 0.07rem;
    text-align: center;
    text-decoration: underline;
  }
  margin-top: 3rem;
`;

const SelectInput = styled(SearchSelectInput)`
  & .Select__option {
    font-size: 1.6rem;
    color: ${({ theme }) => theme.typo.text};
  }

  & .Select__option--is-focused {
    color: ${({ theme }) => theme.typo.text};
    font-weight: 500;
    background-color: ${({ theme }) => theme.brand.lighter};
    :hover {
      color: white;
      background-color: ${({ theme }) => theme.brand.secondary};
    }
  }
  & div {
    margin-bottom: 0;
  }
`;

const StyledLabel = styled(Label)`
  color: ${({ theme }) => theme.typo.subTitle};
  font-size: 1.5rem;
`;

const StyledDateInput = styled(DateInput)`
  padding-top: 2rem;
  padding-bottom: 2rem;
`;

const StyledTextInput = styled(TextInput)`
  margin-bottom: 2rem;
  div {
    /* margin-top: 2.5rem; */
    p {
      color: #f74040;
    }
  }
`;

const Form = styled.form`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const ModeratorCheckbox = styled(AcknowledgementCheckbox)`
  margin-top: 2rem;
`;

const StyledInfoPopup = styled(InfoPopup)`
  float: right;
  font-size: 1.2rem;
  height: 1.6rem;
  line-height: 1.3;
  width: 1.6rem;
  right: 0;
`;

export default FlowOverviewForm;
