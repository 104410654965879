import React, { useEffect, useState } from "react";
import styled from "styled-components";
import useForm from "../hooks/useForm.js";
import {
  TextInput,
  SearchSelectInput,
  ActionButton,
  IconCarFilled,
  IconVanFilled,
  Label,
} from "wg-fe-ui";
import * as Yup from "yup";
import {
  string,
  year,
  number,
  seats,
  catalogueValue,
} from "../constants/validationSchemas.js";
import { fuelType } from "../constants/FlowSearchSelectData.js";
import OptionsPriceInput from "./VATInputSwitcherV2";
import i18n from "../i18n";
import { patchStorage, retrieveStorageById } from "../helpers/storeService.js";
import { useParams } from "react-router";
import InfoPopup from "./InfoPopup";

const FlowManualVehicleForm = ({
  defaultValues,
  resetImage,
  renderNextRoute,
}) => {
  const { id, affinity, insuranceType } = useParams();
  const [fuelTypeOptions, setFuelTypeOptions] = useState([]);
  const [vehicleType, setVehicleType] = useState("PASSENGER_CAR");
  const SelectPlaceholder = i18n.t("Choose your option");
  const [sumOptionsCatValError] = useState("");

  const validationSchema = Yup.object().shape({
    brand: string.required,
    model: string.required,
    motorType: string.required,
    version: string.notRequired,
    registrationYear: year.required.test(
      "valid-registration-year",
      i18n.t("Date cant be in the future"),
      handleRegistrationYear
    ),
    kw: number.required,
    seats: seats,
    options: number.notRequired,
    catalogueValue: catalogueValue,
  });

  const { errors, values, handleSubmit, handleChange } = useForm({
    validationSchema,
  });

  useEffect(() => {
    Object.keys(defaultValues || {}).forEach((name) => {
      if (name === "carImg") {
        resetImage(defaultValues[name]);
      }
      if (name === "vehicleType") {
        setVehicleType(defaultValues[name]);
      } else {
        handleChange({ name, value: defaultValues[name] });
      }
    });
  }, [defaultValues]);

  useEffect(() => {
    if (affinity === "honda" || affinity === "mazda") {
      const affinityCapitalized =
        affinity.charAt(0).toUpperCase() + affinity.slice(1);
      handleChange({ name: "brand", value: affinityCapitalized });
    }
  }, []);

  useEffect(() => {
    if (affinity === "one_automotive") {
      const affinityCapitalized = "DFSK";
      handleChange({ name: "brand", value: affinityCapitalized });
    }
  }, []);

  function handleRegistrationYear() {
    const { registrationYear } = this.parent;
    const firstRegistrationDate = new Date(registrationYear, 0, 1);
    return firstRegistrationDate <= new Date();
  }

  const formSubmitHandler = (e) => {
    e.preventDefault();
    handleSubmit(handleFormValues);
  };

  const handleFormValues = () => {
    if (values.options === null) {
      values.options = 0;
    }

    // if (parseInt(values.options) + parseInt(values.catalogueValue) > 75000) {
    //   SetSumOptionsCatValError(
    //     "The max amount allowed for sum of options + catalogue value is 75"
    //   );
    //   return;
    // } else {
    //   SetSumOptionsCatValError("");
    // }

    Object.keys(values).forEach((key) => {
      const { data } = retrieveStorageById(id, affinity, insuranceType);
      patchStorage({
        payload: {
          car: {
            ...data?.car,
            details: {
              ...data?.car?.details,
              ...values,
              vehicleType: vehicleType,
            },
          },
        },
        id,
        affinity,
        insuranceType,
      });
    });
    renderNextRoute();
  };

  useEffect(() => {
    loadSelectOptions();
  }, []);

  function loadSelectOptions() {
    setFuelTypeOptions(
      fuelType.map(({ value, label }) => {
        return { value, label: i18n.t(label) };
      })
    );
  }

  function handleSelectValue(object, inputName) {
    if (!values[inputName]) return "";
    const key = values[inputName];
    return object.filter(({ value }) => value === key)[0];
  }

  function handleSelectChange(val, name) {
    handleChange({ name, value: val ? val.value : "" });
  }

  const handleRadioInput = (e) => {
    const value = e.currentTarget.value;
    setVehicleType(value);
  };

  return (
    <Form onSubmit={formSubmitHandler}>
      {!["honda", "mazda", "one_automotive"].includes(affinity) ? (
        <div>
          <Label>{i18n.t("Car type")}</Label>
          <RadioBtnContainer>
            <StyledInput
              onChange={(e) => handleRadioInput(e)}
              type="radio"
              checked={vehicleType === "PASSENGER_CAR"}
              name="PASSENGER_CAR"
              id="PASSENGER_CAR"
              value="PASSENGER_CAR"
            />
            <StyledInputLabel htmlFor="PASSENGER_CAR">
              <IconCarFilled />
              <p>{i18n.t("PASSENGER_CAR")}</p>
            </StyledInputLabel>
            <StyledInput
              onChange={(e) => handleRadioInput(e)}
              type="radio"
              name="LIGHT_COMMERCIAL_VEHICLE"
              checked={vehicleType === "LIGHT_COMMERCIAL_VEHICLE"}
              id="LIGHT_COMMERCIAL_VEHICLE"
              value="LIGHT_COMMERCIAL_VEHICLE"
            />
            <StyledInputLabel htmlFor="LIGHT_COMMERCIAL_VEHICLE">
              <IconVanFilled />
              <p>{i18n.t("LIGHT_COMMERCIAL_VEHICLE")}</p>
            </StyledInputLabel>
          </RadioBtnContainer>
        </div>
      ) : (
        ""
      )}
      <FlexWrapper>
        <StyledTextInput
          name="brand"
          error={errors.brand}
          onChange={(val) => {
            handleChange(val);
          }}
          disabled={affinity === "honda" || affinity === "mazda" ? true : false}
          value={values.brand}
        >
          {i18n.t("Brand")} *
        </StyledTextInput>
        <StyledTextInput
          name="model"
          error={errors.model}
          onChange={(val) => {
            handleChange(val);
          }}
          value={values.model}
        >
          {i18n.t("Model")} *
        </StyledTextInput>
      </FlexWrapper>
      <FlexWrapper>
        <StyledTextInput
          name="version"
          error={errors.version}
          onChange={(val) => {
            handleChange(val);
          }}
          value={values.version}
        >
          {i18n.t("Version")}
        </StyledTextInput>
        <StyledHelperInput
          name="seats"
          type="number"
          error={errors.seats}
          onChange={(val) => {
            console.log(val);
            handleChange(val);
          }}
          value={values.seats}
        >
          {i18n.t("Seats")} *
          <StyledInfoPopup title={i18n.t("Seats")} info={i18n.t("seatsInfo")} />
        </StyledHelperInput>
      </FlexWrapper>
      <SearchSelectInput
        onChange={(val) => {
          handleSelectChange(val, "motorType");
        }}
        name="motorType"
        error={errors.motorType}
        options={fuelTypeOptions}
        value={handleSelectValue(fuelTypeOptions, "motorType")}
        placeholder={SelectPlaceholder}
      >
        {i18n.t("Fuel type")}
      </SearchSelectInput>
      <FlexWrapper>
        <StyledHelperInput
          name="registrationYear"
          error={errors.registrationYear}
          onChange={(val) => {
            handleChange(val);
          }}
          value={values.registrationYear}
        >
          <StyledLabel>
            {i18n.t("Registration year")} *
            <StyledInfoPopup
              margin-top="-.55rem"
              title={i18n.t("Registration year")}
              info={i18n.t("Registration year info")}
            />
          </StyledLabel>
        </StyledHelperInput>
        <StyledTextInput
          name="kw"
          error={errors.kw}
          onChange={(val) => {
            handleChange(val);
          }}
          value={values.kw}
        >
          <StyledLabel>{i18n.t("kw")} *</StyledLabel>
          <p className="valueType">{i18n.t("KW")}</p>
        </StyledTextInput>
      </FlexWrapper>
      <OptionsPriceInput
        name="catalogueValue"
        id="catalogueValue"
        error={errors.catalogueValue}
        onChange={({ value, isVATInclusive }) =>
          handleChange({
            name: "catalogueValue",
            value: isVATInclusive ? (value / 1.21).toFixed(2) : value,
          })
        }
        value={values.catalogueValue}
        defaultValue={defaultValues?.catalogueValue}
        popUpTitle={i18n.t("Invoice value")}
        popUpInfo={i18n.t(
          "The invoice value of the car is the new price This price does not include discounts, trade-in conditions and show-room conditions The entry of the value of your car can be done excluding or including VAT, by clicking on the `Excl/Incl VAT` button"
        )}
      >
        {i18n.t("Invoice value")} *
      </OptionsPriceInput>
      <CatalogueValueDetail>
        {errors.catalogueValue
          ? ""
          : i18n.t("Carefully check the details on your invoice / order form")}
      </CatalogueValueDetail>
      <OptionsPriceInput
        name="options"
        id="options"
        error={errors.options}
        onChange={({ value, isVATInclusive }) =>
          handleChange({
            name: "options",
            value: isVATInclusive ? (value / 1.21).toFixed(2) : value,
          })
        }
        value={values.options}
        defaultValue={defaultValues?.options}
        popUpTitle={i18n.t("Value of additional options")}
        popUpInfo={i18n.t("optionsValuePopupText")}
      >
        <Options>{i18n.t("Value of additional options")}</Options>
      </OptionsPriceInput>
      <SumError>{i18n.t(sumOptionsCatValError)}</SumError>
      <ButtonContainer>
        <Required>* {i18n.t("Required fields")}</Required>
        <StyledActionButton
          affinity={affinity}
          type="submit"
          data-test-id="manual_vehicle_flow_submit"
        >
          {i18n.t("Next")}
        </StyledActionButton>
      </ButtonContainer>
    </Form>
  );
};

const SumError = styled.p`
  font-size: 1.2rem;
  color: #f74040;
  margin-top: -2rem;
  margin-bottom: 2rem;
`;

const Required = styled.p`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.typo.label};
  margin-top: 2rem;
  @media (max-width: 768px) {
    margin: 0 2rem 2rem 2rem;
  }
`;

const RadioBtnContainer = styled.div`
  display: grid;
  grid-template-columns: 49% 49%;
  grid-gap: 1rem;
  padding: 1rem 0 3rem 0;
  @media (max-width: 520px) {
    grid-template-columns: auto;
  }
`;

const StyledInput = styled.input`
  display: none;

  &:checked + label {
    justify-content: center;
    background-color: ${({ theme }) => theme.brand.lightest};
    border-color: ${({ theme }) => theme.brand.primary};
    @media (max-width: 1350px) {
      width: 100%;
      justify-content: center;
    }
  }
`;

const StyledInputLabel = styled.label`
  justify-content: center;
  border: 0.1rem solid rgb(204, 204, 204);
  color: rgb(80, 80, 80);
  border-style: solid;
  border-image: initial;
  border-radius: 0.2rem;
  height: 6rem;
  align-items: center;
  padding: 1rem;
  cursor: pointer;

  display: grid;
  grid-template-columns: 20% 80%;

  svg {
    left: 1rem;
    stroke: ${({ theme }) => theme.brand.primary};
    path {
      fill: ${({ theme }) => theme.brand.primary};
    }
  }
  @media (max-width: 1350px) {
    width: 100%;
    justify-content: center;
  }
  p {
    text-align: center;
    @media screen and (max-width: 425px) {
      overflow-wrap: anywhere;
      font-size: 1.4rem;
      width: 85%;
      padding-left: 1rem;
    }
    @media (max-width: 520px) {
      grid-template-columns: 10% 80%;
    }
  }
`;

const StyledActionButton = styled(ActionButton)`
  color: ${({ affinity }) => (affinity === "de-lijn" ? "black" : "white")};
  background-color: ${({ affinity }) =>
    affinity === "callant" || affinity === "uber" || affinity === "at-work"
      ? "#94C11A"
      : "default"};
  font-weight: ${({ affinity }) => (affinity === "de-lijn" ? "700" : "normal")};
`;

const StyledLabel = styled.div`
  height: 1rem;
  max-width: 90%;
  a {
    margin-top: -0.55rem;
  }
`;

const Options = styled.span`
  flex-direction: column;
  position: relative;
  width: 100%;
  font-size: 1.4rem;
  color: #5b5550;
  line-height: 1.1rem;
  display: flex;
  height: 1rem;
  margin: 0;
`;

const Form = styled.form``;

const CatalogueValueDetail = styled.p`
  color: grey;
  font-size: 1.3rem;
  margin: -2rem 0 2rem 0.5rem;
`;

const StyledTextInput = styled(TextInput)`
  height: 9rem;
  p.valueType {
    padding: 1.4rem;
    border-left: 1px solid #cccccc;
    position: absolute;
    margin: 0;
    right: 0rem;
    top: 2.5rem;
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  & > div {
    width: 48%;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: flex-end;

  & button {
    width: 20rem;
    margin-left: auto;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    & button {
      width: 100%;
    }
  }
`;

const StyledHelperInput = styled(StyledTextInput)`
  right: 0;
`;

const StyledInfoPopup = styled(InfoPopup)`
  float: right;
  font-size: 1.2rem;
  height: 1.6rem;
  line-height: 1.3;
  width: 1.6rem;
  right: 0;
  position: absolute;
`;

export default FlowManualVehicleForm;
