import React, { Suspense, useEffect, useState } from "react";
import {
  Switch,
  Route,
  Redirect,
  HashRouter as Router,
} from "react-router-dom";
import { verifyInit } from "../helpers/apiRouterService";
import { loggedIn, setLoggedIn } from "../helpers/authService";
import styled from "styled-components";
import cogoToast from "cogo-toast";
import { useTranslation } from "react-i18next";

import Flow from "../pages/Flow";
import LoadingSpinner from "../components/LoadingSpinner.jsx";
import { doesFlowExist } from "../helpers/tagRouterService";
import { getNestedObject } from "../helpers/objectService";

function RouteFlowMustExist({ component: Component, ...rest }) {
  // Only render component if the flow for that company and insurancetype exists else redirect to not-possible page
  return (
    <Route
      {...rest}
      path="/:affinity/:insuranceType/verify-user"
      render={(props) =>
        doesFlowExist({
          affinity: getNestedObject(props, ["match", "params", "affinity"]),
          insuranceType: getNestedObject(props, [
            "match",
            "params",
            "insuranceType",
          ]),
        }) ? (
          <Component {...rest} />
        ) : (
          <Redirect
            to={{
              pathname: `/${getNestedObject(props, [
                "match",
                "params",
                "affinity",
              ])}/${getNestedObject(props, [
                "match",
                "params",
                "insuranceType",
              ])}/not-possible`,
            }}
          />
        )
      }
    />
  );
}

function PrivateRoute({ component: Component, ...rest }) {
  const [authorized, setAuthorized] = useState("initial"); // initial / authenticated / forbidden

  useEffect(() => {
    loggedIn ? setAuthorized("authenticated") : fetchAuthorization();
  }, []);

  async function fetchAuthorization() {
    const [, status] = await verifyInit();
    if (status === 200) setAuthorized("authenticated");
    else setAuthorized("forbidden");
  }

  switch (authorized) {
    case "initial":
      return <LoadingSpinner />;
    case "authenticated":
      setLoggedIn(true);
      return <RouteFlowMustExist {...rest} component={Component} />;
    case "forbidden":
      return (
        <Route
          {...rest}
          render={(props) => (
            <Redirect
              to={{
                pathname: `/${getNestedObject(props, [
                  "match",
                  "params",
                  "affinity",
                ])}/${getNestedObject(props, [
                  "match",
                  "params",
                  "insuranceType",
                ])}/verify-user`,
              }}
            />
          )}
        />
      );
    default:
      return (
        <Route
          {...rest}
          render={(props) => (
            <Redirect
              to={{
                pathname: `/${getNestedObject(props, [
                  "match",
                  "params",
                  "affinity",
                ])}/${getNestedObject(props, [
                  "match",
                  "params",
                  "insuranceType",
                ])}/verify-user`,
              }}
            />
          )}
        />
      );
  }
}

localStorage.setItem("i18nextLng", "nl");

const Main = () => {
  const { t } = useTranslation();

  // show popup if user is on internet explorer
  useEffect(() => {
    var ua = window.navigator.userAgent; // Check the userAgent property of the window.navigator object
    var msie = ua.indexOf("MSIE "); // IE 10 or older
    var trident = ua.indexOf("Trident/"); //IE 11

    if (msie > 0 || trident > 0) {
      cogoToast.warn(<ToastContainer>{t('ieWarning')}</ToastContainer>, {
        hideAfter: 10,
      });
    }
  }, []);

  return (
    <Suspense fallback={<LoadingSpinner />}>
      <Router>
        <Switch>
          <RouteFlowMustExist
            path="/:affinity/:insuranceType/verify-user"
            component={Flow}
          />
          <Route
            path="/:affinity/:insuranceType/not-possible"
            component={Flow}
          />
          <PrivateRoute
            path="/:affinity/:insuranceType/session"
            component={Flow}
          />
          <Redirect exact from="/" to="/session" />
          <Route
            exact
            path="/:affinity/:insuranceType"
            render={(props) => (
              <Redirect
                to={`/${getNestedObject(props, [
                  "match",
                  "params",
                  "affinity",
                ])}/${getNestedObject(props, [
                  "match",
                  "params",
                  "insuranceType",
                ])}/session`}
              />
            )}
          />
        </Switch>
      </Router>
    </Suspense>
  );
};

const ToastContainer = styled.p`
  text-align: center;
  width: 70vw;
  font-size: 1.6rem;
  font-weight: 700;
`;

export default Main;
