import CallantLogo from "../assets/images/brand-logos/Callant.png";
const general = [];

export const callantHome = {
  brand: {
    name: "Callant",
    logo: CallantLogo,
  },
  flow: [
    ...general,
    {
      title: "user-address",
      step: 1,
      subStep: 1,
      nextRoutes: {
        1: "risk-address",
        2: "home-information",
      },
    },
    {
      title: "risk-address",
      step: 1,
      subStep: 2,
      nextRoutes: {
        1: "home-information",
      },
    },
    {
      title: "home-information",
      step: 1,
      subStep: 3,
      nextRoutes: {
        1: "home-information-extra",
      },
    },
    {
      title: "home-information-extra",
      step: 2,
      subStep: 1,
      nextRoutes: {
        1: "home-room-information",
      },
    },
    {
      title: "home-room-information",
      step: 2,
      subStep: 2,
      nextRoutes: {
        1: "home-specials",
      },
    },
    {
      title: "home-specials",
      step: 2,
      subStep: 3,
      nextRoutes: {
        1: "home-overview",
      },
    },
    {
      title: "home-overview",
      step: 2,
      subStep: 4,
      nextRoutes: {
        1: "thankyou",
      },
    },
    {
      title: "thankyou",
      step: 3,
      subStep: 1,
      nextRoutes: {
        1: "verify-user",
      },
    },
  ],
};

export const callantCar = {
  brand: {
    name: "Callant",
    logo: CallantLogo,
  },
  flow: [
    ...general,
    {
      title: "user-address",
      step: 1,
      subStep: 1,
      nextRoutes: {
        1: "drivers-information",
      },
    },
    {
      title: "drivers-information",
      step: 1,
      subStep: 2,
      nextRoutes: {
        1: "drivers-information-extra",
      },
    },
    {
      title: "drivers-information-extra",
      step: 1,
      subStep: 3,
      nextRoutes: {
        1: "search-vehicle",
      },
    },
    {
      title: "search-vehicle",
      step: 2,
      subStep: 0,
      nextRoutes: {
        1: "car-information",
      },
    },
    {
      title: "car-information",
      step: 2,
      subStep: 1,
      nextRoutes: {
        1: "safety-systems",
      },
    },
    {
      title: "safety-systems",
      step: 2,
      subStep: 2,
      nextRoutes: {
        1: "price-calculator",
        2: "acceptance-conditions",
      },
    },
    {
      title: "acceptance-conditions",
      step: 2,
      subStep: 3,
      nextRoutes: {},
    },
    {
      title: "price-calculator",
      step: 3,
      subStep: 0,
      nextRoutes: {
        1: "overview",
      },
    },
    {
      title: "overview",
      step: 4,
      subStep: 0,
      nextRoutes: {
        1: "thankyou",
      },
    },
    {
      title: "thankyou",
      step: 4,
      subStep: 1,
      nextRoutes: {
        1: "verify-user",
      },
    },
  ],
};

export const callantTwoWheeler = {
  brand: {
    name: "Callant",
    logo: CallantLogo,
  },
  flow: [
    ...general,
    {
      title: "user-address",
      step: 1,
      subStep: 2,
      nextRoutes: {
        1: "bike-type",
      },
    },
    {
      title: "bike-type",
      step: 1,
      subStep: 2,
      nextRoutes: {
        1: "drivers-information-bike",
      },
    },
    {
      title: "drivers-information-bike",
      step: 1,
      subStep: 3,
      nextRoutes: {
        1: "search-bike",
      },
    },
    {
      title: "search-bike",
      step: 2,
      subStep: 0,
      nextRoutes: {
        1: "bike-information",
      },
    },
    {
      title: "bike-information",
      step: 2,
      subStep: 1,
      nextRoutes: {
        1: "bike-price-calculator",
        2: "bike-price-light-scooter-calculator",
        3: "acceptance-conditions",
      },
    },
    {
      title: "acceptance-conditions",
      step: 2,
      subStep: 2,
      nextRoutes: {},
    },
    {
      title: "bike-price-light-scooter-calculator",
      step: 3,
      subStep: 0,
      nextRoutes: {
        1: "overview",
      },
    },
    {
      title: "bike-price-calculator",
      step: 3,
      subStep: 0,
      nextRoutes: {
        1: "overview",
      },
    },
    {
      title: "overview",
      step: 3,
      subStep: 1,
      nextRoutes: {
        1: "thankyou",
      },
    },
    {
      title: "thankyou",
      step: 4,
      subStep: 1,
      nextRoutes: {
        1: "verify-user",
      },
    },
  ],
};

export const callantTeacher = {
  brand: {
    name: "Callant",
    logo: CallantLogo,
  },
  flow: [
    ...general,
    {
      title: "user-address",
      step: 1,
      subStep: 1,
      nextRoutes: {
        1: "insurance-questions",
      },
    },
    {
      title: "insurance-questions",
      step: 1,
      subStep: 2,
      nextRoutes: {
        1: "bike-teacher-calculator",
        2: "callant-search-vehicle",
      },
    },
    {
      title: "bike-teacher-calculator",
      step: 2,
      subStep: 1,
      nextRoutes: {
        1: "overview",
      },
    },
    {
      title: "overview",
      step: 3,
      subStep: 1,
      nextRoutes: {
        1: "thankyou",
      },
    },
    {
      title: "thankyou",
      step: 4,
      subStep: 1,
      nextRoutes: {
        1: "verify-user",
      },
    },
  ],
};

export const callantLegal = {
  brand: {
    name: "Callant",
    logo: CallantLogo,
  },
  flow: [
    ...general,
    {
      title: "user-address",
      step: 1,
      subStep: 1,
      nextRoutes: {
        1: "insurance-pack",
      },
    },
    {
      title: "insurance-pack",
      step: 1,
      subStep: 2,
      nextRoutes: {
        1: "legal-calculator",
      },
    },
    {
      title: "legal-calculator",
      step: 2,
      subStep: 1,
      nextRoutes: {
        1: "overview",
      },
    },
    {
      title: "overview",
      step: 3,
      subStep: 1,
      nextRoutes: {
        1: "thankyou",
      },
    },
    {
      title: "thankyou",
      step: 4,
      subStep: 1,
      nextRoutes: {
        1: "verify-user",
      },
    },
  ],
};

export const callant6Wheeler = {
  brand: {
    name: "Callant",
    logo: CallantLogo,
  },
  flow: [
    ...general,
    {
      title: "user-address",
      step: 1,
      subStep: 1,
      nextRoutes: {
        1: "bike-type",
      },
    },
    {
      title: "bike-type",
      step: 1,
      subStep: 2,
      nextRoutes: {
        1: "drivers-information",
        2: "insurance-type-moto",
      },
    },
    {
      title: "drivers-information",
      step: 1,
      subStep: 2,
      nextRoutes: {
        1: "drivers-information-extra",
      },
    },
    {
      title: "drivers-information-extra",
      step: 1,
      subStep: 3,
      nextRoutes: {
        1: "search-vehicle",
      },
    },
    {
      title: "search-vehicle",
      step: 2,
      subStep: 0,
      nextRoutes: {
        1: "car-information",
      },
    },
    {
      title: "car-information",
      step: 2,
      subStep: 1,
      nextRoutes: {
        1: "safety-systems",
      },
    },
    {
      title: "safety-systems",
      step: 2,
      subStep: 2,
      nextRoutes: {
        1: "price-calculator",
        2: "insurance-type-moto",
        3: "acceptance-conditions",
      },
    },
    {
      title: "insurance-type-moto",
      step: 3,
      subStep: 0,
      nextRoutes: {
        1: "drivers-information-bike",
      },
    },
    {
      title: "drivers-information-bike",
      step: 3,
      subStep: 1,
      nextRoutes: {
        1: "search-bike",
      },
    },
    {
      title: "search-bike",
      step: 3,
      subStep: 2,
      nextRoutes: {
        1: "bike-information",
      },
    },
    {
      title: "bike-information",
      step: 3,
      subStep: 3,
      nextRoutes: {
        1: "bike-price-calculator",
        2: "bike-price-light-scooter-calculator",
        3: "acceptance-conditions",
        4: "price-calculator",
      },
    },
    {
      title: "acceptance-conditions",
      step: 4,
      subStep: 0,
      nextRoutes: {},
    },
    {
      title: "price-calculator",
      step: 4,
      subStep: 0,
      nextRoutes: {
        1: "overview",
        2: "bike-price-calculator",
        3: "bike-price-light-scooter-calculator",
      },
    },
    {
      title: "bike-price-light-scooter-calculator",
      step: 4,
      subStep: 0,
      nextRoutes: {
        1: "overview",
        2: "insurance-type-moto",
      },
    },
    {
      title: "bike-price-calculator",
      step: 4,
      subStep: 0,
      nextRoutes: {
        1: "overview",
        2: "insurance-type-moto",
      },
    },
    {
      title: "overview",
      step: 5,
      subStep: 0,
      nextRoutes: {
        1: "thankyou",
      },
    },
    {
      title: "thankyou",
      step: 5,
      subStep: 1,
      nextRoutes: {
        1: "verify-user",
      },
    },
  ],
};
