import React from "react";
import styled from "styled-components";
import i18n from "../i18n";

import { useParams } from "react-router";

import FlowLeftSideAvatar from "../components/FlowLeftSideAvatar.jsx";

const Login = () => {
  const {affinity, insuranceType} = useParams();
  return (
    <SplitContainer>
      <FlowLeftSideAvatar
        chatText={i18n.t(
          `De ${insuranceType} flow bestaat niet voor ${affinity}`
        )}
      />
      <RightSplit>
        <h1>ONMOGELIJK</h1>
      </RightSplit>
    </SplitContainer>
  );
};

const SplitContainer = styled.div`
  display: flex;
  height: 100%;
`;

const RightSplit = styled.div`
  width: 50%;
  padding: 5rem;
  overflow-y: auto;

  @media (max-width: 768px) {
    width: 100%;
  }

  @media screen and (max-width: 425px) {
    padding: 4rem 2.5rem;
  }
`;

export default Login;
