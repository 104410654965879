import React from "react";

const SvgComponent = ({ colors }) => (
  <svg width="100%" viewBox="0 0 50 50">
    <path
      fill={colors}
      d="M48.58,28.34c-1.92-2.39-9.83-3-13.13-3.14a5.17,5.17,0,0,1-2.69-.92L27,20.2C13.91,18.69,4.42,26.08,1.63,28.55a2.07,2.07,0,0,0-.57,2l.78,3.09A1.71,1.71,0,0,0,3.43,35H7.1a5,5,0,0,0,9.8,0H34.1a5,5,0,0,0,9.8,0h2.58a1.66,1.66,0,0,0,1.59-1.37l.87-3.5A2.17,2.17,0,0,0,48.58,28.34ZM12,37a3,3,0,0,1-2.82-2,2.68,2.68,0,0,1-.18-1,3,3,0,0,1,6,0,2.68,2.68,0,0,1-.18,1A3,3,0,0,1,12,37Zm27,0a3,3,0,0,1-2.82-2,2.68,2.68,0,0,1-.18-1,3,3,0,0,1,6,0,2.68,2.68,0,0,1-.18,1A3,3,0,0,1,39,37Z"
    />
  </svg>
);

export default SvgComponent;
