import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { ActionButton } from "wg-fe-ui";
import i18n from "../i18n";
import InfoPopup from "./InfoPopup";
import CurrencyInput from "./CurrencyInput";
import { useParams } from "react-router";

const OptionsPriceInput = ({
  name,
  error,
  value,
  onChange,
  defaultValue,
  children,
  popUpTitle,
  popUpInfo,
}) => {
  const [isVATInclusive, setIsVATInclusive] = useState(false);
  const [stateValue, setStateValue] = useState(value);
  const { affinity } = useParams();

  useEffect(() => {
    setStateValue(
      isVATInclusive ? (defaultValue * 1.21).toFixed(2) : defaultValue
    );
  }, [defaultValue]);

  const handleVATChange = () => {
    if (isVATInclusive) {
      setStateValue((stateValue / 1.21).toFixed(2));
    } else {
      setStateValue((stateValue * 1.21).toFixed(2));
    }
    setIsVATInclusive(!isVATInclusive);
  };

  return (
    <BTWContainer>
      <InputContainer>
        <CurrencyInput
          value={stateValue ? stateValue : 0}
          error={error}
          name={name}
          onChange={({ value }) => {
            setStateValue(value);
            onChange({ value, isVATInclusive });
          }}
        >
          {children}
        </CurrencyInput>
        {popUpTitle || popUpInfo ? (
          <StyledInfoPopup title={popUpTitle} info={popUpInfo} />
        ) : (
          ""
        )}
        <EuroSign>EUR</EuroSign>
      </InputContainer>
      <InvoiceContainer>
        <BTWButton affinity={affinity} type="button" onClick={handleVATChange}>
          {isVATInclusive ? i18n.t("Incl VAT") : i18n.t("Excl VAT")}
        </BTWButton>
      </InvoiceContainer>
    </BTWContainer>
  );
};

const BTWContainer = styled.div`
  display: flex;
  align-items: center;

  display: grid;
  grid-gap: 1rem;
  grid-template-columns: auto auto;
`;

const BTWButton = styled(ActionButton)`
  justify-content: center;
  background-color: ${({ theme, affinity }) =>
    affinity === "callant" ? theme.brand.primary : theme.brand.secondary};
  color: white;

  font-size: 1.2rem;
  margin-top: 2.4rem;
  height: 3.8rem;
  margin-right: 0;
  /* @media screen and (max-width: 425px) {
    min-width: 12rem;
  } */
`;

const InvoiceContainer = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: 0rem auto;
  width: 100%;
`;

const EuroSign = styled.span`
  border-left: 1px solid #cccccc;
  padding: 1.1rem;
  position: absolute;
  right: 0;
  top: 2.5rem;

  @media screen and (max-width: 425px) {
    font-size: 1.25rem;
    padding: 1.3rem 0.75rem;
  }
`;

const InputContainer = styled.div`
  position: relative;
  width: 100%;
  margin-right: 1rem;

  @media screen and (max-width: 425px) {
    font-size: 1.4rem;
  }
`;

const StyledInfoPopup = styled(InfoPopup)`
  float: right;
  font-size: 1.2rem;
  justify-self: end;
  height: 1.6rem;
  line-height: 1.3;
  width: 1.6rem;
  right: 0;
  top: 0;
  position: absolute;
`;

export default OptionsPriceInput;
