import React, { useState, useEffect } from "react";
import styled from "styled-components";
import i18n from "../i18n";
import bronze from "../assets/callant/bronze.svg";
import silver from "../assets/callant/silver.svg";
import gold from "../assets/callant/gold.svg";
import { patchStorage } from "../helpers/storeService";
import { useParams } from "react-router";
import useRouting from "../hooks/useRouting.js";
import { ActionButton } from "wg-fe-ui";
import ListItem from "../components/ListItem";
import FlowLeftSideAvatar from "../components/FlowLeftSideAvatar.jsx";

const FlowInsurancePack = () => {
  const [selected, setSelected] = useState();
  const { id, affinity, insuranceType } = useParams();
  const { renderNextRoute } = useRouting();
  const [alert, setAlert] = useState();
  const [type, setType] = useState();

  console.log(selected);

  const insurancePackOptions = [
    {
      label: bronze,
      key: "BRONZE",
    },
    {
      label: silver,
      key: "SILVER",
    },
    {
      label: gold,
      key: "GOLD",
    },
  ];

  const bronzeOptions = [
    { value: "Civil story", key: "civil_story" },
    { value: "Criminal defense", key: "criminal_defense" },
    { value: "Civil defense", key: "civil_defense" },
    { value: "Property", key: "proprty" },
    { value: "Insolvency of third parties", key: "third_parties_involved" },
    { value: "Criminal Bail", key: "criminal_bail" },
    { value: "Indemnity Assistance", key: "indemnity_assistance" },
    {
      value: "Post-fire legal aid and related risks",
      key: "post_fire_legal_aid",
    },
  ];

  const silverOptions = [
    { value: "General contracts", key: "general_contrats" },
  ];

  const goldOptions = [
    { value: "Persons and family law", key: "persons_family_law" },
    {
      value: "Missing children's search fees",
      key: "missing_child_search_fee",
    },
    {
      value: "Cancellation by mutual consent",
      key: "cancellation_mutual_consent",
    },
    {
      value: "Right of inheritance, gift and will",
      key: "right_of_inheritance",
    },
    { value: "Tax law", key: "tax_law" },
    { value: "Business law", key: "business_law" },
    { value: "Immovable property (Tax law)", key: "immovable_property" },
    { value: "Areas of labour and social law", key: "areas_labour" },
    { value: "Other matters", key: "other_matters" },
  ];

  function handleSubmit(selected) {
    if (!selected) {
      setAlert("Please select an insurance pack");
      return;
    }
    patchStorage({
      payload: { insurance_pack: selected },
      id,
      affinity,
      insuranceType,
    });

    renderNextRoute();
  }
  useEffect(() => {
    setType(selected);
  }, [selected]);

  console.log(type);

  return (
    <SplitContainer>
      <FlowLeftSideAvatar
        chatText={i18n.t(
          "Choose what deposit you want to take Depending on your cologne, your coverage will vary"
        )}
      />
      <RightSplit>
        <Title>{i18n.t("Which package do you prefer?")}</Title>
        <InsuranceList>
          {insurancePackOptions.map((item, key) => {
            console.log(item, key);
            return (
              <Insurance
                key={key}
                name={item.key}
                checked={selected === item.key ? true : false}
                onClick={() => {
                  setAlert("");
                  setSelected(item.key);
                }}
              >
                <div>
                  <img alt={item.label} src={item.label} />
                </div>
                <p>{i18n.t(item.key)}</p>
              </Insurance>
            );
          })}
        </InsuranceList>
        <InsuranceOptions>
          <h2>{i18n.t("This includes")}</h2>
          <ul>
            {bronzeOptions.map((option, key) => {
              return (
                <ListItem
                  className={selected}
                  key={key}
                  selectedPack={selected}
                  value={option.value}
                  type="BRONZE"
                />
              );
            })}
            {silverOptions.map((option, key) => {
              return (
                <ListItem
                  className={selected}
                  key={key}
                  selectedPack={selected}
                  value={option.value}
                  type="SILVER"
                />
              );
            })}
            {goldOptions.map((option, key) => {
              return (
                <ListItem
                  className={selected}
                  key={key}
                  selectedPack={selected}
                  value={option.value}
                  type="GOLD"
                />
              );
            })}
          </ul>
        </InsuranceOptions>
        <ButtonContainer>
          <Error>{i18n.t(alert)}</Error>
          <StyledActionButton
            affinity={affinity}
            onClick={() => handleSubmit(selected)}
          >
            {i18n.t("Next")}
          </StyledActionButton>
        </ButtonContainer>
      </RightSplit>
    </SplitContainer>
  );
};

const Error = styled.div`
  color: #f74040;
  margin: auto;
  margin-left: 0;
  font-size: 1.2rem;
`;

const InsuranceOptions = styled.div`
  h2 {
    font-weight: 900;
    font-size: 1.55rem;
    letter-spacing: 1.19px;
    color: black;
    margin-top: 3rem;
    margin-bottom: 2rem;
  }
  ul {
    columns: 2;
    list-style: inside;
    column-gap: 2rem;
    font-size: 1.4rem;
    @media screen and (max-width: 425px) {
      font-size: 1.3rem;
    }
  }
  li {
    margin-top: 0.5rem;
  }
`;

const Title = styled.h1`
  font-weight: 900;
  font-size: 1.65rem;
  letter-spacing: 1.19px;
  color: black;
`;

const StyledActionButton = styled(ActionButton)`
  color: ${({ affinity }) => (affinity === "de-lijn" ? "black" : "white")};
  background-color: ${({ affinity }) =>
    affinity === "callant" || affinity === "uber" || affinity === "at-work"
      ? "#94C11A"
      : "default"};
  font-weight: ${({ affinity }) => (affinity === "de-lijn" ? "700" : "normal")};
`;

const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  margin-top: auto;

  & button {
    margin-left: auto;
    width: 20rem;
    @media (max-width: 768px) {
      width: 100%;
      margin: 2rem 0;
    }
  }
`;

const InsuranceList = styled.div`
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: 30% 30% 30%;

  @media screen and (max-width: 425px) {
    grid-gap: 1.5rem;
  }
`;
const Insurance = styled.div`
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  display: grid;
  grid-template-rows: 80% 20%;
  padding: 1rem;
  border: ${({ checked, theme }) =>
    checked ? `1px solid ${theme.brand.primary}` : "1px solid #CCCCCC"};
  background-color: ${({ checked, theme, disabled }) =>
    checked ? theme.brand.lightest : disabled ? "#AEAEAE" : "white"};
  div {
    text-align: center;
    position: relative;
  }
  img {
    /* margin: auto; */
    align-self: center;
    max-width: 10rem;
    justify-self: center;
  }
  p {
    font-weight: 700;
    align-self: center;
    justify-self: center;
    color: black;
  }
`;

const SplitContainer = styled.div`
  display: flex;
  height: 100%;
`;

const RightSplit = styled.div`
  display: grid;
  grid-template-rows: 8% 27% auto 10%;
  width: 50%;
  padding: 5rem 4rem;
  overflow-y: auto;

  @media (max-width: 768px) {
    width: 100%;
    grid-template-rows: 6% 29% auto 10%;
  }

  @media screen and (max-width: 425px) {
    padding: 4rem 2.5rem;
  }
`;

export default FlowInsurancePack;
