import React, { useState, useEffect } from "react";
import styled from "styled-components";
import i18n from "../i18n";
import { ActionButton } from "wg-fe-ui";
import OfferedPolicy from "../components/OfferedPolicy";
import FlowLeftSideMap from "../components/FlowLeftSideMap";
import { retrieveStorageById, patchStorage } from "../helpers/storeService";
import { useParams } from "react-router";
import useForm from "../hooks/useForm";
import * as Yup from "yup";
import { getNestedObject } from "../helpers/objectService";
import useRouting from "../hooks/useRouting";

const FlowHomeInformationExtra = () => {
  const { id, affinity, insuranceType } = useParams();
  const [defaultValues, setDefaultValues] = useState({});
  const { renderNextRoute } = useRouting();
  const { data } = retrieveStorageById(id, affinity, insuranceType) || {};
  const { risk_address } = data;

  useEffect(() => {
    loadDefaultValues();
  }, []);

  const loadDefaultValues = async () => {
    const { data } = retrieveStorageById(id, affinity, insuranceType);
    setDefaultValues(
      getNestedObject(data, [
        "risk_address",
        "parcel",
        "main_building",
        "enquiries",
      ]) || {}
    );
  };

  const enquiries = [
    {
      label: "The facade is made of natural stone",
      key: "facade_natural_stone",
    },
    {
      label: "There are spaces whose walls are finished in massive wood",
      key: "walls_finished_passive_wood",
    },
    {
      label: "The house is equipped with a theft alarm",
      key: "theft_alarm",
    },
    {
      label: "Does the building have a ",
      key: "special_flooring",
      popUp: {
        button: "special floor",
        title: "Special floor",
        text:
          "Does your building have a floor with a solid parquet or natural stone covering",
      },
    },
    {
      label: "Is the building constructed of ",
      key: "traditional_materials",
      popUp: {
        button: "traditional materials",
        title: "Traditional materials",
        text:
          "Is your house not a prefabricated house wooden chalet or a building with more than 25 combustible materials",
      },
    },
    {
      label: "Is the dwelling or the facade of the dwelling protected?",
      key: "dwelling_protected",
    },
    {
      label: "Is there an elevator in the house?",
      key: "has_elevator",
    },
    {
      label: "There's a sauna, a spa or a bathroom in natural stone",
      key: "bathroom_natural_stone",
    },
    {
      label: "There's an indoor pool in the house",
      key: "indoor_pool",
    },
    {
      label: "The house has built-in home automation",
      key: "builtin_home_automation",
    },
    {
      label: "Does the building have a ",
      key: "special_roofing",
      popUp: {
        button: "special roof",
        title: "Special roof",
        text:
          "Does your roofing material consist of straw, reeds or natural slates",
      },
    },
    {
      label: "There's a built-in library in the house",
      key: "builtin_library",
    },
    {
      label:
        "Is the building used primarily for the exercise of a liberal profession?",
      key: "primarily_liberal_profession",
    },
    {
      label: "Does the building consist of several apartments?",
      key: "building_consist_several_apartments",
    },
  ];

  useEffect(() => {
    enquiries.forEach((item) => {
      handleChange({ name: item.key, value: false });
    });
    Object.keys(defaultValues || {}).forEach((name) => {
      handleChange({ name, value: defaultValues[name] });
    });
  }, [defaultValues]);

  const validationSchema = Yup.object().shape({});

  const { values, handleSubmit, handleChange } = useForm({ validationSchema });

  const formSubmitHandler = (e) => {
    e.preventDefault();
    handleSubmit(handleFormValues);
  };

  const handleFormValues = () => {
    patchStorage({
      payload: values,
      path: ["risk_address", "parcel", "main_building", "enquiries"],
      id,
      affinity,
      insuranceType,
    });
    renderNextRoute();
  };

  return (
    <SplitContainer>
      <FlowLeftSideMap
        chatText={
          <>
            <Label>{i18n.t("Address")}</Label>
            <Address>
              {risk_address.address.street} {risk_address.address.housenr},{" "}
              {risk_address.address.zipcode} {risk_address.address.city}
            </Address>
          </>
        }
        addressInfo={risk_address}
      />
      <RightSplit>
        <h1>{i18n.t("Risk address")}</h1>
        <form onSubmit={formSubmitHandler}>
          <RisicoAdresVragen>
            {enquiries.map((item, key) => {
              return (
                <OfferedPolicy
                  key={key}
                  name={item.key}
                  checked={values[item.key]}
                  onChange={handleChange}
                  popUp={item.popUp}
                >
                  {i18n.t(item.label)}
                </OfferedPolicy>
              );
            })}
          </RisicoAdresVragen>
          <ButtonContainer>
            <StyledActionButton affinity={affinity}>
              {i18n.t("Next")}
            </StyledActionButton>
          </ButtonContainer>
        </form>
      </RightSplit>
    </SplitContainer>
  );
};

const StyledActionButton = styled(ActionButton)`
  color: ${({ affinity }) => (affinity === "de-lijn" ? "black" : "white")};
  background-color: ${({ affinity }) =>
    affinity === "callant" ? "#94C11A" : "default"};
  font-weight: ${({ affinity }) => (affinity === "de-lijn" ? "700" : "normal")};
`;

const Label = styled.p`
  font-size: 1.2rem;
  color: ${({ theme }) => theme.typo.title};
`;

const Address = styled.p`
  font-size: 1.5rem;
  color: ${({ theme }) => theme.typo.text};
  font-weight: 600;
`;

const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  margin-top: auto;

  & button {
    margin-top: 2rem;
    margin-left: auto;
    width: 20rem;

    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;

const RisicoAdresVragen = styled.div`
  display: grid;
  grid-gap: 1.5rem;
  grid-template-columns: 49% 49%;
  @media (max-width: 600px) {
    grid-template-columns: auto;
    grid-template-rows: repeat(4, auto);
  }
`;

const RightSplit = styled.div`
  width: 50%;
  padding: 5rem 4rem;
  overflow-y: auto;

  h1 {
    font-weight: bold;
    text-align: center;
    width: 100%;
    font-size: 1.65rem;
    margin-bottom: 2.5rem;
  }

  @media (max-width: 768px) {
    width: 100%;
  }

  @media screen and (max-width: 425px) {
    padding: 4rem 2.5rem;
  }
`;

const SplitContainer = styled.div`
  display: flex;
  height: 100%;
`;

export default FlowHomeInformationExtra;
