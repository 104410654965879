export const Honda = () => {
  const brandPrimary = "#C83434";
  const brandLight = "#FF4242";
  const brandLighter = "#FF5C5C";
  const brandLightest = "#ffdcdc";
  const brandDark = "#131313";
  const brandDarker = "#000";
  const brandSecondary = "#FF4242";
  const backGroundPrimary = "#131313";
  const backGroundSecondary = "#5E5E5E";
  const brandFont = `Lato, Helvetica, Myriad, Sans-Serif`;

  return {
    background: {
      primary: backGroundPrimary,
      secondary: backGroundSecondary,
    },
    brand: {
      primary: brandPrimary,
      light: brandLight,
      lighter: brandLighter,
      lightest: brandLightest,
      dark: brandDark,
      darker: brandDarker,
      secondary: brandSecondary,
    },
    typo: {
      title: "#050505",
      text: "#0E0E0E",
      highlight: "#505050",
      subTitle: "#5B5550",
      interactive: "#C1C1C1",
      outline: "#CCCCCC",
      editable: "#3297FD",
      selected: brandLight,
    },
    ui: {
      background: "#FBFBFB",
      backgroundLight: "#D3D4D8",
      toolbar: "#F6F6F6",
      outline: "#CCCCCC",
      interactive: "#C1C1C1",
      disabled: "#AEAEAE",
      highlight: "#505050",
      active: brandLight,
    },
    hover: {
      primary: brandLightest,
      secondary: "#F6F6F6",
    },
    status: {
      succes: "#37D66C",
      error: "#F74040",
      warning: "#FF9B21",
      succesLight: "#D2FDE0",
      errorLight: "#FFE1E1",
      warningLight: "#FFEFDC",
    },
    font: brandFont,
  };
};

export const OneAutomotive = () => {
  const brandPrimary = "#E61758";
  const brandLight = "#FF4242";
  const brandLighter = "#FF5C5C";
  const brandLightest = "#ffdcdc";
  const brandDark = "#131313";
  const brandDarker = "#000";
  const brandSecondary = "#FF4242";
  const backGroundPrimary = "#131313";
  const backGroundSecondary = "#5E5E5E";
  const brandFont = `Lato, Helvetica, Myriad, Sans-Serif`;

  return {
    background: {
      primary: backGroundPrimary,
      secondary: backGroundSecondary,
    },
    brand: {
      primary: brandPrimary,
      light: brandLight,
      lighter: brandLighter,
      lightest: brandLightest,
      dark: brandDark,
      darker: brandDarker,
      secondary: brandSecondary,
    },
    typo: {
      title: "#050505",
      text: "#0E0E0E",
      highlight: "#505050",
      subTitle: "#5B5550",
      interactive: "#C1C1C1",
      outline: "#CCCCCC",
      editable: "#3297FD",
      selected: brandLight,
    },
    ui: {
      background: "#FBFBFB",
      backgroundLight: "#D3D4D8",
      toolbar: "#F6F6F6",
      outline: "#CCCCCC",
      interactive: "#C1C1C1",
      disabled: "#AEAEAE",
      highlight: "#505050",
      active: brandLight,
    },
    hover: {
      primary: brandLightest,
      secondary: "#F6F6F6",
    },
    status: {
      succes: "#37D66C",
      error: "#F74040",
      warning: "#FF9B21",
      succesLight: "#D2FDE0",
      errorLight: "#FFE1E1",
      warningLight: "#FFEFDC",
    },
    font: brandFont,
  };
};

export const DeLijn = () => {
  const brandPrimary = "#ffd800";
  const brandLight = "#fae77d";
  const brandLighter = "#fff1a6";
  const brandLightest = "#fffbe6";
  const brandDark = "#131313";
  const brandDarker = "#000";
  const brandSecondary = "#ffd800";
  const backGroundPrimary = "#ebebeb";
  const backGroundSecondary = "#bdbdbd";
  const brandFont = `Lato, Helvetica, Myriad, Sans-Serif`;

  return {
    background: {
      primary: backGroundPrimary,
      secondary: backGroundSecondary,
    },
    brand: {
      primary: brandPrimary,
      light: brandLight,
      lighter: brandLighter,
      lightest: brandLightest,
      dark: brandDark,
      darker: brandDarker,
      secondary: brandSecondary,
    },
    typo: {
      title: "#050505",
      text: "#0E0E0E",
      highlight: "#505050",
      subTitle: "#5B5550",
      interactive: "#C1C1C1",
      outline: "#CCCCCC",
      editable: "#3297FD",
      selected: brandLight,
    },
    ui: {
      background: "#FBFBFB",
      backgroundLight: "#D3D4D8",
      toolbar: "#F6F6F6",
      outline: "#CCCCCC",
      interactive: "#C1C1C1",
      disabled: "#AEAEAE",
      highlight: "#505050",
      active: brandLight,
    },
    hover: {
      primary: brandLightest,
      secondary: "#F6F6F6",
    },
    status: {
      succes: "#ffd800",
      error: "#F74040",
      warning: "#FF9B21",
      succesLight: "#D2FDE0",
      errorLight: "#FFE1E1",
      warningLight: "#FFEFDC",
    },
    font: brandFont,
  };
};

export const Callant = () => {
  const brandPrimary = "#94C11A";
  const brandLight = "#c2d989";
  const brandLighter = "#d5e3b3";
  const brandLightest = "#d5e3b3";
  const brandDark = "#131313";
  const brandDarker = "#000";
  const brandSecondary = "#CCCCCC";
  const backGroundPrimary = "#131313";
  const backGroundSecondary = "#bdbdbd";
  const brandFont = `Lato, Helvetica, Myriad, Sans-Serif`;

  return {
    background: {
      primary: backGroundPrimary,
      secondary: backGroundSecondary,
    },
    brand: {
      primary: brandPrimary,
      light: brandLight,
      lighter: brandLighter,
      lightest: brandLightest,
      dark: brandDark,
      darker: brandDarker,
      secondary: brandSecondary,
    },
    typo: {
      title: "#050505",
      text: "#0E0E0E",
      highlight: "#505050",
      subTitle: "#5B5550",
      interactive: "#C1C1C1",
      outline: "#CCCCCC",
      editable: "#3297FD",
      selected: brandLight,
    },
    ui: {
      background: "#FBFBFB",
      backgroundLight: "#D3D4D8",
      toolbar: "#F6F6F6",
      outline: "#CCCCCC",
      interactive: "#C1C1C1",
      disabled: "#AEAEAE",
      highlight: "#505050",
      active: brandLight,
    },
    hover: {
      primary: brandLightest,
      secondary: "#F6F6F6",
    },
    status: {
      succes: "#ffd800",
      error: "#F74040",
      warning: "#FF9B21",
      succesLight: "#D2FDE0",
      errorLight: "#FFE1E1",
      warningLight: "#FFEFDC",
    },
    font: brandFont,
  };
};

export const AtWork = () => {
  const brandPrimary = "#94C11A";
  const brandLight = "#c2d989";
  const brandLighter = "#d5e3b3";
  const brandLightest = "#d5e3b3";
  const brandDark = "#131313";
  const brandDarker = "#000";
  const brandSecondary = "#CCCCCC";
  const backGroundPrimary = "#131313";
  const backGroundSecondary = "#bdbdbd";
  const brandFont = `Lato, Helvetica, Myriad, Sans-Serif`;

  return {
    background: {
      primary: backGroundPrimary,
      secondary: backGroundSecondary,
    },
    brand: {
      primary: brandPrimary,
      light: brandLight,
      lighter: brandLighter,
      lightest: brandLightest,
      dark: brandDark,
      darker: brandDarker,
      secondary: brandSecondary,
    },
    typo: {
      title: "#050505",
      text: "#0E0E0E",
      highlight: "#505050",
      subTitle: "#5B5550",
      interactive: "#C1C1C1",
      outline: "#CCCCCC",
      editable: "#3297FD",
      selected: brandLight,
    },
    ui: {
      background: "#FBFBFB",
      backgroundLight: "#D3D4D8",
      toolbar: "#F6F6F6",
      outline: "#CCCCCC",
      interactive: "#C1C1C1",
      disabled: "#AEAEAE",
      highlight: "#505050",
      active: brandLight,
    },
    hover: {
      primary: brandLightest,
      secondary: "#F6F6F6",
    },
    status: {
      succes: "#ffd800",
      error: "#F74040",
      warning: "#FF9B21",
      succesLight: "#D2FDE0",
      errorLight: "#FFE1E1",
      warningLight: "#FFEFDC",
    },
    font: brandFont,
  };
};

export const Doceo = () => {
  const brandPrimary = "#94C11A";
  const brandLight = "#c2d989";
  const brandLighter = "#d5e3b3";
  const brandLightest = "#d5e3b3";
  const brandDark = "#131313";
  const brandDarker = "#000";
  const brandSecondary = "#CCCCCC";
  const backGroundPrimary = "#131313";
  const backGroundSecondary = "#bdbdbd";
  const brandFont = `Lato, Helvetica, Myriad, Sans-Serif`;

  return {
    background: {
      primary: backGroundPrimary,
      secondary: backGroundSecondary,
    },
    brand: {
      primary: brandPrimary,
      light: brandLight,
      lighter: brandLighter,
      lightest: brandLightest,
      dark: brandDark,
      darker: brandDarker,
      secondary: brandSecondary,
    },
    typo: {
      title: "#050505",
      text: "#0E0E0E",
      highlight: "#505050",
      subTitle: "#5B5550",
      interactive: "#C1C1C1",
      outline: "#CCCCCC",
      editable: "#3297FD",
      selected: brandLight,
    },
    ui: {
      background: "#FBFBFB",
      backgroundLight: "#D3D4D8",
      toolbar: "#F6F6F6",
      outline: "#CCCCCC",
      interactive: "#C1C1C1",
      disabled: "#AEAEAE",
      highlight: "#505050",
      active: brandLight,
    },
    hover: {
      primary: brandLightest,
      secondary: "#F6F6F6",
    },
    status: {
      succes: "#ffd800",
      error: "#F74040",
      warning: "#FF9B21",
      succesLight: "#D2FDE0",
      errorLight: "#FFE1E1",
      warningLight: "#FFEFDC",
    },
    font: brandFont,
  };
};

export const Agoria = () => {
  const brandPrimary = "#3221ff";
  const brandLight = "#9086fc";
  const brandLighter = "#1300ff";
  const brandLightest = "#9086fc";
  const brandDark = "#131313";
  const brandDarker = "#000";
  const brandSecondary = "#3221ff";
  const backGroundPrimary = "#131313";
  const backGroundSecondary = "#bdbdbd";
  const brandFont = `Lato, Helvetica, Myriad, Sans-Serif`;

  return {
    background: {
      primary: backGroundPrimary,
      secondary: backGroundSecondary,
    },
    brand: {
      primary: brandPrimary,
      light: brandLight,
      lighter: brandLighter,
      lightest: brandLightest,
      dark: brandDark,
      darker: brandDarker,
      secondary: brandSecondary,
    },
    typo: {
      title: "#050505",
      text: "#0E0E0E",
      highlight: "#505050",
      subTitle: "#5B5550",
      interactive: "#C1C1C1",
      outline: "#CCCCCC",
      editable: "#3297FD",
      selected: brandLight,
    },
    ui: {
      background: "#FBFBFB",
      backgroundLight: "#D3D4D8",
      toolbar: "#F6F6F6",
      outline: "#CCCCCC",
      interactive: "#C1C1C1",
      disabled: "#AEAEAE",
      highlight: "#505050",
      active: brandLight,
    },
    hover: {
      primary: brandLightest,
      secondary: "#F6F6F6",
    },
    status: {
      succes: "#ffd800",
      error: "#F74040",
      warning: "#FF9B21",
      succesLight: "#D2FDE0",
      errorLight: "#FFE1E1",
      warningLight: "#FFEFDC",
    },
    font: brandFont,
  };
};

export const Valckenier = () => {
  const brandPrimary = "#ffc324";
  const brandLight = "#fae77d";
  const brandLighter = "#fff1a6";
  const brandLightest = "#fffbe6";
  const brandDark = "#131313";
  const brandDarker = "#000";
  const brandSecondary = "#ffc324";
  const backGroundPrimary = "#ebebeb";
  const backGroundSecondary = "#bdbdbd";
  const brandFont = `Lato, Helvetica, Myriad, Sans-Serif`;

  return {
    background: {
      primary: backGroundPrimary,
      secondary: backGroundSecondary,
    },
    brand: {
      primary: brandPrimary,
      light: brandLight,
      lighter: brandLighter,
      lightest: brandLightest,
      dark: brandDark,
      darker: brandDarker,
      secondary: brandSecondary,
    },
    typo: {
      title: "#050505",
      text: "#0E0E0E",
      highlight: "#505050",
      subTitle: "#5B5550",
      interactive: "#C1C1C1",
      outline: "#CCCCCC",
      editable: "#3297FD",
      selected: brandLight,
    },
    ui: {
      background: "#FBFBFB",
      backgroundLight: "#D3D4D8",
      toolbar: "#F6F6F6",
      outline: "#CCCCCC",
      interactive: "#C1C1C1",
      disabled: "#AEAEAE",
      highlight: "#505050",
      active: brandLight,
    },
    hover: {
      primary: brandLightest,
      secondary: "#F6F6F6",
    },
    status: {
      succes: "#ffc324",
      error: "#F74040",
      warning: "#FF9B21",
      succesLight: "#D2FDE0",
      errorLight: "#FFE1E1",
      warningLight: "#FFEFDC",
    },
    font: brandFont,
  };
};
