import React from "react";
import styled from "styled-components";
import { ActionButton } from "wg-fe-ui";

import DownloadIcon from "../assets/icons/download-button.svg";
import { useParams } from "react-router";
const DownloadButton = ({ children, href, className }) => {
  const { affinity } = useParams();
  return (
    <StyledActionButton
      affinity={affinity}
      className={className}
      onClick={() => window.open(href)}
    >
      <Img src={DownloadIcon} />
      {children}
    </StyledActionButton>
  );
};

const StyledActionButton = styled(ActionButton)`
  background-color: ${({ affinity }) =>
    affinity === "callant" ? "#df2815" : "default"};
  position: relative;
  padding: 0.7rem 0 0.7rem 2.4rem;
  :hover {
    background-color: ${({ affinity }) =>
      affinity === "callant" ? "#fc8686" : "default"};
  }
`;

const Img = styled.img`
  height: 1.4rem;
  position: absolute;
  left: 1.3rem;
`;

export default DownloadButton;
