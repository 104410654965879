import HondaLogo from "../assets/images/brand-logos/Honda.svg";
const general = [];

export const hondaCar = {
  brand: {
    name: "Honda",
    logo: HondaLogo,
  },
  flow: [
    ...general,
    {
      title: "user-address",
      step: 1,
      subStep: 1,
      nextRoutes: {
        1: "drivers-information",
      },
    },
    {
      title: "drivers-information",
      step: 1,
      subStep: 2,
      nextRoutes: {
        1: "drivers-information-extra",
      },
    },
    {
      title: "drivers-information-extra",
      step: 1,
      subStep: 3,
      nextRoutes: {
        1: "search-vehicle",
      },
    },
    {
      title: "search-vehicle",
      step: 2,
      subStep: 0,
      nextRoutes: {
        1: "car-information",
      },
    },
    {
      title: "car-information",
      step: 2,
      subStep: 1,
      nextRoutes: {
        1: "safety-systems",
      },
    },
    {
      title: "safety-systems",
      step: 2,
      subStep: 2,
      nextRoutes: {
        1: "price-calculator",
        2: "acceptance-conditions",
      },
    },
    {
      title: "acceptance-conditions",
      step: 2,
      subStep: 3,
      nextRoutes: {},
    },
    {
      title: "price-calculator",
      step: 3,
      subStep: 0,
      nextRoutes: {
        1: "overview",
      },
    },
    {
      title: "overview",
      step: 4,
      subStep: 0,
      nextRoutes: {
        1: "thankyou",
      },
    },
    {
      title: "thankyou",
      step: 4,
      subStep: 1,
      nextRoutes: {
        1: "verify-user",
      },
    },
  ],
};

export const hondaTwoWheeler = {
  brand: {
    name: "Honda",
    logo: "https://files.wegroup.be/carlogos/honda.svg",
  },
  flow: [
    ...general,
    {
      title: "user-address",
      step: 1,
      subStep: 2,
      nextRoutes: {
        1: "bike-type",
      },
    },
    {
      title: "bike-type",
      step: 1,
      subStep: 2,
      nextRoutes: {
        1: "drivers-information-bike",
      },
    },
    {
      title: "drivers-information-bike",
      step: 1,
      subStep: 3,
      nextRoutes: {
        1: "search-bike",
      },
    },
    {
      title: "search-bike",
      step: 2,
      subStep: 0,
      nextRoutes: {
        1: "bike-information",
      },
    },
    {
      title: "bike-information",
      step: 2,
      subStep: 1,
      nextRoutes: {
        1: "bike-price-calculator",
        2: "bike-price-light-scooter-calculator",
        3: "acceptance-conditions",
      },
    },
    {
      title: "acceptance-conditions",
      step: 2,
      subStep: 2,
      nextRoutes: {},
    },
    {
      title: "bike-price-light-scooter-calculator",
      step: 3,
      subStep: 0,
      nextRoutes: {
        1: "overview",
      },
    },
    {
      title: "bike-price-calculator",
      step: 3,
      subStep: 0,
      nextRoutes: {
        1: "overview",
      },
    },
    {
      title: "overview",
      step: 3,
      subStep: 1,
      nextRoutes: {
        1: "thankyou",
      },
    },
    {
      title: "thankyou",
      step: 4,
      subStep: 1,
      nextRoutes: {
        1: "verify-user",
      },
    },
  ],
};
