import React, { useState, useEffect } from "react";
import styled from "styled-components";
import i18n from "../i18n";

import useRouting from "../hooks/useRouting.js";
import { useParams } from "react-router";
import FlowPolicyHolderInfoForm from "../components/FlowPolicyHolderInfoForm.jsx";
import { retrieveStorageById, patchStorage } from "../helpers/storeService.js";
import louise_logo from "../assets/images/louise_logo.svg";

const FlowPolicyHolderInfo = () => {
  const [defaultValues, setDefaultValues] = useState();
  const { renderNextRoute } = useRouting();
  const { id, affinity, insuranceType } = useParams();

  useEffect(() => {
    const { data } = retrieveStorageById(id, affinity, insuranceType) || {};
    setDefaultValues(data);
  }, []);

  function handleSubmit(client) {
    patchStorage({ payload: client, id, affinity, insuranceType });
    renderNextRoute();
  }

  return (
    <SplitContainer>
      <LeftSplit>
        <RoundImage src={louise_logo} alt="Louise" />
        <TextBalloon>{i18n.t("What kind of person are you")}</TextBalloon>
      </LeftSplit>
      <RightSplit>
        <FlowPolicyHolderInfoForm
          retrieveSubmit={handleSubmit}
          defaultValues={defaultValues}
        />
      </RightSplit>
    </SplitContainer>
  );
};

const TextBalloon = styled.div`
  font-size: 2rem;
  line-height: 1.25;
  width: 100%;
  margin-top: 3rem;
  margin-bottom: 3rem;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  border-radius: 0 1.5rem 1.5rem 1.5rem;
  color: white;
  background: ${({ theme }) => theme.brand.primary};
  > span {
    font-weight: 700;
  }
`;

const RightSplit = styled.div`
  width: 60%;
  padding: 5rem;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  ::-webkit-scrollbar:vertical {
    width: 11px;
  }

  ::-webkit-scrollbar:horizontal {
    height: 11px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
    background-color: rgba(0, 0, 0, 0.4);
  }

  @media (max-width: 768px) {
    width: 100%;
  }

  @media screen and (max-width: 425px) {
    padding: 4rem 2.5rem;
  }
`;

const SplitContainer = styled.div`
  display: flex;
  height: 100%;
`;

const LeftSplit = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5rem;
  position: relative;

  :after {
    content: "";
    align-self: center;
    width: 1px;
    height: 90%;
    background: rgba(0, 0, 0, 0.3);
    position: absolute;
    right: 0;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const RoundImage = styled.img`
  max-width: 33rem;
  border-radius: 100%;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
  margin: 0 auto;
`;

export default FlowPolicyHolderInfo;
