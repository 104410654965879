import React, { useState } from "react";
import styled from "styled-components";
import { renderToStaticMarkup } from "react-dom/server";
import { getThemeObject } from "../helpers/themingService";
import BrokerBackground from "../assets/images/bg-broker";
// import { setBrokerData } from "../helpers/brokerDataService";
import Div100vh from "react-div-100vh";
import FlowRoutes from "./FlowRouter";
import FlowProgressBar from "../components/FlowProgressBar";
import FlowHomeProgressBar from "../components/FlowHomeProgressBar";
import { useParams } from "react-router";
import { Helmet } from "react-helmet";
import i18n from "../i18n";
const Flow = () => {
  const [errorState, SetErrorState] = useState();
  const dataUri = `url("data:image/svg+xml,${encodeURIComponent(
    renderToStaticMarkup(<BrokerBackground colors={getThemeObject()} />)
  )}")`;

  const { affinity, insuranceType } = useParams();

  function updateError(error) {
    SetErrorState(error);
  }

  return (
    <>
      <Helmet>
        <link rel="apple-touch-icon" href={`${affinity}_favicon.png`} />
        <link rel="icon" href={`/${affinity}_favicon.png`} />
        <meta
          name="description"
          content={`${affinity} insurance tarification flow`}
        />
        <title>{i18n.t(affinity)} Insurance</title>
      </Helmet>
      <Div100vh>
        <Background img={dataUri}>
          {insuranceType === "home" ? (
            <FlowHomeProgressBar errorState={errorState} />
          ) : (
            <FlowProgressBar errorState={errorState} />
          )}
          <ContentContainer>
            <FlowRoutes sendError={updateError} />
          </ContentContainer>
          <Footer>
            {i18n.t(
              "By requesting this quote, you agree to our privacy statement This can always be consulted on our website"
            )}
          </Footer>
        </Background>
      </Div100vh>
    </>
  );
};

const Footer = styled.div`
  margin-top: 1rem;
  margin-left: 0.5rem;
  display: flex;
  max-width: 1440px;
  width: 90%;
  font-size: 1.15rem;
  padding: 0rem;
`;

const ContentContainer = styled.div`
  max-width: 1440px;
  width: 90%;
  box-shadow: 0 10px 24px rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
  max-height: 1024px;
  height: 80%;
  background-color: white;
  overflow: auto;

  @media screen and (max-width: 425px) {
    max-height: none;
    height: 95%;
    width: 95%;
  }
`;

const Background = styled.main`
  background-image: linear-gradient(
    180deg,
    ${({ theme }) => theme.background.primary} 45.86%,
    ${({ theme }) => theme.background.secondary} 100%
  );
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: ${({ theme }) => theme.font};
  @media screen and (max-width: 425px) {
    padding: 1rem 0;
  }
`;

export default Flow;
