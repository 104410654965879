export const driverLicenseTypeOptions = [
  { value: "DEFINITIVE", label: "Definitive" },
  { value: "PROVISIONAL", label: "Provisional" },
];

export const driverLicenseTypeBikeOptions = [
  { value: "DEFINITIVE", label: "Definitive" },
  { value: "PROVISIONAL", label: "Provisional" },
  { value: "NO_LICENSE", label: "No driving license (< 25km/h)" },
];

export const accidentStatementTypeOptions = [
  { value: "CLIENT_DECLARATION", label: "Client declaration" },
  { value: "OFFICIAL_CERTIFICATE", label: "Official certificate" },
];

export const numberOfAccidentsLastFiveYearsOptions = [
  { value: "0", label: "none" },
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5 or more" },
];

export const primaryOptions = [
  { value: "yes", label: "yes" },
  { value: "no", label: "no" },
];

export const workDistanceOptions = [
  { value: "LESS_THAN_15", label: "LESS_THAN_15" },
  { value: "BETWEEN_15_AND_25", label: "BETWEEN_15_AND_25" },
  { value: "BETWEEN_25_AND_50", label: "BETWEEN_25_AND_50" },
  { value: "MORE_THAN_50", label: "MORE_THAN_50" },
];

export const currentCompanyName = [
  { value: "EUROCONTROL", label: "AIPE - Eurocontrol" },
  { value: "AON", label: "AON" },
  { value: "ARCELORMITTAL", label: "Arcelormittal" },
  { value: "CALLANT", label: "Callant" },
  { value: "CHIREC", label: "Chirec" },
  { value: "DAIKIN_EUROPE", label: "Daikin Europe" },
  { value: "DELOITTE_BELGIUM", label: "Deloitte Belgium" },
  { value: "EIFFAGE_BENELUX", label: "Eiffage Benelux" },
  { value: "FEDEX_EXPRESS", label: "Fedex Express" },
  { value: "G4S_BELGIUM", label: "G4S Belgium" },
  { value: "MAZDA", label: "Mazda" },
  { value: "MENSURA", label: "Mensura" },
  { value: "ONE_AUTOMOTIVE", label: "One Automotive" },
  { value: "ORANGE_BELGIUM", label: "Orange Belgium" },
  { value: "RANDSTAD_BELGIUM", label: "Randstad Belgium" },
  { value: "SPORTOASE", label: "Sportoase" },
  { value: "UMICORE", label: "Umicore" },
];

export const buildingAge = [
  { value: "less_than_10", label: "Less than 10 years old" },
  { value: "between_10_and_20", label: "Between 11 and 20 years old" },
  { value: "between_20_and_50", label: "Between 21 and 50 years old" },
  { value: "more_than_50", label: "More than 50 years old" },
];

export const propertyType = [
  { value: "owner", label: "Owner (inhabitant)" },
  { value: "landlord", label: "Owner (Not inhabitant)" },
  { value: "tenant", label: "Renter" },
];

export const buildingType = [
  { value: "apartment", label: "Appartment" },
  { value: "closed", label: "Terraced house" },
  { value: "halfopen", label: "Semi-detached house" },
  { value: "open", label: "Detached house" },
  { value: "villa", label: "Villa" },
];

export const specialEmployment = [
  { value: "yes", label: "yes" },
  { value: "no", label: "no" },
];

export const usedForOptions = [
  { value: "Private", label: "private" },
  { value: "Professional", label: "professional" },
];

export const currentProfessionOptions = [
  { value: "PENSIONED", label: "PENSIONED" },
  { value: "CIVIL_SERVANT", label: "CIVIL_SERVANT" },
  { value: "WORKER", label: "WORKER" },
  { value: "TRADER", label: "TRADER" },
  { value: "CLERK", label: "CLERK" },
  { value: "HOUSE_PERSON", label: "HOUSE_PERSON" },
  { value: "TEACHER", label: "TEACHER" },
  { value: "STUDENT", label: "STUDENT" },
  { value: "SELF_EMPLOYED", label: "SELF_EMPLOYED" },
  { value: "LIBERAL_PROFESSION", label: "LIBERAL_PROFESSION" },
  { value: "OTHER", label: "OTHER" },
];

export const fuelType = [
  { value: "PET", label: "PET" },
  { value: "DIE", label: "DIE" },
  { value: "LPG", label: "LPG" },
  // { value: "DIE_LPG", label: "DIE_LPG" },
  { value: "ELE", label: "ELE" },
  { value: "ELE_PET", label: "ELE_PET" },
  { value: "ELE_DIE", label: "ELE_DIE" },
  // { value: "ELE_LPG", label: "ELE_LPG" },
  // { value: "ELE_HYDRO", label: "ELE_HYDRO" },
  // { value: "HYDRO", label: "HYDRO" },
  { value: "CNG", label: "CNG" },
  // { value: "CNG_PET", label: "CNG_PET" },
  // { value: "BIO", label: "BIO" },
  // { value: "OTHER", label: "OTHER" },
];

export const transmissionType = [
  { value: "man", label: "man" },
  { value: "auto", label: "auto" },
];

export const motoCategoryOptions = [
  { value: "Standard", label: "Standard" },
  { value: "Classic", label: "Classic" },
  { value: "Off-road", label: "Off-road" },
  { value: "Cruiser", label: "Cruiser" },
  { value: "Minibike", label: "Minibike" },
  { value: "Sport", label: "Sport" },
  { value: "Sport Touring", label: "Sport Touring" },
  { value: "Touring", label: "Touring" },
];

export const accaptances = [
  {
    key: "generalAcceptanceCloseOnline",
    title: "Online communication",
    short: "I agree to receive my documents by e-mail",
    long:
      "By purchasing this insurance online, I agree to receive my contract (general and special conditions) and all other documents relating to it by e-mail",
  },
  {
    key: "generalAcceptanceNotTerminated",
    title: "No prior notice of termination due to damage",
    short:
      "I confirm that my previous insurances were not cancelled due to a claim",
    long:
      "I confirm that one or more of my previous insurances, if any, have not been cancelled by the respective insurance companies for a claim",
  },
  {
    key: "generalAcceptanceNotRefused",
    title: "No previous refusal",
    short:
      "I confirm that I have no previous refused applications for the selected coverages",
    long:
      "I confirm that I do not have any previous applications refused by an insurance company for any of the selected coverages",
  },
  {
    key: "generalAcceptanceTerms",
    title: "General terms and conditions",
    short: "I agree with the terms and conditions",
    long:
      "I agree to the terms and conditions including my 14 calendar day right of withdrawal and confirm that the information provided is truthful",
  },
  {
    key: "generalAcceptancePrivacy",
    title: "Privacy policy",
    short: "I agree with the privacy policy",
    long:
      "I agree with the privacy policy and confirm that the content of the chosen insurance contract meets my requirements and needs",
  },
];
