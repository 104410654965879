import React from "react";
import styled from "styled-components";
import InfoPopUp from "./InfoPopUpLink";
import i18n from "../i18n";
// import { ToggleInput } from 'wg-fe-ui';

// eslint-disable-next-line react/prop-types
const AcknowledgementCheckbox = ({
  checked = false,
  name,
  children,
  onChange,
  popUp = false,
  disabled = false,
  itemKey,
  error,
}) => {

  console.log('ERRORS 2', error);
  return (
    <StyledDiv className="unsetHeight" checked={checked} disabled={disabled} >
      <InputContainer>
        <StyledRadioButton>
          {i18n.t('No')}
          <input type="radio" name={name} value={false} onChange={() => onChange({ name: name, value: {agreed: false, key: itemKey} })}/>
          <StyledRadioCheckMark />
        </StyledRadioButton>
        <StyledRadioButton>
          {i18n.t('Yes')}
          <input type="radio" name={name} value={true} onChange={() => onChange({ name: name, value: {agreed: true, key: itemKey} })}/>
          <StyledRadioCheckMark />
        </StyledRadioButton>
      </InputContainer>
      {/* <StyledToggleInput
        name={name}
        onChange={(item) => onChange({ name: item?.name, value: {agreed: item?.value, key: itemKey} })}
        checked={checked}
        falseLabel={i18n.t('No')}
        trueLabel={i18n.t('Yes')}
        disabled={disabled}
      /> */}
      {/* <StyledCheckBox
        name={name}
        checked={checked}
        disabled={disabled}
        onChange={(item) => onChange({ name: item?.name, value: {agreed: item?.value, key: itemKey} })}
      /> */}
      <div>
        <StyledSpan dangerouslySetInnerHTML={{ __html: children }} />
        {popUp ? (
          <InfoPopUp
            title={`${i18n.t(children)}${i18n.t(popUp.button)}`}
            buttonText={i18n.t(popUp.button)}
            info={i18n.t(popUp.text)}
            // onClick={() => {
            //   onChange({ name: name, value: {agreed: true, key: key} });
            // }}
          />
        ) : null}
      </div>
      {error && (<StyledError>{i18n.t('required')}</StyledError>)}
    </StyledDiv>
  );
};

const StyledError = styled.p`
  color: red;
`;

const InputContainer = styled.div`
  display: flex;
  padding-right: 1.5rem;
`;

const StyledRadioCheckMark = styled.span`
  display: inline;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 2rem;
  width: 2rem;
  background-color: ${({ disabled, theme }) =>
    disabled ? theme.ui.disabled : 'white'};
  border-radius: 50%;
  border: 1px solid #CCCCCC;
`;

const StyledRadioButton = styled.label`
  display: block;
  position: relative;
  padding-left: 3.5rem;
  margin-right: 1rem;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  :nth-last-of-type {
    margin-right: 0;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;

    :checked ~ ${StyledRadioCheckMark} {
      background-color: ${({ disabled, theme }) =>
      disabled ? theme.ui.disabled : theme.brand.primary};
    }
  }

  :hover input ~ ${StyledRadioCheckMark} {
    background-color: #ccc;
  }
`;

const StyledSpan = styled.span`
  font-weight: normal !important;
`;

const StyledDiv = styled.div`
  font-size: 1.4rem;
  align-items: center;
  border: 1px solid #CCCCCC;
  align-items: center;
  border-radius: 3px;
  margin-bottom: 2%;
  padding-left: 1.5rem;
  padding-right: 2rem;
  color: ${({ disabled, theme }) =>
    disabled ? theme.ui.disabled : theme.typo.text};
  display: flex;
  line-height: 1.3;
  transition: all 0.2s ease;
  background-color: ${({ disabled }) => (disabled ? "#efefef" : "white")};

  & > div:nth-of-type(2) {
    margin: 1.5rem 1rem 1.5rem 1.5rem;
    word-break: break-word;
    display: inline;
  }

  span {
    font-weight: bold;
  }
`;

// const StyledToggleInput = styled(ToggleInput)`
//   padding-right: 1.5rem;
//   border-right: 1px solid #CCCCCC;
// `;

export default AcknowledgementCheckbox;
