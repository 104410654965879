import React, { useContext } from "react";
import { Route, Switch, Redirect, useParams } from "react-router-dom";
import { retrieveStorage, retrieveStorageById } from "../helpers/storeService";

import FlowDriversInformation from "./FlowDriversInformation";
import FlowDriversInformationBike from "./FlowDriversInformationBike";
import FlowDriversInformationExtra from "./FlowDriversInformationExtra";
import FlowDriversInformationExtraBike from "./FlowDriversInformationExtraBike";
import FlowHomeInformationExtra from "./FlowHomeInformationExtra";
import FlowHomeInformation from "./FlowHomeInformation";
import FlowAcceptanceCondition from "./FlowAcceptanceCondition";
import FlowSearchForVehicle from "./FlowSearchForVehicle";
import FlowSearchForBike from "./FlowSearchForBike";
import FlowCarInformation from "./FlowCarInformation";
import FlowPriceCalculator from "./FlowPriceCalculator";
import FlowPolicyHolderInfo from "./FlowPolicyHolderInfo";
import FlowOverview from "./FlowOverview";
import FlowGeneratingDocuments from "./FlowGeneratingDocuments";
import FlowHomeIndications from "./FlowHomeIndications";
import FlowSafetySystem from "./FlowSafetySystem";
import Login from "./Login";
import FlowPolicyHolderAddress from "./FlowPolicyHolderAddress";
import FlowRiskAddress from "./FlowRiskAddress";
import FlowHomeRoomEstimations from "./FlowHomeRoomEstimations";
import FlowHomeOverview from "./FlowHomeOverview";
import FlowThankyou from "./FlowThankyou";
import FlowManualVehicleExtra from "./FlowManualVehicleExtra";
import FlowBikeType from "./FlowBikeType";
import FlowBikeInformation from "./FlowBikeInformation";
import FlowPriceBikeCalculator from "./FlowPriceBikeCalculator";
import FlowPriceTeacherCalculator from "./FlowPriceTeacherCalculator";
import FlowPriceBikeLightScooterCalculator from "./FlowPriceBikeLightScooterCalculator";
import FlowTeacherInsuranceQuestions from "./FlowTeacherInsuranceQuestions";
import FlowInsurancePack from "./FlowInsurancePack";
import FlowPriceLegalCalculator from "./FlowPriceLegalCalculator";
import FlowInsuranceTypeMoto from "./FlowInsuranceTypeMoto";
import FlowCarType from "./FlowCarType";

import ImpossibleFlow from "../pages/ImpossibleFlow";

import FlowRouterRedirect from "../components/FlowRouterRedirect";
import { getNestedObject } from "../helpers/objectService";
import useRouting from "../hooks/useRouting";
import { ThemeContext } from "../contexts/ThemingContext";

function RouteHandler({ component: Component, ...rest }) {
  // Check if the id exists in the storage, otherwhise redirect to login page
  return (
    <Route
      {...rest}
      render={(props) =>
        retrieveStorageById(
          getNestedObject(props, ["match", "params", "id"]),
          getNestedObject(props, ["match", "params", "affinity"]),
          getNestedObject(props, ["match", "params", "insuranceType"])
        ) ? (
          <Component {...rest} />
        ) : (
          <Redirect
            to={{
              pathname: `/${getNestedObject(props, [
                "match",
                "params",
                "affinity",
              ])}/${getNestedObject(props, [
                "match",
                "params",
                "insuranceType",
              ])}/verify-user`,
            }}
          />
        )
      }
    />
  );
}

const FlowRouter = ({ sendError }) => {
  const { getFirstRoute } = useRouting();
  const { affinity, insuranceType } = useParams();
  const { id } = retrieveStorage(affinity, insuranceType) || "";
  const themeContext = useContext(ThemeContext);
  themeContext.setTheme(affinity);

  return (
    <Switch>
      <Route path="/:affinity/:insuranceType/verify-user" component={Login} />
      <Route
        path="/:affinity/:insuranceType/not-possible"
        component={ImpossibleFlow}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/drivers-information/:id"
        component={FlowDriversInformation}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/drivers-information-bike/:id"
        component={FlowDriversInformationBike}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/drivers-information-extra/:id"
        component={FlowDriversInformationExtra}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/drivers-information-extras/:id"
        component={FlowDriversInformationExtra}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/drivers-information-extra-bike/:id"
        component={FlowDriversInformationExtraBike}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/user-address/:id"
        component={FlowPolicyHolderAddress}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/risk-address/:id"
        component={FlowRiskAddress}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/home-information/:id"
        component={FlowHomeInformation}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/home-information-extra/:id"
        component={FlowHomeInformationExtra}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/home-room-information/:id"
        component={FlowHomeRoomEstimations}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/home-specials/:id"
        component={FlowHomeIndications}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/thankyou/:id"
        component={FlowThankyou}
        sendError={sendError}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/home-overview/:id"
        component={FlowHomeOverview}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/bike-type/:id"
        component={FlowBikeType}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/acceptance-conditions/:id"
        component={FlowAcceptanceCondition}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/search-vehicle/:id"
        component={FlowSearchForVehicle}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/search-vehicle-form/:id"
        component={FlowSearchForVehicle}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/search-bike/:id"
        component={FlowSearchForBike}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/search-bike/:id"
        component={FlowSearchForBike}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/insurance-type-moto/:id"
        component={FlowInsuranceTypeMoto}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/rider-insurance-type-moto/:id"
        component={FlowInsuranceTypeMoto}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/car-information/:id"
        component={FlowCarInformation}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/bike-information/:id"
        component={FlowBikeInformation}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/callant-bike-information/:id"
        component={FlowBikeInformation}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/bike-price-calculator/:id"
        component={FlowPriceBikeCalculator}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/bike-teacher-calculator/:id"
        component={FlowPriceTeacherCalculator}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/bike-price-light-scooter-calculator/:id"
        component={FlowPriceBikeLightScooterCalculator}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/legal-calculator/:id"
        component={FlowPriceLegalCalculator}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/insurance-questions/:id"
        component={FlowTeacherInsuranceQuestions}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/insurance-pack/:id"
        component={FlowInsurancePack}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/car-information-extra/:id"
        component={FlowManualVehicleExtra}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/car-type/:id"
        component={FlowCarType}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/safety-systems/:id"
        component={FlowSafetySystem}
      />

      <RouteHandler
        path="/:affinity/:insuranceType/session/price-calculator/:id"
        component={FlowPriceCalculator}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/policyholder-information/:id"
        component={FlowPolicyHolderInfo}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/generating-documents/:id"
        component={FlowGeneratingDocuments}
      />
      <RouteHandler
        path="/:affinity/:insuranceType/session/overview/:id"
        component={FlowOverview}
      />
      <FlowRouterRedirect
        from="/:affinity/:insuranceType/session"
        to={getFirstRoute()}
        id={id}
        loginPath="verify-user"
      />
    </Switch>
  );
};

export default FlowRouter;
