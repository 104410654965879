import React, { useEffect, useState } from "react";
import styled from "styled-components";
import i18n from "../i18n.js";
import { useHistory, useParams } from "react-router";
import LouiseThankyou from "../assets/images/LouiseThankyou.jsx";
import { LoadingSpinner } from "wg-fe-ui";
import { DISTRIBUTION_ID } from "../helpers/brokerDataService";
import {
  parseIncDateToIsoFormat,
  getEndDateInIsoFormat,
} from "../helpers/dateService";
import { getProspectObject } from "../helpers/prospectObjectService";
import {
  getHomeObject,
  getCarObject,
  getTwoWheelerObject,
  getTeacherObject,
  getFamilyObject,
} from "../helpers/riskObjectForCRMService";
import {
  createLead,
  createOffer,
  createSixWheelsOffer,
} from "../helpers/apiRouterService";
import { retrieveStorageById } from "../helpers/storeService";
import { getBrandData } from "../helpers/tagRouterService";
import ReactGA from "react-ga";
import { uploadDocument } from "../helpers/documentUploadService.js";

const FlowThankyou = ({ sendError }) => {
  const { id, affinity, insuranceType } = useParams();
  const { data } = retrieveStorageById(id, affinity, insuranceType);
  const affinityName = getBrandData().name;
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [quoteRecalculated] = useState(false);
  const history = useHistory();
  const [successMsg, setSuccessMsg] = useState("");

  useEffect(() => {
    ReactGA.ga(`${affinity.replace("-", "")}.send`, "event", {
      eventCategory: "Louise Flow",
      eventAction: "Created Lead/Offer",
      eventLabel: insuranceType,
    });

    callCreateLeadOrOffer();
  }, []);

  useEffect(() => {
    if (quoteRecalculated) {
      callCreateLeadOrOffer();
    }
  }, [quoteRecalculated]);

  useEffect(() => {
    sendError(error);
  }, [error]);

  const b64toBlob = (attachment) => {
    // decode base64 string, remove space for IE compatibility
    var binary = atob(attachment.base64.replace(/\s/g, ""));
    var len = binary.length;
    var buffer = new ArrayBuffer(len);
    var view = new Uint8Array(buffer);
    for (var i = 0; i < len; i++) {
      view[i] = binary.charCodeAt(i);
    }

    // create the blob object with content-type "application/pdf"
    var blob = new Blob([view], { type: attachment.type });

    return blob;
  };

  async function callCreateLeadOrOffer() {
    const prospect = getProspectObject(data);
    const documents = [];
    if (
      insuranceType === "6-wheeler" &&
      data?.selectedFormula === "MOTOR_COMPANY_CAR"
    ) {
      // Upload green card attachment here!
      var i;
      for (i = 0; i < data?.info?.attachments?.length; i++) {
        const attachment = data?.info?.attachments[i];
        let blobFile = b64toBlob(attachment);
        const formData = new FormData();
        formData.append("file", blobFile, attachment?.filename);

        // Upload file with uploadService
        const [respUpload, statusUpload] = await uploadDocument(
          DISTRIBUTION_ID,
          insuranceType === "home" || affinity === "uber" ? "LEADS" : "OFFERS",
          formData,
          blobFile.size,
          attachment?.filename,
          "OTHER"
        );
        if (statusUpload !== 200 && statusUpload !== 201) {
          setLoading(false);
          setError(true);
        } else {
          documents.push(respUpload);
        }
      }
    }
    const riskObjects = {};
    if (insuranceType === "home") {
      riskObjects["residences"] = [getHomeObject(data)];
    } else if (insuranceType === "car") {
      riskObjects["cars"] = [getCarObject(data)];
    } else if (insuranceType === "two-wheeler") {
      riskObjects["two_wheelers"] = [getTwoWheelerObject(data)];
    } else if (insuranceType === "teacher") {
      riskObjects["teachers"] = [getTeacherObject(data)];
      riskObjects["families"] = [getFamilyObject(data)];
    } else if (insuranceType === "6-wheeler") {
      if (
        ["MOTOR_COMPANY_CAR", "CAR_ALREADY_INSURED"].includes(
          data?.selectedFormula
        )
      ) {
        riskObjects["two_wheelers"] = [getTwoWheelerObject(data)];
      } else if (["MOTOR_ALREADY_INSURED"].includes(data?.selectedFormula)) {
        riskObjects["cars"] = [getCarObject(data)];
      } else {
        riskObjects["cars"] = [getCarObject(data)];
        riskObjects["two_wheelers"] = [getTwoWheelerObject(data)];
      }
    }

    // If there is extra information to be sent along, do it here (such as the companies and their info with at_work)
    let description = null;
    if (affinity === "at-work") {
      const companyDetailsName =
        data?.info?.currentCompany === "DELOITTE_BELGIUM"
          ? i18n.t("SAP Number")
          : data?.info?.currentCompany === "EIFFAGE_BENELUX"
          ? i18n.t("Subsidiary")
          : data?.info?.currentCompany === "CALLANT"
          ? i18n.t("Company Email")
          : data?.info?.currentCompany === "AON"
          ? i18n.t("Company Email")
          : data?.info?.currentCompany === "MAZDA"
          ? i18n.t("Company Email")
          : data?.info?.currentCompany === "EUROCONTROL"
          ? i18n.t("Company Email")
          : null;
      description = `${i18n.t(`Current company`)}: ${
        data?.info?.currentCompany
          ? data.info.currentCompany
          : i18n.t(`Not specified`)
      }`;
      if (companyDetailsName) {
        description += ` | ${companyDetailsName}: ${
          data?.info?.currentCompanyInfo
            ? data.info.currentCompanyInfo
            : i18n.t(`Not specified`)
        }`;
      }
    }
    if (insuranceType === "6-wheeler") {
      if (description) {
        description += " | ";
      } else {
        description = "";
      }
      description += "[SIX WHEELS] ";
      if (data?.selectedFormula === "MOTOR_COMPANY_CAR") {
        description += i18n.t(`Company green card is attached`);
      }
      if (data?.selectedFormula === "CAR_ALREADY_INSURED") {
        description += data?.info?.carPolicyNumber
          ? `${i18n.t(`Car policy number`)}: ${data?.info?.carPolicyNumber}`
          : "";
      }
      if (data?.selectedFormula === "MOTOR_ALREADY_INSURED") {
        description += data?.info?.motorPolicyNumber
          ? `${i18n.t(`Motor policy number`)}: ${data?.info?.motorPolicyNumber}`
          : "";
      }
      if (data?.selectedFormula === "6WHEELS") {
        description += i18n.t(`Offer created through 6 wheels flow`);
      }
    }

    let send_mail_to_prospect = true;
    if (data?.moderator) {
      send_mail_to_prospect = data?.send_mail_to_customer?.agreed || false;
    }

    // TODO: Change here if lead or offer creation is needed
    if (insuranceType === "home" || affinity === "uber") {
      const [resp, status] = await createLead(
        prospect,
        riskObjects,
        affinityName,
        data.acknowledgements,
        false,
        description,
        data?.refId,
        documents,
        data?.consessionHolder
      );

      setLoading(false);
      if (status !== 201) {
        setError(true);
      }
      setSuccessMsg("We will contact you soon about your proposal!");
      return [resp, status];
    } else if (data?.selectedFormula === "6WHEELS") {
      // Create car offer
      const guaranteesTwoWheeler = data?.priceInformationTwoWheeler?.quoteResp?.guaranteesChosen;
      const guaranteesCar = data?.priceInformationCar?.quoteResp?.guaranteesChosen;
      const [resp, status] = await createSixWheelsOffer(
        data.priceInformationTwoWheeler.quoteId,
        guaranteesTwoWheeler,
        data.priceInformationCar.quoteId,
        guaranteesCar,
        data.acknowledgements,
        parseIncDateToIsoFormat(data.startDate),
        getEndDateInIsoFormat(),
        prospect,
        riskObjects,
        affinityName,
        data?.paymentSpread,
        description,
        data?.refId,
        send_mail_to_prospect,
        documents,
        data?.consessionHolder
      );
      setLoading(false);
      if (status !== 201) {
        setError(true);
      }
      setSuccessMsg("You can find your offer in your mailbox");
      return [resp, status];
    } else {
      const guarantees = data?.priceInformation?.quoteResp?.guaranteesChosen;
      const [respOffer, statusOffer] = await createOffer(
        data.priceInformation.quoteId,
        guarantees,
        data.acknowledgements,
        parseIncDateToIsoFormat(data.startDate),
        getEndDateInIsoFormat(),
        prospect,
        riskObjects,
        affinityName,
        data?.paymentSpread,
        description,
        data?.refId,
        send_mail_to_prospect,
        documents,
        data?.consessionHolder
      );
      console.log("Offer", respOffer, statusOffer);
      if (statusOffer !== 201) {
        setLoading(false);
        setError(true);
      }
      setLoading(false);
      if (statusOffer !== 201) {
        setError(true);
      }
      setSuccessMsg("You can find your offer in your mailbox");
      return [respOffer, statusOffer];
    }
  }

  function handleNewSession() {
    history.push(`/${affinity}/${insuranceType}/verify-user`);
  }

  return (
    <RightSplit>
      {loading ? (
        <LouiseContainer>
          <LouiseText>
            <LoadingSpinner />
            <p
              dangerouslySetInnerHTML={{
                __html: i18n.t("Wait a second as we process your request"),
              }}
            />
          </LouiseText>
        </LouiseContainer>
      ) : error ? (
        <LouiseContainer>
          <LouiseText>
            <p
              dangerouslySetInnerHTML={{
                __html: i18n.t(
                  "Something went wrong with processing your proposal"
                ),
              }}
            />
          </LouiseText>
          <StartNewSessionButton
            affinity={affinity}
            onClick={() => handleNewSession()}
          >
            {i18n.t("Go back")}
          </StartNewSessionButton>
        </LouiseContainer>
      ) : (
        <LouiseContainer>
          <LouiseThankyou />
          <LouiseText>
            <p
              dangerouslySetInnerHTML={{
                __html: i18n.t("Thank you for choosing Callant Insurance", {
                  affinityName,
                }),
              }}
            ></p>
            <p
              dangerouslySetInnerHTML={{
                __html: i18n.t(successMsg),
              }}
            />
          </LouiseText>
          <StartNewSessionButton
            affinity={affinity}
            onClick={() => handleNewSession()}
          >
            {i18n.t("Calculate another rate")}
          </StartNewSessionButton>
        </LouiseContainer>
      )}
    </RightSplit>
  );
};

const StartNewSessionButton = styled.button`
  align-items: center;
  background-color: #df2815;
  border-radius: 15px;
  border: none;
  color: white;
  cursor: pointer;
  font-weight: bold;
  font-size: 1.5rem;
  height: 5.6rem;
  justify-content: center;
  display: none;
  @media (max-width: 426px) {
    display: flex;
  }
`;

const LouiseText = styled.div`
  > p {
    margin-top: 2rem;
    > b {
      font-weight: 700;
      > span {
        color: #ff8000;
      }
    }
    > span {
      font-weight: bold;
    }
  }
`;

const LouiseContainer = styled.div`
  letter-spacing: 0.03rem;
  line-height: 2.5rem;
  margin: auto;
  text-align: center;
  width: 48%;
  @media (max-width: 768px) {
    width: 90%;
  }
  @media (max-width: 426px) {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 100%;
  }
`;

const RightSplit = styled.div`
  display: flex;

  flex-direction: column;

  overflow-y: auto;
  padding: 5rem;
  width: 100%;
  height: 100%;

  @media (max-width: 768px) {
    width: 100%;
  }

  @media (max-width: 425px) {
    padding: 4rem 2.5rem;
  }
`;

export default FlowThankyou;
