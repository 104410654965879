import React, { useReducer, useEffect, useState } from "react";
import { LoadingSpinner, ActionButton } from "wg-fe-ui";
import styled from "styled-components";
import { useParams } from "react-router";
import FlowPriceCalculatorOption from "../components/FlowPriceCalculatorOption";
import FlowLeftSideAvatar from "../components/FlowLeftSideAvatar.jsx";

import useRouting from "../hooks/useRouting";
import { patchStorage, retrieveStorageById } from "../helpers/storeService";
import { parseIncDateToObject } from "../helpers/dateService";
import { getTwoWheelerObject } from "../helpers/riskObjectService";
import i18n from "../i18n";
import { retrieveBikePrice } from "../helpers/apiRouterService";
import { format } from "date-fns";
import { getNestedObject } from "../helpers/objectService";
import { getProspectObjectPyModels } from "../helpers/prospectObjectService";

const FlowPriceBikeLightScooterCalculator = () => {
  const { id, affinity, insuranceType } = useParams();

  const storageData = retrieveStorageById(id, affinity, insuranceType);
  const [loading] = useState(false);
  const [selectedLightType] = useState("civil_liability");

  const [totalPremium, setTotalPremium] = useState("");
  const { renderNextRoute } = useRouting();
  const [lightScooterPrices, setLightScooterPrices] = useState();
  const [quoteId, setQuoteId] = useState(null);
  const [quoteResp, setQuoteResp] = useState(null);

  async function setOptionsLightPrices() {
    const response = await callBikePrice("&option=legal", selectedLightType);
    console.log(response);

    setLightScooterPrices(response);
  }

  function handleSubmit() {
    patchStorage({
      payload: { priceInformation: { quoteId, quoteResp } },
      id,
      affinity,
      insuranceType,
    });
    renderNextRoute();
  }

  useEffect(() => {
    setOptionsLightPrices();
  }, [selectedLightType]);

  // var myObj = {
  //   style: "currency",
  //   currency: "EUR",
  // };

  async function callBikePrice(query = "", insurance) {
    const { data } = storageData || {};

    console.log(data);

    const currentDateObj = parseIncDateToObject(
      format(new Date(), "dd/MM/yyyy")
    );

    data.currentDateObj = currentDateObj;

    const payload = {
      two_wheeler: getTwoWheelerObject(data),
      policy_holder: getProspectObjectPyModels(data),
      quote_specifications: {
        currency: "EUR",
        payment_interval: "annual",
        save_quote: true,
        bonus_malus: data?.twoWheeler?.mainDriver?.bonusMalus,
      },
    };
    console.log(payload);

    const [resp, status] = await retrieveBikePrice(
      payload,
      affinity,
      insurance,
      query
    );
    console.log(resp);

    if ((await status) === 200);
    else return {};

    return resp;
  }

  async function retrievePrice(queryString) {
    const { premium, insurance, quote, id } =
      (await callBikePrice(queryString, selectedLightType)) || {};
    const { total_premium } = premium || {};
    setTotalPremium(total_premium);
    setQuoteId(id);
    const guaranteesChosen = [quote?.base?.name].concat(insurance?.options || []);
    setQuoteResp({ insurance, quote, premium, guaranteesChosen });
  }
  function handleOptionChange(name, status) {
    if (status) {
      dispatch({ type: "add", value: name });
    } else {
      dispatch({ type: "remove", value: name });
    }
  }
  function processOptionsqueryString(_selectedOptions) {
    if (_selectedOptions === undefined) return;
    if (_selectedOptions.length === 0) return retrievePrice("");
    const queryString = "option=" + _selectedOptions.join("&option=");

    console.log(_selectedOptions);
    retrievePrice(queryString);
  }

  const [selectedOptions, dispatch] = useReducer((arr, { type, value }) => {
    switch (type) {
      case "add":
        return [...arr, value];
      case "remove":
        return arr.filter((item) => item !== value);
      default:
        return arr;
    }
  }, []);

  useEffect(() => {
    processOptionsqueryString(
      selectedOptions.filter(
        (item) =>
          item !== "civil_liability" &&
          item !== "omnium" &&
          item !== "mini_omnium"
      )
    );
  }, [selectedOptions, selectedLightType]);

  function handleSubPrices(name) {
    if (name === "civil_liability") {
      console.log(lightScooterPrices);

      return (
        getNestedObject(lightScooterPrices, [
          "quote",
          "base",
          "total_premium",
        ]) + 3.62
      );
    }

    const options =
      getNestedObject(lightScooterPrices, ["quote", "options"]) || [];
    const currentOption = options.filter((item) => item.name === name)[0] || {};
    return currentOption.total_premium || 0;
  }

  const docsData = {
    "callant": {
      "terms": {
        "nl": "https://docs.callant.eu/Affinity/AtWork/AV_Callant%20Mobility_20210101_NL.pdf",
        "fr": "https://docs.callant.eu/Affinity/AtWork/CG_Callant%20Mobility_20210101_FR.pdf",
      },
      "ipid": {
        "nl": "https://docs.callant.eu/Affinity/MotorInsurance/IPID_Callant%20Mobility_20220719_NL.pdf",
        "fr": "https://docs.callant.eu/Affinity/MotorInsurance/IPID_Callant%20Mobility_20220719_FR.pdf",
      }
    },
    "honda": {
      "terms": {
        "nl": "https://docs.callant.eu/Affinity/AtWork/AV_Callant%20Mobility_20210101_NL.pdf",
        "fr": "https://docs.callant.eu/Affinity/AtWork/CG_Callant%20Mobility_20210101_FR.pdf",
      },
      "ipid": {
        "nl": "https://docs.callant.eu/Affinity/Honda/IPID_Callant%20Mobility_Honda_20210101_NL.pdf",
        "fr": "https://docs.callant.eu/Affinity/Honda/IPID_Callant%20Mobility_Honda_20210101_FR.pdf",
      }
    },
    "valckenier": {
      "terms": {
        "nl": "https://docs.callant.eu/Affinity/AtWork/AV_Callant%20Mobility_20210101_NL.pdf",
        "fr": "https://docs.callant.eu/Affinity/AtWork/CG_Callant%20Mobility_20210101_FR.pdf",
      },
      "ipid": {
        "nl": "https://docs.callant.eu/Affinity/Valckenier/IPID_Callant%20Mobility_Valckenier_20210101_NL.pdf",
        "fr": "https://docs.callant.eu/Affinity/Valckenier/IPID_Callant%20Mobility_Valckenier_20210101_FR.pdf",
      }
    },
    "arag": {
      "terms": {
        "nl": "https://docs.callant.eu/affinity/atwork/AV_Rechtsbijstand_ARAG_NL_.pdf",
        "fr": "https://docs.callant.eu/affinity/atwork/CG_Protection Juridique_ARAG_FR.pdf",
      },
      "ipid": {
        "nl": "https://docs.callant.eu/affinity/atwork/IPID_Rechtsbijstand_Particulier Basics_ARAG_NL.pdf",
        "fr": "https://docs.callant.eu/affinity/atwork/IPID_Protection Juridique_Particulier Basics_ARAG_FR.pdf",
      }
    },
  }
  let ipidLink = docsData?.[affinity]?.ipid?.[i18n.language];
  let termsLink = docsData?.[affinity]?.terms?.[i18n.language];
  if (!ipidLink || !termsLink) {
    ipidLink = docsData?.callant?.ipid?.[i18n.language];
    termsLink = docsData?.callant?.terms?.[i18n.language];
  }
  let ipidLinkLegal = docsData?.arag?.ipid?.[i18n.language];
  let termsLinkLegal = docsData?.arag?.terms?.[i18n.language];

  if (loading) {
    return (
      <LoadingContainer>
        <LoadingSpinner />
        <LoadingText>{i18n.t("Please wait")}</LoadingText>
      </LoadingContainer>
    );
  }
  return (
    <SplitContainer>
      <FlowLeftSideAvatar
        chatText={i18n.t(
          "Choose what deposit you want to take Depending on your cologne, your coverage will vary"
        )}
      />
      <RightSplit>
        <Title>{i18n.t("What guarantee do you wish to take?")}</Title>

        <PriceCalculatorContainer>
          <FlowPriceCalculatorOption
            id="civil_liability"
            key="civil_liability"
            title={i18n.t("Civil liablity")}
            ipidLink={ipidLink}
            termsLink={termsLink}
            onChange={handleOptionChange}
            mandatory={true}
            price={handleSubPrices("civil_liability")}
            helperText={i18n.t("civil_liability_description")}
            lightScooter="lightScooter"
          />
          <FlowPriceCalculatorOption
            id="legal"
            key="legal"
            title={i18n.t("Legal aid")}
            ipidLink={ipidLinkLegal}
            termsLink={termsLinkLegal}
            onChange={handleOptionChange}
            mandatory={false}
            price={handleSubPrices("legal")}
            helperText={i18n.t("legal_aid_description")}
            lightScooter="lightScooter"
          />
        </PriceCalculatorContainer>
        <CheckoutContainer>
          <TotalPrice>
            {i18n.t(`Total price`)}{" "}
            <span>
              {parseFloat(totalPremium).toLocaleString("nl-BE", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}{" "}
              EUR/
              {i18n.t("y")}
            </span>
          </TotalPrice>
          <Button
            affinity={affinity}
            onClick={() => {
              handleSubmit();
            }}
          >
            {i18n.t(`Next`)}
          </Button>
        </CheckoutContainer>
      </RightSplit>
    </SplitContainer>
  );
};
const Title = styled.h1`
  font-weight: 900;
  font-size: 1.65rem;
  letter-spacing: 1.19px;
  color: black;
`;

const CheckoutContainer = styled.div`
  display: flex;
`;

const TotalPrice = styled.p`
  font-size: 2rem;
  align-self: center;
  > span {
    font-weight: 700;
  }
`;

const PriceCalculatorContainer = styled.div`
  display: flex;
  flex-direction: column;
  div {
    margin-bottom: 1.5rem;
  }
`;

const LoadingText = styled.p`
  margin-top: 2rem;
  font-size: 2rem;
`;

const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Button = styled(ActionButton)`
  background-color: ${({ affinity }) =>
    affinity === "callant" ? "#94C11A" : "default"};
  width: 20rem;
  margin-left: auto;
  margin-top: auto;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const SplitContainer = styled.div`
  display: flex;
  height: 100%;

  @media (max-width: 1140px) {
    justify-content: center;
  }
`;

const RightSplit = styled.div`
  width: 50%;
  display: grid;
  grid-template-rows: 10% 80% 10%;
  padding: 3rem 4rem;

  height: 100%;

  @media (max-width: 768px) {
    width: 100%;
  }

  @media screen and (max-width: 900px) {
    padding: 3rem 2rem;
  }
`;

export default FlowPriceBikeLightScooterCalculator;
